import * as RadixRadioGroup from '@radix-ui/react-radio-group';
import { FC, ReactNode } from 'react';

import { sprinkles } from 'components/ds';

import * as styles from './index.css';

export type Props = {
  /**
   * Value to be checked
   */
  defaultValue?: string;
  /**
   * Prevents interacting with radios when true
   */
  disabled?: boolean;
  /**
   * Event handler called when value changes
   */
  onValueChange: (value: string) => void;
  /**
   * Controlled value of the item to check
   */
  value?: string;
  /**
   * Array of values to render radio items
   */
  values: string[];
  /**
   * Transform the value before displaying it to the suer
   */
  renderValue?: (value: string) => ReactNode;
};

export const EmbedRadioGroup: FC<Props> = ({
  defaultValue,
  disabled,
  onValueChange,
  values,
  value,
  renderValue,
}) => (
  <RadixRadioGroup.Root
    className={styles.root}
    defaultValue={defaultValue}
    disabled={disabled}
    onValueChange={onValueChange}
    orientation={'vertical'}
    value={value}>
    {values.map((value, i) => (
      <div
        className={sprinkles({
          flexItems: 'alignCenter',
          justifyContent: 'flex-start',
          cursor: 'pointer',
        })}
        data-testid={value}
        key={value}>
        <RadixRadioGroup.Item className={styles.item} id={`radio${i}`} value={value}>
          <RadixRadioGroup.Indicator className={styles.indicator} />
        </RadixRadioGroup.Item>
        <label className={styles.label} htmlFor={`radio${i}`}>
          {renderValue ? renderValue(value) : value}
        </label>
      </div>
    ))}
  </RadixRadioGroup.Root>
);
