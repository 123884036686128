// eslint-disable-next-line import/no-named-as-default
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import deExportOptions from 'locales/de/ExportOptions.json';
import enExportOptions from 'locales/en/ExportOptions.json';

export const defaultNS = 'ExportOptions';

i18n.use(initReactI18next).init({
  lng: 'en',
  ns: ['ExportOptions'],
  defaultNS,
  resources: {
    en: {
      ExportOptions: enExportOptions,
    },
    de: {
      ExportOptions: deExportOptions,
    },
  },
  fallbackLng: 'en',
});

export default i18n;
