import cx from 'classnames';
import { FC } from 'react';

import { Icon, sprinkles, vars } from 'components/ds';
import { getChartIcon } from 'constants/chartIcons';
import { GLOBAL_STYLE_CLASSNAMES } from 'globalStyles';
import { DashboardVariableMap } from 'types/dashboardTypes';
import { DataPanel } from 'types/exploResource';
import { replaceVariablesInString } from 'utils/variableUtils';

import * as styles from './index.css';

interface Props {
  chartName: string;
  dp: DataPanel;
  isInLayout: boolean;
  onChartItemClicked: () => void;
  variables: DashboardVariableMap | null;
}

export const AddChartMenuItem: FC<Props> = ({
  chartName,
  dp,
  isInLayout,
  onChartItemClicked,
  variables,
}) => {
  return (
    <div
      className={cx(
        styles.chartMenuOption,
        GLOBAL_STYLE_CLASSNAMES.base.actionColor.interactionStates.dropdownItemHover,
      )}
      key={dp.id}
      onClick={onChartItemClicked}>
      <div className={sprinkles({ flexItems: 'alignCenter', gap: 'sp1', overflow: 'hidden' })}>
        {getChartIcon(
          dp.visualize_op.operation_type,
          24,
          vars.customTheme.colors.action,
          vars.customTheme.colors.interaction,
        )}
        <div className={styles.chartName} title={chartName}>
          {replaceVariablesInString(chartName, variables)}
        </div>
      </div>
      <div
        className={cx(styles.iconStyle, isInLayout ? styles.trashIconStyle : styles.plusIconStyle)}>
        <Icon name={isInLayout ? 'trash-can-reg' : 'plus'} size="sm" />
      </div>
    </div>
  );
};
