import cx from 'classnames';
import {
  ChangeEventHandler,
  DetailedHTMLProps,
  FocusEventHandler,
  forwardRef,
  InputHTMLAttributes,
  KeyboardEventHandler,
  ReactNode,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import { Icon, IconButton, Intent, Label, Spinner, sprinkles, Tooltip } from 'components/ds';
import { IconName } from 'components/ds/Icon';
import { DEFAULT_DELAY } from 'components/ds/Tooltip';
import { camelCase } from 'utils/standard';

import * as styles from './index.css';

type UncontrolledProps = {
  onChange?: never;
  value?: never;
  onEnter?: never;
  onBlur?: never;

  showInputButton?: boolean;
  defaultValue?: string;
  inputIcon?: IconName;
  handleIconButtonClicked?: (value: string) => void;
  onSubmit: (value: string) => void;
};

type ControlledProps = {
  defaultValue?: never;
  onSubmit?: never;
  showInputButton?: never;
  inputIcon?: never;
  handleIconButtonClicked?: never;

  value: string | undefined;
  onChange: (value: string) => void;
  onEnter?: (value: string) => void;
  onBlur?: (value: string) => void;
};

type StateProps = UncontrolledProps | ControlledProps;

type LabelProps = { text: string; infoText?: ReactNode; variableInput?: boolean };

interface InputProps
  extends Omit<
    DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
    'onChange' | 'onSubmit' | 'ref'
  > {
  descriptiveText?: string;
  errorText?: string;
  fillWidth?: boolean;
  leftIcon?: IconName;
  intent?: Intent;
  label?: string | LabelProps;
  keepFocus?: boolean;
  showLoadingSpinner?: boolean;
  renderErrorTextAsIcon?: boolean;
  /**
   * whether to track the input value locally.
   * this can improve performance, but can cause the rendered value to become stale
   * when only certain values are valid for this input.
   */
  useValuePropOnly?: boolean;
}

export type Props = InputProps & StateProps;

export const Input = forwardRef<HTMLInputElement, Props>(
  (
    {
      className,
      defaultValue,
      descriptiveText,
      disabled,
      errorText,
      fillWidth,
      leftIcon,
      onChange,
      onSubmit,
      placeholder,
      showInputButton,
      showLoadingSpinner,
      value,
      label,
      style,
      intent,
      onEnter,
      keepFocus,
      onKeyDown,
      onBlur,
      inputIcon,
      handleIconButtonClicked,
      renderErrorTextAsIcon,
      useValuePropOnly = false,
      ...props
    },
    ref,
  ) => {
    const initialValue = defaultValue || value || '';
    const [inputValue, setInputValue] = useState(initialValue);

    // Allows us to access the forwarded ref
    const inputRef = useRef<HTMLInputElement>(null);
    useImperativeHandle(ref, () => inputRef.current as HTMLInputElement);

    const currLabel = typeof label === 'string' ? label : label?.text;
    const name = currLabel ? camelCase(currLabel) : '';

    // For controlled inputs, update the input value when the value prop changes
    useEffect(() => {
      setInputValue(initialValue);
    }, [initialValue]);

    const handleChange: ChangeEventHandler<HTMLInputElement> = useCallback(
      (e) => {
        const newValue = e.target.value;
        setInputValue(newValue);
        onChange?.(newValue);
      },
      [onChange],
    );

    const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = useCallback(
      (e) => {
        onKeyDown?.(e);
        if (e.key !== 'Enter') return;

        inputRef.current?.blur(); // Manually blur since we may have prevented it before
        if (onSubmit) onSubmit(inputValue);
        else onEnter?.(inputValue);
      },
      [inputValue, onEnter, onKeyDown, onSubmit],
    );

    const hasChanges = inputValue !== initialValue;
    const handleActionClick = useCallback(() => {
      if (handleIconButtonClicked) return handleIconButtonClicked(inputValue);

      // Should only be called for uncontrolled inputs
      if (!onSubmit) return;

      inputRef.current?.blur(); // Manually blur since we prevented it before
      if (hasChanges) {
        onSubmit(inputValue);
      } else {
        setInputValue('');
        onSubmit('');
      }
    }, [handleIconButtonClicked, hasChanges, inputValue, onSubmit]);

    const handleBlur: FocusEventHandler<HTMLInputElement> = useCallback(
      (e) => {
        onSubmit?.(inputValue);
        onBlur?.(inputValue);
        if (keepFocus) e.target.focus();
      },
      [inputValue, keepFocus, onBlur, onSubmit],
    );

    return (
      <div
        className={cx(className, {
          [sprinkles({ width: 'fill' })]: fillWidth,
        })}
        style={style}>
        {currLabel == null || label == null ? null : (
          <Label
            className={cx(styles.label, {
              [styles.fakeLabel]: currLabel === '',
            })}
            forVariableInput={typeof label === 'string' ? undefined : label.variableInput}
            htmlFor={name}
            infoText={typeof label === 'string' ? undefined : label.infoText}>
            {currLabel}
          </Label>
        )}
        <div className={sprinkles({ position: 'relative', width: fillWidth ? 'fill' : undefined })}>
          <input
            {...props}
            className={cx(styles.base, {
              [styles.error]: !!errorText || intent === Intent.ERROR,
              [styles.success]: intent === Intent.SUCCESS,
              [styles.disabled]: disabled,
              [sprinkles({ paddingLeft: 'sp4' })]: leftIcon,
              [sprinkles({ paddingRight: 'sp3.5' })]:
                (showInputButton || showLoadingSpinner) && !disabled,
            })}
            disabled={disabled}
            id={name}
            onBlur={handleBlur}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            placeholder={placeholder}
            ref={inputRef}
            value={useValuePropOnly ? value : inputValue}
          />
          {leftIcon ? (
            <div className={styles.iconContainer}>
              <Icon className={styles.icon} name={leftIcon} />
            </div>
          ) : null}
          {renderErrorTextAsIcon && errorText ? (
            <Tooltip text={errorText}>
              <Icon
                className={cx(styles.errorIcon, {
                  [styles.errorIconWithInputButtonRightPosition]: !disabled && showInputButton,
                })}
                name="circle-exclamation"
              />
            </Tooltip>
          ) : null}
          {!disabled && showInputButton ? (
            <IconButton
              className={styles.inputButton}
              disabled={!initialValue && !inputValue}
              name={hasChanges ? inputIcon ?? 'enter-key' : inputIcon ?? 'cross'}
              onClick={handleActionClick}
              onMouseDown={(e) => e.preventDefault()} // Prevent input's onBlur from getting called
              tooltipProps={{
                text: hasChanges ? 'Save \u23CE' : 'Clear',
                delayDuration: DEFAULT_DELAY,
                side: 'bottom',
              }}
              variant="tertiary"
            />
          ) : null}
          {!disabled && showLoadingSpinner ? (
            <Spinner className={styles.inputSpinner} size="md" />
          ) : null}
        </div>
        {!renderErrorTextAsIcon && errorText ? (
          <span className={cx(styles.additionalText, sprinkles({ color: 'red' }))} role="alert">
            {errorText}
          </span>
        ) : descriptiveText ? (
          <span className={styles.additionalText}>{descriptiveText}</span>
        ) : null}
      </div>
    );
  },
);

Input.displayName = 'Input';
