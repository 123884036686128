import { PayloadAction, createSlice, isAnyOf } from '@reduxjs/toolkit';

import { getSessionExpirationSuccess } from 'actions/authAction';
import * as customerReportActions from 'actions/customerReportActions';
import { embedFetchDashboardActions } from 'actions/embedActions';
import { EmbeddedDashboardType } from 'components/EmbeddedDashboard/types';

interface EmbeddedAuthReducer {
  jwtExpiration?: number;
  embedType?: EmbeddedDashboardType;
}

const getInitialState = (): EmbeddedAuthReducer => ({});

const embeddedAuthSlice = createSlice({
  name: 'embeddedAuth',
  initialState: () => getInitialState(),
  reducers: {
    setEmbedType: (state, { payload }: PayloadAction<EmbeddedDashboardType>) => {
      state.embedType = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        isAnyOf(
          embedFetchDashboardActions.successAction,
          customerReportActions.listCustomerReportsSuccess,
          getSessionExpirationSuccess,
        ),
        (state, { payload }) => {
          state.jwtExpiration = payload.expiration;
        },
      )
      .addDefaultCase((state) => state);
  },
});

export const { setEmbedType } = embeddedAuthSlice.actions;
export const embeddedAuthReducer = embeddedAuthSlice.reducer;
