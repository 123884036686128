import { FC } from 'react';

import { Slider, Props as SliderProps } from 'components/ds/Slider';

import * as styles from './index.css';

export type EmbedSliderProps = SliderProps;

export const EmbedSlider: FC<EmbedSliderProps> = ({ ...props }) => {
  return (
    <Slider
      {...props}
      rangeClassName={styles.EmbedSliderRange}
      thumbClassName={styles.EmbedSliderThumb}
    />
  );
};

EmbedSlider.displayName = 'EmbedSlider';
