import { configureStore } from '@reduxjs/toolkit';

import {
  reportBuilderSharedMiddleware,
  reportBuilderEmbeddedDataMiddleware,
} from './middleware/reportBuilderDataMiddleware';
import rootReducer from './reducers/rootReducer';

export default () => {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat([
        reportBuilderSharedMiddleware,
        reportBuilderEmbeddedDataMiddleware,
      ]),
    preloadedState: {},
    devTools: false,
  });
};
