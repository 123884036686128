import produce from 'immer';

import { EmbedCustomer } from 'actions/teamActions';
import { SELECTABLE_CHARTS } from 'constants/dataConstants';
import {
  resetSecondaryRequests,
  setBlockedVariables,
  setDashboardVariables,
  updateDashboardVariables,
} from 'reducers/dashboardDataReducer';
import { setDashboardParam } from 'reducers/dashboardEditConfigReducer';
import { getArchetypeProperties } from 'reducers/selectors';
import { sendVariableUpdatedEventThunk } from 'reducers/thunks/customEventThunks';
import { DashboardElement, DashboardVariable, DashboardVariableMap } from 'types/dashboardTypes';
import { DashboardParam } from 'types/dashboardVersionConfig';
import { ResourceDataset } from 'types/exploResource';
import { getCustomerVariables } from 'utils/customerUtils';
import * as dashboardUtils from 'utils/dashboardUtils';
import * as extraVarUtils from 'utils/extraVariableUtils';
import { getDefaultVariableFromParam, maybeUpdateUrlParams } from 'utils/paramUtils';
import { cloneDeep, isEqual, keyBy } from 'utils/standard';

import { DashboardLayoutThunk } from '../dashboardLayoutThunks/types';

import { fetchDataAfterVariableChange, initializeDashboardDataThunk } from './requestLogicThunks';
import { DashboardDataThunk } from './types';
import { getDashboardConfig } from './utils';

type UpdateVariableData = {
  varName: string;
  newValue: DashboardVariable;
  options?: extraVarUtils.VariableSelectOptions;
};

/*
 * This is called when a variable is updated
 * Either by editable variable list or when color configs are changed
 */
export const updateVariableThunk =
  ({ varName, newValue, options }: UpdateVariableData): DashboardDataThunk =>
  (dispatch, getState) => {
    const variables = getState().dashboardData.variables;
    if (!variables || variables[varName] === newValue) return;

    const newVariables: DashboardVariableMap = { [varName]: newValue };
    extraVarUtils.applyExtraVariables(newVariables, varName, options);

    dispatch(updateDashboardVariables(newVariables));
    dispatch(fetchDataAfterVariableChange([varName]));
  };

/*
 * This is called when a variable is deleted
 * Either when a filter type is changed or dashboard params are changed
 */
export const deleteVariablesThunk =
  (varNames: string[]): DashboardLayoutThunk =>
  (dispatch, getState) => {
    if (varNames.length === 0) return;
    const variables = getState().dashboardData.variables;
    if (!variables) return;

    const newVariables = produce(variables, (draft) => {
      varNames.forEach((varName) => {
        if (varName in draft) delete draft[varName];
      });
    });
    dispatch(setDashboardVariables(newVariables));
  };

/*
 * This is called when a customer changes (should only happen in app)
 * Resets customer variables and initializes dashboard data fetching
 */
export const handleCustomerChangeThunk =
  (
    elements: DashboardElement[],
    datasets: Record<string, ResourceDataset>,
    customer: EmbedCustomer,
  ): DashboardDataThunk =>
  (dispatch, getState) => {
    const variables = cloneDeep(getState().dashboardData.variables) ?? {};
    const archetypeProperties = getArchetypeProperties(getState());

    Object.keys(variables).forEach((key) => {
      if (key.startsWith('user_group.')) delete variables[key];
      else if (key.startsWith('customer.')) delete variables[key];
      else if (key.startsWith('properties.')) delete variables[key];
      else if (archetypeProperties.has(key)) delete variables[key];
    });

    const newVariables = { ...variables, ...getCustomerVariables(customer, archetypeProperties) };

    dispatch(resetSecondaryRequests());
    dispatch(setDashboardVariables(newVariables));
    dispatch(setBlockedVariables({}));
    dispatch(initializeDashboardDataThunk(elements, datasets));
  };

type SetVariableData = {
  varName: string;
  value: DashboardVariable;
  elementId?: string;
  options?: extraVarUtils.VariableSelectOptions;
};

/*
 * This is called when any variable is set by user on a dashboard
 */
export const setVariableThunk =
  ({ varName, value, elementId, options }: SetVariableData): DashboardDataThunk =>
  (dispatch, getState) => {
    const state = getState();
    const config = getDashboardConfig(state);

    const { variables, blockedVariables } = state.dashboardData;
    if (!config || !variables) return null;

    const { elements } = config;

    const blockedElementId = dashboardUtils.elementBlockedOnApplyButton(elements, elementId);

    if (blockedElementId) {
      const newBlockedVariables = produce(blockedVariables, (draft) => {
        // produce doesn't actually set elementValue as undefined if no value was set for it
        // already and we need the distinction for setting vars as undefined after applied
        if (value === undefined && !(blockedElementId in draft)) {
          draft[blockedElementId] = 'temp';
        }

        // remove the blocked element if this is a revert
        if (variables[varName] === value) {
          delete draft[blockedElementId];
          extraVarUtils.clearExtraVariables(draft, varName);
        }
        // otherwise set to the new value
        else {
          draft[blockedElementId] = value;
          extraVarUtils.applyExtraVariables(draft, varName, options);
        }
        if (value === undefined) {
          dashboardUtils.resetDependentBlockedElements(blockedElementId, elements, draft);
        }
      });
      dispatch(setBlockedVariables(newBlockedVariables));
    } else {
      if (isEqual(variables[varName], value)) return;
      const newVariables = produce(variables, (draft) => {
        draft[varName] = value;
        extraVarUtils.applyExtraVariables(draft, varName, options);

        if (elementId && value === undefined) {
          dashboardUtils.resetDependedElements(elementId, elements, draft);
        }
      });
      dispatch(setDashboardVariables(newVariables));

      // No need to fetch data if its just a chart being shown/hidden
      if (!varName.endsWith('.hide')) {
        const element = elements.find((element) => element.name === varName);

        dispatch(
          fetchDataAfterVariableChange(
            element ? extraVarUtils.getVarNamesForElement(element) : [varName],
            options?.clearData,
          ),
        );
      }

      maybeUpdateUrlParams(
        state.dashboardInteractions.interactionsInfo.updateUrlParams,
        getArchetypeProperties(state),
        newVariables,
      );
    }

    dispatch(sendVariableUpdatedEventThunk(varName, value));
  };

export type SetVariablesData = {
  variables: { varName: string; value: DashboardVariable }[];
  options?: extraVarUtils.VariableSelectOptions;
};

export const setVariablesThunk =
  ({ variables, options }: SetVariablesData): DashboardDataThunk =>
  (dispatch) => {
    variables.forEach(({ varName, value }) => {
      dispatch(setVariableThunk({ varName, value, options }));
    });
  };

/*
 * This is called when apply filters is pressed.
 * Sets all variables from blocked variables and refreshes data
 */
export const applyFiltersThunk =
  (elementIds: string[]): DashboardDataThunk =>
  (dispatch, getState) => {
    const state = getState();
    const config = getDashboardConfig(state);
    const { variables, blockedVariables } = state.dashboardData;
    if (!config || !variables) return null;

    const varNamesToRefresh: string[] = [];
    const elementsById = keyBy(config.elements, (elem) => elem.id);

    const newVariables = cloneDeep(variables);
    const newBlockedVariables = cloneDeep(blockedVariables);

    elementIds.forEach((elemId) => {
      const element = elementsById[elemId];
      if (!element || !(elemId in newBlockedVariables)) return;

      const blockedValue = newBlockedVariables[elemId];
      varNamesToRefresh.push(...extraVarUtils.getVarNamesForElement(element));
      newVariables[element.name] = blockedValue;
      delete newBlockedVariables[elemId];

      extraVarUtils
        .getListOfExtraVarsForElement(element.name, element.element_type)
        .forEach((variable) => {
          if (!(variable in newBlockedVariables)) return;

          newVariables[variable] = newBlockedVariables[variable];
          delete newBlockedVariables[variable];
        });

      if (blockedValue !== undefined) return;
      dashboardUtils.resetDependedElements(elemId, config.elements, newVariables);
    });

    dispatch(setBlockedVariables(newBlockedVariables));
    dispatch(setDashboardVariables(newVariables));

    if (varNamesToRefresh.length === 0) return;
    dispatch(fetchDataAfterVariableChange(varNamesToRefresh));

    maybeUpdateUrlParams(
      state.dashboardInteractions.interactionsInfo.updateUrlParams,
      getArchetypeProperties(state),
      newVariables,
    );
  };

export const varRenameThunk =
  (renames: [string, string][]): DashboardLayoutThunk =>
  (dispatch, getState) => {
    if (renames.length === 0) return;
    const variables = getState().dashboardData.variables ?? {};

    const newVariables = produce(variables, (draft) => {
      renames.forEach(([oldName, newName]) => {
        if (!draft[oldName]) return;
        draft[newName] = draft[oldName];
        delete draft[oldName];
      });
    });

    dispatch(setDashboardVariables(newVariables));
  };

export const updateDashboardParamThunk =
  (oldParam: DashboardParam | null, newParam: DashboardParam): DashboardLayoutThunk =>
  (dispatch, getState) => {
    const state = getState();
    const variables = state.dashboardData.variables ?? {};

    const oldDefaultValue = oldParam ? getDefaultVariableFromParam(oldParam) : undefined;
    const newDefaultValue = getDefaultVariableFromParam(newParam);

    const newVariables = produce(variables, (draft) => {
      if (oldParam) {
        // If type is changed then delete the variable
        if (oldParam.type !== newParam.type) {
          if (oldParam.name in draft) delete draft[oldParam.name];
          // If name is changed then update variable name
        } else if (oldParam.name !== newParam.name) {
          draft[newParam.name] = draft[oldParam.name];
          if (oldParam.name in draft) delete draft[oldParam.name];
        }
      }
      // Set default value for variable if that changed
      if (oldDefaultValue !== newDefaultValue) draft[newParam.name] = newDefaultValue;
    });

    dispatch(setDashboardParam(newParam));
    dispatch(setDashboardVariables(newVariables));
  };

export const selectKpiThunk =
  (kpiId: string): DashboardDataThunk =>
  (dispatch, getState) => {
    const state = getState();
    const variables = state.dashboardData.variables ?? {};
    const config = getDashboardConfig(state);

    if (!config) return;

    const variablesChanged: string[] = [];
    const newVariables = produce(variables, (draft) => {
      Object.values(config.dataPanels).forEach(({ visualize_op, provided_id: providedId }) => {
        if (!SELECTABLE_CHARTS.has(visualize_op.operation_type)) return;
        if (providedId in draft) {
          variablesChanged.push(providedId);
          delete draft[providedId];
        } else if (providedId === kpiId) {
          variablesChanged.push(providedId);
          draft[providedId] = 'selected';
        }
      });
    });

    dispatch(setDashboardVariables(newVariables));
    dispatch(fetchDataAfterVariableChange(variablesChanged));

    maybeUpdateUrlParams(
      state.dashboardInteractions.interactionsInfo.updateUrlParams,
      getArchetypeProperties(state),
      newVariables,
    );
  };
