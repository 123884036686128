import { AnyAction, ThunkAction } from '@reduxjs/toolkit';

import { AllStates } from 'reducers/rootReducer';
import { OUTPUT_EVENT } from 'types/customEventTypes';
import { DashboardVariable } from 'types/dashboardTypes';
import { EditableSectionChart } from 'types/dashboardVersionConfig';
import { DatasetRow } from 'types/datasets';
import { editableSectionChartToEventChartTemplate } from 'utils/editableSectionUtils';

type Thunk = ThunkAction<void, AllStates, unknown, AnyAction>;

export const sendCustomJSEventWithRowDataThunk =
  (event: string, rowData?: DatasetRow): Thunk =>
  (dispatch) =>
    dispatch(sendEventThunk(event, { rowData }));

export const sendJSEventThunk =
  (event: string): Thunk =>
  (dispatch, getState) => {
    const analyticsMetadata = getState().analytics.analyticsMetadata;
    const detail = {
      customerName: analyticsMetadata?.customer_name,
      customerProvidedId: analyticsMetadata?.customer_provided_id,
    };
    dispatch(sendEventThunk(event, detail));
  };

export const sendVariableUpdatedEventThunk =
  (varName: string, newValue: DashboardVariable): Thunk =>
  (dispatch) =>
    dispatch(sendEventThunk(OUTPUT_EVENT.UPDATE_VARIABLE, { varName, newValue }));

export const sendDashboardLoadedEventThunk =
  (dashboardId: string, dashboardHeight: number): Thunk =>
  (dispatch) =>
    dispatch(sendEventThunk(OUTPUT_EVENT.DASHBOARD_LOADED, { dashboardId, dashboardHeight }));

export const sendDashboardReadyToLoadEventThunk =
  (dashboardId: string, dashboardHeight: number): Thunk =>
  (dispatch) =>
    dispatch(
      sendEventThunk(OUTPUT_EVENT.DASHBOARD_READY_TO_LOAD, { dashboardId, dashboardHeight }),
    );

export const sendDashboardUpdatedEventThunk =
  (dashboardId: string, dashboardHeight: number): Thunk =>
  (dispatch) =>
    dispatch(sendEventThunk(OUTPUT_EVENT.DASHBOARD_UPDATED, { dashboardId, dashboardHeight }));

/**
 * @param charts - List of charts in the editable section
 * @param availableChartTemplates - List of available charts to add to the editable section
 */
export const sendEditableSectionUpdatedEventThunk =
  (charts: EventChart[], availableChartTemplates: EventChartTemplate[]): Thunk =>
  (dispatch) =>
    dispatch(
      sendEventThunk(OUTPUT_EVENT.EDITABLE_SECTION_UPDATED, { charts, availableChartTemplates }),
    );

export type EventChart = {
  id: string;
  chartTemplateId: string;
  name: string;
  type: string;
};

export type EventChartTemplate = {
  // chart template id
  id: string;
  name: string;
  type: string;
};

/**
 * @param chartId - This is actually data panel ID, but to be user friendly, we use chartId
 * @param chartTemplate - Chart template this chart was created from
 */
export const sendAddEditableSectionChartEventThunk =
  (chartId: string, chartTemplate: EditableSectionChart): Thunk =>
  (dispatch) =>
    dispatch(
      sendEventThunk(OUTPUT_EVENT.EDITABLE_SECTION_CHART_ADDED, {
        chart: { ...editableSectionChartToEventChartTemplate(chartTemplate), id: chartId },
      }),
    );

/**
 * @param chartId - This is actually data panel ID, but to be user friendly, we use chartId
 * @param chartTemplate - Chart template this chart was created from
 */
export const sendRemoveEditableSectionChartEventThunk =
  (chartId: string, chartTemplate?: EditableSectionChart): Thunk =>
  (dispatch) =>
    dispatch(
      sendEventThunk(OUTPUT_EVENT.EDITABLE_SECTION_CHART_REMOVED, {
        ...(chartTemplate ? editableSectionChartToEventChartTemplate(chartTemplate) : {}),
        chartId,
      }),
    );

export const sendEventThunk =
  <T>(event: string, detail: T): Thunk =>
  (_, getState) => {
    const state = getState();
    const embedType = 'embeddedAuth' in state ? state.embeddedAuth.embedType : undefined;

    if (embedType === 'iframe') {
      window.parent.postMessage({ event: event, detail }, '*');
    } else if (embedType === 'embedded') {
      window.dispatchEvent(new CustomEvent(event, { detail }));
    }
  };
