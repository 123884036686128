import { PayloadAction, createSelector, createSlice, isAnyOf } from '@reduxjs/toolkit';

import { updateSelectedChart } from 'actions/dataPanelConfigActions';
import * as dptActions from 'actions/dataPanelTemplateAction';
import * as datasetActions from 'actions/datasetActions';
import { DatasetDataObject } from 'actions/datasetActions';
import * as embedActions from 'actions/embedActions';
import {
  FetchDashboardDatasetPreviewData,
  FetchDataPanelData,
  QueryDebuggingInformation,
} from 'actions/responseTypes';
import { EMPTY_FILTER_CONFIG, V2_VIZ_INSTRUCTION_TYPE } from 'constants/dataConstants';
import { OPERATION_TYPES, KPI_NUMBER_TREND_OPERATION_TYPES } from 'constants/types';
import * as RD from 'remotedata';
import { DashboardVariableMap } from 'types/dashboardTypes';
import {
  AdHocOperationInstructions,
  DataPanelData,
  initDataPanelData,
} from 'types/dataPanelTemplate';
import { dataTableRowCountPropertyId } from 'utils/fido/fidoInstructionShims';
import { getEmbeddoResponseFromFidoResponse, shimFidoPivotTable } from 'utils/fido/fidoShims';

import { DRILLDOWN_DATA_PANEL_ID } from './dashboardEditConfigReducer';
import { clearDashboardLayoutReducer } from './dashboardLayoutReducer';
import {
  fetchPrimaryDataThunk,
  fetchRowCountDataThunk,
  fetchSecondaryDataThunk,
} from './thunks/dashboardDataThunks/fetchDataPanelThunks';
import { fetchDatasetPreviewThunk } from './thunks/dashboardDataThunks/fetchDatasetPreviewThunks';
import {
  fetchFidoComputationData,
  fetchFidoViewData,
} from './thunks/dashboardDataThunks/fetchFidoDataThunks';

type DefaultDropdownInfo = {
  // Element Ids of the default dropdowns used to map over when dataset loading is done
  elementIds: string[];
  // Dataset Ids of elements above, used to know which datasets not to fetch when fetching
  // rest of dashboard data
  datasetIds: string[];
  // Used by middleware to know when to fire of thunk when all datasets are loaded
  datasetsLeftToLoad: string[];
  // Used for when a variable(s) is changed on how to handle variable after dataset is loaded
  // Whether to try to assign it a new value or not
  changedVarNames?: string[];
};

// This is used when dashboard data is refreshed when one or more variables are changed
type RefreshDataInfo = {
  // This is the list of data panel ids that we will be loading once datasets are done loading
  loadingDataPanelIds: string[];
  // The list of variables that were changed. Used to see which dataset and data panels to refetch
  changedVarNames: string[];
  // If there are non default dropdown updates this is how middleware keeps track of when to fire action
  datasetsLeftToLoad: string[];
  // Same as above, list of datasetIds to not fetch when fetching rest of dashboard data
  datasetIds?: string[];
  // Elements to update once the datasets above are loaded
  updatedElementIds?: string[];
};

type DataPanelId = string;
interface DashboardDataReducer {
  variables: DashboardVariableMap | null; // var_name -> variable
  blockedVariables: DashboardVariableMap; // element_id -> variable
  dataPanelData: Record<DataPanelId, DataPanelData | undefined>;
  datasetData: DatasetDataObject;
  dashboardLoaded: boolean;

  // Used to know when default dropdown queries are done
  defaultDropdownInfo: DefaultDropdownInfo;
  // Used when variables are changed
  refreshDataInfo: RefreshDataInfo;
}

const initialState: DashboardDataReducer = {
  variables: null,
  blockedVariables: {},
  dataPanelData: {},
  datasetData: {},
  dashboardLoaded: false,

  defaultDropdownInfo: { elementIds: [], datasetIds: [], datasetsLeftToLoad: [] },
  refreshDataInfo: { loadingDataPanelIds: [], changedVarNames: [], datasetsLeftToLoad: [] },
};

const dashboardDataSlice = createSlice({
  name: 'dashboardData',
  initialState,
  reducers: {
    setDashboardVariables: (state, { payload }: PayloadAction<DashboardVariableMap>) => {
      state.variables = payload;
    },
    setDashboardLoaded: (state, { payload }: PayloadAction<boolean>) => {
      state.dashboardLoaded = state.dashboardLoaded || payload;
    },
    updateDashboardVariables: (state, { payload }: PayloadAction<DashboardVariableMap>) => {
      if (!state.variables) state.variables = {};
      state.variables = { ...state.variables, ...payload };
    },
    setBlockedVariables: (state, { payload }: PayloadAction<DashboardVariableMap>) => {
      state.blockedVariables = payload;
    },
    setDefaultDropdownInfo: (
      state,
      { payload }: PayloadAction<Omit<DefaultDropdownInfo, 'datasetsLeftToLoad'>>,
    ) => {
      state.defaultDropdownInfo = { ...payload, datasetsLeftToLoad: payload.datasetIds };
    },
    setRefreshDataInfo: (
      state,
      { payload }: PayloadAction<Pick<RefreshDataInfo, 'loadingDataPanelIds' | 'changedVarNames'>>,
    ) => {
      state.refreshDataInfo = { ...payload, datasetsLeftToLoad: [] };
    },
    updateRefreshDataInfo: (
      state,
      { payload }: PayloadAction<Omit<RefreshDataInfo, 'changedVarNames'>>,
    ) => {
      state.refreshDataInfo.updatedElementIds = payload.updatedElementIds;
      state.refreshDataInfo.loadingDataPanelIds = payload.loadingDataPanelIds;
      state.refreshDataInfo.datasetsLeftToLoad = payload.datasetsLeftToLoad;
      state.refreshDataInfo.datasetIds = payload.datasetIds;
    },
    setDataPanelsLoading: (
      state,
      { payload }: PayloadAction<{ ids: string[]; loading: boolean; clearData?: boolean }>,
    ) => {
      payload.ids.forEach((dpId) => {
        const panelData = state.dataPanelData[dpId];
        if (panelData && !payload.clearData) panelData.loading = payload.loading;
        else state.dataPanelData[dpId] = initDataPanelData(payload.loading);
      });
    },
    updateAdHocOperationInstructions: (
      state,
      { payload }: PayloadAction<{ id: string; instructions: AdHocOperationInstructions }>,
    ) => {
      const panelData = state.dataPanelData[payload.id];
      if (panelData) panelData.adHocOperationInstructions = payload.instructions;
    },
    resetSecondaryRequests: (state) => {
      Object.values(state.dataPanelData).forEach((dp) => {
        if (dp) dp.outstandingSecondaryDataRequests = 0;
      });
    },
    clearDataPanelData: (state, { payload }: PayloadAction<string>) => {
      if (payload in state.dataPanelData) delete state.dataPanelData[payload];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(clearDashboardLayoutReducer, () => {
        return initialState;
      })
      .addCase(updateSelectedChart, (state, { payload }) => {
        if (V2_VIZ_INSTRUCTION_TYPE[payload.from] === V2_VIZ_INSTRUCTION_TYPE[payload.to]) return;
        state.dataPanelData[payload.id] = initDataPanelData();
      })
      .addCase(dptActions.updateDrilldownDataPanel, (state, { payload }) => {
        if (!payload) {
          if (!(DRILLDOWN_DATA_PANEL_ID in state.dataPanelData)) return;
          delete state.dataPanelData[DRILLDOWN_DATA_PANEL_ID];
        } else {
          const dataPanelData = initDataPanelData();
          dataPanelData.adHocOperationInstructions = payload.adHocInstructions;
          state.dataPanelData[DRILLDOWN_DATA_PANEL_ID] = dataPanelData;
        }
      })
      .addCase(fetchPrimaryDataThunk.pending, (state, { meta }) => {
        receiveDpRequest(state, meta.arg.postData, meta.requestId);
      })
      .addCase(fetchPrimaryDataThunk.rejected, (state, { meta, payload }) => {
        receiveDpError(state, meta.arg.postData.id, meta.requestId, payload?.error_msg);
      })
      .addCase(fetchPrimaryDataThunk.fulfilled, (state, { meta, payload }) => {
        receiveDpSuccess(state, meta.arg.postData.id, meta.requestId, payload);
      })
      .addCase(fetchFidoComputationData.pending, (state, { meta }) => {
        let panelData = state.dataPanelData[meta.arg.reducerArgs.dataPanelId];
        if (!panelData) panelData = initDataPanelData();

        if (!meta.arg.reducerArgs.isPrimaryRequest) {
          handleSecondaryDataRequest(state, meta.arg.reducerArgs.dataPanelId);
          return;
        }

        panelData.loading = true;
        panelData.loadingRequestId = meta.requestId;
        panelData.adHocOperationInstructions = {
          currentPage: meta.arg.reducerArgs.pageNumber ?? 1,
          sortInfo: meta.arg.reducerArgs.sortInfo,
          filterInfo: meta.arg.reducerArgs.filterInfo || { ...EMPTY_FILTER_CONFIG },
        };
        panelData.queryInformation = undefined;
        panelData.outstandingSecondaryDataRequests = 0;
        state.dataPanelData[meta.arg.reducerArgs.dataPanelId] = panelData;
      })
      .addCase(fetchFidoComputationData.fulfilled, (state, { meta, payload }) => {
        const panelData = getPanelDataIfShouldProcessDataPanelResult(
          state.dataPanelData,
          meta.arg.reducerArgs.dataPanelId,
          meta.arg.reducerArgs.primaryRequestId || meta.requestId,
        );
        if (!panelData) return;

        const { rows, schema, queryInformation } = getEmbeddoResponseFromFidoResponse(
          payload,
          meta.arg.reducerArgs.timezone,
        );

        if (meta.arg.reducerArgs.isPrimaryRequest) {
          panelData.loading = false;
          panelData.queryInformation = queryInformation;
          panelData.error = undefined;
          if (
            meta.arg.reducerArgs.visualizeOp.operation_type ===
            OPERATION_TYPES.VISUALIZE_PIVOT_TABLE
          ) {
            const shimmedInfo = shimFidoPivotTable(
              rows,
              schema,
              meta.arg.reducerArgs.visualizeOp.instructions.VISUALIZE_PIVOT_TABLE,
            );
            panelData.rows = shimmedInfo.rows;
            panelData.schema = shimmedInfo.schema;
          } else {
            panelData.rows = rows;
            panelData.schema = schema;
          }
        } else {
          panelData.outstandingSecondaryDataRequests = Math.max(
            panelData.outstandingSecondaryDataRequests - 1,
            0,
          );

          // TODO we REALLY need to get rid of the concept of secondary data for something more
          // opinionated
          if (
            KPI_NUMBER_TREND_OPERATION_TYPES.has(meta.arg.reducerArgs.visualizeOp.operation_type)
          ) {
            // secondary data requests for KPI trends and should always come back with a single row
            // and single column. For that data, we just want to override the already present value, if set, so that
            // we properly handle cases where no data is present. This is obviously pretty hackey, and we should
            // consider reworking the secondary data concept in the future when we're done with this migration
            if (panelData.secondaryData === undefined || panelData.secondaryData.length === 0) {
              panelData.secondaryData = rows;
            } else {
              // this is all VERY tied to the kpi trend implementation. If any new panels require secondary data,
              // this will HAVE to change
              const expectedAggColumnName = meta.arg.reducerArgs.secondaryDataAgg ?? '';
              const existingDataRow = panelData.secondaryData.find(
                (dataRow) => Object.keys(dataRow)[0] === expectedAggColumnName,
              );

              if (existingDataRow && expectedAggColumnName) {
                // there's a chance that the row is empty (for no data), in that case just use 0
                existingDataRow[expectedAggColumnName] = rows[0][expectedAggColumnName] ?? '0';
              } else {
                panelData.secondaryData = panelData.secondaryData.concat(rows);
              }
            }
          } else if (
            meta.arg.reducerArgs.visualizeOp.operation_type === OPERATION_TYPES.VISUALIZE_TABLE ||
            meta.arg.reducerArgs.visualizeOp.operation_type ===
              OPERATION_TYPES.VISUALIZE_PIVOT_TABLE
          ) {
            // tables have at most two secondary data requests, one to get the row count, which will
            // always return one row with one value, and one to get metrics for progress bars or gradients
            if (rows[0]?.[dataTableRowCountPropertyId] != null) {
              const totalResults = parseInt(Object.values(rows[0])[0].toString());
              panelData.totalRowCount = isNaN(totalResults)
                ? RD.Error('Error loading total results')
                : RD.Success(totalResults);
            } else {
              panelData.secondaryData = rows;
            }
          }
        }

        updateDashboardLoadState(state);
      })
      .addCase(fetchFidoComputationData.rejected, (state, { meta, error }) => {
        const panelData = getPanelDataIfShouldProcessDataPanelResult(
          state.dataPanelData,
          meta.arg.reducerArgs.dataPanelId,
          meta.arg.reducerArgs.primaryRequestId || meta.requestId,
        );
        if (!panelData) return;

        if (!meta.arg.reducerArgs.isPrimaryRequest) {
          handleSecondaryDataError(state, meta.arg.reducerArgs.dataPanelId);
          return;
        }
        panelData.loading = false;
        panelData.error = error.message ?? 'There was an error fetching the results';
        updateDashboardLoadState(state);
      })
      .addCase(fetchSecondaryDataThunk.pending, (state, { meta }) => {
        handleSecondaryDataRequest(state, meta.arg.id);
      })
      .addCase(fetchSecondaryDataThunk.fulfilled, (state, { payload, meta }) => {
        handleSecondaryDataSuccess(state, meta.arg.id, payload, meta.arg.is_box_plot);
      })
      .addCase(fetchSecondaryDataThunk.rejected, (state, { meta }) => {
        handleSecondaryDataError(state, meta.arg.id);
      })
      .addCase(fetchRowCountDataThunk.pending, (state, { meta }) => {
        const panelData = state.dataPanelData[meta.arg.id];
        if (panelData) panelData.totalRowCount = RD.Loading();
      })
      .addCase(fetchRowCountDataThunk.rejected, (state, { meta }) => {
        const panelData = state.dataPanelData[meta.arg.id];
        if (panelData) panelData.totalRowCount = RD.Error('Error loading row count');
      })
      .addCase(fetchRowCountDataThunk.fulfilled, (state, { meta, payload }) => {
        const panelData = state.dataPanelData[meta.arg.id];
        if (panelData) panelData.totalRowCount = RD.Success(payload._total_row_count);
      })
      .addCase(fetchDatasetPreviewThunk.fulfilled, (state, { meta, payload }) => {
        receiveDatasetSuccess(state, meta.arg.dataset_id, payload);
      })
      .addCase(fetchFidoViewData.fulfilled, (state, { meta, payload }) => {
        const { rows, schema, queryInformation } = getEmbeddoResponseFromFidoResponse(
          payload,
          meta.arg.body.timezone,
        );

        receiveDatasetSuccess(state, meta.arg.dataset.id, {
          dataset_preview: { _rows: rows, schema, _unsupported_operations: [] },
          query_information: queryInformation,
        });
      })
      .addCase(dptActions.fetchDataPanelError, (state, { payload }) => {
        receiveDpError(
          state,
          payload.postData.id,
          payload.postData.jobId,
          payload.error_msg,
          payload.query_information,
        );
      })
      .addCase(embedActions.embedFetchDataPanelError, (state, { payload }) => {
        receiveDpError(state, payload.postData.id, payload.postData.jobId, payload.error_msg);
      })
      .addCase(fetchDatasetPreviewThunk.rejected, (state, { meta }) => {
        receiveDatasetError(state, meta.arg.dataset_id);
      })
      .addCase(fetchFidoViewData.rejected, (state, { meta }) => {
        receiveDatasetError(state, meta.arg.dataset.id);
      })
      .addCase(fetchFidoViewData.pending, (state, { meta }) => {
        receiveDatasetRequest(state, meta.arg.dataset.id);
      })
      .addCase(fetchDatasetPreviewThunk.pending, (state, { meta }) => {
        receiveDatasetRequest(state, meta.arg.dataset_id);
      })

      .addMatcher(
        isAnyOf(
          datasetActions.fetchDashboardDatasetPreviewSuccess,
          embedActions.embedFetchDashboardDatasetPreviewSuccess,
        ),
        (state, { payload }) => {
          receiveDatasetSuccess(state, payload.postData.dataset_id, payload);
        },
      )
      .addMatcher(
        isAnyOf(
          datasetActions.fetchDashboardDatasetPreviewRequest,
          embedActions.embedFetchDashboardDatasetPreviewRequest,
        ),
        (state, { payload }) => {
          receiveDatasetRequest(state, payload.postData.dataset_id);
        },
      )
      .addMatcher(
        isAnyOf(
          datasetActions.fetchDashboardDatasetPreviewError,
          embedActions.embedFetchDashboardDatasetPreviewError,
        ),
        (state, { payload }) => {
          receiveDatasetError(state, payload.postData.dataset_id);
        },
      )
      .addMatcher(
        isAnyOf(embedActions.embedFetchDataPanelRequest, dptActions.fetchDataPanelRequest),
        (state, { payload }) => {
          receiveDpRequest(state, payload.postData, payload.postData.jobId);
        },
      )
      .addMatcher(
        isAnyOf(embedActions.embedFetchDataPanelSuccess, dptActions.fetchDataPanelSuccess),
        (state, { payload }) => {
          receiveDpSuccess(state, payload.postData.id, payload.postData.jobId, payload);
        },
      )
      .addMatcher(
        isAnyOf(embedActions.embedFetchSecondaryDataRequest, dptActions.fetchSecondaryDataRequest),
        (state, { payload }) => {
          handleSecondaryDataRequest(state, payload.postData.id);
        },
      )
      .addMatcher(
        isAnyOf(embedActions.embedFetchSecondaryDataSuccess, dptActions.fetchSecondaryDataSuccess),
        (state, { payload }) => {
          handleSecondaryDataSuccess(
            state,
            payload.postData.id,
            payload,
            payload.postData.is_box_plot,
          );
        },
      )
      .addMatcher(
        isAnyOf(embedActions.embedFetchSecondaryDataError, dptActions.fetchSecondaryDataError),
        (state, { payload }) => {
          handleSecondaryDataError(state, payload.postData.id);
        },
      )
      .addMatcher(
        isAnyOf(
          embedActions.embedFetchDataPanelRowCountRequest,
          dptActions.fetchDataPanelRowCountRequest,
        ),
        (state, { payload }) => {
          const panelData = state.dataPanelData[payload.postData.id];
          if (panelData) panelData.totalRowCount = RD.Loading();
        },
      )
      .addMatcher(
        isAnyOf(
          embedActions.embedFetchDataPanelRowCountError,
          dptActions.fetchDataPanelRowCountError,
        ),
        (state, { payload }) => {
          const panelData = state.dataPanelData[payload.postData.id];
          if (panelData) panelData.totalRowCount = RD.Error('Error loading row count');
        },
      )
      .addMatcher(
        isAnyOf(
          embedActions.embedFetchDataPanelRowCountSuccess,
          dptActions.fetchDataPanelRowCountSuccess,
        ),
        (state, { payload }) => {
          const panelData = state.dataPanelData[payload.postData.id];
          if (panelData) panelData.totalRowCount = RD.Success(payload._total_row_count);
        },
      );
  },
});

const handleSecondaryDataRequest = (state: DashboardDataReducer, id: string) => {
  let panelData = state.dataPanelData[id];
  if (!panelData) panelData = initDataPanelData();

  panelData.outstandingSecondaryDataRequests += 1;
  state.dataPanelData[id] = panelData;
};

const handleSecondaryDataError = (state: DashboardDataReducer, id: string) => {
  const panelData = state.dataPanelData[id];
  if (!panelData) return;

  panelData.outstandingSecondaryDataRequests = Math.max(
    panelData.outstandingSecondaryDataRequests - 1,
    0,
  );
  panelData.error = 'There was an error fetching the results';
  updateDashboardLoadState(state);
};

const handleSecondaryDataSuccess = (
  state: DashboardDataReducer,
  id: string,
  { data_panel_template, query_information }: FetchDataPanelData,
  isBoxPlot: boolean,
) => {
  const panelData = state.dataPanelData[id];
  if (!panelData) return;
  const { _rows, _source_type } = data_panel_template;

  panelData.outstandingSecondaryDataRequests = Math.max(
    panelData.outstandingSecondaryDataRequests - 1,
    0,
  );

  // KPI with no trend lines only make secondary requests.
  if (!panelData.loading && panelData.rows === undefined) {
    panelData.queryInformation = query_information;
    panelData.error = data_panel_template._error;
  }

  // For BoxPlots backed by a Redshift or MySQL DB, we need to fetch batches of metrics for different calc columns
  // one at a time. So we don't want to overwrite secondaryData, but append to it instead.
  const secondaryData =
    isBoxPlot && (_source_type === 'redshift' || _source_type === 'mysql')
      ? (panelData.secondaryData ?? []).concat(_rows ?? [])
      : _rows;

  panelData.secondaryData = secondaryData;
  updateDashboardLoadState(state);
};

const receiveDpError = (
  state: DashboardDataReducer,
  id: string,
  requestId: string,
  errorMsg?: string,
  queryInformation?: QueryDebuggingInformation,
) => {
  const panelData = state.dataPanelData[id];
  if (panelData?.loadingRequestId !== requestId) return;
  panelData.error = errorMsg || 'There was an error fetching the results';
  panelData.loading = false;
  panelData.queryInformation = queryInformation;
  updateDashboardLoadState(state);
};

const receiveDpRequest = (
  state: DashboardDataReducer,
  postData: dptActions.FetchDataPanelBody,
  requestId: string | undefined,
) => {
  let panelData = state.dataPanelData[postData.id];
  if (!panelData) panelData = initDataPanelData();

  panelData.loading = true;
  panelData.loadingRequestId = requestId;
  panelData.adHocOperationInstructions = {
    ...panelData.adHocOperationInstructions,
    currentPage: postData.page_number || 1,
    sortInfo: postData.sort_info,
    filterInfo: postData.filter_info || { ...EMPTY_FILTER_CONFIG },
  };
  panelData.queryInformation = undefined;
  state.dataPanelData[postData.id] = panelData;
};

const receiveDpSuccess = (
  state: DashboardDataReducer,
  id: string,
  requestId: string,
  { data_panel_template, query_information }: FetchDataPanelData,
) => {
  const panelData = state.dataPanelData[id];

  if (panelData?.loadingRequestId !== requestId) return;

  panelData.loading = false;
  panelData.rows = data_panel_template._rows;
  panelData.error = data_panel_template._error;

  panelData.schema = data_panel_template._schema;
  panelData.sourceType = data_panel_template._source_type;
  panelData.unsupportedOperations = data_panel_template._unsupported_operations;
  panelData.queryInformation = query_information;
  updateDashboardLoadState(state);
};

const updateDashboardLoadState = (state: DashboardDataReducer) => {
  if (state.dashboardLoaded) return;
  const hasDataPanelLoading = Object.values(state.dataPanelData).some(
    (data) => data?.loading || !!data?.outstandingSecondaryDataRequests,
  );
  const hasDatasetDataLoading = Object.values(state.datasetData).some((data) => data?.loading);
  if (!hasDataPanelLoading && !hasDatasetDataLoading) state.dashboardLoaded = true;
};

const receiveDatasetRequest = (state: DashboardDataReducer, id: string) => {
  state.datasetData[id] = {
    ...state.datasetData[id],
    loading: true,
  };
};

const receiveDatasetSuccess = (
  state: DashboardDataReducer,
  id: string,
  { dataset_preview, query_information }: FetchDashboardDatasetPreviewData,
) => {
  receiveDatasetResult(state, id);
  state.datasetData[id] = {
    schema: dataset_preview.schema,
    rows: dataset_preview._rows,
    unsupportedOperations: dataset_preview._unsupported_operations,
    loading: false,
    error: undefined,
    queryInformation: query_information,
  };
  updateDashboardLoadState(state);
};

const receiveDatasetError = (state: DashboardDataReducer, id: string) => {
  receiveDatasetResult(state, id);
  state.datasetData[id] = {
    ...state.datasetData[id],
    loading: false,
    error: `There was an error loading dataset ${id}`,
  };
  updateDashboardLoadState(state);
};
const receiveDatasetResult = (state: DashboardDataReducer, datasetId: string) => {
  const defaultIdx = state.defaultDropdownInfo.datasetsLeftToLoad.indexOf(datasetId);
  if (defaultIdx >= 0) state.defaultDropdownInfo.datasetsLeftToLoad.splice(defaultIdx, 1);
  const refreshIdx = state.refreshDataInfo.datasetsLeftToLoad.indexOf(datasetId);
  if (refreshIdx >= 0) state.refreshDataInfo.datasetsLeftToLoad.splice(refreshIdx, 1);
};

const getPanelDataIfShouldProcessDataPanelResult = (
  panelDataMap: Record<string, DataPanelData | undefined>,
  dataPanelId: string,
  primaryRequestId: string,
) => {
  const panelData = panelDataMap[dataPanelId];

  if (panelData && panelData.loadingRequestId === primaryRequestId) {
    return panelData;
  }

  return undefined;
};

export const {
  setDashboardVariables,
  setBlockedVariables,
  setDefaultDropdownInfo,
  updateDashboardVariables,
  setRefreshDataInfo,
  updateRefreshDataInfo,
  updateAdHocOperationInstructions,
  setDataPanelsLoading,
  resetSecondaryRequests,
  setDashboardLoaded,
  clearDataPanelData,
} = dashboardDataSlice.actions;

export const dashboardDataReducer = dashboardDataSlice.reducer;

export const isDataPanelLoading = createSelector(
  (state: DashboardDataReducer) => state.dataPanelData,
  (_: DashboardDataReducer, id: string) => id,
  (dataPanelData, dpId) => {
    const data = dataPanelData[dpId];
    if (!data) return true;
    return data.loading || !!data.outstandingSecondaryDataRequests;
  },
);
