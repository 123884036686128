import { ThemeProvider, StylesProvider, createGenerateClassName } from '@material-ui/core/styles';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Provider } from 'react-redux';

import 'utils/globalSetupUtils';
import EmbeddedDashboard from 'components/EmbeddedDashboard';
import { baseThemeName } from 'components/ds';
import theme from 'theme';
import { VIEW_MODE } from 'types/dashboardTypes';

import configureStore from './store';

const generateClassName = createGenerateClassName({
  productionPrefix: 'explo-embed',
});

const MainApp = ({
  id,
  customerToken,
  dashboardEmbedId,
  isProduction,
  environment,
  variables,
  updateUrlParams,
  customStyles,
  refreshMinutes,
  localeCode,
  currencyCode,
  timezone,
  analyticsProperties,
  embedJwt,
  dashboardTheme,
  disableEditableSectionEditing,
  hideEditableSection,
  hideEditableSectionEditControls,
}) => {
  const parsedVariables = variables === undefined ? {} : JSON.parse(variables);
  const parsedStyles = customStyles === undefined ? {} : JSON.parse(customStyles);
  const parsedAnalyticsProperties =
    analyticsProperties === undefined ? {} : JSON.parse(analyticsProperties);

  return (
    <div className="explo-embed">
      <div
        className={baseThemeName}
        style={{ all: 'initial', boxSizing: 'border-box', font: 'inherit', lineHeight: 'normal' }}>
        <Provider store={configureStore()}>
          <StylesProvider generateClassName={generateClassName}>
            <ThemeProvider theme={theme}>
              <DndProvider backend={HTML5Backend}>
                <EmbeddedDashboard
                  analyticsProperties={parsedAnalyticsProperties}
                  currencyCode={currencyCode}
                  customStyles={parsedStyles}
                  customerToken={customerToken}
                  dashboardEmbedId={dashboardEmbedId ?? ''}
                  dashboardTheme={dashboardTheme}
                  disableEditableSectionEditing={disableEditableSectionEditing}
                  embedJwt={embedJwt}
                  embedType="embedded"
                  embeddedVariables={parsedVariables}
                  environment={environment}
                  hideEditableSection={hideEditableSection}
                  hideEditableSectionEditControls={hideEditableSectionEditControls}
                  id={id}
                  isProduction={isProduction}
                  localeCode={localeCode}
                  refreshMinutes={refreshMinutes}
                  timezone={timezone}
                  updateUrlParams={updateUrlParams === 'true'}
                  viewMode={VIEW_MODE.default}
                />
              </DndProvider>
            </ThemeProvider>
          </StylesProvider>
        </Provider>
      </div>
    </div>
  );
};

export default MainApp;
