import { DatasetDataObject } from 'actions/datasetActions';
import { NUMBER_TYPES } from 'constants/dataConstants';
import {
  DropdownOption,
  INPUT_TYPE,
  SelectedDropdownInputItem,
  SortOption,
  SortOrder,
} from 'constants/types';
import { getAxisNumericalValue } from 'pages/dashboardPage/charts/utils';
import { DropdownValuesConfig } from 'types/dashboardTypes';
import { sortByValues } from 'utils/sortUtils';
import { uniqueId } from 'utils/standard';

export const constructOptions = (
  valuesConfig: DropdownValuesConfig,
  datasetData: DatasetDataObject,
): SelectedDropdownInputItem[] => {
  if (valuesConfig.valuesSource === INPUT_TYPE.MANUAL) {
    return constructManualOptions(valuesConfig);
  } else if (valuesConfig.valuesSource === INPUT_TYPE.QUERY) {
    return constructQueryOptions(valuesConfig, datasetData);
  }

  return [];
};

export const constructManualOptions = (
  valuesConfig: DropdownValuesConfig,
): SelectedDropdownInputItem[] => {
  let values: (string | number)[] = [];
  let displays: string[] = [];

  try {
    values = JSON.parse(valuesConfig.manualValues);
    if (!Array.isArray(values)) return [];
  } catch {
    return [];
  }

  try {
    const displaysJson = JSON.parse(valuesConfig.manualDisplays);
    if (Array.isArray(displaysJson)) displays = displaysJson;
  } catch {
    return [];
  }

  return values.map((value, i) => ({
    id: `option-${i}`,
    value: value,
    name: displays[i] || String(value),
  }));
};

const constructQueryOptions = (
  valuesConfig: DropdownValuesConfig,
  datasetData: DatasetDataObject,
): SelectedDropdownInputItem[] => {
  const { queryTable, queryValueColumn, queryDisplayColumn, querySortOption, querySortByValue } =
    valuesConfig;
  if (!queryTable || !queryValueColumn) return [];
  const datasetDatum = datasetData[queryTable.id];
  const datasetRows = datasetDatum?.rows;
  if (!datasetDatum || !datasetRows?.length) return [];

  const existingValues = new Set<string | number>();
  const options = datasetRows.reduce<DropdownOption[]>((acc, row) => {
    // Default missing values to null instead of undefined. FIDO strips null values from the
    // returned data and missing fields should be interpreted as SQL null since there is no
    // concept of undefined.
    const value = row[queryValueColumn.name] ?? null;
    if (existingValues.has(value)) return acc;
    existingValues.add(value);

    const name = (queryDisplayColumn && row[queryDisplayColumn.name]) || value;
    const option: DropdownOption = {
      id: uniqueId('dash_dropdown_elem'),
      value,
      name: String(name),
    };
    return [...acc, option];
  }, []);

  if (!querySortOption || querySortOption === SortOption.DEFAULT) return options;

  const columnName = (!querySortByValue && queryDisplayColumn?.name) || queryValueColumn.name;
  const type = datasetDatum?.schema?.find((column) => column.name === columnName)?.type;
  return sortByValues(
    options,
    (option) => {
      const value = querySortByValue ? option.value : option.name;
      if (type && NUMBER_TYPES.has(type)) return getAxisNumericalValue(value);
      return value;
    },
    querySortOption === SortOption.ASC ? SortOrder.ASC : SortOrder.DESC,
  );
};
