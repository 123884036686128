import { DateTime } from 'luxon';

import { Timezones } from 'constants/dashboardConstants';
import { DATE_TYPES } from 'constants/dataConstants';
import { DATE_RELATIVE_OPTION } from 'constants/dataPanelEditorConstants';
import { CategoryChartColumnInfo, ColorColumnOption, KPIPeriodColumnInfo } from 'constants/types';
import { DateTimeRangeDashboardVariable } from 'types/dashboardTypes';
import {
  DATETIME_PIVOT_AGGS_SET,
  PeriodComparisonRangeTypes,
  PeriodRangeTypes,
  PivotAgg,
  TEXT_TREND_PERIOD_COMPARISON_RANGE_TYPES,
} from 'types/dateRangeTypes';
import { assertNever } from 'utils/typeUtils';

import { getTimezoneAwareDate } from './timezoneUtils';

/**
 * Returns the start and end date for the DateRelativeOption filter, which is configured
 * via the data panel config tab. Computes the previous/next offset calendar units of time.
 * This means that for weeks, months, and years, this computes the previous/next calendar
 * weeks (starting on Mondays), months, or years. For example, the next 2 weeks from
 * Tuesday August 15 2023, the start date would be Monday August 21, and the end date
 * would be EOD Sunday September 3.
 */
export const getDatesFromDateRelativeOption = (
  option: DATE_RELATIVE_OPTION,
  offset: number,
  isPrevious: boolean,
) => {
  const now = DateTime.now();

  let startDate = now;
  let endDate = now;
  switch (option) {
    case DATE_RELATIVE_OPTION.DAYS:
      if (isPrevious) {
        startDate = now.minus({ days: offset });
      } else {
        endDate = now.plus({ days: offset });
      }
      break;
    case DATE_RELATIVE_OPTION.WEEKS:
      if (isPrevious) {
        startDate = now.minus({ weeks: offset });
      } else {
        endDate = now.plus({ weeks: offset });
      }
      break;
    case DATE_RELATIVE_OPTION.MONTHS:
      if (isPrevious) {
        startDate = now.minus({ months: offset });
      } else {
        endDate = now.plus({ months: offset });
      }
      break;
    case DATE_RELATIVE_OPTION.YEARS:
      if (isPrevious) {
        startDate = now.minus({ years: offset });
      } else {
        endDate = now.plus({ years: offset });
      }
      break;
  }

  return { startDate: startDate.startOf('day'), endDate: endDate.endOf('day') };
};

/**
 * Given the current date, returns the end date of the period for a KPI trend. If this is a
 * custom range, then the user has provided an end date and we return that instead
 */
export const getPeriodEndDate = (periodCol: KPIPeriodColumnInfo, currentDate: DateTime) => {
  const periodRange = periodCol.periodRange;
  let datetime = currentDate;
  if (
    periodRange === PeriodRangeTypes.CUSTOM_RANGE ||
    periodRange === PeriodRangeTypes.DATE_RANGE_INPUT ||
    periodRange === PeriodRangeTypes.TIME_PERIOD_DROPDOWN ||
    periodRange === PeriodRangeTypes.CUSTOM_RANGE_VARIABLES
  ) {
    // TODO: Should validate customEndDate > customStartDate
    const customDateTime = DateTime.fromISO(periodCol.customEndDate ?? '');
    if (customDateTime.isValid) return customDateTime.endOf('day');
  } else if (periodRange === PeriodRangeTypes.PREVIOUS_MONTH) {
    datetime = datetime.minus({ month: 1 }).endOf('month');
  }

  return datetime.minus({ day: periodCol.trendDateOffset ?? 0 }).endOf('day');
};

/**
 * Given the current date, returns the start date of the period for a KPI trend. If this is a
 * customer range, then the user has provided a start date and we return that instead. If
 * this is calculating the comparison period's start date, then this offsets the returned date
 * by the period. For example, if the start date is last week, but the comparison period is
 * previous year, this returns last week minus one year.
 */
export const getPeriodStartDate = (
  periodCol: KPIPeriodColumnInfo,
  currentDate: DateTime,
  comparison?: PeriodComparisonRangeTypes,
) => {
  const periodRange = periodCol.periodRange;
  let datetime = currentDate;

  switch (periodRange) {
    case PeriodRangeTypes.LAST_7_DAYS:
      datetime = datetime.minus({ day: 6 });
      break;
    case PeriodRangeTypes.LAST_4_WEEKS:
      datetime = datetime.minus({ week: 4 });
      break;
    case PeriodRangeTypes.PREVIOUS_MONTH:
      datetime = datetime.minus({ month: 1 }).startOf('month');
      break;
    case PeriodRangeTypes.LAST_3_MONTHS:
      datetime = datetime.minus({ month: 3 });
      break;
    case PeriodRangeTypes.LAST_12_MONTHS:
      datetime = datetime.minus({ month: 12 });
      break;
    case PeriodRangeTypes.MONTH_TO_DATE:
      datetime = datetime.startOf('month');
      break;
    case PeriodRangeTypes.YEAR_TO_DATE:
      datetime = datetime.startOf('year');
      break;
    case PeriodRangeTypes.CUSTOM_RANGE:
    case PeriodRangeTypes.CUSTOM_RANGE_VARIABLES:
    case PeriodRangeTypes.DATE_RANGE_INPUT:
    case PeriodRangeTypes.TIME_PERIOD_DROPDOWN:
      // the current date has nothing to do with a range input, so set that to be
      // the end of the range, plus 1 to handle rounding issues below
      currentDate = DateTime.fromISO(periodCol.customEndDate ?? '').plus({ days: 1 });
      datetime = DateTime.fromISO(periodCol.customStartDate ?? '');
  }

  switch (comparison) {
    case PeriodComparisonRangeTypes.PREVIOUS_YEAR:
      datetime = datetime.minus({ year: 1 });
      break;
    case PeriodComparisonRangeTypes.PREVIOUS_MONTH:
      datetime = datetime.minus({ month: 1 });
      break;
    case PeriodComparisonRangeTypes.PREVIOUS_PERIOD:
      datetime = datetime.minus({ days: Math.floor(currentDate.diff(datetime, 'days').days) });
      break;
    default:
      break;
  }

  // don't need to do anything else if it's a time period
  if (periodRange === PeriodRangeTypes.TIME_PERIOD_DROPDOWN) return datetime;

  return datetime
    .minus({ day: periodCol.trendDateOffset ?? 0 })
    .set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
};

/**
 * Differs from the embeddo getPeriodStartDate and getPeriodEndDate because it's wrong
 *
 * @param periodCol
 * @param timezone
 */
export const getKpiDateRangeFIDO = (periodCol: KPIPeriodColumnInfo, timezone?: string) => {
  const currentDate = DateTime.now().setZone(timezone);

  // Don't offset custom ranges
  const periodRange = periodCol.periodRange;
  const isDropdown = periodRange === PeriodRangeTypes.TIME_PERIOD_DROPDOWN;
  const offset = isDropdown ? 0 : periodCol.trendDateOffset || 0;
  const offsetDate = currentDate.minus({ day: offset });

  let endDate = offsetDate.endOf('day');
  let startDate = offsetDate.startOf('day');

  switch (periodRange) {
    case PeriodRangeTypes.TODAY:
      break;
    case PeriodRangeTypes.LAST_7_DAYS:
      // Since endDate is end of day, it's the same as being 1 day later so startDate loses a day
      // i.e. If endDate is January 15 23:59:59, startDate should be January 9 00:00:00 which is a full 7 days between
      startDate = startDate.minus({ days: 7 }).plus({ days: 1 });
      break;
    case PeriodRangeTypes.LAST_4_WEEKS:
      startDate = startDate.minus({ week: 4 }).plus({ days: 1 });
      break;
    case PeriodRangeTypes.PREVIOUS_MONTH:
      endDate = endDate.minus({ month: 1 }).endOf('month');
      startDate = endDate.startOf('month'); // End date should be end of month
      break;
    case PeriodRangeTypes.LAST_3_MONTHS:
      startDate = startDate.minus({ month: 3 }).plus({ days: 1 });
      break;
    case PeriodRangeTypes.LAST_12_MONTHS:
      startDate = startDate.minus({ month: 12 }).plus({ days: 1 });
      break;
    case PeriodRangeTypes.MONTH_TO_DATE:
      startDate = startDate.startOf('month');
      break;
    case PeriodRangeTypes.YEAR_TO_DATE:
      startDate = startDate.startOf('year');
      break;
    case PeriodRangeTypes.CUSTOM_RANGE:
    case PeriodRangeTypes.DATE_RANGE_INPUT:
    case PeriodRangeTypes.TIME_PERIOD_DROPDOWN:
    case PeriodRangeTypes.CUSTOM_RANGE_VARIABLES: {
      // these shouldn't use endOf('day') because the time is either user provided or already set to end of day.
      // Also if these are invalid let that fail further down the line
      startDate = DateTime.fromISO(periodCol.customStartDate || '');
      endDate = DateTime.fromISO(periodCol.customEndDate || '');

      break;
    }
    default:
      assertNever(periodRange);
  }

  return { startDate, endDate };
};

/**
 * Calculates the period for a KPI trend. If a comparison range is passed, returns the
 * ENTIRE period, from the beginning of the comparison to the end of the current period
 */
export const getDateBetweenFilterValues = (
  periodCol: KPIPeriodColumnInfo,
  comparison?: PeriodComparisonRangeTypes,
) => {
  const currentDate = DateTime.now();

  // note that if a comparison is provided, then this is the start date of the
  // whole period, not just the current
  const startDate = getPeriodStartDate(periodCol, currentDate, comparison);
  const endDate = getPeriodEndDate(periodCol, currentDate);

  return { startDate, endDate };
};

export type KpiDateRanges = {
  currentPeriod: DateTimeRangeDashboardVariable;
  previousPeriod?: DateTimeRangeDashboardVariable;
};

export const getKpiDateRanges = (
  periodCol: KPIPeriodColumnInfo,
  comparison?: PeriodComparisonRangeTypes,
  timezone?: string,
): KpiDateRanges => {
  const { startDate: currentPeriodStartDate, endDate: currentPeriodEndDate } = getKpiDateRangeFIDO(
    periodCol,
    timezone,
  );

  const ranges: KpiDateRanges = {
    currentPeriod: { startDate: currentPeriodStartDate, endDate: currentPeriodEndDate },
  };

  if (comparison && TEXT_TREND_PERIOD_COMPARISON_RANGE_TYPES.has(comparison)) {
    /**
     * Currently TEXT_TREND_PERIOD_COMPARISON_RANGE_TYPES only has options that just need to take from custom start/end dates
     */
    ranges.previousPeriod = {
      startDate: DateTime.fromISO(periodCol.comparisonInfo?.customStartDate ?? ''),
      endDate: DateTime.fromISO(periodCol.comparisonInfo?.customEndDate ?? ''),
    };
  } else if (comparison && comparison !== PeriodComparisonRangeTypes.NO_COMPARISON) {
    let startDate = currentPeriodStartDate;
    let endDate = currentPeriodEndDate;

    const isTimePeriod = periodCol.periodRange === PeriodRangeTypes.TIME_PERIOD_DROPDOWN;

    switch (comparison) {
      case PeriodComparisonRangeTypes.PREVIOUS_MONTH: {
        endDate = currentPeriodEndDate.minus({ months: 1 });
        break;
      }
      case PeriodComparisonRangeTypes.PREVIOUS_YEAR: {
        endDate = currentPeriodEndDate.minus({ years: 1 });
        break;
      }
      case PeriodComparisonRangeTypes.PREVIOUS_PERIOD: {
        if (isTimePeriod) {
          // time periods are offset by milliseconds, not by days
          endDate = currentPeriodStartDate.minus({ milliseconds: 1 });
          break;
        } else {
          // re-setting the time values here and below because sometimes the
          // filter isn't supposed to use end of day, but that's handle
          // when the user selects an option
          endDate = currentPeriodStartDate.minus({ days: 1 }).set({
            hour: currentPeriodEndDate.hour,
            minute: currentPeriodEndDate.minute,
            second: currentPeriodEndDate.second,
            millisecond: currentPeriodEndDate.millisecond,
          });
          break;
        }
      }
    }

    // we need to use milliseconds for time period comparisons, but days for all others
    // to account for the fun that is daylight savings
    const periodDuration = currentPeriodEndDate.diff(currentPeriodStartDate, [
      'milliseconds',
      'days',
    ]);

    if (isTimePeriod) {
      startDate = endDate.minus({ milliseconds: periodDuration.milliseconds });
    } else {
      startDate = endDate.minus({ days: Math.floor(periodDuration.days) }).set({
        hour: currentPeriodStartDate.hour,
        minute: currentPeriodStartDate.minute,
        second: currentPeriodStartDate.second,
        millisecond: currentPeriodStartDate.millisecond,
      });
    }

    ranges.previousPeriod = {
      startDate,
      endDate,
    };
  }

  return ranges;
};

export interface DateRange {
  startDate: DateTime;
  endDate: DateTime;
}

export const getDateRangeExampleForDropdown = (dateRange: DateRange) =>
  `(${getTimezoneAwareDate(
    dateRange.startDate.setZone(Timezones.UTC, { keepLocalTime: true }).toISO(),
  ).toLocaleString()} - ${getTimezoneAwareDate(
    dateRange.endDate.setZone(Timezones.UTC, { keepLocalTime: true }).toISO(),
  ).toLocaleString()})`;

// if not a Date Type, this function will return original value since it doesn't need to parse
export const parseVariableSelectDateRanges = (
  variable: string | undefined,
  columnInfo: CategoryChartColumnInfo | ColorColumnOption | undefined,
): DateRange | string | undefined => {
  if (
    !variable ||
    !DATE_TYPES.has(columnInfo?.column.type || '') ||
    !DATETIME_PIVOT_AGGS_SET.has(columnInfo?.bucket?.id || '')
  ) {
    return variable;
  }

  const startDate = DateTime.fromMillis(parseInt(variable));

  let endDate;
  switch (columnInfo?.bucket?.id) {
    case PivotAgg.DATE_HOUR:
      endDate = startDate.endOf('hour');
      break;
    case PivotAgg.DATE_DAY:
      endDate = startDate.endOf('day');
      break;
    case PivotAgg.DATE_WEEK:
      endDate = startDate.endOf('week');
      break;
    case PivotAgg.DATE_MONTH:
      endDate = startDate.endOf('month');
      break;
    case PivotAgg.DATE_QUARTER:
      endDate = startDate.endOf('quarter');
      break;
    case PivotAgg.DATE_YEAR:
      endDate = startDate.endOf('year');
      break;
  }
  if (!endDate) return variable;
  return { startDate, endDate };
};
