import cx from 'classnames';
import { FC, MouseEventHandler } from 'react';

import { sprinkles, Icon } from 'components/ds';
import { IconName } from 'components/ds/Icon';
import { GLOBAL_STYLE_CLASSNAMES } from 'globalStyles';
import { embedSprinkles } from 'globalStyles/sprinkles.css';

import * as styles from './exportStyles.css';

interface ExportOptionItemProps {
  onClick: MouseEventHandler;
  option: string;
  icon: IconName;
  rightArrow?: boolean;
}

export const ExportOptionItem: FC<ExportOptionItemProps> = ({
  onClick,
  option,
  icon,
  rightArrow,
}) => {
  return (
    <div
      className={cx(
        styles.menuItem,
        GLOBAL_STYLE_CLASSNAMES.base.actionColor.interactionStates.dropdownItemHover,
      )}
      data-testid={option}
      onClick={onClick}>
      <div className={styles.menuItemLabelIcon}>
        <Icon className={sprinkles({ color: 'contentSecondary' })} name={icon} />
        <div className={embedSprinkles({ body: 'primary' })}>{option}</div>
      </div>
      {rightArrow ? (
        <Icon className={sprinkles({ color: 'contentTertiary' })} name="arrow-right" />
      ) : null}
    </div>
  );
};
