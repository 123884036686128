import { DateTime } from 'luxon';

import { Dataset } from 'actions/datasetActions';
import {
  COLOR_CATEGORY_FILTER_SUFFIX,
  NONE_CATEGORY_COLOR_VALUE,
} from 'constants/dashboardConstants';
import {
  DATE_TYPES,
  NUMBER_TYPES,
  STRING,
  VIZ_OPS_WITH_CATEGORY_SELECT_DRILLDOWN,
} from 'constants/dataConstants';
import {
  OPERATION_TYPES,
  SelectedDropdownInputItem,
  V2TwoDimensionChartInstructions,
} from 'constants/types';
import { DashboardVariable, DashboardVariableMap, DrilldownVariable } from 'types/dashboardTypes';
import { DataPanelTemplate } from 'types/dataPanelTemplate';
import {
  FilterOperator,
  FILTER_OPS_DATE_PICKER,
  FILTER_OPS_DATE_RANGE_PICKER,
  FILTER_OPS_NUMBER,
  FILTER_OPS_STRING,
} from 'types/filterOperations';
import { parseVariableSelectDateRanges } from 'utils/dateTimeUtils';

import { getColorColumn } from './colorColUtils';

export const COLOR_SUFFIX = '.color';
export const CATEGORY_SUFFIX = '.category';
export const DATE_START_SUFFIX = '.startDate';
export const DATE_END_SUFFIX = '.endDate';

export const findDrilldownValue = (
  variables: DashboardVariableMap,
  varId: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any | undefined => {
  const value = (id: string) => {
    return variables[id] as DrilldownVariable;
  };

  if (varId.includes(COLOR_SUFFIX)) {
    const id = varId.replace(COLOR_SUFFIX, '');
    return value(id)?.color;
  } else {
    const id = varId.replace(CATEGORY_SUFFIX, '');
    return value(id)?.category;
  }
};

export const isDrilldownVar = (filterVarId: string): boolean => {
  return filterVarId.includes(CATEGORY_SUFFIX) || filterVarId.includes(COLOR_SUFFIX);
};

export const filterDpsWithDrilldowns = (
  dataPanelTemplates: DataPanelTemplate[],
): DataPanelTemplate[] => {
  return dataPanelTemplates.filter(({ visualize_op: vizOp }) => {
    const { operation_type, instructions } = vizOp;
    const drilldownConfig = instructions.VISUALIZE_TABLE.drilldownConfig;
    if (VIZ_OPS_WITH_CATEGORY_SELECT_DRILLDOWN.has(operation_type)) {
      return instructions.V2_TWO_DIMENSION_CHART?.drilldown?.categorySelectEnabled;
    } else if (operation_type === OPERATION_TYPES.VISUALIZE_TABLE) {
      return (
        drilldownConfig?.drilldownEnabled &&
        (drilldownConfig?.drilldownColumn || drilldownConfig?.allColumns)
      );
    }

    return false;
  });
};

export const filterDataPanelsForValidDrilldownVarsBasedOnType = (
  dataPanels: DataPanelTemplate[],
  filterOperator: FilterOperator | undefined,
  datasets: Record<string, Dataset>,
  variableMap: DashboardVariableMap | null,
): SelectedDropdownInputItem[] => {
  let categoryDrilldowns: DataPanelTemplate[] = [];
  if (!filterOperator) return [];

  if (
    FILTER_OPS_DATE_PICKER.has(filterOperator) ||
    FILTER_OPS_DATE_RANGE_PICKER.has(filterOperator)
  ) {
    categoryDrilldowns = dataPanels.filter(({ visualize_op }) =>
      DATE_TYPES.has(
        visualize_op.instructions.V2_TWO_DIMENSION_CHART?.categoryColumn?.column.type ?? '',
      ),
    );
  } else if (FILTER_OPS_STRING.has(filterOperator)) {
    categoryDrilldowns = dataPanels.filter(
      ({ visualize_op }) =>
        visualize_op.instructions.V2_TWO_DIMENSION_CHART?.categoryColumn?.column.type === STRING,
    );
  } else if (FILTER_OPS_NUMBER.has(filterOperator)) {
    categoryDrilldowns = dataPanels.filter(({ visualize_op }) =>
      NUMBER_TYPES.has(
        visualize_op.instructions.V2_TWO_DIMENSION_CHART?.categoryColumn?.column.type ?? '',
      ),
    );
  }

  // for now, since color & table columns can have multiple different types, we just allow them to choose a DPT
  // with color if there are any color columns - the user has to make sure the types match up on their own
  const tableAndColorDrilldowns: SelectedDropdownInputItem[] = [];
  dataPanels.forEach((dp) => {
    const { operation_type: operationType, instructions } = dp.visualize_op;
    if (operationType === OPERATION_TYPES.VISUALIZE_TABLE) {
      const drilldownConfig = instructions.VISUALIZE_TABLE.drilldownConfig;
      if (!drilldownConfig?.drilldownEnabled || !datasets[dp.table_id]) return;
      const schema = datasets[dp.table_id].schema ?? [];

      if (drilldownConfig.drilldownColumn) {
        const column = schema.find((col) => col.name === drilldownConfig.drilldownColumn);
        if (!checkIfColIsValid(column?.type, filterOperator)) return;

        tableAndColorDrilldowns.push({ id: dp.provided_id, name: dp.provided_id });
      } else if (drilldownConfig.allColumns) {
        schema.forEach((col) => {
          if (!checkIfColIsValid(col.type, filterOperator)) return;

          const drilldownVar = `${dp.provided_id}.${col.name}`;
          tableAndColorDrilldowns.push({ id: drilldownVar, name: drilldownVar });
        });
      }
    } else if (instructions.V2_TWO_DIMENSION_CHART?.colorColumnOptions?.length) {
      const selectedColorColName = variableMap
        ? (variableMap[dp.provided_id + COLOR_CATEGORY_FILTER_SUFFIX] as string)
        : '';
      const selectedColorCol = getColorColumn(
        instructions.V2_TWO_DIMENSION_CHART,
        selectedColorColName,
      );
      tableAndColorDrilldowns.push(
        ...generateDrilldownVarsForColumnType(
          dp.provided_id,
          selectedColorCol?.column.type ?? '',
          COLOR_SUFFIX,
          filterOperator,
        ),
      );
    }
  });

  const newCatDrilldowns: SelectedDropdownInputItem[] = [];
  categoryDrilldowns.forEach((dataPanel) => {
    newCatDrilldowns.push(
      ...generateDrilldownVarsForColumnType(
        dataPanel.provided_id,
        dataPanel.visualize_op.instructions.V2_TWO_DIMENSION_CHART?.categoryColumn?.column.type ??
          '',
        CATEGORY_SUFFIX,
        filterOperator,
      ),
    );
  });
  return tableAndColorDrilldowns.concat(newCatDrilldowns);
};

const generateDrilldownVarsForColumnType = (
  dataPanelId: string,
  columnType: string,
  baseSuffix: string,
  filterOperator: FilterOperator,
): SelectedDropdownInputItem[] => {
  const baseDrilldownVar = dataPanelId + baseSuffix;
  if (
    DATE_TYPES.has(columnType) &&
    // special case: for ranges we just return the default (a single variable)
    !FILTER_OPS_DATE_RANGE_PICKER.has(filterOperator)
  ) {
    return [
      {
        id: baseDrilldownVar + DATE_START_SUFFIX,
        name: baseDrilldownVar + DATE_START_SUFFIX,
      },
      {
        id: baseDrilldownVar + DATE_END_SUFFIX,
        name: baseDrilldownVar + DATE_END_SUFFIX,
      },
    ];
  }
  return [
    {
      id: baseDrilldownVar,
      name: baseDrilldownVar,
    },
  ];
};

const checkIfColIsValid = (
  colType: string | undefined,
  filterOperator: FilterOperator,
): boolean => {
  if (!colType) return false;
  if (FILTER_OPS_DATE_PICKER.has(filterOperator)) {
    return DATE_TYPES.has(colType);
  } else if (FILTER_OPS_NUMBER.has(filterOperator)) {
    return NUMBER_TYPES.has(colType);
  } else if (FILTER_OPS_STRING.has(filterOperator)) {
    return colType === STRING;
  }
  return false;
};

// Retrieves the drilldown value for a chart variable, parsing datetime values for comparison with chart clicks.
// Since the chart's x-point represents the start date, only the start date needs checking.
// This function facilitates determining if a section of the chart has been selected for drilldown.
const getDrilldownChartValue = (drilldownChartVariable: DashboardVariable): DashboardVariable =>
  drilldownChartVariable &&
  typeof drilldownChartVariable === 'object' &&
  'startDate' in drilldownChartVariable &&
  DateTime.isDateTime(drilldownChartVariable['startDate'])
    ? drilldownChartVariable['startDate'].valueOf()
    : drilldownChartVariable;

export const getDrilldownChartVariable = (
  variables: DashboardVariableMap,
  instructions: V2TwoDimensionChartInstructions | undefined,
  dataPanelProvidedId: string,
) => {
  const drilldownVariables = variables[dataPanelProvidedId] as DrilldownVariable | undefined;
  if (!instructions?.drilldown?.categorySelectEnabled || !drilldownVariables) return undefined;

  const transformedVariables = Object.entries(drilldownVariables).reduce((acc, [key, value]) => {
    acc[key as keyof DrilldownVariable] = getDrilldownChartValue(value);
    return acc;
  }, {} as DrilldownVariable);

  return transformedVariables;
};

/**
 * Iterates through the given property names and retrieves the leaf value of the given value object.
 * Returns null or undefined if a property within the property names does not exist on the object.
 */
export function accessLeafDrilldownVariableProperty(
  propertyNames: string[],
  // TODO(zifanxiang): Find a way to enforce the typing here.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any {
  return accessLeafDrilldownVariablePropertyHelper(
    /* propertyNameIndex= */ 0,
    propertyNames,
    value,
  );
}

function accessLeafDrilldownVariablePropertyHelper(
  propertyNameIndex: number,
  propertyNames: string[],
  // TODO(zifanxiang): Find a way to enforce the typing here.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any {
  if (!value) {
    return value;
  }
  if (propertyNameIndex === propertyNames.length) {
    return value;
  }

  const nextValue = value[propertyNames[propertyNameIndex]];
  return accessLeafDrilldownVariablePropertyHelper(propertyNameIndex + 1, propertyNames, nextValue);
}

export const setCategorySelect = (
  dataPanelProvidedId: string,
  variables: DashboardVariableMap,
  category: string,
  subCategory?: string,
  selectedColorColName?: string,
  setVariableFn?: (value: DashboardVariable) => void,
  instructions?: V2TwoDimensionChartInstructions,
) => {
  if (!setVariableFn || !instructions?.categoryColumn) return;

  const { categoryColumn } = instructions;
  const drilldownVar = getDrilldownChartVariable(variables, instructions, dataPanelProvidedId);
  const colorColumnOption = getColorColumn(instructions, selectedColorColName);

  if (category === drilldownVar?.category && subCategory === drilldownVar.color) {
    setVariableFn({ category: undefined, color: undefined });
  } else {
    setVariableFn({
      category: parseVariableSelectDateRanges(category, categoryColumn),
      color: parseVariableSelectDateRanges(
        isColorColUsed(selectedColorColName, instructions) ? subCategory : undefined,
        colorColumnOption,
      ),
    });
  }
};

export const isColorColUsed = (
  selectedColorColName?: string,
  instructions?: V2TwoDimensionChartInstructions,
) => instructions?.colorColumnOptions?.length && selectedColorColName !== NONE_CATEGORY_COLOR_VALUE;
