import { FC, useMemo } from 'react';

import { CustomerReportView, TotalAccumulator } from 'actions/customerReportActions';
import { sprinkles } from 'components/ds';
import { renderCell } from 'components/ds/DataGrid/columnUtils';
import { ColumnConfigs } from 'types/columnTypes';
import { DatasetSchema } from 'types/datasets';

import { isCountAggregation } from '../utils/viewUtils';

type PivotTotalProps = {
  keyPath: string[]; // Horizontal group bys
  cellId: string; // Vertical group bys (if no pivots)
  pivotColumnPath?: string[]; // Vertical group bys (pivots)
  totalAccumulator?: TotalAccumulator;
  schema: DatasetSchema;
  columnConfigs: ColumnConfigs;
  view?: CustomerReportView;
};

export const PivotTotal: FC<PivotTotalProps> = ({
  cellId,
  keyPath,
  pivotColumnPath,
  totalAccumulator,
  schema,
  columnConfigs,
  view,
}) => {
  const { cellPath, pivotPath } = useMemo(() => {
    // The order of the paths is important and must match useTotalFooter
    const pivotPath = pivotColumnPath?.join('') || cellId;
    const cellPath = [pivotPath, ...keyPath].join('');
    return { pivotPath, cellPath };
  }, [cellId, keyPath, pivotColumnPath]);

  const aggId = view?.totals?.[pivotPath];
  const lastPivotCol = pivotColumnPath?.[pivotColumnPath.length - 1]; // Defined for leaf rows
  const columnName = lastPivotCol || pivotPath;
  const datasetColumn = useMemo(
    () => schema.find((col) => col.name === columnName),
    [columnName, schema],
  );

  const config = useMemo(() => columnConfigs?.[columnName] || {}, [columnConfigs, columnName]);

  const isCount = isCountAggregation(aggId);
  const value = aggId && totalAccumulator?.columns[cellPath]?.[aggId];
  const renderedValue = useMemo(
    () =>
      (datasetColumn && config && !isCount
        ? renderCell({
            config,
            columnInfo: datasetColumn,
            value,
          })
        : value) ?? '-',
    [config, datasetColumn, isCount, value],
  );

  return <div className={sprinkles({ truncateText: 'ellipsis' })}>{renderedValue}</div>;
};
