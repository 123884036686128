import {
  faArrowTurnDownRight,
  faLayerGroup,
  faSpinnerThird as faSpinnerThirdDuo,
} from '@fortawesome/pro-duotone-svg-icons';
import {
  faSpinnerThird as faSpinnerThirdLight,
  faCircle as faCircleLight,
} from '@fortawesome/pro-light-svg-icons';
import {
  faChartLine as faChartLineReg,
  faCircleExclamation as faCircleExclamationReg,
  faCircleQuestion as faCircleQuestionReg,
  faMessages as faMessagesReg,
  faDatabase as faDatabaseReg,
  faFile as faFileReg,
  faFilter as regularFilter,
  faPaintRoller as faPaintRollerReg,
  faStar as regularStar,
  faUsers as faRegUsers,
  faMoneyBill1Wave as faMoneyBill1WaveReg,
  faEnvelope as faEnvelopeReg,
  faGear as faGearReg,
  faSpinnerThird as faSpinnerThirdRegular,
  faCircle as faCircleRegular,
  faClipboard as faClipboardRegular,
  faWindowMaximize as faWindowMaximizeRegular,
  faTrashCan as faTrashCanReg,
  faClock as faClockReg,
  faRightLeft as faRightLeft,
} from '@fortawesome/pro-regular-svg-icons';
import {
  faAngleLeft,
  faAngleRight,
  faArrowDownRight,
  faArrowLeft,
  faArrowRight,
  faArrowUp,
  faArrowDown,
  faArrowDownArrowUp,
  faArrowLeftToLine,
  faArrowRightToLine,
  faArrowsRotate,
  faArrowUpRight,
  faArrowUpRightFromSquare,
  faArrowUpRightAndArrowDownLeftFromCenter,
  faArrowDownToBracket,
  faArrowTurnDownLeft,
  faUpLong,
  faRightLong,
  faDownLong,
  faBars,
  faBold,
  faBracketsCurly,
  faCircle,
  faCircleCheck,
  faChartBar,
  faChartColumn,
  faChartScatter,
  faCalendar,
  faChartLine,
  faChartArea,
  faChartPieSimple,
  faCaretDown,
  faCaretUp,
  faCaretRight,
  faCheck,
  faChevronDown,
  faChevronRight,
  faChevronUp,
  faChevronLeft,
  faCircleExclamation,
  faCircleInfo,
  faClock,
  faClone,
  faCode,
  faComputerMouse,
  faDatabase,
  faDownload,
  faDrawCircle,
  faDrawPolygon,
  faUser,
  faEdit,
  faEllipsisVertical,
  faEmptySet,
  faEnvelope,
  faFile,
  faFileExport,
  faGear,
  faGlobe,
  faMessages,
  faMoneyBill1Wave,
  faPaintRoller,
  faEye,
  faEyeSlash,
  faFilter,
  faFilters,
  faFilterList,
  faFolder,
  faFunction,
  faGrid2,
  faGrid4,
  faGridRound,
  faGripDotsVertical,
  faHandPointer,
  faHorizontalRule,
  faInfinity,
  faItalic,
  faList,
  faListTree,
  faLock,
  faLocationPin,
  faMinus,
  faObjectsAlignLeft,
  faObjectsAlignRight,
  faObjectsAlignCenterHorizontal,
  faObjectsAlignTop,
  faObjectsAlignBottom,
  faObjectsAlignCenterVertical,
  faPalette,
  faPaperPlane,
  faPencil,
  faPlay,
  faPlus,
  faRectangleTerminal,
  faRightToBracket,
  faSearch,
  faSparkles,
  faSquare,
  faSquareB,
  faSquareM,
  faUsers,
  faUserSlash,
  faStar,
  faSquarePlus,
  faTable,
  faTableCells,
  faTableColumns,
  faTableRows,
  faTrash,
  faTrashUndo,
  faUserGroup,
  faWrench,
  faXmark,
  faXmarkLarge,
  faSidebar,
  faSidebarFlip,
  faDesktop,
  faMobileScreen,
  faFilePdf,
  faArrowUpFromLine,
  faArrowUpShortWide,
  faArrowDownWideShort,
  faEllipsis,
  faRefresh,
  faInfoCircle,
  faWandMagicSparkles,
  faCopy,
  faUpDownLeftRight,
  faLightbulbOn,
  faBookBlank,
  faTrashCan,
} from '@fortawesome/pro-solid-svg-icons';
import {
  faSpinnerThird as faSpinnerThirdThin,
  faCircle as faCircleThin,
} from '@fortawesome/pro-thin-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import { FC, forwardRef, Ref, DetailedHTMLProps, HTMLAttributes } from 'react';

import {
  exSidebarDashboards,
  exSidebarDashboardsFilled,
  exSidebarReport,
  exSidebarReportFilled,
  exBlank,
  reportBuilderKpi,
  reportBuilderNumber,
  reportBuilderDecimal,
  reportBuilderString,
  reportScatterPoints,
} from './custom';
import * as styles from './index.css';

// Import and add any new FA icons here
const nameMapping = {
  'angle-left': faAngleLeft,
  'angle-right': faAngleRight,
  'arrow-left': faArrowLeft,
  'arrow-right': faArrowRight,
  'arrow-up': faArrowUp,
  'arrow-down': faArrowDown,
  'arrow-down-arrow-up': faArrowDownArrowUp,
  'arrow-down-to-bracket': faArrowDownToBracket,
  'arrow-left-to-line': faArrowLeftToLine,
  'arrow-right-to-line': faArrowRightToLine,
  'arrows-rotate': faArrowsRotate,
  'arrow-up-from-line': faArrowUpFromLine,
  'arrow-up-right': faArrowUpRight,
  'arrow-up-right-from-square': faArrowUpRightFromSquare,
  'arrow-down-right': faArrowDownRight,
  'arrow-up-short-wide': faArrowUpShortWide,
  'arrow-down-wide-short': faArrowDownWideShort,
  'arrow-turn-down-right': faArrowTurnDownRight,
  bars: faBars,
  blank: exBlank, // TODO: Remove when no longer using BP
  bold: faBold,
  brackets: faBracketsCurly,
  calendar: faCalendar,
  'chart-bar': faChartBar,
  'chart-column': faChartColumn,
  'chart-line': faChartLine,
  'chart-line-reg': faChartLineReg,
  'chart-area': faChartArea,
  'chart-pie-simple': faChartPieSimple,
  'chart-scatter': faChartScatter,
  check: faCheck,
  'chevron-up': faChevronUp,
  'caret-down': faCaretDown,
  'caret-up': faCaretUp,
  'caret-right': faCaretRight,
  'chevron-down': faChevronDown,
  'chevron-right': faChevronRight,
  'chevron-left': faChevronLeft,
  circle: faCircle,
  'circle-check': faCircleCheck,
  'circle-exclamation': faCircleExclamation,
  'circle-exclamation-reg': faCircleExclamationReg,
  'circle-question-reg': faCircleQuestionReg,
  'circle-info': faCircleInfo,
  'clipboard-reg': faClipboardRegular,
  clock: faClock,
  'clock-reg': faClockReg,
  clone: faClone,
  code: faCode,
  'computer-mouse': faComputerMouse,
  copy: faCopy,
  cross: faXmark,
  download: faDownload,
  'draw-circle': faDrawCircle,
  'draw-polygon': faDrawPolygon,
  'ellipsis-vertical': faEllipsisVertical,
  ellipsis: faEllipsis,
  'empty-set': faEmptySet,
  'enter-key': faArrowTurnDownLeft,
  'eye-closed': faEyeSlash,
  'eye-open': faEye,
  folder: faFolder,
  function: faFunction,
  gear: faGear,
  'gear-reg': faGearReg,
  grid2: faGrid2,
  grid4: faGrid4,
  'grid3-round': faGridRound,
  globe: faGlobe,
  'grip-dots': faGripDotsVertical,
  'hand-pointer': faHandPointer,
  infinity: faInfinity,
  italic: faItalic,
  'large-cross': faXmarkLarge,
  'lightbulb-on': faLightbulbOn,
  'up-long': faUpLong,
  'right-long': faRightLong,
  'down-long': faDownLong,
  'regular-filter': regularFilter,
  'regular-star': regularStar,
  'user-group': faUserGroup,
  'vertical-grip': faGripDotsVertical,
  database: faDatabase,
  'database-reg': faDatabaseReg,
  envelope: faEnvelope,
  'envelope-reg': faEnvelopeReg,
  edit: faEdit,
  file: faFile,
  'file-export': faFileExport,
  'file-reg': faFileReg,
  'horizontal-rule': faHorizontalRule,
  infoCircle: faInfoCircle,
  layer: faLayerGroup,
  list: faList,
  'list-tree': faListTree,
  lock: faLock,
  'location-pin': faLocationPin,
  messages: faMessages,
  'messages-reg': faMessagesReg,
  money: faMoneyBill1Wave,
  'money-reg': faMoneyBill1WaveReg,
  'align-left': faObjectsAlignLeft,
  'align-right': faObjectsAlignRight,
  'align-center': faObjectsAlignCenterHorizontal,
  'align-top': faObjectsAlignTop,
  'align-bottom': faObjectsAlignBottom,
  'align-center-vertical': faObjectsAlignCenterVertical,
  'paint-roller': faPaintRoller,
  'paint-roller-reg': faPaintRollerReg,
  'paper-plane': faPaperPlane,
  'rectangle-terminal': faRectangleTerminal,
  refresh: faRefresh,
  'right-arrow-from-bracket': faRightToBracket,
  'right-left': faRightLeft,
  search: faSearch,
  sparkles: faSparkles,
  spinner: faSpinnerThirdDuo,
  spinnerRegular: faSpinnerThirdRegular,
  'square-b': faSquareB,
  'square-m': faSquareM,
  'square-plus': faSquarePlus,
  square: faSquare,
  circleRegular: faCircleRegular,
  spinnerLight: faSpinnerThirdLight,
  circleLight: faCircleLight,
  spinnerThin: faSpinnerThirdThin,
  circleThin: faCircleThin,
  'user-slash': faUserSlash,
  users: faUsers,
  user: faUser,
  'users-reg': faRegUsers,
  expand: faArrowUpRightAndArrowDownLeftFromCenter,
  filter: faFilter,
  filters: faFilters,
  'filter-list': faFilterList,
  minus: faMinus,
  palette: faPalette,
  pencil: faPencil,
  play: faPlay,
  plus: faPlus,
  star: faStar,
  sidebar: faSidebar,
  'sidebar-flip': faSidebarFlip,
  desktop: faDesktop,
  'mobile-screen': faMobileScreen,
  'file-pdf': faFilePdf,
  table: faTable,
  'table-rows': faTableRows,
  'table-columns': faTableColumns,
  'table-cells': faTableCells,
  tick: faCheck, // TODO: Remove when no longer using BP
  trash: faTrash,
  'trash-can': faTrashCan,
  'trash-can-reg': faTrashCanReg,
  trashUndo: faTrashUndo,
  wand: faWandMagicSparkles,
  wrench: faWrench,
  'up-down-left-right': faUpDownLeftRight,
  'book-blank': faBookBlank,
  'window-maximize': faWindowMaximizeRegular,
};

const customIconNameMapping = {
  'sidebar-dashboards': exSidebarDashboards,
  'sidebar-dashboards-filled': exSidebarDashboardsFilled,
  'sidebar-report': exSidebarReport,
  'sidebar-report-filled': exSidebarReportFilled,
  'report-builder-kpi': reportBuilderKpi,
  'report-builder-number': reportBuilderNumber,
  'report-builder-decimal': reportBuilderDecimal,
  'report-builder-string': reportBuilderString,
  'report-scatter-points': reportScatterPoints,
};

export type IconName = keyof typeof nameMapping | keyof typeof customIconNameMapping;
export type IconSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

type HTMLSVGProps = Omit<DetailedHTMLProps<HTMLAttributes<SVGSVGElement>, SVGSVGElement>, 'ref'>;

export type Props = HTMLSVGProps & {
  // Name of the Font Awesome icon
  name: IconName;
  // React ref to pass for interacting with DOM instance
  parentRef?: Ref<SVGSVGElement>;
  // Size of the icon relative to font size
  size?: IconSize;
  // Animate icon with a spin animation
  spin?: boolean;
  // Animate icon with a bounce animation
  bounce?: boolean;
};

const allIcons = { ...nameMapping, ...customIconNameMapping };

export const Icon: FC<Props> = forwardRef<SVGSVGElement, Props>(
  ({ className, name, parentRef, size, ...props }, ref) => {
    return (
      <FontAwesomeIcon
        {...props}
        className={cx(styles.base({ size }), className)}
        fillRule={name in customIconNameMapping ? 'evenodd' : undefined}
        icon={allIcons[name]}
        ref={parentRef ?? ref}
      />
    );
  },
);

Icon.displayName = 'Icon';
