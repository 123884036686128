import { DateTime } from 'luxon';

import { OPERATION_TYPES, TrendGroupToggleOptionId, V2KPITrendInstructions } from 'constants/types';
import { DashboardVariableMap, DateTimeRangeDashboardVariable } from 'types/dashboardTypes';
import { DatasetRow } from 'types/datasets';
import {
  PeriodComparisonRangeTypes,
  PeriodRangeTypes,
  TrendGroupingOptions,
} from 'types/dateRangeTypes';
import { aggReady } from 'utils/dataPanelConfigUtils';
import { TIME_FORMATS, formatTime } from 'utils/localizationUtils';
import { isEmpty } from 'utils/standard';
import { removeBracesFromVariableString } from 'utils/variableUtils';

import { getAxisNumericalValue } from '.';

export const getPctChange = (base: number, comparison: number) => {
  let change = 0;
  if (base === comparison) {
    change = 0;
  } else if (base === 0) {
    change = -1;
  } else if (comparison === 0) {
    change = 1;
  } else {
    change = (base - comparison) / Math.abs(comparison);
  }

  return change;
};

export const enumerateDatesByGroup = (
  startDate: DateTime,
  endDate: DateTime,
  datesList: DateTime[],
  trendGrouping: TrendGroupingOptions,
) => {
  // comparing to 1 here because the times aren't always at start of day and diff can go into decimals
  if ((endDate.diff(startDate, 'days').toObject().days ?? 0) < 1) {
    datesList.push(endDate);
    return;
  }

  datesList.push(startDate);

  switch (trendGrouping) {
    case TrendGroupingOptions.HOURLY:
      startDate = startDate.plus({ hour: 1 });
      break;
    case TrendGroupingOptions.DAILY:
      startDate = startDate.plus({ day: 1 });
      break;
    case TrendGroupingOptions.WEEKLY:
      startDate = startDate.plus({ week: 1 });
      break;
    case TrendGroupingOptions.MONTHLY:
      startDate = startDate.plus({ month: 1 });
      break;
    case TrendGroupingOptions.YEARLY:
      startDate = startDate.plus({ year: 1 });
      break;
  }
  enumerateDatesByGroup(startDate, endDate, datesList, trendGrouping);
};

export const getPeriodDates = (
  periodRange: PeriodRangeTypes,
  trendGrouping: TrendGroupingOptions,
  customStartDate: DateTime,
  customEndDate: DateTime,
  periodRangeOffset: number,
) => {
  const periodEndDate = getPeriodEndDate(
    periodRange,
    trendGrouping,
    customEndDate,
    periodRangeOffset,
  );
  const periodStartDate = getPeriodStartDate(
    periodRange,
    trendGrouping,
    customStartDate,
    periodEndDate,
  );

  const periodDates: DateTime[] = [];

  enumerateDatesByGroup(periodStartDate, periodEndDate, periodDates, trendGrouping);

  return {
    periodStartDate,
    periodEndDate,
    periodDates,
  };
};

const getPeriodEndDate = (
  periodRange: PeriodRangeTypes,
  trendGrouping: TrendGroupingOptions,
  customEndDate: DateTime,
  periodRangeOffset: number,
) => {
  let periodEndDate = DateTime.local().minus({ day: periodRangeOffset });

  if (
    periodRange === PeriodRangeTypes.CUSTOM_RANGE ||
    periodRange === PeriodRangeTypes.DATE_RANGE_INPUT ||
    periodRange === PeriodRangeTypes.CUSTOM_RANGE_VARIABLES
  ) {
    periodEndDate = customEndDate;
  } else if (periodRange === PeriodRangeTypes.TIME_PERIOD_DROPDOWN) {
    periodEndDate = customEndDate;
  } else if (periodRange === PeriodRangeTypes.PREVIOUS_MONTH) {
    periodEndDate = DateTime.local()
      .minus({ month: 1 })
      .endOf('month')
      .minus({ day: periodRangeOffset });
  }
  return getEntryStartDateFromGrouping(periodEndDate, trendGrouping);
};

const getPeriodStartDate = (
  periodRange: PeriodRangeTypes,
  trendGrouping: TrendGroupingOptions,
  customStartDate: DateTime,
  periodEndDate: DateTime,
) => {
  let periodStartDate = periodEndDate;

  switch (periodRange) {
    case PeriodRangeTypes.CUSTOM_RANGE:
    case PeriodRangeTypes.DATE_RANGE_INPUT:
    case PeriodRangeTypes.CUSTOM_RANGE_VARIABLES:
      periodStartDate = customStartDate;
      break;
    case PeriodRangeTypes.TIME_PERIOD_DROPDOWN:
      periodStartDate = customStartDate;
      break;
    // The start date and end date for TODAY are the same
    case PeriodRangeTypes.TODAY:
      break;
    case PeriodRangeTypes.LAST_7_DAYS:
      periodStartDate = periodEndDate.minus({ days: 6 });
      break;
    case PeriodRangeTypes.LAST_4_WEEKS:
      // When the dates are in days, we need to subtract the full 4 weeks
      if (trendGrouping === TrendGroupingOptions.DAILY) {
        periodStartDate = periodEndDate.minus({ weeks: 4 });
      } else {
        periodStartDate = periodEndDate.minus({ weeks: 3 });
      }
      break;
    case PeriodRangeTypes.PREVIOUS_MONTH:
      periodStartDate = periodEndDate.startOf('month');
      break;
    case PeriodRangeTypes.LAST_3_MONTHS:
      periodStartDate = periodEndDate.minus({ months: 3 });
      break;
    case PeriodRangeTypes.LAST_12_MONTHS:
      periodStartDate = periodEndDate.minus({ months: 12 });
      break;
    case PeriodRangeTypes.MONTH_TO_DATE:
      periodStartDate = getEntryStartDateFromGrouping(
        periodEndDate.startOf('month'),
        trendGrouping,
      );
      break;
    case PeriodRangeTypes.YEAR_TO_DATE:
      periodStartDate = getEntryStartDateFromGrouping(periodEndDate.startOf('year'), trendGrouping);
      break;
  }

  return getEntryStartDateFromGrouping(periodStartDate, trendGrouping);
};

export const getComparisonDates = (
  startDate: DateTime,
  endDate: DateTime,
  numIntervals: number,
  comparisonRange: PeriodComparisonRangeTypes,
  periodRange: PeriodRangeTypes,
  trendGrouping: TrendGroupingOptions,
) => {
  const comparisonDates: DateTime[] = [];
  let comparisonStartDate;
  let comparisonEndDate;

  switch (comparisonRange) {
    case PeriodComparisonRangeTypes.PREVIOUS_PERIOD:
      comparisonStartDate = shiftDateByGrouping(startDate, -numIntervals, trendGrouping);
      comparisonEndDate = shiftDateByGrouping(startDate, -1, trendGrouping);
      break;
    case PeriodComparisonRangeTypes.PREVIOUS_MONTH:
      switch (periodRange) {
        case PeriodRangeTypes.TODAY:
        case PeriodRangeTypes.CUSTOM_RANGE:
        case PeriodRangeTypes.CUSTOM_RANGE_VARIABLES:
        case PeriodRangeTypes.DATE_RANGE_INPUT:
        case PeriodRangeTypes.TIME_PERIOD_DROPDOWN:
        case PeriodRangeTypes.LAST_7_DAYS:
        case PeriodRangeTypes.PREVIOUS_MONTH:
          comparisonStartDate = getEntryStartDateFromGrouping(
            startDate.minus({ month: 1 }),
            trendGrouping,
          );
          comparisonEndDate = getEntryStartDateFromGrouping(
            endDate.minus({ month: 1 }),
            trendGrouping,
          );
          break;
        case PeriodRangeTypes.LAST_4_WEEKS:
          comparisonStartDate = shiftDateByGrouping(startDate, -numIntervals, trendGrouping);
          comparisonEndDate = shiftDateByGrouping(endDate, -numIntervals, trendGrouping);
          break;
        case PeriodRangeTypes.LAST_3_MONTHS:
        case PeriodRangeTypes.LAST_12_MONTHS:
          comparisonStartDate = shiftDateByGrouping(
            startDate,
            -numGroupsInMonth(trendGrouping),
            trendGrouping,
          );
          comparisonEndDate = shiftDateByGrouping(
            endDate,
            -numGroupsInMonth(trendGrouping),
            trendGrouping,
          );
          break;
        case PeriodRangeTypes.MONTH_TO_DATE:
          comparisonStartDate = getEntryStartDateFromGrouping(
            endDate.minus({ month: 1 }).startOf('month'),
            trendGrouping,
          );
          comparisonEndDate = shiftDateByGrouping(
            comparisonStartDate,
            numIntervals - 1,
            trendGrouping,
          );
          break;
        case PeriodRangeTypes.YEAR_TO_DATE:
          comparisonStartDate = shiftDateByGrouping(
            startDate,
            -numGroupsInMonth(trendGrouping),
            trendGrouping,
          );
          comparisonEndDate = shiftDateByGrouping(
            endDate,
            -numGroupsInMonth(trendGrouping),
            trendGrouping,
          );
          break;
      }
      break;
    case PeriodComparisonRangeTypes.PREVIOUS_YEAR:
      switch (periodRange) {
        case PeriodRangeTypes.TODAY:
        case PeriodRangeTypes.LAST_7_DAYS:
        case PeriodRangeTypes.CUSTOM_RANGE:
        case PeriodRangeTypes.CUSTOM_RANGE_VARIABLES:
        case PeriodRangeTypes.DATE_RANGE_INPUT:
        case PeriodRangeTypes.TIME_PERIOD_DROPDOWN:
          comparisonStartDate = getEntryStartDateFromGrouping(
            startDate.minus({ year: 1 }),
            trendGrouping,
          );
          comparisonEndDate = getEntryStartDateFromGrouping(
            endDate.minus({ year: 1 }),
            trendGrouping,
          );

          if (trendGrouping === TrendGroupingOptions.WEEKLY) {
            /** This is to offset the fact that start and end date have been double rounded
             * down to start on Monday, as per chosen convention. First round down in calculating
             * start and end, and second round down after subtracting a year and adjusting again.
             */
            comparisonStartDate = comparisonStartDate.plus({ week: 1 });
            comparisonEndDate = comparisonEndDate.plus({ week: 1 });
          }
          break;
        case PeriodRangeTypes.LAST_4_WEEKS:
        case PeriodRangeTypes.PREVIOUS_MONTH:
        case PeriodRangeTypes.LAST_3_MONTHS:
        case PeriodRangeTypes.LAST_12_MONTHS:
          comparisonStartDate = shiftDateByGrouping(
            startDate,
            -numGroupsInYear(trendGrouping),
            trendGrouping,
          );
          comparisonEndDate = shiftDateByGrouping(
            endDate,
            -numGroupsInYear(trendGrouping),
            trendGrouping,
          );
          break;
        case PeriodRangeTypes.MONTH_TO_DATE:
          comparisonStartDate = getEntryStartDateFromGrouping(
            endDate.minus({ year: 1 }).startOf('month'),
            trendGrouping,
          );
          comparisonEndDate = shiftDateByGrouping(
            comparisonStartDate,
            numIntervals - 1,
            trendGrouping,
          );
          break;
        case PeriodRangeTypes.YEAR_TO_DATE:
          comparisonStartDate = getEntryStartDateFromGrouping(
            endDate.minus({ year: 1 }).startOf('year'),
            trendGrouping,
          );
          comparisonEndDate = shiftDateByGrouping(
            comparisonStartDate,
            numIntervals - 1,
            trendGrouping,
          );
          break;
      }
      break;
    // Won't get PREVIOUS_CUSTOM_RANGE, PREVIOUS_DATE_RANGE_INPUT, PREVIOUS_TIME_PERIOD_DROPDOWN because they are text trend only
    case PeriodComparisonRangeTypes.PREVIOUS_CUSTOM_RANGE:
    case PeriodComparisonRangeTypes.PREVIOUS_DATE_RANGE_INPUT:
    case PeriodComparisonRangeTypes.PREVIOUS_TIME_PERIOD_DROPDOWN:
    case PeriodComparisonRangeTypes.NO_COMPARISON:
      return [];
  }

  enumerateDatesByGroup(comparisonStartDate, comparisonEndDate, comparisonDates, trendGrouping);

  return comparisonDates;
};

const shiftDateByGrouping = (
  date: DateTime,
  shift: number,
  trendGrouping: TrendGroupingOptions,
) => {
  switch (trendGrouping) {
    case TrendGroupingOptions.HOURLY:
      return date.plus({ hours: shift });
    case TrendGroupingOptions.DAILY:
      return date.plus({ days: shift });
    case TrendGroupingOptions.WEEKLY:
      return date.plus({ weeks: shift });
    case TrendGroupingOptions.MONTHLY:
      return date.plus({ months: shift });
    case TrendGroupingOptions.YEARLY:
      return date.plus({ years: shift });
    default:
      return date;
  }
};

export const getEntryStartDateFromGrouping = (
  date: DateTime,
  trendGrouping: TrendGroupingOptions,
) => {
  switch (trendGrouping) {
    case TrendGroupingOptions.HOURLY:
    case TrendGroupingOptions.DAILY:
      return date;
    case TrendGroupingOptions.WEEKLY:
      return date.startOf('week');
    case TrendGroupingOptions.MONTHLY:
      return date.startOf('month');
    case TrendGroupingOptions.YEARLY:
      return date.startOf('year');
    default:
      return date;
  }
};

const numGroupsInMonth = (trendGrouping: TrendGroupingOptions) => {
  switch (trendGrouping) {
    case TrendGroupingOptions.HOURLY:
      return 30 * 24;
    case TrendGroupingOptions.DAILY:
      return 30;
    case TrendGroupingOptions.WEEKLY:
      return 4;
    case TrendGroupingOptions.MONTHLY:
      return 1;
    case TrendGroupingOptions.YEARLY:
      return 1;
    default:
      return 1;
  }
};

const numGroupsInYear = (trendGrouping: TrendGroupingOptions) => {
  switch (trendGrouping) {
    case TrendGroupingOptions.HOURLY:
      return 365 * 24;
    case TrendGroupingOptions.DAILY:
      return 365;
    case TrendGroupingOptions.WEEKLY:
      return 52;
    case TrendGroupingOptions.MONTHLY:
      return 12;
    case TrendGroupingOptions.YEARLY:
      return 1;
    default:
      return 1;
  }
};

export const formatDateRange = (date: DateTime, isEndDate?: boolean) => {
  const localizedDateFormat = isEndDate ? TIME_FORMATS['MMM D, YYYY'] : TIME_FORMATS['MMM D'];
  return formatTime(date, localizedDateFormat);
};

const areRequiredVariablesSetForPeriodRange = (
  variables: DashboardVariableMap,
  instructions?: V2KPITrendInstructions,
) => {
  if (instructions?.periodColumn?.periodRange === PeriodRangeTypes.DATE_RANGE_INPUT) {
    const rangeVarId = instructions?.periodColumn?.rangeElemId;

    if (!rangeVarId) return true;

    const rangeVariable = variables[rangeVarId] as DateTimeRangeDashboardVariable;

    return !!(rangeVariable?.startDate && rangeVariable?.endDate);
  } else if (instructions?.periodColumn?.periodRange === PeriodRangeTypes.TIME_PERIOD_DROPDOWN) {
    const timeVarId = instructions?.periodColumn?.timePeriodElemId;

    if (!timeVarId) return true;

    const rangeVariable = variables[timeVarId] as number;

    return !!rangeVariable;
  } else if (instructions?.periodColumn?.periodRange === PeriodRangeTypes.CUSTOM_RANGE_VARIABLES) {
    if (
      !instructions.periodColumn.customStartDateVariable ||
      !instructions.periodColumn.customEndDateVariable
    ) {
      return false;
    }

    const startDateVariable = removeBracesFromVariableString(
      instructions.periodColumn.customStartDateVariable,
    );
    const endDateVariable = removeBracesFromVariableString(
      instructions.periodColumn.customEndDateVariable,
    );

    return startDateVariable in variables && endDateVariable in variables;
  } else {
    return true;
  }
};

const areRequiredVariablesSetForPreviousPeriod = (
  variables: DashboardVariableMap,
  instructions?: V2KPITrendInstructions,
) => {
  if (
    instructions?.periodComparisonRange === PeriodComparisonRangeTypes.PREVIOUS_DATE_RANGE_INPUT
  ) {
    const rangeVarId = instructions?.periodColumn?.comparisonInfo?.rangeElemId;

    if (!rangeVarId) return true;

    const rangeVariable = variables[rangeVarId] as DateTimeRangeDashboardVariable;

    return !!(rangeVariable?.startDate && rangeVariable?.endDate);
  } else if (
    instructions?.periodComparisonRange === PeriodComparisonRangeTypes.PREVIOUS_TIME_PERIOD_DROPDOWN
  ) {
    const timeVarId = instructions?.periodColumn?.comparisonInfo?.timePeriodElemId;

    if (!timeVarId) return true;

    const rangeVariable = variables[timeVarId] as number;

    return !!rangeVariable;
  }
  return true;
};

export const areRequiredVariablesSet = (
  variables: DashboardVariableMap,
  instructions?: V2KPITrendInstructions,
) =>
  areRequiredVariablesSetForPeriodRange(variables, instructions) &&
  areRequiredVariablesSetForPreviousPeriod(variables, instructions);

export const isKpiTrendReadyToDisplay = (
  instructions: V2KPITrendInstructions | undefined,
): boolean => {
  if (!instructions) return false;
  const { periodColumn, trendGrouping, trendGroupingElementId, periodComparisonRange } =
    instructions;

  if (trendGrouping === TrendGroupToggleOptionId && !trendGroupingElementId) return false;

  const customRangeValid =
    periodColumn?.periodRange !== PeriodRangeTypes.CUSTOM_RANGE ||
    (periodColumn.customEndDate && periodColumn.customStartDate);

  const customRangeVariablesValid =
    periodColumn?.periodRange !== PeriodRangeTypes.CUSTOM_RANGE_VARIABLES ||
    (periodColumn.customEndDateVariable && periodColumn.customStartDateVariable);

  const rangeInputValue =
    periodColumn?.periodRange !== PeriodRangeTypes.DATE_RANGE_INPUT || periodColumn.rangeElemId;

  const timePeriodValue =
    periodColumn?.periodRange !== PeriodRangeTypes.TIME_PERIOD_DROPDOWN ||
    periodColumn.timePeriodElemId;

  const previousCustomRangeValid =
    periodComparisonRange !== PeriodComparisonRangeTypes.PREVIOUS_CUSTOM_RANGE ||
    (periodColumn?.comparisonInfo?.customStartDate && periodColumn.comparisonInfo.customEndDate);

  const previousDateRangeValid =
    periodComparisonRange !== PeriodComparisonRangeTypes.PREVIOUS_DATE_RANGE_INPUT ||
    periodColumn?.comparisonInfo?.rangeElemId;

  const previousTimePeriodValid =
    periodComparisonRange !== PeriodComparisonRangeTypes.PREVIOUS_TIME_PERIOD_DROPDOWN ||
    periodColumn?.comparisonInfo?.timePeriodElemId;

  return !!(
    aggReady(instructions.aggColumn) &&
    periodColumn?.column &&
    customRangeValid &&
    rangeInputValue &&
    timePeriodValue &&
    customRangeVariablesValid &&
    previousCustomRangeValid &&
    previousDateRangeValid &&
    previousTimePeriodValid
  );
};

export const getRangeAggregateValues = (data: DatasetRow[]) => {
  if (isEmpty(data)) return undefined;

  // Array.findLast exists but typescript won't accept that
  // @ts-ignore
  const comparisonRow = data.findLast((r) => Object.keys(r)[0] === 'previous_period_agg');
  // @ts-ignore
  const currentRow = data.findLast((r) => Object.keys(r)[0] === 'current_period_agg');

  // handles the edge case where the query only returns one of the two values
  if (comparisonRow && currentRow)
    return {
      comparisonRange: getAxisNumericalValue(comparisonRow['previous_period_agg']) ?? 0,
      periodRange: getAxisNumericalValue(currentRow['current_period_agg']) ?? 0,
    };
  else if (comparisonRow)
    return {
      comparisonRange: getAxisNumericalValue(comparisonRow['previous_period_agg']) ?? 0,
      periodRange: 0,
    };
  else if (currentRow)
    return {
      comparisonRange: 0,
      periodRange: getAxisNumericalValue(currentRow['current_period_agg']) ?? 0,
    };
  // non-fido way of getting the data
  else
    return {
      comparisonRange: data[0] ? (data[0][Object.keys(data[0])[0] as string] as number) ?? 0 : 0,
      periodRange: data[1] ? (data[1][Object.keys(data[1])[0] as string] as number) ?? 0 : 0,
    };
};

/**
 * @returns Whether this visualization is a number trend text panel or not. This is needed in the
 *     interim while we migrate to a visualization type and away from a property on the KPI trend
 *     instructions.
 */
export const isNumberTrendTextPanelVisualizationType = (
  operationType: OPERATION_TYPES,
  kpiTrendInstructions: V2KPITrendInstructions | undefined,
) => {
  return (
    operationType === OPERATION_TYPES.VISUALIZE_NUMBER_TREND_TEXT_PANEL ||
    (operationType === OPERATION_TYPES.VISUALIZE_NUMBER_TREND_V2 &&
      kpiTrendInstructions?.hideTrendLines)
  );
};

/**
 * @returns Returns the new number trend text panel visualization type if the current operation type
 *    is the number trend visualization type and the KPI trend instructions are set to hide trend
 *    lines. Otherwise, returns the current operation type.
 */
export const maybeChangeNumberTrendVisualizationType = (
  operationType: OPERATION_TYPES,
  kpiTrendInstructions: V2KPITrendInstructions | undefined,
): OPERATION_TYPES => {
  if (
    operationType === OPERATION_TYPES.VISUALIZE_NUMBER_TREND_V2 &&
    kpiTrendInstructions?.hideTrendLines
  ) {
    return OPERATION_TYPES.VISUALIZE_NUMBER_TREND_TEXT_PANEL;
  }

  return operationType;
};
