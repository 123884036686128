import cx from 'classnames';
import { DateTime } from 'luxon';
import { FC, useContext, useState, useEffect, useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';

import DashboardLayoutContext from 'components/DashboardLayout/DashboardLayoutContext';
import { DatePickerInput } from 'components/DatePickerInput';
import { sprinkles } from 'components/ds';
import { REPORTED_ANALYTIC_ACTION_TYPES, SelectedDropdownInputItem } from 'constants/types';
import DropdownSelect from 'shared/DropdownSelect';
import { sendAnalyticsEventThunk } from 'telemetry/telemetryThunks';
import { DateRangePickerElemConfig, DateTimeRangeDashboardVariable } from 'types/dashboardTypes';
import { DEFAULT_DATE_RANGES } from 'types/dateRangeTypes';
import { useStringWithVariablesReplaced } from 'utils/dataPanelConfigUtils';
import { getValidDateRanges } from 'utils/dateRangeUtils';
import { getDefaultRangeValues, dateTimeFromISOString } from 'utils/dateUtils';

import * as styles from './DashboardDateRangePickerElement.css';
import { getConfigDateLimits } from './datePickerUtil';

type Props = {
  className?: string;
  config: DateRangePickerElemConfig;
  value?: DateRangePickerValue;
  onNewValueSelect: (newValue: DateTimeRangeDashboardVariable | undefined) => void;
  disabled?: boolean;
  withPortal?: boolean;
  noDropdown?: boolean;
  openElementToLeft?: boolean;
  timezone: string;
  isInContainer?: boolean;
  isEmbed?: boolean;
  maxDate?: DateTime;
  minDate?: DateTime;
  datasetNamesToId: Record<string, string>;
};

export type DateRangePickerValue = {
  startDate?: string | DateTime;
  endDate?: string | DateTime;
};

export const DashboardDateRangePickerElement: FC<Props> = ({
  className,
  config,
  disabled,
  isEmbed,
  isInContainer,
  noDropdown,
  onNewValueSelect,
  openElementToLeft,
  timezone,
  withPortal,
  value,
  maxDate,
  minDate,
  datasetNamesToId,
}) => {
  const [currentValue, setCurrentValue] = useState(value);
  const [selectedOption, setSelectedOption] = useState<SelectedDropdownInputItem | undefined>();
  const { dashboardLayoutTagId } = useContext(DashboardLayoutContext);

  const dispatch = useDispatch();

  const startDate = currentValue?.startDate;
  const endDate = currentValue?.endDate;

  const startDateParsed =
    typeof startDate === 'string' ? dateTimeFromISOString(startDate) : startDate;
  const endDateParsed = typeof endDate === 'string' ? dateTimeFromISOString(endDate) : endDate;

  const hasMounted = useRef(false);
  useEffect(() => {
    if (noDropdown || hasMounted.current) return;
    hasMounted.current = true;

    if (!startDateParsed || !endDateParsed) return;
    const startDateStartOfDay = +startDateParsed.startOf('day');
    const endDateStartOfDay = +endDateParsed.startOf('day');
    const validDateRanges = getValidDateRanges(config).filter((range) => {
      const { startDate: rangeStart, endDate: rangeEnd } = getDefaultRangeValues(
        range.id,
        config.endDateEndOfDay,
        config.presetRanges,
        timezone,
      );

      // PS: + converts to a number to avoid object comparison
      const rangeStartStartOfDay = +rangeStart.startOf('day');
      const rangeEndStartOfDay = +rangeEnd.startOf('day');
      return (
        rangeStartStartOfDay === startDateStartOfDay && rangeEndStartOfDay === endDateStartOfDay
      );
    });

    if (validDateRanges.length === 0) return;

    const matchingRange = validDateRanges.find((range) => range.id === config.defaultDateRange);
    const preselectedRange = matchingRange ?? validDateRanges[0];

    setSelectedOption(preselectedRange);
  }, [config, endDateParsed, noDropdown, startDateParsed, timezone]);

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  const label = useStringWithVariablesReplaced(config.label, datasetNamesToId);

  const rangeOptions = useMemo(
    () => (config.includeRangeDropdown && !noDropdown ? getValidDateRanges(config) : []),
    [config, noDropdown],
  );

  const renderDropdown = () => {
    return (
      <DropdownSelect
        fillWidth
        isEmbed
        minimal
        showIcon
        containerClassName={cx(styles.rangeDropdown, {
          [styles.rangeOnly]: config.excludeDatePicker,
          [styles.comboControl]: !config.excludeDatePicker,
        })}
        disabled={disabled}
        filterable={false}
        label={label}
        labelHelpText={config.showTooltip ? config.infoTooltipText : undefined}
        noSelectionText="Select a range"
        onCancelClick={() => {
          setCurrentValue({});
          setSelectedOption(undefined);
          onNewValueSelect(undefined);
          dispatch(sendAnalyticsEventThunk(REPORTED_ANALYTIC_ACTION_TYPES.DATEPICKER_SELECTED));
        }}
        onChange={(newValue) => {
          const dateRangeId = newValue.id as DEFAULT_DATE_RANGES;
          const defaultValues = getDefaultRangeValues(
            dateRangeId,
            config.endDateEndOfDay,
            config.presetRanges,
            timezone,
          );

          if (config.endDateEndOfDay) defaultValues.endDate = defaultValues.endDate.endOf('day');

          setCurrentValue(defaultValues);
          setSelectedOption(newValue);

          onNewValueSelect(defaultValues);
          dispatch(sendAnalyticsEventThunk(REPORTED_ANALYTIC_ACTION_TYPES.DATEPICKER_SELECTED));
        }}
        options={rangeOptions}
        selectedItem={selectedOption}
        showCancelBtn={!config.disableCancel && config.excludeDatePicker}
        usePortal={isInContainer}
      />
    );
  };

  const portalId: string | undefined = isInContainer ? dashboardLayoutTagId : undefined;

  const { minDate: configMinDate, maxDate: configMaxDate } = getConfigDateLimits(config, timezone);
  return (
    <div className={cx(sprinkles({ flexItems: 'alignCenterBetween' }), className)}>
      {config.includeRangeDropdown && !noDropdown ? renderDropdown() : null}
      {!config.excludeDatePicker ? (
        <DatePickerInput
          selectsRange
          className={cx(styles.datePicker, {
            [styles.datePickerInput]: config.includeRangeDropdown,
          })}
          disabled={disabled}
          endDate={endDateParsed?.toLocal()}
          isEmbed={isEmbed}
          label={config.includeRangeDropdown || !isEmbed ? undefined : label}
          labelHelpText={
            config.showTooltip && !config.includeRangeDropdown ? config.infoTooltipText : undefined
          }
          maxDate={maxDate ?? configMaxDate}
          minDate={minDate ?? configMinDate}
          onCalendarClose={() => {
            const readyToCompute = !!(startDateParsed && endDateParsed);

            if (!readyToCompute) setCurrentValue(value);
          }}
          onNewValueSelect={(date) => {
            if (!date) {
              setCurrentValue({});
              setSelectedOption(undefined);
              onNewValueSelect(undefined);
              return;
            }

            const startDate = Array.isArray(date) ? date[0] : date;
            let endDate = Array.isArray(date) ? date[1] : undefined;
            if (endDate && config.endDateEndOfDay) endDate = endDate.endOf('day');

            setCurrentValue({ startDate: startDate, endDate: endDate });
            setSelectedOption(undefined);

            if (startDate && endDate) {
              onNewValueSelect({ startDate: startDate.toUTC(), endDate: endDate.toUTC() });
            }
          }}
          openElementToLeft={openElementToLeft}
          portalId={portalId}
          selectedValue={startDateParsed?.toLocal()}
          showCancelBtn={!config.disableCancel}
          startDate={startDateParsed?.toLocal()}
          withPortal={!portalId ? withPortal : undefined}
        />
      ) : undefined}
    </div>
  );
};
