import { DateTime } from 'luxon';
import { useCallback, useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { getSessionExpiration } from 'actions/authAction';
import { EmbedReduxState } from 'embeddedContent/reducers/rootReducer';
import { setJwt } from 'reducers/dashboardLayoutReducer';
import { ReportBuilderReduxState } from 'reportBuilderContent/reducers/rootReducer';
import { INPUT_EVENT, INTERNAL_EVENT, UpdateCustomerJwtPayload } from 'types/customEventTypes';
import { useCustomEvent } from 'utils/customEvent/useCustomEvent';

interface SaveEventData {
  resourceId: number;
}

export const saveResourceConfig = (resourceId: number) => {
  setTimeout(() => {
    window.dispatchEvent(
      new CustomEvent<SaveEventData>(INTERNAL_EVENT.SAVE_RESOURCE_CONFIG, {
        detail: {
          resourceId: resourceId,
        },
      }),
    );
  }, 200);
};

export const saveMapViewState = () => {
  window.dispatchEvent(new CustomEvent(INTERNAL_EVENT.SAVE_MAP_VIEW_STATE));
};

export const sendCustomerJwtExpiredEvent = (isIframe: boolean) => {
  if (isIframe) {
    window.parent.postMessage({ event: 'customerJwtExpired' }, '*');
  } else {
    window.dispatchEvent(new CustomEvent('customerJwtExpired'));
  }
};

export const useJwtRefreshListener = () => {
  const dispatch = useDispatch();

  const handleUpdateCustomerJwtEvent = useCallback(
    ({ detail: { jwt } }: CustomEvent<UpdateCustomerJwtPayload>) => {
      if (typeof jwt !== 'string') return;
      dispatch(setJwt(jwt));
      dispatch(getSessionExpiration({ jwt }));
    },
    [dispatch],
  );

  useCustomEvent(INPUT_EVENT.UPDATE_CUSTOMER_JWT, handleUpdateCustomerJwtEvent);
};

export const useJwtExpirationNotifier = () => {
  const dispatch = useDispatch();

  const { jwtExpiration, embedType } = useSelector(
    (state: ReportBuilderReduxState | EmbedReduxState) => ({
      jwtExpiration: state.embeddedAuth.jwtExpiration,

      embedType: state.embeddedAuth.embedType,
    }),

    shallowEqual,
  );

  useEffect(() => {
    if (!jwtExpiration || !embedType) return;

    const timeoutId = window.setTimeout(() => {
      sendCustomerJwtExpiredEvent(embedType !== 'embedded');

      // minus 60 just to give some leeway for generating the new jwt
    }, DateTime.fromSeconds(jwtExpiration).minus({ seconds: 60 }).diffNow().milliseconds);

    return () => {
      window.clearTimeout(timeoutId);
    };
  }, [jwtExpiration, embedType, dispatch]);
};
