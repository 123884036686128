import * as Dialog from '@radix-ui/react-dialog';
import cx from 'classnames';
import { FC, ReactNode } from 'react';

import { Button, sprinkles, IconButton } from 'components/ds';

import * as styles from './index.css';

export type ModalSize = 'small' | 'medium' | 'large' | 'xlarge';

type BaseProps = {
  // Override styles for content
  className?: string;
  // Specify a container element id to portal the content into.
  portalContainerId?: string;
  // Determines size of modal
  size: ModalSize;
  // The title of the modal
  title: string;
  // The subtitle of the modal
  subtitle?: string;
  // Event handler when back button is clicked
  onBack?: () => void;
  /**
   * ClassName to override base style of modal's content.
   */
  contentClassName?: string;
};

type LinkButtonProps = {
  disabled?: never;
  loading?: never;
  onClick?: never;
  // URL to go to when clicked
  to: string;
};

type ActionButtonProps = {
  to?: never;
  // Determines whether the action button should be loading
  loading?: boolean;
  // Determines whether the action button should be disabled
  disabled?: boolean;
  // Event handler called when footer button is clicked.
  onClick: () => void;
};

type FooterButtonProps = { text: string } & (ActionButtonProps | LinkButtonProps);

type FooterProps = {
  primaryButtonProps?: FooterButtonProps;
  secondaryButtonProps?: FooterButtonProps;
  tertiaryButtonProps?: FooterButtonProps;
  customButton?: ReactNode;
};

type ExternalStateProps = {
  defaultOpen?: never;
  trigger?: never;
  // The controlled open state of the dialog. Must be used in conjunction with onOpenChange.
  isOpen: boolean;
  // Event handler called when the dialog opens.
  onOpen?: () => void;
  // Event handler called when the dialog closes.
  onClose?: () => void;
};

type InternalStateProps = {
  isOpen?: never;
  onOpen?: () => void;
  onClose?: () => void;
  // The open state of the dialog when it is initially rendered. Use when you do not need to control its open state.
  defaultOpen?: boolean;
  // Trigger to open and close modal
  trigger: JSX.Element;
};

type InteractionProps = ExternalStateProps | InternalStateProps;

export type Props = BaseProps & InteractionProps & FooterProps;

export const Modal: FC<Props> = ({
  className,
  isOpen,
  portalContainerId,
  size,
  title,
  children,
  defaultOpen = false,
  trigger,
  primaryButtonProps,
  secondaryButtonProps,
  tertiaryButtonProps,
  contentClassName,
  onBack,
  onOpen,
  onClose,
  customButton,
  subtitle,
}) => {
  const header = (
    <div className={styles.modalHeader}>
      <div className={sprinkles({ flexItems: 'alignCenter', gap: 'sp.5' })}>
        {onBack ? (
          <IconButton data-testid="modal-back-button" name="arrow-left" onClick={onBack} />
        ) : null}
        <Dialog.Title className={sprinkles({ heading: 'h2', margin: 'sp0' })}>
          {title}
          {subtitle ? (
            <div
              className={sprinkles({
                heading: 'h4',
                color: 'contentSecondary',
              })}>
              {subtitle}
            </div>
          ) : null}
        </Dialog.Title>
      </div>
      {onClose ? (
        <Dialog.Close asChild>
          <IconButton data-testid="modal-close-button" name="cross" />
        </Dialog.Close>
      ) : null}
    </div>
  );

  const footer =
    primaryButtonProps || secondaryButtonProps || tertiaryButtonProps ? (
      <div className={styles.dialogFooter}>
        {customButton}
        {tertiaryButtonProps ? (
          <Dialog.Close asChild>
            <Button data-testid="modal-tertiary-button" variant="tertiary" {...tertiaryButtonProps}>
              {tertiaryButtonProps.text}
            </Button>
          </Dialog.Close>
        ) : null}
        {secondaryButtonProps ? (
          <Dialog.Close asChild>
            <Button
              data-testid="modal-secondary-button"
              variant="secondary"
              {...secondaryButtonProps}>
              {secondaryButtonProps.text}
            </Button>
          </Dialog.Close>
        ) : null}
        {primaryButtonProps ? (
          <Dialog.Close asChild>
            <Button data-testid="modal-primary-button" variant="primary" {...primaryButtonProps}>
              {primaryButtonProps.text}
            </Button>
          </Dialog.Close>
        ) : null}
      </div>
    ) : null;

  const content = (
    <div className={sprinkles({ flexItems: 'center' })}>
      <Dialog.Overlay className={styles.dialogOverlay} />
      <Dialog.Content className={cx(styles.dialogContent({ size }), className)}>
        {header}
        <div
          className={cx(
            styles.childContent({ size }),
            contentClassName,
            footer ? undefined : sprinkles({ marginBottom: 'sp3' }),
          )}>
          {children}
        </div>
        {footer}
      </Dialog.Content>
    </div>
  );

  return (
    <Dialog.Root
      defaultOpen={defaultOpen}
      onOpenChange={(open) => {
        if (open && onOpen) onOpen();
        else if (!open && onClose) onClose();
      }}
      open={isOpen}>
      {trigger ? <Dialog.Trigger asChild>{trigger}</Dialog.Trigger> : null}
      {portalContainerId ? (
        <Dialog.Portal container={document.getElementById(portalContainerId)}>
          {content}
        </Dialog.Portal>
      ) : (
        content
      )}
    </Dialog.Root>
  );
};
