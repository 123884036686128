import { Menu, MenuItem, PopoverPosition } from '@blueprintjs/core';
import {
  ItemListPredicate,
  ItemListRenderer,
  ItemRenderer,
  MultiSelect,
} from '@blueprintjs/select';
import { Theme, makeStyles } from '@material-ui/core/styles';
import cx from 'classnames';
import { useContext, useEffect, useState } from 'react';

import { SelectedDropdownInputItem } from 'constants/types';
import { GLOBAL_STYLE_CLASSNAMES } from 'globalStyles';
import InputLabel from 'shared/InputLabel';
import { map, uniqueId, xorBy } from 'utils/standard';

import DashboardLayoutContext from './DashboardLayout/DashboardLayoutContext';
import { withWidth } from './HOCs/withWidth';
import { EmbedFilterLabel } from './embed';
import * as styles from './multiSelect.css';

const useStyles = makeStyles((theme: Theme) => ({
  fullWidthRoot: {
    width: '100%',
    position: 'relative',

    '& .bp3-popover-target': {
      width: '100%',
    },

    '& .bp3-transition-container': {
      minWidth: '100%',

      '& .bp3-popover': {
        width: '100%',
        minWidth: 120,
        boxShadow: 'none',
        border: `1px solid ${theme.palette.ds.grey300}`,
      },
    },
  },
  menuList: {
    maxHeight: 200,
    // blueprint puts a minwidth on the menu items which causes the menu items to
    // overflow the menu containers. Unset the min width so that we can control it
    // more granularly at the menu level
    minWidth: 'unset',
    overflow: 'auto',
  },
  dropdownMenuItem: {
    '&.bp3-menu-item': {
      display: 'flex',
      alignItems: 'center',
    },
  },
  input: {
    height: '32px !important',
    overflowY: 'auto',

    '&::-webkit-scrollbar': {
      width: 5,
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'transparent',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.ds.grey500,
      borderRadius: 5,
    },
  },
}));

const usePopoverStyles = makeStyles<Theme, { width?: number }>(() => ({
  popoverContainer: { width: ({ width }) => width ?? 'auto' },
}));

type Props = {
  containerClassName?: string;
  selectedItems: SelectedDropdownInputItem[];
  updateSelectedValues: (values: string[] | number[] | undefined) => void;
  options: SelectedDropdownInputItem[];
  disabled?: boolean;
  label?: string;
  placeholder?: string;
  disableOnNoItems?: boolean;
  usePortal?: boolean;
  width?: number;
  openElementToLeft?: boolean;
  infoTooltipText?: string;
  isEmbed?: boolean;
};

const MultiSelectElem = (props: Props) => {
  const classes = useStyles();

  const { dashboardLayoutTagId } = useContext(DashboardLayoutContext);
  const { popoverContainer } = usePopoverStyles({ width: props.width });

  const [query, setQuery] = useState('');
  const [currSelectedItems, setCurrSelectedItems] = useState<SelectedDropdownInputItem[]>(
    props.selectedItems,
  );

  useEffect(() => {
    setCurrSelectedItems(props.selectedItems);
  }, [props.selectedItems]);

  const toggleItem = (item: SelectedDropdownInputItem) => {
    const currValues = new Set(map(currSelectedItems, 'value'));
    if (currValues.has(item.value)) currValues.delete(item.value);
    else currValues.add(item.value);

    const newSelectedOptions = props.options.filter((opt) => currValues.has(opt.value));
    setCurrSelectedItems(newSelectedOptions);
  };

  const listPredicate: ItemListPredicate<SelectedDropdownInputItem> = (query, items) => {
    if (query.length === 0) return items;
    const lowerQuery = query.toLowerCase();
    return items.filter((item) => item.name.toLowerCase().includes(lowerQuery));
  };

  const isItemSelected = (itemId: string) =>
    currSelectedItems.findIndex((selectedItem) => selectedItem.id === itemId) > -1;

  const itemRenderer: ItemRenderer<SelectedDropdownInputItem> = (
    item: SelectedDropdownInputItem,
    { handleClick, modifiers, query },
  ) => {
    let itemText;
    if (query.length > 0) {
      const text = item.name.split(query);
      itemText = [];
      for (let i = 0; i <= text.length - 2; i++) {
        itemText.push(text[i]);
        itemText.push(<b key={uniqueId('query_text')}>{query}</b>);
      }
      itemText.push(text[text.length - 1]);
    } else {
      itemText = item.name;
    }

    return (
      <MenuItem
        active={modifiers.active}
        className={cx(
          classes.dropdownMenuItem,
          GLOBAL_STYLE_CLASSNAMES.text.body.dropdownMenuItem,
          GLOBAL_STYLE_CLASSNAMES.base.actionColor.default.dropdownInputMenuItem,
        )}
        icon={isItemSelected(item.id) ? 'tick' : 'blank'}
        key={item.id}
        onClick={handleClick}
        text={itemText}
      />
    );
  };

  const itemListRenderer: ItemListRenderer<SelectedDropdownInputItem> = ({
    filteredItems,
    renderItem,
    itemsParentRef,
  }) => {
    let itemsList;
    if (filteredItems.length === 0) {
      itemsList = <MenuItem disabled text="No results." />;
    } else if (filteredItems.length > 100) {
      itemsList = filteredItems.slice(0, 100).map(renderItem);
      itemsList.push(<MenuItem disabled key="no_results" text="Filter for more results." />);
    } else {
      itemsList = filteredItems.map((item, idx) => renderItem(item, idx));
    }

    return (
      <Menu className={classes.menuList} ulRef={itemsParentRef}>
        {itemsList}
      </Menu>
    );
  };

  const usePortal = !!props.usePortal;

  return (
    <div className={props.containerClassName}>
      {props.isEmbed ? (
        <EmbedFilterLabel helpText={props.infoTooltipText}>{props.label}</EmbedFilterLabel>
      ) : props.label ? (
        <InputLabel helpText={props.infoTooltipText} text={props.label} />
      ) : null}
      <MultiSelect
        className={cx(
          classes.fullWidthRoot,
          styles.multiSelect,
          GLOBAL_STYLE_CLASSNAMES.base.actionColor.interactionStates.multiSelectInputBorderHover,
          GLOBAL_STYLE_CLASSNAMES.base.actionColor.default.multiSelectInputOutlineActive,
          GLOBAL_STYLE_CLASSNAMES.base.actionColor.default.multiSelectInputTag,
          GLOBAL_STYLE_CLASSNAMES.container.outline.multiSelectInputBorder,
          GLOBAL_STYLE_CLASSNAMES.container.shadow.multiSelectInputShadow,
          GLOBAL_STYLE_CLASSNAMES.container.outline.popoverBorder,
          GLOBAL_STYLE_CLASSNAMES.container.cornerRadius.inputFields.multiselectBorderRadius,
        )}
        itemListPredicate={listPredicate}
        itemListRenderer={itemListRenderer}
        itemRenderer={itemRenderer}
        items={props.options}
        noResults={<MenuItem disabled={true} text="No results." />}
        onItemSelect={(item) => {
          toggleItem(item);
          setQuery('');
        }}
        onQueryChange={setQuery}
        onRemove={(item) => toggleItem(item)}
        placeholder={props.placeholder}
        popoverProps={{
          boundary: 'window',
          onClosed: () => {
            const hasDiff = xorBy(currSelectedItems, props.selectedItems, 'value').length > 0;
            if (!hasDiff) return;

            const newValues = currSelectedItems.map((item) => item.value) as string[] | number[];
            props.updateSelectedValues(newValues.length === 0 ? undefined : newValues);
          },
          minimal: true,
          usePortal,
          portalContainer: usePortal
            ? document.getElementById(dashboardLayoutTagId) ?? document.body
            : undefined,
          popoverClassName: popoverContainer,
          position: props.openElementToLeft
            ? PopoverPosition.BOTTOM_RIGHT
            : PopoverPosition.BOTTOM_LEFT,
        }}
        query={query}
        resetOnQuery={false}
        selectedItems={currSelectedItems}
        tagInputProps={{
          disabled: props.disabled || (props.disableOnNoItems && props.options.length === 0),
          className: cx(classes.input, GLOBAL_STYLE_CLASSNAMES.text.body.input),
        }}
        tagRenderer={(item) => item.name}
      />
    </div>
  );
};

export default withWidth(MultiSelectElem);
