import cx from 'classnames';
import { forwardRef, useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { UNSUPPORTED_CHART_ERROR } from 'constants/dashboardConstants';
import { UserTransformedSchema } from 'constants/types';
import { GLOBAL_STYLE_CLASSNAMES } from 'globalStyles';
import { embedSprinkles } from 'globalStyles/sprinkles.css';
import { DashboardDatasetView } from 'pages/dashboardPage/DashboardDatasetView';
import { PanelError } from 'pages/dashboardPage/DashboardDatasetView/PanelError';
import { DashboardStates } from 'reducers/rootReducer';
import * as RD from 'remotedata';
import { DashboardElement, DashboardVariableMap, PAGE_TYPE } from 'types/dashboardTypes';
import { DataPanel, ResourceDataset } from 'types/exploResource';
import { getDashboardTimezone, prepareDataPanel } from 'utils/dashboardUtils';
import { getDataPanelDatasetId } from 'utils/exploResourceUtils';

import { DataPanelLinkOverlay } from './DataPanelLinkOverlay';
import { IdTag } from './IdTag';
import * as styles from './wrapperStyles.css';

type Props = {
  dashboardElements: DashboardElement[];
  // If editable section chart got deleted then there is no DP
  dataPanel: DataPanel | undefined;
  datasetNamesToId: Record<string, string>;
  datasets: Record<string, ResourceDataset>;
  dpEndsOnRightSide: boolean | undefined;
  hideEditingElements?: boolean;
  isDemoCustomer?: boolean;
  isDragging: boolean;
  isEditing: boolean;
  isInContainer?: boolean;
  isResizing: boolean;
  isViewOnly: boolean;
  pageType: PAGE_TYPE;
  variables: DashboardVariableMap;
  defaultUserTransformedSchema?: UserTransformedSchema;
  isScreenshotDownload?: boolean;

  onSelect: () => void;
  onDelete?: () => void;
  stopDragging?: () => void;
};

export const DataPanelWrapper = forwardRef<HTMLDivElement, Props>(
  (
    {
      dataPanel,
      isDragging,
      isResizing,
      stopDragging,
      isViewOnly,
      dashboardElements,
      datasets,
      isDemoCustomer,
      dpEndsOnRightSide,
      pageType,
      variables,
      isEditing,
      onDelete,
      hideEditingElements,
      onSelect,
      datasetNamesToId,
      children,
      isInContainer,
      defaultUserTransformedSchema,
      isScreenshotDownload,
      ...props
    },
    ref,
  ) => {
    const { isSelected, viewMode, datasetData, dashboard } = useSelector(
      (state: DashboardStates) => ({
        isSelected: state.dashboardInteractions.selectedItem?.id === dataPanel?.id,
        datasetData: state.dashboardData.datasetData,
        viewMode: state.dashboardInteractions.interactionsInfo.viewMode,
        dashboard: state.embedDashboard.dashboard,
      }),
      shallowEqual,
    );
    const dashboardTimezone = getDashboardTimezone(RD.getOrDefault(dashboard, undefined));

    const preparedDataPanel = useMemo(() => {
      if (!dataPanel) return;

      return prepareDataPanel(
        variables,
        dataPanel,
        datasets,
        datasetData,
        dashboardElements,
        dashboardTimezone,
      );
    }, [variables, dataPanel, datasets, dashboardElements, datasetData, dashboardTimezone]);

    const applyHoverClass = isEditing && !isSelected && !isDragging;

    return (
      <div
        {...props}
        className={cx(styles.dataPanel, {
          [styles.selectedItem]: isSelected,
          [styles.editableElement]: isEditing,
          [styles.hoverElement]: applyHoverClass,
        })}
        onClick={(e) => {
          if (isEditing && !isSelected) onSelect();
          e.stopPropagation();
        }}
        ref={ref}>
        {isEditing && !hideEditingElements ? (
          <IdTag
            name={onDelete ? undefined : dataPanel?.provided_id}
            onDelete={onDelete}
            stopDragging={stopDragging}
          />
        ) : null}
        {preparedDataPanel ? (
          <>
            <DashboardDatasetView
              canDownloadDataPanel
              dataPanel={preparedDataPanel}
              datasetNamesToId={datasetNamesToId}
              datasets={datasets}
              defaultUserTransformedSchema={defaultUserTransformedSchema}
              displayDemoWatermark={isDemoCustomer}
              dpEndsOnRightSide={dpEndsOnRightSide}
              editableDashboard={isEditing}
              isInContainer={isInContainer}
              isScreenshotDownload={isScreenshotDownload}
              isSelected={isSelected}
              isUpdatingPosition={isResizing || isDragging}
              isViewOnly={isViewOnly}
              pageType={pageType}
              variables={variables}
              viewMode={viewMode}
            />
            {isEditing && dataPanel ? (
              <DataPanelLinkOverlay
                dataPanelDatasetId={getDataPanelDatasetId(preparedDataPanel)}
                dataPanelId={dataPanel.id}
                datasets={datasets}
                operationType={dataPanel.visualize_op.operation_type}
              />
            ) : null}
          </>
        ) : (
          <PanelError
            className={cx(
              embedSprinkles({ borderRadius: 'container' }),
              GLOBAL_STYLE_CLASSNAMES.container.outline.border,
              GLOBAL_STYLE_CLASSNAMES.container.shadow.dropShadow,
            )}
            description={UNSUPPORTED_CHART_ERROR.DESCRIPTION}
            title={UNSUPPORTED_CHART_ERROR.TITLE}
          />
        )}
        {/* Children here is the resizer handle, so not needed unless editing */}
        {isEditing && !hideEditingElements ? children : null}
      </div>
    );
  },
);

DataPanelWrapper.displayName = 'DataPanelWrapper';
