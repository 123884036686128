import { CustomerEditableSection } from 'actions/dashboardActions';
import {
  FetchDashboardDatasetPreviewData,
  FetchDataPanelData,
  FetchDataPanelRowCountData,
} from 'actions/responseTypes';
import { EmbedCustomer, EmbedTeam } from 'actions/teamActions';
import { ACTION } from 'actions/types';
import { GlobalFontConfig, GlobalStyleConfig } from 'globalStyles/types';
import { CustomerDashboardState } from 'types/customerDashboardStateTypes';
import { EmbedDashboard } from 'types/dashboardTypes';
import { DashboardVersion } from 'types/dashboardVersion';
import { ResourceType } from 'types/exploResource';

import { defineAPIAction, defineEmbedAction, defineEmbedPostAction } from './actionUtils';
import {
  FetchDataPanelBody,
  FetchDataPanelRowCountBody,
  FetchSecondaryDataBody,
} from './dataPanelTemplateAction';
import { FetchDashboardDatasetPreviewBody } from './datasetActions';

// Dashboard

type EmbedFetchDashboardBody = {
  dashboard_embed_id: string | undefined;
  version_number: number | undefined;
  environment: string | undefined;
  is_preview?: boolean;
};

export type EmbedFidoDashboardDataConfig = {
  token: string;
  url: string;
  data_source_mapping: Record<string, string>;
  // TODO(tarastentz): Remove this once we don't need to shift individual data sources back to job queue
  data_source_preferred_execution_mapping?: Record<string, string>;
};

type DashboardHierarchyResponse = {
  root_dashboard_id: string;
  dashboards: Record<string, EmbedDashboard>;
};

type DashboardVersionHierarchyResponse = Record<string, DashboardVersion>;

type EmbedFetchDashboardData = {
  dashboard_version: DashboardVersion;
  style_config_v2: GlobalStyleConfig | null;
  font_config: GlobalFontConfig | null;
  dashboard_template: EmbedDashboard;
  team: EmbedTeam;
  customer: EmbedCustomer;
  additional_style_configs: Record<string, GlobalStyleConfig>;
  customer_editable_section: CustomerEditableSection | null;
  customer_dashboard_state: CustomerDashboardState | null;
  fido_config?: EmbedFidoDashboardDataConfig | null;
  data_panel_id?: string;
  expiration?: number;
  // TODO(zifanxiang/tarastentz): Remove this once we completely rollout drilldowns.
  dashboard_hierarchy?: DashboardHierarchyResponse;
  dashboard_version_hierarchy?: DashboardVersionHierarchyResponse;
};

export const { actionFn: embedFetchDashboard, ...embedFetchDashboardActions } =
  defineEmbedPostAction<EmbedFetchDashboardBody, EmbedFetchDashboardData>(
    ACTION.EMBED_FETCH_DASHBOARD,
    'embed',
    'dashboard',
  );

// Dashboard Data

export const {
  actionFn: embedFetchDataPanel,
  requestAction: embedFetchDataPanelRequest,
  errorAction: embedFetchDataPanelError,
  successAction: embedFetchDataPanelSuccess,
} = defineEmbedPostAction<FetchDataPanelBody, FetchDataPanelData>(
  ACTION.EMBED_FETCH_DATA_PANEL_TEMPLATE,
  'embed',
  'get_data',
);

type EmbedFetchDataPanelRowCountBody = FetchDataPanelRowCountBody & {
  version_number: number;
};

export const {
  actionFn: embedFetchDataPanelRowCount,
  requestAction: embedFetchDataPanelRowCountRequest,
  successAction: embedFetchDataPanelRowCountSuccess,
  errorAction: embedFetchDataPanelRowCountError,
} = defineEmbedPostAction<EmbedFetchDataPanelRowCountBody, FetchDataPanelRowCountData>(
  ACTION.EMBED_FETCH_DATA_PANEL_ROW_COUNT,
  'embed',
  'get_row_count',
);

type EmbedFetchSecondaryDataBody = FetchSecondaryDataBody & { version_number: number };

export const {
  actionFn: embedFetchSecondaryData,
  requestAction: embedFetchSecondaryDataRequest,
  successAction: embedFetchSecondaryDataSuccess,
  errorAction: embedFetchSecondaryDataError,
} = defineEmbedPostAction<EmbedFetchSecondaryDataBody, FetchDataPanelData>(
  ACTION.EMBED_FETCH_SECONDARY_DATA,
  'embed',
  'get_data',
);

type EmbedFetchDashboardDatasetPreviewBody = FetchDashboardDatasetPreviewBody & {
  version_number: number;
  resource_embed_id: string;
};

export const {
  actionFn: embedFetchDashboardDatasetPreview,
  requestAction: embedFetchDashboardDatasetPreviewRequest,
  successAction: embedFetchDashboardDatasetPreviewSuccess,
  errorAction: embedFetchDashboardDatasetPreviewError,
} = defineEmbedPostAction<EmbedFetchDashboardDatasetPreviewBody, FetchDashboardDatasetPreviewData>(
  ACTION.EMBED_FETCH_DASHBOARD_DATASET_PREVIEW,
  'embed',
  'get_preview',
);

// Share Links

export type EmbedFetchShareData = {
  dashboard_embed_id: string;
  customer_token: string;
  user_group_name: string;
  version_number: number;
  is_strict_viewing_mode: boolean;
  favicon_url?: string;
  site_title?: string;
};

export const { actionFn: embedFetchShareData } = defineEmbedAction<EmbedFetchShareData>(
  ACTION.EMBED_FETCH_SHARE_DATA,
  'share',
  'get_share_data',
  'GET',
);

type EmbedFetchShareDataWithPasswordBody = {
  password: string;
  username: string | undefined;
};

export const { actionFn: embedFetchShareDataWithPassword } = defineEmbedPostAction<
  EmbedFetchShareDataWithPasswordBody,
  EmbedFetchShareData
>(ACTION.EMBED_FETCH_SHARE_DATA, 'share', 'get_share_data');

export interface EmbedIdConversionBody {
  convertedId: string;
  resourceType: ResourceType;
}

export const { actionFn: getResourceIdFromEmbedId } = defineAPIAction<EmbedIdConversionBody>(
  ACTION.GET_RESOURCE_ID_FROM_EMBED_ID,
  'embed',
  'get_resource_id_from_embed_id',
  'GET',
);
