import cx from 'classnames';
import { DateTime } from 'luxon';
import { FC, useState, useEffect, forwardRef, ForwardedRef } from 'react';
import DatePicker from 'react-datepicker';

import {
  getDateOutOfBoundsErrorMessage,
  getDateRangeOutOfBoundsErrorMessage,
} from 'components/DatePickerInput/messageUtils';
import { Label, Input, sprinkles } from 'components/ds';
import { EmbedFilterLabel, EmbedInput } from 'components/embed';
import { dateTimeFromISOString, isDateBetweenLimits } from 'utils/dateUtils';
import { formatTime, getFormatForDatePicker, TIME_FORMATS } from 'utils/localizationUtils';
import { getDatePickerDateFromISO, getLuxonDateFromDatePicker } from 'utils/timezoneUtils';

import * as styles from './index.css';

export type Props = {
  className?: string;
  selectedValue?: DateTime;
  startDate?: DateTime;
  endDate?: DateTime;
  minDate?: DateTime;
  maxDate?: DateTime;
  onNewValueSelect: (date: DateTime | [DateTime, DateTime | undefined] | null) => void;
  showCancelBtn?: boolean;
  showTimeSelect?: boolean;
  disabled?: boolean;
  withPortal?: boolean;
  selectsRange?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  customInput?: (onClick: any, ref: any) => JSX.Element;
  onCalendarClose?: () => void;
  onCalendarOpen?: () => void;
  placeholder?: string;
  /**
   * If passed in, the input will be positioned as if it had a label (with spacing above it)
   * but no words will be visible
   */
  portalId?: string;
  openElementToLeft?: boolean;
  label?: string;
  labelHelpText?: string;
  isEmbed?: boolean;
};

/*
  Note: The react-datepicker takes in user inputs as local time, we convert to UTC
  to store the date, but the convert it back to local time to display so that it looks
  the same as they selected in the UI
*/

// TODO: Needs to be split into DS and Embed components
export const DatePickerInput: FC<Props> = ({
  className,
  selectedValue,
  onNewValueSelect,
  showCancelBtn,
  showTimeSelect,
  disabled,
  withPortal,
  endDate,
  maxDate,
  minDate,
  selectsRange,
  customInput,
  onCalendarClose,
  onCalendarOpen,
  placeholder,
  portalId,
  label,
  labelHelpText,
  isEmbed,
  openElementToLeft,
}) => {
  const [currentRange, setCurrentRange] = useState<[DateTime | undefined, DateTime | undefined]>([
    selectedValue,
    endDate,
  ]);
  const [startValue, endValue] = currentRange;
  const [errorText, setErrorText] = useState<string>('');

  useEffect(() => {
    setCurrentRange([selectedValue, endDate]);
  }, [selectsRange, selectedValue, endDate]);

  const RenderedLabel = isEmbed ? EmbedFilterLabel : Label;

  const CustomInputElement =
    // eslint-disable-next-line
    customInput && forwardRef(({ onClick }: any, ref) => customInput(onClick, ref));
  const formattedMaxDate =
    maxDate && typeof maxDate === 'string' ? dateTimeFromISOString(maxDate) : maxDate;
  const formattedMinDate =
    minDate && typeof minDate === 'string' ? dateTimeFromISOString(minDate) : minDate;

  const initialText = renderInputText(startValue ?? null, endValue);

  const handleChange = (value: Date | [Date, Date] | null) => {
    if (Array.isArray(value)) {
      const [datejs1, datejs2] = value as [Date, Date];

      let date1: DateTime = getLuxonDateFromDatePicker(datejs1);
      let date2: DateTime | undefined = datejs2 ? getLuxonDateFromDatePicker(datejs2) : undefined;

      if (!showTimeSelect) {
        date1 = date1 ? date1.startOf('day') : date1;
        date2 = date2 ? date2.startOf('day') : date2;
      }

      const date1InLimits = isDateBetweenLimits(date1, formattedMinDate, formattedMaxDate);
      const date2InLimits = date2
        ? isDateBetweenLimits(date2, formattedMinDate, formattedMaxDate)
        : true;
      if (!date1InLimits || !date2InLimits) {
        setCurrentRange([date1, date2]);
        setErrorText(
          date2
            ? getDateRangeOutOfBoundsErrorMessage(date1, date2, formattedMinDate, formattedMaxDate)
            : getDateOutOfBoundsErrorMessage(date1, formattedMinDate, formattedMaxDate),
        );
        return;
      }

      setCurrentRange([date1, date2]);
      onNewValueSelect([date1, date2]);
      setErrorText('');
    } else if (value) {
      let date = getLuxonDateFromDatePicker(value as Date);
      // The user may have selected a time, but then toggled off
      // show time select. In this case, we have to clear the
      // time originally set by the user, and default to 0 local time
      if (date && !showTimeSelect) {
        date = date.startOf('day');
      }

      const dateInLimits = isDateBetweenLimits(date, formattedMinDate, formattedMaxDate);
      if (!dateInLimits) {
        setCurrentRange([date, undefined]);
        setErrorText(getDateOutOfBoundsErrorMessage(date, formattedMinDate, formattedMaxDate));
        return;
      }

      setCurrentRange([date, undefined]);
      onNewValueSelect(date);
      setErrorText('');
    } else {
      setCurrentRange([undefined, undefined]);
      onNewValueSelect(null);
      setErrorText('');
    }
  };

  return (
    <div className={cx(styles.root, className)}>
      {label ? (
        <RenderedLabel helpText={labelHelpText} htmlFor="">
          {label}
        </RenderedLabel>
      ) : null}
      <div className={sprinkles({ position: 'relative' })} style={{ height: 32 }}>
        <DatePicker
          disabledKeyboardNavigation
          showMonthDropdown
          showYearDropdown
          customInput={
            CustomInputElement ? (
              <CustomInputElement />
            ) : (
              <TextInput
                renderErrorTextAsIcon
                errorText={errorText}
                initialText={initialText}
                isEmbed={isEmbed}
                onSubmit={handleChange}
                showCancelBtn={showCancelBtn}
              />
            )
          }
          dateFormat={
            showTimeSelect
              ? getFormatForDatePicker(TIME_FORMATS['MM/DD/YYYY h:mm aa'])
              : getFormatForDatePicker(TIME_FORMATS['MM/DD/YYYY'])
          }
          disabled={disabled}
          endDate={endValue ? getDatePickerDateFromISO(endValue.toISO()) : null}
          maxDate={formattedMaxDate?.toJSDate()}
          minDate={formattedMinDate?.toJSDate()}
          onCalendarClose={onCalendarClose}
          onCalendarOpen={onCalendarOpen}
          onChange={handleChange}
          openToDate={
            startValue && !errorText ? getDatePickerDateFromISO(startValue.toISO()) : undefined
          }
          placeholderText={placeholder}
          popperClassName={cx(styles.datePickerPopover, styles.customStylesPopoverText)}
          popperModifiers={{
            // This needs to be disabled otherwise the popper in containers won't work properly
            preventOverflow: {
              enabled: portalId === undefined,
            },
          }}
          popperPlacement={openElementToLeft ? 'bottom-end' : 'bottom-start'}
          portalId={portalId}
          selected={
            startValue && !selectsRange && !errorText
              ? getDatePickerDateFromISO(startValue.toISO())
              : null
          }
          selectsRange={selectsRange}
          shouldCloseOnSelect={false}
          showTimeSelect={showTimeSelect}
          startDate={startValue && !errorText ? getDatePickerDateFromISO(startValue.toISO()) : null}
          withPortal={withPortal}
        />
      </div>
    </div>
  );
};

const TextInput: React.ForwardRefExoticComponent<
  Omit<
    React.PropsWithoutRef<React.InputHTMLAttributes<HTMLInputElement>>,
    'onSubmit' | 'defaultValue' | 'onBlur'
  > &
    Pick<Props, 'isEmbed' | 'showCancelBtn'> & {
      initialText?: string;
      onSubmit: (value: Date | [Date, Date] | null) => void;
      errorText: string;
      renderErrorTextAsIcon: boolean;
    }
> &
  React.RefAttributes<HTMLInputElement> = forwardRef(
  (
    {
      onClick,
      initialText,
      isEmbed,
      onSubmit,
      showCancelBtn,
      errorText,
      renderErrorTextAsIcon,
      ...rest
    },
    ref: ForwardedRef<HTMLInputElement>,
  ) => {
    const [inputText, setInputText] = useState<string | undefined>(initialText);
    const RenderedInput = isEmbed ? EmbedInput : Input;

    // Remove the onKeyDown prop to ensure manual key event handling. Allowing the React date picker
    // to manage key events could override the set date with its internal state (The React date
    // picker creates a copy of this element and passes in its own onKeyDown prop).
    delete rest.onKeyDown;

    useEffect(() => {
      setInputText(initialText);
    }, [initialText]);

    const handleInputSubmit = (value: string) => {
      if (value.includes('-')) {
        const [date1, date2] = value.split('-');
        // struggling to parse these - maybe just return JS date and use onChange method?
        const date1Parsed = new Date(date1);
        const date2Parsed = new Date(date2);
        if (isNaN(date1Parsed.getTime()) || isNaN(date2Parsed.getTime())) return;

        onSubmit([date1Parsed, date2Parsed]);
      } else if (value) {
        const date = new Date(value);
        if (isNaN(date.getTime())) return;

        onSubmit(date);
      } else {
        onSubmit(null);
      }
    };

    // Don't inherit onChange and value from rest
    return (
      <RenderedInput
        {...rest}
        fillWidth
        defaultValue={inputText ?? ''}
        errorText={errorText}
        onChange={undefined}
        onClick={onClick}
        onSubmit={handleInputSubmit}
        ref={ref}
        renderErrorTextAsIcon={renderErrorTextAsIcon}
        showInputButton={showCancelBtn}
        value={undefined}
      />
    );
  },
);

TextInput.displayName = 'TextInput';

const renderInputText = (startDate: DateTime | null, endDate?: DateTime | null) => {
  // We show the dates in the date picker in the default timezone but store them in the `variables` in
  // UTC. We need to convert to the default timezone when displayed
  if (startDate && endDate) {
    return `${formatTime(startDate, TIME_FORMATS['MM/DD/YYYY'])}-${formatTime(
      endDate,
      TIME_FORMATS['MM/DD/YYYY'],
    )}`;
  } else if (startDate && !endDate) {
    return `${formatTime(startDate, TIME_FORMATS['MM/DD/YYYY'])}`;
  }

  return '';
};
