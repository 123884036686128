import { FC, ReactNode, ComponentType } from 'react';
import { ErrorBoundary as ReactErrorBoundary, FallbackProps } from 'react-error-boundary';

import { sendDatadogError } from 'telemetry/datadog';

type Props = {
  children: ReactNode;
  FallbackComponent: ComponentType<FallbackProps>;
};

export const ErrorBoundary: FC<Props> = ({ children, FallbackComponent }) => (
  <ReactErrorBoundary FallbackComponent={FallbackComponent} onError={sendDatadogError}>
    {children}
  </ReactErrorBoundary>
);
