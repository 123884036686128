import { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { clearDashboardConfigReducer } from 'actions/dashboardV2Actions';
import { listTeamDataSources } from 'actions/dataSourceActions';
import { fetchEnvironmentTags } from 'actions/environmentTagActions';
import { fetchAllSchemaTables, fetchUsedParentSchemas } from 'actions/parentSchemaActions';
import { getSelectedCustomer } from 'reducers/customersReducer';
import { setInteractionsInfo } from 'reducers/dashboardInteractionsReducer';
import { clearComputedViews } from 'reducers/fidoReducer';
import { clearReportBuilderReducer } from 'reducers/reportBuilderEditReducer';
import { DashboardStates, ReduxState } from 'reducers/rootReducer';
import { initializeCustomerSelectorThunk } from 'reducers/thunks/customerThunks';
import { getComputedViews, getNamespaces } from 'reducers/thunks/fidoThunks';
import { isIdle, isSuccess, hasNotReturned } from 'remotedata';
import { VIEW_MODE } from 'types/dashboardTypes';

// This custom hook loads all the basic data needed for editing experience,
// and returns the loading state of the metadata - no need to load customers in
export function useLoadEditMetadata(viewIds: string[] | undefined) {
  const dispatch = useDispatch();
  const {
    parentSchemas,
    schemaTablesMap,
    selectedCustomer,
    fetchCustomerStatus,
    dataSources,
    shouldUseFido,
    fido,
    envTags,
  } = useSelector(
    (state: ReduxState) => ({
      parentSchemas: state.parentSchemas.usedParentSchemas,
      schemaTablesMap: state.parentSchemas.schemaTablesMap,
      selectedCustomer: getSelectedCustomer(state.customers),
      fetchCustomerStatus: state.customers.fetchCustomerStatus,
      dataSources: state.dataSource.dataSources,
      fido: state.fido,
      shouldUseFido: state.currentUser.team?.feature_flags.use_fido,
      envTags: state.environmentTags.tags,
    }),
    shallowEqual,
  );

  const { computedViews, fidoDaos, embeddoDaos } = fido;

  useEffect(() => {
    if (isIdle(envTags)) dispatch(fetchEnvironmentTags());
  }, [dispatch, envTags]);

  useEffect(() => {
    if (isIdle(parentSchemas)) dispatch(fetchUsedParentSchemas());
  }, [dispatch, parentSchemas]);

  useEffect(() => {
    if (isIdle(dataSources)) dispatch(listTeamDataSources());
  }, [dispatch, dataSources]);

  useEffect(() => {
    if (isIdle(schemaTablesMap)) dispatch(fetchAllSchemaTables());
  }, [dispatch, schemaTablesMap]);

  useEffect(() => {
    if (!selectedCustomer && isIdle(fetchCustomerStatus)) {
      dispatch(initializeCustomerSelectorThunk(true));
    }
  }, [dispatch, selectedCustomer, fetchCustomerStatus]);

  useEffect(() => {
    if (!shouldUseFido || !viewIds?.length) return;

    dispatch(getComputedViews(viewIds));
  }, [dispatch, viewIds, shouldUseFido]);

  useEffect(() => {
    if (!shouldUseFido || !isIdle(fidoDaos)) return;

    dispatch(getNamespaces());
  }, [dispatch, fidoDaos, shouldUseFido, embeddoDaos]);

  useEffect(() => {
    return function clearEdit() {
      dispatch(clearDashboardConfigReducer());
      dispatch(clearReportBuilderReducer());
      dispatch(clearComputedViews());
    };
  }, [dispatch]);

  const isFidoLoaded =
    !shouldUseFido || ((viewIds?.length === 0 || isSuccess(computedViews)) && isSuccess(fidoDaos));

  /**
   * For customer logic: in order to load a dashboard we need to know who our selected customer is first. This means we should be in the loading
   * state so long as we have not populated our selected customer and we have not finished trying to fetch our initial customer.
   */
  return (
    !isSuccess(schemaTablesMap) ||
    (!selectedCustomer && hasNotReturned(fetchCustomerStatus)) ||
    !isSuccess(parentSchemas) ||
    !isFidoLoaded
  );
}

/**
 * A simple React hook for differentiating single and double clicks on the same component.
 *
 * @param {number} [latency=300] The amount of time (in milliseconds) to wait before differentiating a single from a double click
 * @param {function} onSingleClick A callback function for single click events
 * @param {function} onDoubleClick A callback function for double click events
 */
export const useDoubleClick = ({
  latency = 300,
  onSingleClick,
  onDoubleClick,
}: {
  latency?: number;
  onSingleClick: () => void;
  onDoubleClick: () => void;
}) => {
  const [click, setClick] = useState(0);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (click === 1) onSingleClick();
      setClick(0);
    }, latency);

    if (click === 2) onDoubleClick();

    return () => {
      clearTimeout(timeout);
    };
  }, [click, latency, onSingleClick, onDoubleClick]);

  return { triggerClick: () => setClick((prev) => prev + 1) };
};

type InteractionsProps = {
  isEditing?: boolean;
  updateUrlParams?: boolean;
  viewMode?: VIEW_MODE;
  disableFiltersWhileLoading?: boolean;
  disableInputs?: boolean;
  supportEmail?: string;
  disableEditingEditableSection?: boolean;
  hideEditableSectionEditControls?: boolean;
  shouldPersistCustomerState?: boolean;
};

// This hook makes sure that only the latest in interactions info state is being used
export const useDashboardInteractionsInfo = ({
  isEditing,
  viewMode,
  updateUrlParams,
  disableFiltersWhileLoading,
  supportEmail,
  disableInputs,
  disableEditingEditableSection,
  hideEditableSectionEditControls,
  shouldPersistCustomerState,
}: InteractionsProps) => {
  const dispatch = useDispatch();

  const latestInteractionsInfo = useSelector(
    (state: DashboardStates) => state.dashboardInteractions.interactionsInfo,
  );

  useEffect(() => {
    dispatch(
      setInteractionsInfo({
        isEditing: isEditing ?? false,
        viewMode: viewMode ?? VIEW_MODE.DEFAULT,
        updateUrlParams,
        disableFiltersWhileLoading,
        disableInputs,
        supportEmail,
        disableEditingEditableSection,
        hideEditableSectionEditControls,
        shouldPersistCustomerState,
      }),
    );
  }, [
    dispatch,
    isEditing,
    viewMode,
    updateUrlParams,
    disableFiltersWhileLoading,
    disableInputs,
    supportEmail,
    disableEditingEditableSection,
    shouldPersistCustomerState,
    hideEditableSectionEditControls,
  ]);

  return latestInteractionsInfo;
};
