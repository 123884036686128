import * as RadixSlider from '@radix-ui/react-slider';
import cx from 'classnames';
import { FC, useState } from 'react';

import * as styles from './index.css';

export type Props = RadixSlider.SliderProps & {
  numThumbs: number;
  showThumbLabels?: boolean;
  rangeClassName?: string;
  thumbClassName?: string;
};

export const Slider: FC<Props> = ({
  numThumbs,
  className,
  rangeClassName = styles.SliderRange,
  thumbClassName = styles.SliderThumb,
  value,
  onValueChange,
  showThumbLabels = false,
  ...rootProps
}) => {
  const [localValue, setLocalValue] = useState(rootProps.defaultValue ?? value ?? []);
  const currentValue = value ?? localValue;

  const handleValueChange = (newValue: number[]) => {
    setLocalValue(newValue);
    onValueChange?.(newValue);
  };

  return (
    <RadixSlider.Root
      className={cx(styles.SliderRoot, className)}
      onValueChange={handleValueChange}
      value={value}
      {...rootProps}>
      <RadixSlider.Track className={styles.SliderTrack}>
        <RadixSlider.Range className={rangeClassName} />
      </RadixSlider.Track>
      {[...Array(numThumbs)].map((_, i) => {
        const thumbValue = currentValue[i];
        return (
          <RadixSlider.Thumb className={styles.SliderThumbMargin} key={i}>
            <div className={thumbClassName}>
              {showThumbLabels && thumbValue != null ? (
                <div className={styles.ThumbValueLabel}>
                  {
                    // https://stackoverflow.com/questions/11832914/how-to-round-to-at-most-2-decimal-places-if-necessary
                    Math.round((thumbValue + Number.EPSILON) * 100) / 100
                  }
                </div>
              ) : null}
            </div>
          </RadixSlider.Thumb>
        );
      })}
    </RadixSlider.Root>
  );
};
