import { makeStyles, Theme } from '@material-ui/core/styles';
import cx from 'classnames';
import produce from 'immer';
import { FC, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { DatasetDataObject } from 'actions/datasetActions';
import BaseDataTable from 'components/dataTable/baseDataTable';
import LoadingDataTable from 'components/dataTable/loadingDataTable';
import { MAX_ROWS_TO_PREVIEW } from 'constants/dataConstants';
import {
  LegacyWidths,
  REPORTED_ANALYTIC_ACTION_TYPES,
  VisualizeOperationGeneralFormatOptions,
  VisualizePivotTableInstructions,
} from 'constants/types';
import { HEADER_HEIGHT } from 'pages/dashboardPage/DashboardDatasetView/DashboardDatasetView';
import { NeedsConfigurationPanel } from 'pages/dashboardPage/needsConfigurationPanel';
import { ResponseData, isSuccess } from 'remotedata';
import { sendAnalyticsEventThunk } from 'telemetry/telemetryThunks';
import { Metadata } from 'telemetry/telemetryUtils';
import { DashboardVariableMap } from 'types/dashboardTypes';
import { AdHocOperationInstructions } from 'types/dataPanelTemplate';
import { DatasetSchema, DatasetRow, DatabaseUnsupportedOperations } from 'types/datasets';
import { sortTable, getPrimarySortInfo } from 'utils/adHocUtils';
import { getMetricsByColumn } from 'utils/dashboardUtils';
import { isPivotTableReadyToDisplay } from 'utils/dataPanelConfigUtils';

import DataTableFooter from '../DataTable/DataTableFooter';

const FOOTER_HEIGHT = 34;

const useStyles = makeStyles((theme: Theme) => ({
  dataTable: {
    height: `calc(100% - ${HEADER_HEIGHT}px - ${FOOTER_HEIGHT}px) !important`,

    '&.noHeader': {
      height: `calc(100% - ${FOOTER_HEIGHT}px) !important`,
    },
    '&.noFooter': {
      height: `calc(100% - ${HEADER_HEIGHT}px) !important`,
    },
    '&.noHeader.noFooter': {
      height: `calc(100%) !important`,
    },
  },
  loadingDataTable: {
    height: `calc(100% - ${HEADER_HEIGHT}px - ${FOOTER_HEIGHT}px) !important`,
    borderBottom: `1px solid ${theme.palette.ds.grey500}`,
    '&.noHeader': {
      height: `calc(100% - ${FOOTER_HEIGHT}px) !important`,
    },
    '&.noFooter': {
      height: `calc(100% - ${HEADER_HEIGHT}px) !important`,
    },
    '&.noHeader.noFooter': {
      height: `calc(100%) !important`,
    },
  },
}));

type Props = {
  adHocOperationInstructions: AdHocOperationInstructions;
  loading?: boolean;
  onAdHocSortOrPageUpdate: (adHocOperationInstructions: AdHocOperationInstructions) => void;
  previewData: Record<string, string | number>[];
  schema: DatasetSchema;
  secondaryData: DatasetRow[];
  totalRowCount: ResponseData<number> | undefined;
  datasetData: DatasetDataObject;
  datasetNamesToId: Record<string, string>;
  generalOptions: VisualizeOperationGeneralFormatOptions | undefined;
  instructions: VisualizePivotTableInstructions | undefined;
  isEmbed: boolean;
  unsupportedOperations: DatabaseUnsupportedOperations[] | undefined;
  variables: DashboardVariableMap;
};

type WithAnalyticsTracker = {
  sendEvent: (eventName: REPORTED_ANALYTIC_ACTION_TYPES, metadata?: Metadata) => void;
};

const getOnColumnSelect =
  ({
    adHocOperationInstructions,
    onAdHocSortOrPageUpdate,
    sendEvent,
  }: Pick<Props, 'adHocOperationInstructions' | 'onAdHocSortOrPageUpdate'> &
    WithAnalyticsTracker) =>
  (colIndex: number, headerList: DatasetSchema) => {
    const sortInfo = sortTable(
      colIndex,
      headerList,
      adHocOperationInstructions,
      false,
      onAdHocSortOrPageUpdate,
    );

    sendEvent(REPORTED_ANALYTIC_ACTION_TYPES.TABLE_SORTED, {
      column_name: sortInfo.column_name,
      order: sortInfo.order.toString(),
    });
  };

const getOnNewPage =
  ({
    adHocOperationInstructions,
    onAdHocSortOrPageUpdate,
    sendEvent,
    totalRowCount,
  }: Pick<Props, 'adHocOperationInstructions' | 'onAdHocSortOrPageUpdate' | 'totalRowCount'> &
    WithAnalyticsTracker) =>
  (newPage: string) => {
    if (!isSuccess(totalRowCount)) return;

    const newPageNumber = Number.parseInt(newPage);
    const maxPageNumber = Math.ceil(totalRowCount.data / 50);

    if (
      !newPageNumber ||
      newPageNumber < 1 ||
      newPageNumber > maxPageNumber ||
      adHocOperationInstructions.currentPage === newPageNumber
    ) {
      return;
    }

    const newAdHocOperationInstructions = produce(adHocOperationInstructions, (draft) => {
      draft.currentPage = newPageNumber;
    });

    onAdHocSortOrPageUpdate(newAdHocOperationInstructions);

    sendEvent(REPORTED_ANALYTIC_ACTION_TYPES.TABLED_PAGED, { new_page: newPageNumber });
  };

export const PivotTable: FC<Props> = ({
  adHocOperationInstructions,
  loading,
  onAdHocSortOrPageUpdate,
  previewData,
  schema,
  secondaryData,
  totalRowCount,
  instructions,
  generalOptions,
  isEmbed,
  unsupportedOperations,
  variables,
  datasetData,
  datasetNamesToId,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const metricsByColumn = useMemo(() => getMetricsByColumn(secondaryData || []), [secondaryData]);

  const sendEvent = useCallback(
    (eventName: REPORTED_ANALYTIC_ACTION_TYPES, metadata?: Metadata) => {
      dispatch(sendAnalyticsEventThunk(eventName, metadata));
    },
    [dispatch],
  );

  const tableLoading = loading || !previewData;

  if (!isPivotTableReadyToDisplay(instructions)) {
    return <NeedsConfigurationPanel instructionsNeedConfiguration />;
  }

  const footer = (
    <DataTableFooter
      adHocOperationInstructions={adHocOperationInstructions}
      isEmbed={isEmbed}
      loading={tableLoading}
      onNewPage={getOnNewPage({
        adHocOperationInstructions,
        onAdHocSortOrPageUpdate,
        sendEvent,
        totalRowCount,
      })}
      totalRowCount={totalRowCount}
      unsupportedOperations={unsupportedOperations}
    />
  );

  if (tableLoading) {
    return (
      <>
        <LoadingDataTable
          disableRowHeader
          className={cx(classes.loadingDataTable, {
            noHeader: generalOptions?.headerConfig?.isHeaderHidden,
            noFooter: instructions?.isFooterHidden,
          })}
          maxRows={50}
          rowHeight={instructions?.rowHeight}
        />
        {!instructions?.isFooterHidden && footer}
      </>
    );
  }

  if (instructions?.displaySumRow) {
    const rowColName = instructions.rowColumn?.column.name;
    if (previewData.length && previewData[0] && rowColName) {
      previewData[0][rowColName] = instructions.sumRowText || 'Totals';
    }
  }

  return (
    <>
      <BaseDataTable
        disableRowHeader
        fill
        isDashboardTable
        noBorderRadius
        truncateEmptyRowSpace
        unrestrictedHeight
        useFriendlyNameForHeader
        changeSchemaList={instructions?.changeSchemaList}
        className={cx(classes.dataTable, {
          noHeader: generalOptions?.headerConfig?.isHeaderHidden,
          noFooter: instructions?.isFooterHidden,
        })}
        columnLinesEnabled={instructions?.isColumnLinesEnabled}
        columnWidths={instructions?.columnWidths as LegacyWidths}
        datasetData={datasetData}
        datasetNamesToId={datasetNamesToId}
        dateFormat={instructions?.dateFormat}
        enableColumnResizing={false}
        firstColumnTitle={instructions?.firstColumnTitle}
        firstRowBackgroundColor={
          instructions?.displaySumRow ? instructions?.sumRowBackgroundColor : undefined
        }
        isColumnHeadersBolded={instructions?.isColumnHeadersBolded}
        isFirstColumnBolded={instructions?.isFirstColumnBolded}
        isFirstColumnFrozen={instructions?.isFirstColumnFrozen}
        isFirstRowBolded={instructions?.displaySumRow && instructions?.sumRowBold}
        isSortable={!instructions?.isColumnSortingDisabled}
        loading={tableLoading}
        maxRows={MAX_ROWS_TO_PREVIEW}
        metricsByColumn={metricsByColumn}
        onColumnSelect={getOnColumnSelect({
          adHocOperationInstructions,
          onAdHocSortOrPageUpdate,
          sendEvent,
        })}
        rowHeight={instructions?.rowHeight}
        rowLinesDisabled={instructions?.isRowLinesDisabled}
        rows={previewData || []}
        schema={schema}
        schemaDisplayOptions={instructions?.schemaDisplayOptions || {}}
        shouldTruncateText={instructions?.shouldTruncateText}
        shouldVisuallyGroupByFirstColumn={instructions?.shouldVisuallyGroupByFirstColumn}
        sortInfo={getPrimarySortInfo(adHocOperationInstructions.sortInfo)}
        stringFormat={instructions?.stringFormat}
        variables={variables}
      />
      {footer}
    </>
  );
};
