const headElem = document.getElementsByTagName('HEAD')[0];
const stylePath = `${process.env.REACT_APP_EMBED_ASSET_URL}main.css`;

export const loadStyles = (onLoad: () => void) => {
  // If stylesheet isn't found, load it and then render
  if (!document.querySelector(`[href='${stylePath}']`)) {
    const linkElem = document.createElement('link');
    linkElem.rel = 'stylesheet';
    linkElem.type = 'text/css';
    linkElem.href = stylePath;
    linkElem.onload = onLoad;
    // Could happen if a font or something else fails to load.
    // But shouldn't block dashboard from being rendered
    linkElem.onerror = onLoad;
    headElem.prepend(linkElem);
  } else {
    // Render immediately if stylesheets are already loaded
    onLoad();
  }
};

// Remove the styles we added if there are no Explo web components in the DOM
export const unloadStyles = () => {
  const exploDashboards = document.getElementsByTagName('explo-dashboard');
  const exploReportBuilders = document.getElementsByTagName('explo-report-builder');

  if (exploDashboards.length === 0 && exploReportBuilders.length === 0) {
    const elem = document.querySelector(`[href='${stylePath}']`);
    if (elem) {
      headElem.removeChild(elem);
    }
  }
};
