import { EmbedCustomer, EmbedTeam } from 'actions/teamActions';
import { trackEvent } from 'telemetry/exploAnalytics';
import { EmbedDashboard } from 'types/dashboardTypes';

export const EDSInteraction = {
  EDIT: 'clicked `edit`',
  ADDED_CHART: '`added` chart',
  DELETED_CHART: '`deleted` chart',
  DISCARDED: '`discarded` state',
  SAVED: '`saved` state',
};
export type EDSInteraction = typeof EDSInteraction[keyof typeof EDSInteraction];

export const pingEDSInteractionMsg = ({
  customer,
  team,
  dashboard,
  interaction,
}: {
  customer: EmbedCustomer | undefined;
  team: EmbedTeam | undefined;
  dashboard: EmbedDashboard;
  interaction: EDSInteraction;
}) => {
  return (
    `"${customer?.name}" (on team "${team?.team_name}")` +
    ` on dashboard "${dashboard.name}" ${interaction} on the EDS`
  );
};

export const trackEDSInteractionEvent = ({
  customer,
  team,
  dashboard,
  interaction,
}: {
  customer: EmbedCustomer | undefined;
  team: EmbedTeam | undefined;
  dashboard: EmbedDashboard;
  interaction: EDSInteraction;
}) => {
  trackEvent(`${interaction} on EDS`, {
    customer: customer?.name,
    team: team?.team_name,
    dashboard: dashboard.name,
  });
};
