import cx from 'classnames';
import { FC, useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { ReportBuilderDataset } from 'actions/reportBuilderConfigActions';
import { Icon, sprinkles } from 'components/ds';
import { EmbedInput } from 'components/embed/EmbedInput';
import { EmbedText } from 'pages/ReportBuilder/EmbedText';
import { ReportBuilderReduxState } from 'reportBuilderContent/reducers/rootReducer';
import { filterByNameAndDescription } from 'utils/adHocUtils';
import { createThrottleFn } from 'utils/general';
import { sortBy } from 'utils/standard';

import * as styles from './DatasetSelection.css';

type Props = {
  selectedDatasetId?: string;
  onClick: (dataset: ReportBuilderDataset) => void;
};

const throttleFn = createThrottleFn(300);

export const DatasetSelection: FC<Props> = ({ onClick, selectedDatasetId }) => {
  const [searchQuery, setSearchQuery] = useState<string>('');

  const reportBuilderConfig = useSelector(
    (state: ReportBuilderReduxState) => state.embeddedReportBuilder.reportBuilderVersion?.config,
  );

  const sortedPopulatedDatasets = useMemo(() => {
    const populatedDatasets = Object.values(reportBuilderConfig?.datasets ?? {}).filter(
      ({ schema }) => schema?.length,
    );
    return sortBy(
      filterByNameAndDescription(searchQuery, populatedDatasets),
      (dataset) => dataset.name,
    );
  }, [reportBuilderConfig, searchQuery]);

  const renderDataset = useCallback(
    (dataset: ReportBuilderDataset) => {
      return (
        <div
          className={cx(styles.datasetItem, {
            [styles.selectedDatasetItem]: dataset.id === selectedDatasetId,
          })}
          key={dataset.id}
          onClick={() => onClick(dataset)}>
          <div>
            <EmbedText heading="h3">{dataset.name}</EmbedText>
            {dataset.description.trim() === '' ? null : (
              <div className={sprinkles({ marginTop: 'sp1' })}>
                <EmbedText body="b1">{dataset.description}</EmbedText>
              </div>
            )}
          </div>
          <Icon name="angle-right" />
        </div>
      );
    },
    [selectedDatasetId, onClick],
  );

  const renderedContent = useMemo(() => {
    if (sortedPopulatedDatasets.length) {
      return sortedPopulatedDatasets.map(renderDataset);
    } else {
      return (
        <EmbedText
          body="b1"
          className={sprinkles({ parentContainer: 'fill', flexItems: 'centerColumn' })}
          color="contentSecondary">
          No datasets.
        </EmbedText>
      );
    }
  }, [sortedPopulatedDatasets, renderDataset]);

  if (!reportBuilderConfig) return null;

  return (
    <div className={styles.container}>
      <EmbedText color="contentTertiary" heading="h3">
        Select a dataset
      </EmbedText>
      <EmbedInput
        className={sprinkles({ marginTop: 'sp2' })}
        leftIcon="search"
        onChange={(input) => throttleFn(() => setSearchQuery(input))}
        placeholder="Search datasets by name or description"
        value={searchQuery}
      />
      <div className={styles.datasetList}>{renderedContent}</div>
    </div>
  );
};
