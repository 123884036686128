import cx from 'classnames';

import { sprinkles } from 'components/ds';
import { EmbedInfoIcon, EmbedSpinner } from 'components/embed';
import { V2_NUMBER_FORMATS } from 'constants/dataConstants';
import {
  DEFAULT_NO_DATA_FONT_SIZE,
  KPITitleFormat,
  KPITrendDisplayFormat,
  KPIValueFormat,
  VisualizeOperationGeneralFormatOptions,
} from 'constants/types';
import { embedSprinkles } from 'globalStyles/sprinkles.css';
import { GlobalStyleConfig } from 'globalStyles/types';
import { formatValue } from 'pages/dashboardPage/charts/utils';
import { TrendChange } from 'shared/charts/TrendChange';

import * as styles from './numberTrendTextPanel.css';

interface Props {
  aggValuesLoading?: boolean;
  globalStyleConfig: GlobalStyleConfig;
  headerActions: JSX.Element | null;
  infoTooltipText?: string;
  currentPeriodValue: number;
  comparisonValue: number;
  subtitle: string;
  // How much trend changed as either an absolute (e.g. 400,000) or percentage (e.g. 0.4) value
  trendChangeVal?: number;
  // Shown after the percent change text e.g. +40% <Comparison Period>
  trendChangeValLabel: string;
  trendChangeValFormatId: string;
  noData: boolean;
  noDataPrevPeriod: boolean;
  valueFormat: KPIValueFormat | undefined;
  generalOptions: VisualizeOperationGeneralFormatOptions | undefined;
  displayFormat: KPITrendDisplayFormat | undefined;
  titleFormat: KPITitleFormat | undefined;
  processString: (s: string) => string;
}

export default function NumberTrendTextPanel(props: Props) {
  const {
    aggValuesLoading,
    infoTooltipText,
    headerActions,
    currentPeriodValue,
    comparisonValue,
    subtitle,
    noData,
    noDataPrevPeriod,
    generalOptions,
    titleFormat,
    valueFormat,
    processString,
  } = props;
  const formattedNumber = formatValue({
    value: currentPeriodValue,
    decimalPlaces: valueFormat?.decimalPlaces ?? 2,
    formatId: valueFormat?.numberFormat?.id || V2_NUMBER_FORMATS.NUMBER.id,
    hasCommas: true,
    customTimeFormat: valueFormat?.timeCustomerFormat,
    customDurationFormat: valueFormat?.customDurationFormat,
    timeFormatId: valueFormat?.timeFormat?.id,
    significantDigits: valueFormat?.significantDigits ?? 3,
    multiplier: valueFormat?.multiplyFactor,
  });

  const renderHeader = () => {
    if (generalOptions?.headerConfig?.isHeaderHidden) return;

    return (
      <div
        className={styles.titleClass({ alignment: titleFormat?.horizAlignment })}
        style={{ fontSize: titleFormat?.fontSize }}>
        {processString(generalOptions?.headerConfig?.title || '')}
        {aggValuesLoading ? (
          <EmbedSpinner className={styles.titleLoadingSpinner} size="md" />
        ) : null}
        {infoTooltipText ? <EmbedInfoIcon text={infoTooltipText} /> : null}
      </div>
    );
  };

  return (
    <>
      {headerActions ? <div className={styles.headerActionsStyle}>{headerActions}</div> : null}
      <div
        className={cx(
          sprinkles({
            flex: 2,
            parentContainer: 'fill',
            flexItems: 'centerColumn',
          }),
          embedSprinkles({ body: 'primaryWithoutColor' }),
        )}>
        <div className={styles.titleContainer({ alignment: titleFormat?.horizAlignment })}>
          {renderHeader()}
          {subtitle ? <div className={styles.subtitle}>{subtitle}</div> : null}
        </div>
        <div className={styles.valueContainer({ alignment: valueFormat?.horizAlignment })}>
          {noData ? (
            <div className={styles.numberTrendContainer}>
              <span
                className={embedSprinkles({ body: 'primary' })}
                style={{
                  fontSize:
                    generalOptions?.noDataState?.noDataFontSize || DEFAULT_NO_DATA_FONT_SIZE,
                }}>
                {processString(generalOptions?.noDataState?.noDataText || 'No Data')}
              </span>
            </div>
          ) : (
            <div
              className={cx(styles.numberTrendContainer, {
                [styles.bold]: valueFormat?.bold,
                [styles.italic]: valueFormat?.italic,
              })}>
              {formattedNumber}
              {valueFormat?.units && (
                <span className={cx(valueFormat.unitPadding && styles.numberUnit)}>
                  {valueFormat?.units}
                </span>
              )}
            </div>
          )}
          {!noData ? (
            <TrendChange
              comparisonValue={comparisonValue}
              currentValue={currentPeriodValue}
              displayFormat={props.displayFormat}
              globalStyleConfig={props.globalStyleConfig}
              noDataPrevPeriod={noDataPrevPeriod}
              trendChangeVal={props.trendChangeVal}
              trendChangeValFormatId={props.trendChangeValFormatId}
              trendChangeValLabel={props.trendChangeValLabel}
              valueFormat={props.valueFormat}
            />
          ) : null}
        </div>
      </div>
      {/* Dummy div to balance the header and vertically center the main content */}
      <div className={sprinkles({ flex: 1 })} />
    </>
  );
}
