import { makeStyles } from '@material-ui/core/styles';
import { useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { DatasetDataObject } from 'actions/datasetActions';
import { DashboardButtonGroupInput } from 'components/dashboardButtonGroupInput';
import { INPUT_TYPE, REPORTED_ANALYTIC_ACTION_TYPES } from 'constants/types';
import { sendAnalyticsEventThunk } from 'telemetry/telemetryThunks';
import {
  DateGroupToggleConfig,
  DashboardVariable,
  DateGroupToggleSelectTypes,
} from 'types/dashboardTypes';
import { getDateGroupSwitchOptions } from 'utils/dashboardUtils';
import { useStringWithVariablesReplaced } from 'utils/dataPanelConfigUtils';
import { VariableSelectOptions } from 'utils/extraVariableUtils';

import { DashboardDropdownElement } from './dashboardDropdownElement';

const useStyles = makeStyles(() => ({
  root: {
    '& .bp3-button-group .bp3-button, .bp3-button-group .bp3-button.bp3-active, .bp3-button-group .bp3-button:hover':
      {
        zIndex: 0,
      },
  },
}));

type Props = {
  config: DateGroupToggleConfig;
  disabled?: boolean;
  value?: DashboardVariable;
  onNewValueSelect: (newValue: DashboardVariable, options?: VariableSelectOptions) => void;
  openElementToLeft: boolean;
  datasetData: DatasetDataObject;
  datasetNamesToId: Record<string, string>;
};

const DashboardDateGroupSwitchElement = ({
  config,
  datasetData,
  datasetNamesToId,
  disabled,
  value,
  onNewValueSelect,
  openElementToLeft,
}: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const options = useMemo(() => getDateGroupSwitchOptions(config), [config]);
  const manualValues = useMemo(() => JSON.stringify(options.map((option) => option.id)), [options]);
  const manualDisplays = useMemo(
    () => JSON.stringify(options.map((option) => option.name)),
    [options],
  );

  let selectedItem = undefined;
  if (value) {
    selectedItem = options.find((option) => option.id === value);
  }

  const label = useStringWithVariablesReplaced(config.label, datasetNamesToId);

  if (config.filterType === DateGroupToggleSelectTypes.DROPDOWN) {
    return (
      <DashboardDropdownElement
        config={{
          disableCancel: true,
          disableSearch: true,
          label,
          valuesConfig: { valuesSource: INPUT_TYPE.MANUAL, manualValues, manualDisplays },
          infoTooltipText: config.infoTooltipText,
          showTooltip: config.showTooltip,
        }}
        datasetData={datasetData}
        datasetNamesToId={datasetNamesToId}
        disabled={disabled}
        onNewValueSelect={(newValue) => {
          // Don't want to keep stale data when using date group switch
          onNewValueSelect(newValue, { clearData: true });
          dispatch(sendAnalyticsEventThunk(REPORTED_ANALYTIC_ACTION_TYPES.DROPDOWN_SELECTED));
        }}
        openElementToLeft={openElementToLeft}
        value={value}
      />
    );
  }

  return (
    <div className={classes.root}>
      <DashboardButtonGroupInput
        preventUnselect
        disabled={disabled}
        infoTooltipText={config.showTooltip ? config.infoTooltipText : undefined}
        label={label}
        onChange={(item) => {
          // Don't want to keep stale data when using date group switch
          onNewValueSelect(item?.id, { clearData: true });
          dispatch(sendAnalyticsEventThunk(REPORTED_ANALYTIC_ACTION_TYPES.DROPDOWN_SELECTED));
        }}
        options={options}
        selectedItem={selectedItem}
      />
    </div>
  );
};

export default DashboardDateGroupSwitchElement;
