import { DashboardVariableMap } from 'types/dashboardTypes';
import { DataPanel } from 'types/exploResource';

import { isSelectableKPI } from './selectableKpiUtils';

export const areConditionalFiltersSupported = (dataPanels: DataPanel[]) =>
  dataPanels.some(isSelectableKPI);

export const handleConditionalFilters = (
  dp: DataPanel,
  dataPanels: Record<string, DataPanel>,
  variables: DashboardVariableMap,
) => {
  const filterClauses = dp.filter_op.instructions.filterClauses;
  if (!filterClauses.length) return;

  dp.filter_op.instructions.filterClauses = filterClauses.filter(({ conditionalFilterConfig }) => {
    if (!conditionalFilterConfig?.isConditional) return true;
    const panels = conditionalFilterConfig.chartsConditionalOn;
    if (!panels?.length) return false;

    return panels.some((panelId) => {
      const providedId = dataPanels[panelId]?.provided_id;
      return providedId && !!variables[providedId];
    });
  });
};
