import { v4 as uuidv4 } from 'uuid';

import { CustomerReportView } from 'actions/customerReportActions';
import { DATE_TYPES, FLOAT, UNSIGNED_INTEGER } from 'constants/dataConstants';
import { Aggregation } from 'constants/types';
import { AggColInfo } from 'types/columnTypes';

export function createBlankView(): CustomerReportView {
  return {
    id: uuidv4(),
    name: 'View 1',
    columnOrder: [],
    hiddenColumns: [],
    filters: [],
    sort: [],
  };
}

export function getNextViewName(views: CustomerReportView[] | undefined) {
  let currentNum = views?.length || 0;
  for (const view of views || []) {
    const viewDigits = view.name.match(/\d+$/)?.[0];
    const viewNum = parseInt(viewDigits || '') || 0;
    if (viewNum > currentNum) currentNum = viewNum;
  }
  return `View ${currentNum + 1}`;
}

export const isCountAggregation = (aggId: Aggregation | undefined) =>
  aggId === Aggregation.COUNT || aggId === Aggregation.COUNT_DISTINCT;

export const getAggregationType = (aggCol: AggColInfo) => {
  const { id } = aggCol.agg;

  if (DATE_TYPES.has(aggCol.column.type) && (id === Aggregation.MAX || id === Aggregation.MIN)) {
    return aggCol.column.type;
  }

  return isCountAggregation(id) ? UNSIGNED_INTEGER : FLOAT;
};
