import produce from 'immer';

import { DEFAULT_CATEGORY_COLORS } from 'constants/colorConstants';
import {
  CategoryToColor,
  ChartColumnInfo,
  COLOR_SYNC_CHART_TYPES,
  ColorCategoryTracker,
  ColorFormat,
  ColorPalette,
  ColorPaletteV2,
  ColumnColorTracker,
  OPERATION_TYPES,
  SchemaDisplayOptions,
  StringDisplayFormat,
  StringDisplayOptions,
  VisualizeOperationInstructions,
} from 'constants/types';
import { GlobalStyleConfig } from 'globalStyles/types';
import {
  getAxisNumericalValue,
  getColorColNames,
  getColorPalette,
  shouldProcessColAsDate,
} from 'pages/dashboardPage/charts/utils';
import { DatasetColumn, DatasetRow } from 'types/datasets';
import { orderBy } from 'utils/standard';

import { isSelectedColorDateType } from './colorColUtils';
import { getTimezoneAwareUnix } from './timezoneUtils';

type SetColorCategoryTableDataParams = {
  displayOptions: SchemaDisplayOptions;
  previewData: DatasetRow[];
  columnColorTracker?: ColumnColorTracker;
  globalStyleConfig: GlobalStyleConfig;
  shouldReplace?: boolean; // If true, replaces existing colors in tracker. Should be used for tables or custom palettes which do not have syncing
};

export function getTableColors(globalStyleConfig: GlobalStyleConfig) {
  const colorFormat: ColorFormat = { selectedPalette: ColorPaletteV2.CATEGORICAL };
  return getColorPalette(globalStyleConfig, colorFormat) || DEFAULT_CATEGORY_COLORS;
}

export const setTableColorCategoryData = ({
  displayOptions,
  columnColorTracker,
  previewData,
  globalStyleConfig,
  shouldReplace,
}: SetColorCategoryTableDataParams): ColumnColorTracker => {
  return produce(columnColorTracker || {}, (draft) => {
    Object.entries(displayOptions).forEach(([columnName, displayOptions]) => {
      const stringDisplayOptions = displayOptions as StringDisplayOptions;
      if (stringDisplayOptions.format !== StringDisplayFormat.CATEGORY) return;

      const colors = getTableColors(globalStyleConfig);

      // For tables, we want to replace the existing colors in the tracker. Tables do not share colors so this is fine
      // This should match the behavior of CategoryFieldColorAssignment where we alternate through DEFAULT_CATEGORY_COLORS
      // unless the category exists in categoryColorAssignments
      const paletteToColor: CategoryToColor = draft[columnName] || {};
      setColorsForColorCategoryTracker({
        previewData,
        columnName,
        tracker: paletteToColor,
        colors,
        shouldReplace,
        colorAssignments: stringDisplayOptions.categoryColorAssignments,
      });
      draft[columnName] = paletteToColor;
    });
  });
};

export const setAggColColorsForColorCategoryTracker = (
  paletteToColor: CategoryToColor,
  aggCol: ChartColumnInfo,
  dashboardCategoryColors: Record<string, string>,
) => {
  // Allow user to set column colors by data label name, we will always save it under the agg column name
  const colName = aggCol.name;
  const dataLabelKey = aggCol?.friendly_name ?? colName;
  if (!dataLabelKey || !colName) return;
  const dashboardColor = dashboardCategoryColors?.[dataLabelKey];
  paletteToColor[colName] = dashboardColor;
};

type SetColorsForColorCategoryTrackerParams = {
  previewData: DatasetRow[];
  columnName: string;
  tracker: CategoryToColor;
  // Color palette
  colors: string[];
  // If true, replaces existing colors in tracker.
  // Should be used for tables or custom palettes which do not have syncing
  shouldReplace: boolean | undefined;
  // For tables: Color overrides. Key is the value name, value is the color
  colorAssignments?: Record<string | number, string>;
  // 2D Charts convert date columns to number
  isDateType?: boolean;
  dashboardCategoryColors?: Record<string, string>;
};

export const setColorsForColorCategoryTracker = ({
  previewData,
  columnName,
  tracker,
  colors,
  shouldReplace,
  colorAssignments,
  isDateType,
  dashboardCategoryColors,
}: SetColorsForColorCategoryTrackerParams) => {
  const categories = new Set<string>();
  let currentIndex = shouldReplace ? 0 : Object.keys(tracker).length;
  for (const row of previewData) {
    let val = row[columnName];
    if (isDateType && typeof val === 'string') {
      val = getTimezoneAwareUnix(val);
    }

    const category = String(val);
    if (shouldReplace ? categories.has(category) : tracker[category]) continue;

    const dashboardColor = dashboardCategoryColors?.[category];
    if (dashboardColor) tracker[category] = dashboardColor;
    else if (colorAssignments?.[category]) {
      tracker[category] = colorAssignments?.[category];
    } else {
      tracker[category] = colors[currentIndex % colors.length];
      currentIndex++;
    }

    categories.add(category);
  }
};

type Set2DColorCategoryDataParams = {
  operationInstructions: VisualizeOperationInstructions;
  colorCategoryTracker: ColorCategoryTracker;
  previewData: DatasetRow[];
  globalStyleConfig: GlobalStyleConfig;
  schema: DatasetColumn[];
  operationType: OPERATION_TYPES;
  dataPanelId: string;
  shouldReplace?: boolean; // If true, replaces existing colors in tracker. Should be used for tables or custom palettes which do not have syncing
  dashboardCategoryColors: Record<string, string>;
};

function set2DColorCategoryData({
  operationInstructions,
  globalStyleConfig,
  schema,
  operationType,
  colorCategoryTracker,
  previewData,
  dataPanelId,
  shouldReplace,
  dashboardCategoryColors,
}: Set2DColorCategoryDataParams) {
  const v2Instructions = operationInstructions.V2_TWO_DIMENSION_CHART ?? {};
  const colorFormat = v2Instructions.colorFormat ?? {};
  const colors = getColorPalette(globalStyleConfig, colorFormat) ?? [];
  if (colors.length === 0) return;

  const { xAxisColName, colorColName } = getColorColNames(schema, operationType);
  const groupName = getColorTrackerCategoryName(xAxisColName, colorColName);
  const aggCols = v2Instructions?.aggColumns || [];
  const aggColNames = schema.map((col) => col.name).slice(1);

  const isPieChart = operationType === OPERATION_TYPES.VISUALIZE_PIE_CHART_V2;

  const isDateType =
    // Pie chart does color grouping on the category column not color columns
    isPieChart
      ? shouldProcessColAsDate(v2Instructions.categoryColumn)
      : isSelectedColorDateType(v2Instructions);

  const pieAggColName = schema[1]?.name;

  // Order data so that the slices are colored by order of palette
  const data =
    isPieChart && pieAggColName
      ? orderBy(previewData, (row) => getAxisNumericalValue(row[pieAggColName]), 'desc')
      : previewData;

  const isCustomPalette = colorFormat.selectedPalette === ColorPalette.CUSTOM;
  const key = getColorPaletteId(dataPanelId, colorFormat.selectedPalette);
  colorCategoryTracker[key] = produce(colorCategoryTracker[key] || {}, (draft) => {
    // When user updates custom palette configs, we want to replace the existing tracker with all the new colors
    // For other palettes, we want to keep the existing colors since the tracker is shared between charts and colors won't change
    const paletteToColor: CategoryToColor = draft[groupName] || {};
    setColorsForColorCategoryTracker({
      previewData: data,
      columnName: colorColName,
      tracker: paletteToColor,
      colors,
      shouldReplace: isCustomPalette && !!shouldReplace,
      isDateType,
      // If custom palette is used, we want to ignore dashboard colors
      dashboardCategoryColors: isCustomPalette ? undefined : dashboardCategoryColors,
    });
    draft[groupName] = paletteToColor;
    // we don't want to overwrite the custom colors with agg col colors
    if (isCustomPalette) return;
    // for each agg column, set the dashboard-wide color in the color category tracker
    aggColNames.forEach((aggColName, index) => {
      const column = aggCols?.[index]?.column;
      if (!column) return;
      const paletteToColor: CategoryToColor = draft[aggColName] || {};
      setAggColColorsForColorCategoryTracker(paletteToColor, column, dashboardCategoryColors);
      draft[aggColName] = paletteToColor;
    });
  });
}

// if the xAxis and colorCol are the same,
// colorColName will suffix '1' from /query_runner/__init__.py `fetch_columns`
// so use xAxisColName for categoryName
export const getColorTrackerCategoryName = (xAxisColName: string, colorColName: string) =>
  colorColName === xAxisColName + '1' ? xAxisColName : colorColName;

type SetColorCategoryDataParams = {
  operationInstructions: VisualizeOperationInstructions;
  colorCategoryTracker: ColorCategoryTracker;
  previewData: DatasetRow[];
  globalStyleConfig: GlobalStyleConfig;
  dashboardCategoryColors: Record<string, string>;
  schema: DatasetColumn[];
  operationType: OPERATION_TYPES;
  dataPanelId: string;
  shouldReplace?: boolean; // If true, replaces existing colors in tracker. Should be used for tables or custom palettes which do not have syncing
};

export const setColorCategoryData = ({
  colorCategoryTracker,
  globalStyleConfig,
  operationType,
  operationInstructions,
  previewData,
  schema,
  dataPanelId,
  shouldReplace,
  dashboardCategoryColors,
}: SetColorCategoryDataParams): void => {
  if (!previewData || previewData.length === 0 || !schema || schema.length === 0) return;

  if (!COLOR_SYNC_CHART_TYPES.has(operationType)) return;

  if (
    operationType === OPERATION_TYPES.VISUALIZE_TABLE &&
    operationInstructions.VISUALIZE_TABLE.schemaDisplayOptions
  ) {
    colorCategoryTracker[dataPanelId] = setTableColorCategoryData({
      displayOptions: operationInstructions.VISUALIZE_TABLE.schemaDisplayOptions,
      globalStyleConfig,
      columnColorTracker: colorCategoryTracker[dataPanelId],
      previewData,
      shouldReplace,
    });
    return;
  }

  set2DColorCategoryData({
    operationInstructions,
    globalStyleConfig,
    schema,
    operationType,
    colorCategoryTracker,
    previewData,
    dataPanelId,
    shouldReplace,
    dashboardCategoryColors,
  });
};

interface GetColorFromPaletteTrackerParams {
  columnName: string;
  valueName: string;
  colorTracker?: ColumnColorTracker;
}

/**
 * @param columnName
 * @param valueName - Value should be raw and unformatted
 * @param colorTracker
 */
export const getColorFromPaletteTracker = ({
  columnName,
  valueName,
  colorTracker,
}: GetColorFromPaletteTrackerParams) => colorTracker?.[columnName]?.[valueName];

const getColorPaletteId = (dataPanelId?: string, paletteName?: string) => {
  return (
    (paletteName === ColorPalette.CUSTOM ? dataPanelId : paletteName) || ColorPaletteV2.CATEGORICAL
  );
};

interface GetColorPaletteTrackerParams {
  dataPanelId?: string;
  paletteName?: ColorPalette | ColorPaletteV2;
  colorCategoryTracker?: ColorCategoryTracker;
}

export const getColorPaletteTracker = ({
  dataPanelId,
  paletteName,
  colorCategoryTracker,
}: GetColorPaletteTrackerParams) => {
  const key = getColorPaletteId(dataPanelId, paletteName);
  return colorCategoryTracker?.[key];
};
