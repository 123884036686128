import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import XRegExp from 'xregexp';

import { V2PivotTableInstructions } from 'actions/V2PivotTableActions';
import { Timezones } from 'constants/dashboardConstants';
import {
  BAR_CHART_SCROLL_DIRECTION,
  BOOLEAN,
  NUMBER_TYPES,
  STRING,
  TIME_COLUMN_TYPES,
  V2_VIZ_INSTRUCTION_TYPE,
} from 'constants/dataConstants';
import {
  AggedChartColumnInfo,
  Aggregation,
  CategoryChartColumnInfo,
  ChartColumnInfo,
  FilterClause,
  FilterValueDateType,
  FilterValueNumberRangeType,
  FilterValueRelativeDateType,
  FilterValueSourceType,
  FilterValueType,
  KPIPeriodColumnInfo,
  KPI_NUMBER_TREND_OPERATION_TYPES,
  NumberDisplayDisplayType,
  NumberDisplayFormat,
  NumberDisplayOptions,
  OPERATION_TYPES,
  PivotOperationInstructions,
  SchemaChange,
  SchemaDisplayOptions,
  SortAxis,
  StringDisplayOptions,
  UserTransformedSchema,
  VisualizeGeospatialChartInstructions,
  VisualizePivotTableInstructions,
} from 'constants/types';
import { isTwoDimVizInstructionsReadyToDisplay } from 'pages/dashboardPage/charts/utils';
import { isKpiTrendReadyToDisplay } from 'pages/dashboardPage/charts/utils/trendUtils';
import { ReduxState } from 'reducers/rootReducer';
import {
  DataPanelTemplate,
  FilterOperation,
  PivotOperation,
  VisualizeOperation,
} from 'types/dataPanelTemplate';
import { DatasetSchema } from 'types/datasets';
import {
  DATETIME_PIVOT_AGGS_SET,
  DEFAULT_DATE_RANGES,
  DEFAULT_DATE_RANGES_SET,
  PeriodRangeTypes,
  PivotAgg,
  RELATIVE_DATE_OPTIONS,
  RELATIVE_DATE_OPTIONS_SET,
} from 'types/dateRangeTypes';
import { DataPanel, ResourceDataset } from 'types/exploResource';
import {
  FILTER_OPS_DATE_PICKER,
  FILTER_OPS_DATE_RANGE_PICKER,
  FILTER_OPS_MULTISELECT,
  FILTER_OPS_NO_VALUE,
  FILTER_OPS_NUMBER_RANGE,
  FILTER_OPS_RELATIVE_PICKER,
  FilterOperator,
} from 'types/filterOperations';
import { cloneDeep, isEqual, keyBy, reject, some, times } from 'utils/standard';

import { getDataPanelDatasetId } from './exploResourceUtils';
import { isDataRequiredForTableColumnGradient } from './gradientUtils';
import { sortSchemaByOrderedColumnNames } from './tableSchemaUtils';
import { replaceVariablesInString } from './variableUtils';

export const variableRegex = /(\{\{(?<variable>.+?)\}\})/g;

interface DataPanelConfigReducerState {
  filterOperation: FilterOperation;
  pivotOperation: PivotOperation;
  visualizeOperation: VisualizeOperation;
}

export const dataPanelToConfig = (dp: DataPanelTemplate): DataPanelConfigReducerState => {
  return {
    filterOperation: dp.filter_op,
    pivotOperation: dp.group_by_op,
    visualizeOperation: dp.visualize_op,
  };
};

export const shouldRecomputeDataForDataPanel = (
  prevConfig: DataPanelConfigReducerState,
  newConfig: DataPanelConfigReducerState,
): boolean => {
  const prevConfigClean = cleanConfig(cloneDeep(prevConfig));
  const newConfigClean = cleanConfig(cloneDeep(newConfig));
  if (!isDataPanelConfigReady(newConfigClean.visualizeOperation)) return false;

  if (
    prevConfigClean.filterOperation?.instructions.matchOnAll !==
      newConfigClean.filterOperation?.instructions.matchOnAll ||
    differentFilterOps(
      prevConfigClean.filterOperation?.instructions.filterClauses,
      newConfigClean.filterOperation?.instructions.filterClauses,
    )
  ) {
    return true;
  }

  if (
    differentPivotOps(
      prevConfigClean.pivotOperation?.instructions,
      newConfigClean.pivotOperation?.instructions,
    )
  ) {
    return true;
  }

  if (
    visualizeOperationNeedsRecompute(
      prevConfigClean.visualizeOperation,
      newConfigClean.visualizeOperation,
    )
  ) {
    return true;
  }

  return false;
};

export const shouldRecomputeSecondaryDataForDataPanel = (
  prevConfig: DataPanelConfigReducerState,
  newConfig: DataPanelConfigReducerState,
) =>
  isGradientAddedToTable(prevConfig.visualizeOperation, newConfig.visualizeOperation) ||
  isGoalQueryAddedToTable(prevConfig.visualizeOperation, newConfig.visualizeOperation);

export const aggReady = (aggColumn: AggedChartColumnInfo | undefined): boolean =>
  !!aggColumn?.column && (aggColumn.agg.id !== Aggregation.FORMULA || !!aggColumn.agg.formula);

export const isPivotTableReadyToDisplay = (
  instructions: VisualizePivotTableInstructions | undefined,
): boolean =>
  !!instructions?.colColumn && !!instructions.rowColumn && aggReady(instructions.aggregation);

export const isPivotTableV2ReadyToDisplay = (
  instructions: V2PivotTableInstructions | undefined,
): boolean => !!instructions?.rowGroupBys.length;

const isGeospatialMapReadyToDisplay = (
  instructions: VisualizeGeospatialChartInstructions | undefined,
): boolean => !!instructions?.latitudeColumn && !!instructions?.longitudeColumn;

export const isDataPanelConfigReady = (visualizeOperation: VisualizeOperation): boolean => {
  const { operation_type: operationType, instructions } = visualizeOperation;

  if (
    operationType === OPERATION_TYPES.VISUALIZE_TABLE ||
    operationType === OPERATION_TYPES.VISUALIZE_REPORT_BUILDER
  ) {
    return true;
  } else if (
    operationType === OPERATION_TYPES.VISUALIZE_NUMBER_V2 ||
    operationType === OPERATION_TYPES.VISUALIZE_PROGRESS_V2
  ) {
    return aggReady(instructions?.V2_KPI?.aggColumn);
  } else if (KPI_NUMBER_TREND_OPERATION_TYPES.has(operationType)) {
    const trendConfig = instructions.V2_KPI_TREND;
    return isKpiTrendReadyToDisplay(trendConfig);
  } else if (operationType === OPERATION_TYPES.VISUALIZE_BOX_PLOT_V2) {
    return (
      !!instructions.V2_BOX_PLOT?.groupingColumn && !!instructions.V2_BOX_PLOT?.calcColumns?.length
    );
  } else if (operationType === OPERATION_TYPES.VISUALIZE_SCATTER_PLOT_V2) {
    return (
      !!instructions.V2_SCATTER_PLOT?.xAxisColumn && !!instructions.V2_SCATTER_PLOT?.yAxisColumn
    );
  } else if (
    operationType === OPERATION_TYPES.VISUALIZE_PIVOT_TABLE ||
    operationType === OPERATION_TYPES.VISUALIZE_PIVOT_REPORT_BUILDER
  ) {
    return isPivotTableReadyToDisplay(instructions.VISUALIZE_PIVOT_TABLE);
  } else if (operationType === OPERATION_TYPES.VISUALIZE_COLLAPSIBLE_LIST) {
    return (
      !!instructions.VISUALIZE_COLLAPSIBLE_LIST?.rowColumns &&
      !!instructions.VISUALIZE_COLLAPSIBLE_LIST?.aggregations
    );
  } else if (operationType === OPERATION_TYPES.VISUALIZE_PIVOT_TABLE_V2) {
    return isPivotTableV2ReadyToDisplay(instructions.VISUALIZE_PIVOT_TABLE_V2);
  } else if (
    operationType === OPERATION_TYPES.VISUALIZE_LOCATION_MARKER_MAP ||
    operationType === OPERATION_TYPES.VISUALIZE_DENSITY_MAP
  ) {
    return isGeospatialMapReadyToDisplay(instructions.VISUALIZE_GEOSPATIAL_CHART);
  } else {
    return isTwoDimVizInstructionsReadyToDisplay(
      instructions.V2_TWO_DIMENSION_CHART,
      operationType,
    );
  }
};

export const isDataPanelReadyToCompute = (
  dataPanel: DataPanel,
  datasets: Record<string, ResourceDataset>,
): boolean => {
  if (!isDataPanelConfigReady(dataPanel.visualize_op)) return false;

  const dataset = datasets[getDataPanelDatasetId(dataPanel)];
  return !!dataset;
};

// If the visualization switches from being a table to visualization, or vise versa
// then recompute the dpt
const visualizeOperationNeedsRecompute = (
  oldVizOp?: VisualizeOperation,
  newVizOp?: VisualizeOperation,
) => {
  if (oldVizOp === undefined) return newVizOp !== undefined;
  if (newVizOp === undefined) return oldVizOp !== undefined;

  if (
    V2_VIZ_INSTRUCTION_TYPE[oldVizOp.operation_type] !==
    V2_VIZ_INSTRUCTION_TYPE[newVizOp.operation_type]
  )
    return true;

  // if scroll is enabled, recompute the data panel when the bart chart type switches axes.
  if (
    oldVizOp.instructions.V2_TWO_DIMENSION_CHART?.xAxisFormat?.enableScroll &&
    BAR_CHART_SCROLL_DIRECTION[oldVizOp.operation_type] !==
      BAR_CHART_SCROLL_DIRECTION[newVizOp.operation_type]
  )
    return true;

  const oldXAxisFormat = oldVizOp.instructions.V2_TWO_DIMENSION_CHART?.xAxisFormat;
  const newXAxisFormat = newVizOp.instructions.V2_TWO_DIMENSION_CHART?.xAxisFormat;
  const isNewSortSet =
    newXAxisFormat?.sortAxis === SortAxis.COLUMN &&
    newXAxisFormat?.sortOption &&
    newXAxisFormat?.sortColumns;

  const isOldSortSet =
    oldXAxisFormat?.sortAxis === SortAxis.COLUMN &&
    oldXAxisFormat?.sortOption &&
    oldXAxisFormat?.sortColumns;

  const tableRequiresResort =
    oldVizOp.instructions.VISUALIZE_TABLE.orderedColumnNames?.[0] !==
      newVizOp.instructions.VISUALIZE_TABLE.orderedColumnNames?.[0] &&
    newVizOp.instructions.VISUALIZE_TABLE.shouldVisuallyGroupByFirstColumn;

  if (tableRequiresResort) return true;

  return !isEqual(
    {
      generalFormatOptions: {
        enableRawDataDrilldown: newVizOp.generalFormatOptions?.enableRawDataDrilldown,
      },
      VISUALIZE_TABLE: {
        rowsPerPage: newVizOp.instructions.VISUALIZE_TABLE.rowsPerPage,
        keepCols: getKeepColInfo(newVizOp.instructions.VISUALIZE_TABLE.changeSchemaList),
        colSchemaDisplayOptions: getColumnSchemaDisplayOptions(
          newVizOp.instructions.VISUALIZE_TABLE.schemaDisplayOptions,
        ),
      },
      V2_TWO_DIMENSION_CHART: {
        aggColumns: removeFriendlyNameFromAggs(
          newVizOp.instructions.V2_TWO_DIMENSION_CHART?.aggColumns,
        ),
        categoryColumn: newVizOp.instructions.V2_TWO_DIMENSION_CHART?.categoryColumn,
        colorColumnOptions: removeFriendlyNameFromColorCols(
          newVizOp.instructions.V2_TWO_DIMENSION_CHART?.colorColumnOptions,
        ),
        groupingOption: newVizOp.instructions.V2_TWO_DIMENSION_CHART?.groupingColumn,
        xAxisFormat: newVizOp.instructions.V2_TWO_DIMENSION_CHART?.xAxisFormat?.enableScroll,
        xAxisSorting: isNewSortSet
          ? {
              sortAxis: newXAxisFormat?.sortAxis,
              sortCol: newXAxisFormat?.sortColumns,
            }
          : undefined,
      },
      V2_KPI: {
        aggColumn: newVizOp.instructions.V2_KPI?.aggColumn,
      },
      V2_BOX_PLOT: {
        groupingColumn: newVizOp.instructions.V2_BOX_PLOT?.groupingColumn,
        calcColumns: newVizOp.instructions.V2_BOX_PLOT?.calcColumns,
      },
      V2_SCATTER_PLOT: {
        xAxisColumn: removeFriendlyName(newVizOp.instructions.V2_SCATTER_PLOT?.xAxisColumn),
        yAxisColumn: removeFriendlyName(newVizOp.instructions.V2_SCATTER_PLOT?.yAxisColumn),
        groupingColumn: newVizOp.instructions.V2_SCATTER_PLOT?.groupingColumn,
      },
      V2_KPI_TREND: {
        aggColumn: newVizOp.instructions.V2_KPI_TREND?.aggColumn,
        periodColumn: removeDatesIfNotCompleteOrNotCustom(
          newVizOp.instructions.V2_KPI_TREND?.periodColumn,
        ),
        periodComparisonRange: newVizOp.instructions.V2_KPI_TREND?.periodComparisonRange,
        trendGrouping: newVizOp.instructions.V2_KPI_TREND?.trendGrouping,
        trendGroupingElementId: newVizOp.instructions.V2_KPI_TREND?.trendGroupingElementId,
        hideTrendLines: newVizOp.instructions.V2_KPI_TREND?.hideTrendLines,
      },
      PIVOT_TABLE: {
        rowColumn: newVizOp.instructions.VISUALIZE_PIVOT_TABLE?.rowColumn,
        colColumn: newVizOp.instructions.VISUALIZE_PIVOT_TABLE?.colColumn,
        aggregation: newVizOp.instructions.VISUALIZE_PIVOT_TABLE?.aggregation,
        displaySumRow: newVizOp.instructions.VISUALIZE_PIVOT_TABLE?.displaySumRow,
      },
      PIVOT_TABLE_V2: {
        rowGroupBys: newVizOp.instructions.VISUALIZE_PIVOT_TABLE_V2?.rowGroupBys,
        colGroupBys: newVizOp.instructions.VISUALIZE_PIVOT_TABLE_V2?.colGroupBys,
        aggregations: newVizOp.instructions.VISUALIZE_PIVOT_TABLE_V2?.aggregations,
        rowSortOrder: newVizOp.instructions.VISUALIZE_PIVOT_TABLE_V2?.rowSortOrder,
        columnSortOrder: newVizOp.instructions.VISUALIZE_PIVOT_TABLE_V2?.columnSortOrder,
      },
      VISUALIZE_COLLAPSIBLE_LIST: {
        rowColumns: newVizOp.instructions.VISUALIZE_COLLAPSIBLE_LIST?.rowColumns,
        aggregations: newVizOp.instructions.VISUALIZE_COLLAPSIBLE_LIST?.aggregations?.map(
          removeFriendlyNameFromAggColInfo,
        ),
      },
      VISUALIZE_GEOSPATIAL_CHART: {
        latitudeColumn: newVizOp.instructions.VISUALIZE_GEOSPATIAL_CHART?.latitudeColumn,
        longitudeColumn: newVizOp.instructions.VISUALIZE_GEOSPATIAL_CHART?.longitudeColumn,
        rowLimit: newVizOp.instructions.VISUALIZE_GEOSPATIAL_CHART?.rowLimit,
        tooltipColumns: newVizOp.instructions.VISUALIZE_GEOSPATIAL_CHART?.tooltipColumns,
      },
    },
    {
      generalFormatOptions: {
        enableRawDataDrilldown: oldVizOp.generalFormatOptions?.enableRawDataDrilldown,
      },
      VISUALIZE_TABLE: {
        rowsPerPage: oldVizOp.instructions.VISUALIZE_TABLE.rowsPerPage,
        keepCols: getKeepColInfo(oldVizOp.instructions.VISUALIZE_TABLE.changeSchemaList),
        colSchemaDisplayOptions: getColumnSchemaDisplayOptions(
          oldVizOp.instructions.VISUALIZE_TABLE.schemaDisplayOptions,
        ),
      },
      V2_TWO_DIMENSION_CHART: {
        aggColumns: removeFriendlyNameFromAggs(
          oldVizOp.instructions.V2_TWO_DIMENSION_CHART?.aggColumns,
        ),
        categoryColumn: oldVizOp.instructions.V2_TWO_DIMENSION_CHART?.categoryColumn,
        colorColumnOptions: removeFriendlyNameFromColorCols(
          oldVizOp.instructions.V2_TWO_DIMENSION_CHART?.colorColumnOptions,
        ),
        groupingOption: oldVizOp.instructions.V2_TWO_DIMENSION_CHART?.groupingColumn,
        xAxisFormat: oldVizOp.instructions.V2_TWO_DIMENSION_CHART?.xAxisFormat?.enableScroll,
        xAxisSorting: isOldSortSet
          ? {
              sortAxis: oldXAxisFormat?.sortAxis,
              sortCol: oldXAxisFormat?.sortColumns,
            }
          : undefined,
      },
      V2_KPI: {
        aggColumn: oldVizOp.instructions.V2_KPI?.aggColumn,
      },
      V2_BOX_PLOT: {
        groupingColumn: oldVizOp.instructions.V2_BOX_PLOT?.groupingColumn,
        calcColumns: oldVizOp.instructions.V2_BOX_PLOT?.calcColumns,
      },
      V2_SCATTER_PLOT: {
        xAxisColumn: removeFriendlyName(oldVizOp.instructions.V2_SCATTER_PLOT?.xAxisColumn),
        yAxisColumn: removeFriendlyName(oldVizOp.instructions.V2_SCATTER_PLOT?.yAxisColumn),
        groupingColumn: oldVizOp.instructions.V2_SCATTER_PLOT?.groupingColumn,
      },
      V2_KPI_TREND: {
        aggColumn: oldVizOp.instructions.V2_KPI_TREND?.aggColumn,
        periodColumn: removeDatesIfNotCompleteOrNotCustom(
          oldVizOp.instructions.V2_KPI_TREND?.periodColumn,
        ),
        periodComparisonRange: oldVizOp.instructions.V2_KPI_TREND?.periodComparisonRange,
        trendGrouping: oldVizOp.instructions.V2_KPI_TREND?.trendGrouping,
        trendGroupingElementId: oldVizOp.instructions.V2_KPI_TREND?.trendGroupingElementId,
        hideTrendLines: oldVizOp.instructions.V2_KPI_TREND?.hideTrendLines,
      },
      PIVOT_TABLE: {
        rowColumn: oldVizOp.instructions.VISUALIZE_PIVOT_TABLE?.rowColumn,
        colColumn: oldVizOp.instructions.VISUALIZE_PIVOT_TABLE?.colColumn,
        aggregation: oldVizOp.instructions.VISUALIZE_PIVOT_TABLE?.aggregation,
        displaySumRow: oldVizOp.instructions.VISUALIZE_PIVOT_TABLE?.displaySumRow,
      },
      PIVOT_TABLE_V2: {
        rowGroupBys: oldVizOp.instructions.VISUALIZE_PIVOT_TABLE_V2?.rowGroupBys,
        colGroupBys: oldVizOp.instructions.VISUALIZE_PIVOT_TABLE_V2?.colGroupBys,
        aggregations: oldVizOp.instructions.VISUALIZE_PIVOT_TABLE_V2?.aggregations,
        rowSortOrder: oldVizOp.instructions.VISUALIZE_PIVOT_TABLE_V2?.rowSortOrder,
        columnSortOrder: oldVizOp.instructions.VISUALIZE_PIVOT_TABLE_V2?.columnSortOrder,
      },
      VISUALIZE_COLLAPSIBLE_LIST: {
        rowColumns: oldVizOp.instructions.VISUALIZE_COLLAPSIBLE_LIST?.rowColumns,
        aggregations: oldVizOp.instructions.VISUALIZE_COLLAPSIBLE_LIST?.aggregations?.map(
          removeFriendlyNameFromAggColInfo,
        ),
      },
      VISUALIZE_GEOSPATIAL_CHART: {
        latitudeColumn: oldVizOp.instructions.VISUALIZE_GEOSPATIAL_CHART?.latitudeColumn,
        longitudeColumn: oldVizOp.instructions.VISUALIZE_GEOSPATIAL_CHART?.longitudeColumn,
        rowLimit: oldVizOp.instructions.VISUALIZE_GEOSPATIAL_CHART?.rowLimit,
        tooltipColumns: oldVizOp.instructions.VISUALIZE_GEOSPATIAL_CHART?.tooltipColumns,
      },
    },
  );
};

const removeFriendlyNameFromAggColInfo = (agg: AggedChartColumnInfo) => {
  return {
    ...agg,
    column: {
      ...agg.column,
      friendly_name: undefined,
    },
  };
};

export const isSecondaryDataRequiredForTableCol = (displayOptions?: NumberDisplayOptions) => {
  if (!displayOptions) return false;
  const { displayType, displayTypeOptions, format, useColumnMaxForGoal } = displayOptions;

  const hasGradient = isDataRequiredForTableColumnGradient(displayOptions);
  const hasPercentColumnMaxGoal = format === NumberDisplayFormat.PERCENT && useColumnMaxForGoal;
  const hasProgressBarColumnMaxGoal =
    displayType === NumberDisplayDisplayType.PROGRESS_BAR &&
    displayTypeOptions?.useColumnMaxForProgressBarGoal;

  return hasGradient || hasPercentColumnMaxGoal || hasProgressBarColumnMaxGoal;
};

const isGradientAddedToTable = (oldVizOp?: VisualizeOperation, newVizOp?: VisualizeOperation) => {
  const oldSchemaDisplayOptions = oldVizOp?.instructions.VISUALIZE_TABLE.schemaDisplayOptions || {};
  const newSchemaDisplayOptions = newVizOp?.instructions.VISUALIZE_TABLE.schemaDisplayOptions || {};

  return Object.keys(newSchemaDisplayOptions).some((columnName) => {
    /**
     * Casting as union type with undefined because the column may not actually be a number column, so
     * we should use optional chaining when accessing gradientType, otherwise we may get a runtime error.
     * Unioning with undefined forces us to use optional chaining.
     */
    const oldDisplayOptions = oldSchemaDisplayOptions[columnName] as
      | NumberDisplayOptions
      | undefined;
    const newDisplayOptions = newSchemaDisplayOptions[columnName] as
      | NumberDisplayOptions
      | undefined;

    // If secondary data was already required, we already fetched it
    if (!newDisplayOptions || isSecondaryDataRequiredForTableCol(oldDisplayOptions)) {
      return false;
    }

    return isDataRequiredForTableColumnGradient(newDisplayOptions);
  });
};

const isGoalQueryAddedToTable = (oldVizOp?: VisualizeOperation, newVizOp?: VisualizeOperation) => {
  let isProgressBarQueryAdded = false;
  let isChangedToFormatWithGoalQuery = false;
  const oldSchemaDisplayOptions = oldVizOp?.instructions.VISUALIZE_TABLE.schemaDisplayOptions || {};
  const newSchemaDisplayOptions = newVizOp?.instructions.VISUALIZE_TABLE.schemaDisplayOptions || {};

  Object.keys(newSchemaDisplayOptions).forEach((columnName) => {
    /**
     * Casting as union type with undefined because the column may not actually be a number column, so
     * we should use optional chaining when accessing gradientType, otherwise we may get a runtime error.
     * Unioning with undefined forces us to use optional chaining.
     */
    const oldDisplayOptions = oldSchemaDisplayOptions[columnName] as
      | NumberDisplayOptions
      | undefined;
    const newDisplayOptions = newSchemaDisplayOptions[columnName] as
      | NumberDisplayOptions
      | undefined;

    // If secondary data was already required, we already fetched it
    if (isSecondaryDataRequiredForTableCol(oldDisplayOptions)) {
      return;
    }

    const {
      displayType: oldSchemaDisplayType,
      displayTypeOptions: oldSchemaDisplayTypeOptions,
      format: oldSchemaFormat,
      useColumnMaxForGoal: oldSchemaUsesColumnMax,
    } = oldDisplayOptions ?? {};
    const {
      displayType: newSchemaDisplayType,
      displayTypeOptions: newSchemaDisplayTypeOptions,
      format: newSchemaFormat,
      useColumnMaxForGoal: newSchemaUsesColumnMax,
    } = newDisplayOptions ?? {};

    if (
      (!oldSchemaUsesColumnMax && newSchemaUsesColumnMax) ||
      (!oldSchemaDisplayTypeOptions?.useColumnMaxForProgressBarGoal &&
        newSchemaDisplayTypeOptions?.useColumnMaxForProgressBarGoal)
    ) {
      isProgressBarQueryAdded = true;
    }

    if (
      oldSchemaFormat !== NumberDisplayFormat.PERCENT &&
      newSchemaFormat === NumberDisplayFormat.PERCENT &&
      newSchemaUsesColumnMax
    ) {
      isChangedToFormatWithGoalQuery = true;
    }

    if (
      oldSchemaDisplayType !== NumberDisplayDisplayType.PROGRESS_BAR &&
      newSchemaDisplayType === NumberDisplayDisplayType.PROGRESS_BAR &&
      newSchemaDisplayTypeOptions?.useColumnMaxForProgressBarGoal
    ) {
      isChangedToFormatWithGoalQuery = true;
    }
  });

  return isProgressBarQueryAdded || isChangedToFormatWithGoalQuery;
};

const removeFriendlyName = (col?: ChartColumnInfo): ChartColumnInfo | undefined =>
  col ? { ...col, friendly_name: undefined } : undefined;

const removeFriendlyNameFromAggs = (aggCols?: AggedChartColumnInfo[]) => {
  return (aggCols || []).map((aggCol) => ({
    agg: aggCol.agg,
    column: removeFriendlyName(aggCol.column),
  }));
};

const removeFriendlyNameFromColorCols = (colorCols?: CategoryChartColumnInfo[]) => {
  return (colorCols || []).map((colorCol) => ({
    bucket: colorCol.bucket,
    column: removeFriendlyName(colorCol.column),
  }));
};

const removeDatesIfNotCompleteOrNotCustom = (periodColumn?: KPIPeriodColumnInfo) => {
  if (!periodColumn) return;

  if (
    periodColumn.periodRange === PeriodRangeTypes.CUSTOM_RANGE ||
    periodColumn.periodRange === PeriodRangeTypes.DATE_RANGE_INPUT ||
    periodColumn.periodRange === PeriodRangeTypes.TIME_PERIOD_DROPDOWN
  ) {
    return periodColumn;
  } else {
    return {
      column: periodColumn.column,
      periodRange: periodColumn.periodRange,
      trendDateOffset: periodColumn.trendDateOffset,
      comparisonInfo: periodColumn.comparisonInfo,
    };
  }
};

const getColumnSchemaDisplayOptions = (schemaDisplayOptions: SchemaDisplayOptions | undefined) => {
  if (schemaDisplayOptions === undefined) return {};
  return Object.entries(schemaDisplayOptions).filter(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ([_, option]) => (option as StringDisplayOptions)?.urlFormat === 'Column',
  );
};

const getKeepColInfo = (changeSchemaList: SchemaChange[]) => {
  return changeSchemaList.map((schemaChange) => ({
    name: schemaChange.col,
    keepCol: schemaChange.keepCol,
  }));
};

// Removes configs that do not impact the resulting table (incomplete configs)
const cleanConfig = (config: DataPanelConfigReducerState) => {
  if (config.filterOperation) cleanFilterConfig(config.filterOperation);
  if (config.pivotOperation) cleanPivotConfig(config.pivotOperation);
  return config;
};

const cleanFilterConfig = (filterOperation: FilterOperation) => {
  filterOperation.instructions.filterClauses = reject(
    filterOperation.instructions.filterClauses,
    isFilterClauseIncomplete,
  );
};

export const isFilterClauseIncomplete = (clause: FilterClause) => {
  if (!clause.filterColumn || !clause.filterOperation) return true;

  // If no value is required for operation no need to check anything else
  if (FILTER_OPS_NO_VALUE.has(clause.filterOperation.id)) return false;

  // If the filter is a variable-based filter value, then it will be incomplete
  // if the variable is not yet specified
  if (clause.filterValueSource === FilterValueSourceType.VARIABLE) {
    return clause.filterValueVariableId === undefined;
  }

  if (FILTER_OPS_MULTISELECT.has(clause.filterOperation.id)) {
    if (clause.filterValue === undefined) {
      return true;
    } else {
      if (Array.isArray(clause.filterValue)) return clause.filterValue.length === 0;

      // When filter values are passed in through inputs or web components, this is a string
      try {
        const filterValue = JSON.parse(clause.filterValue as string);
        return !(Array.isArray(filterValue) || filterValue.length > 0);
      } catch {
        return true;
      }
    }
  }

  if (clause.filterValue === undefined || clause.filterValue === null || clause.filterValue === '')
    return true;

  if (FILTER_OPS_DATE_RANGE_PICKER.has(clause.filterOperation.id)) {
    const dateValue = clause.filterValue as FilterValueDateType | DEFAULT_DATE_RANGES;
    if (typeof dateValue === 'string') {
      return !DEFAULT_DATE_RANGES_SET.has(dateValue);
    }
    const rangeValue = clause.filterValue as FilterValueDateType;
    return !rangeValue.startDate || !rangeValue.endDate;
  } else if (FILTER_OPS_DATE_PICKER.has(clause.filterOperation.id)) {
    const dateValue = clause.filterValue as FilterValueDateType | RELATIVE_DATE_OPTIONS;
    return typeof dateValue === 'string'
      ? !RELATIVE_DATE_OPTIONS_SET.has(dateValue)
      : !dateValue.startDate;
  } else if (FILTER_OPS_RELATIVE_PICKER.has(clause.filterOperation.id)) {
    const relativeDateValue = clause.filterValue as FilterValueRelativeDateType;
    return !relativeDateValue.number || !relativeDateValue.relativeTimeType;
  } else if (FILTER_OPS_NUMBER_RANGE.has(clause.filterOperation.id)) {
    const rangeValue = clause.filterValue as FilterValueNumberRangeType;
    return rangeValue.min == null || rangeValue.max == null || rangeValue.min > rangeValue.max;
  }

  return false;
};

const cleanPivotConfig = (pivotOperation: PivotOperation) => {
  pivotOperation.instructions.aggregations = reject(
    pivotOperation.instructions.aggregations,
    (agg) => !agg.aggedOnColumn || !agg.type,
  );
  return pivotOperation;
};

const differentFilterOps = (prevClauses?: FilterClause[], newClauses?: FilterClause[]) => {
  if (prevClauses === undefined) return newClauses !== undefined;
  if (newClauses === undefined) return prevClauses !== undefined;

  return listsAreDifferent(prevClauses, newClauses);
};

const differentPivotOps = (
  prevInst?: PivotOperationInstructions,
  newInst?: PivotOperationInstructions,
) => {
  if (prevInst === undefined) return newInst !== undefined;
  if (newInst === undefined) return prevInst !== undefined;

  return listsAreDifferent(prevInst.aggregations, newInst.aggregations);
};

export const listsAreDifferent = (list1: unknown[], list2: unknown[]) => {
  if (list1.length !== list2.length) return true;

  return some(times(list1.length, (i) => !isEqual(list1[i], list2[i])));
};

export function useStringWithVariablesReplaced(
  s: string,
  datasetNamesToId: Record<string, string>,
): string {
  const { datasetData, variables } = useSelector(
    (state: ReduxState) => ({
      datasetData: state.dashboardData?.datasetData,
      variables: state.dashboardData?.variables,
    }),
    shallowEqual,
  );

  return useMemo(
    () => replaceVariablesInString(s, variables, datasetNamesToId, datasetData),
    [datasetData, datasetNamesToId, s, variables],
  );
}

export const getQueryTablesReferencedByText = (
  text: string | undefined,
  datasetsByName: { [datasetName: string]: ResourceDataset },
): string[] => {
  if (!text || text.trim() === '') return [];

  const queryTables = new Set<string>([]);

  //@ts-ignore
  XRegExp.forEach(text, variableRegex, (match) => {
    const varName = match[2]?.trim();
    if (varName) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [datasetName, _, ...other] = varName.split('.');

      // nesting further than table.column_name isn't supported
      if (other.length === 0 && datasetName in datasetsByName) {
        const dataset = datasetsByName[datasetName];

        queryTables.add(dataset.id);
      }
    }
  });

  return Array.from(queryTables);
};

export const dataPanelRequiresPrimaryData = (visualizeOperation: VisualizeOperation) => {
  const operationType = visualizeOperation.operation_type;
  const kpiInstructions = visualizeOperation.instructions.V2_KPI_TREND;
  if (operationType === OPERATION_TYPES.VISUALIZE_NUMBER_TREND_V2) {
    // Since users can put `current_period` and `comparison_period` variables in the subtitle
    // we need to load the primary data if they have a subtitle set
    return !kpiInstructions?.hideTrendLines || kpiInstructions?.textOnlyFormat?.subtitle;
  } else if (
    visualizeOperation.operation_type === OPERATION_TYPES.VISUALIZE_NUMBER_TREND_TEXT_PANEL
  ) {
    return kpiInstructions?.textOnlyFormat?.subtitle;
  }

  return true;
};

const convertColumnAndValueIntoFilter = (
  column: CategoryChartColumnInfo,
  category?: string | number,
  excludedCategories?: (string | number)[],
): FilterClause[] | undefined => {
  const col = column.column;

  if (!col.type || !col.name || !category) return [];

  const createFilter = (filterOp: FilterOperator, filterValue?: FilterValueType): FilterClause => {
    return {
      // This is checked above so its not null
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      filterColumn: { name: col.name!, type: col.type! },
      filterValue,
      filterOperation: { id: filterOp },
    };
  };

  if (category === 'Other' && excludedCategories !== undefined) {
    if (col.type !== STRING && !NUMBER_TYPES.has(col.type)) return;
    const filterOp =
      col.type === STRING ? FilterOperator.STRING_IS_NOT_IN : FilterOperator.NUMBER_IS_NOT_IN;
    return [createFilter(filterOp, JSON.stringify(excludedCategories))];
  }

  if (category === 'null') return [createFilter(FilterOperator.IS_EMPTY)];

  if (col.type === STRING) {
    return [createFilter(FilterOperator.STRING_IS, category)];
  } else if (NUMBER_TYPES.has(col.type)) {
    if (column.bucketSize !== undefined) {
      const categoryAsNum = parseInt(String(category));
      const upperBound = categoryAsNum + parseInt(String(column.bucketSize));

      return [
        createFilter(FilterOperator.NUMBER_GTE, categoryAsNum),
        createFilter(FilterOperator.NUMBER_LT, upperBound),
      ];
    } else {
      return [createFilter(FilterOperator.NUMBER_EQ, category)];
    }
  } else if (col.type === BOOLEAN) {
    const boolOp =
      category === 'true' ? FilterOperator.BOOLEAN_IS_TRUE : FilterOperator.BOOLEAN_IS_FALSE;
    return [createFilter(boolOp)];
  } else if (TIME_COLUMN_TYPES.has(col.type)) {
    if (!column.bucket || !DATETIME_PIVOT_AGGS_SET.has(column.bucket.id)) return;

    const startDate = DateTime.fromMillis(parseInt(category as string), { locale: Timezones.UTC });
    let endDate = startDate;

    switch (column.bucket.id) {
      case PivotAgg.DATE_DAY:
        return [createFilter(FilterOperator.DATE_IS, { startDate: startDate?.toUTC().toISO() })];
      case PivotAgg.DATE_MONTH:
        endDate = startDate.endOf('month');
        break;
      case PivotAgg.DATE_QUARTER:
        endDate = startDate.endOf('quarter');
        break;
      case PivotAgg.DATE_YEAR:
        endDate = startDate.endOf('year');
        break;
      case PivotAgg.DATE_WEEK:
        endDate = startDate.plus({ week: 1 }).minus({ day: 1 }).endOf('day');
        break;
      case PivotAgg.DATE_HOUR:
        endDate = startDate.endOf('hour');
        break;
    }

    return [
      createFilter(FilterOperator.DATE_IS_BETWEEN, {
        startDate: startDate?.toUTC().toISO(),
        endDate: endDate?.toUTC().toISO(),
      }),
    ];
  }
};

export const constructFilterFromDrilldownColumn = (
  categoryColumn: CategoryChartColumnInfo,
  category?: string | number,
  subCategoryColumn?: CategoryChartColumnInfo,
  subCategory?: string | number,
  excludedCategories?: (string | number)[],
): FilterClause[] | undefined => {
  const col = categoryColumn.column;
  if (!col.type || !col.name) return;

  const categoryFilters = convertColumnAndValueIntoFilter(
    categoryColumn,
    category,
    excludedCategories,
  );

  if (!categoryFilters || !subCategoryColumn) return categoryFilters;
  const subCol = subCategoryColumn.column;
  if (!subCol.type || !subCol.name || !subCategory) return categoryFilters;

  const subCategoryFilters = convertColumnAndValueIntoFilter(subCategoryColumn, subCategory);

  return categoryFilters.concat(subCategoryFilters || []);
};

export const getViewableSchemaForPdf = (
  dataPanel: DataPanel,
  dpSchema: DatasetSchema | undefined,
  userTransformedSchema: UserTransformedSchema | undefined,
): UserTransformedSchema => {
  const operationType = dataPanel.visualize_op.operation_type;

  const shouldUseTransformedSchema =
    (operationType === OPERATION_TYPES.VISUALIZE_TABLE &&
      dataPanel.visualize_op.instructions.VISUALIZE_TABLE.isSchemaCustomizationEnabled) ||
    operationType === OPERATION_TYPES.VISUALIZE_REPORT_BUILDER;

  if (userTransformedSchema && shouldUseTransformedSchema) return userTransformedSchema;

  const changeSchemaList = dataPanel.visualize_op.instructions.VISUALIZE_TABLE.changeSchemaList;
  const changeSchemaByColName = keyBy(changeSchemaList, 'col');

  const schema =
    dpSchema?.map((col) => ({
      ...col,
      isVisible: changeSchemaByColName[col.name]?.keepCol ?? true,
    })) ?? [];

  return sortSchemaByOrderedColumnNames(
    schema,
    dataPanel.visualize_op.instructions.VISUALIZE_TABLE.orderedColumnNames,
  );
};
