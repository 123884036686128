import { Layout } from '@explo-tech/react-grid-layout';

import { defineAPIAction, defineAPIPostAction } from 'actions/actionUtils';
import { ACTION } from 'actions/types';
import { Timezones } from 'constants/dashboardConstants';
import { EmbedDashboard } from 'types/dashboardTypes';
import { Breadcrumb, LatestVersionInfo } from 'types/exploResource';

import { DashboardVersion } from '../types/dashboardVersion';

// TYPES
type DashboardCachingConfigOptions = {
  is_cache_enabled?: boolean;
  cache_cron?: string;
};

export interface Dashboard extends EmbedDashboard, DashboardCachingConfigOptions {
  team_id: number;
  embed_id: string;
  disabled?: boolean;
  share_link_title: string | null;
  dashboard_attributes: { attribute_id: number; value_id: number }[];
  latest_versions: LatestVersionInfo[];
  entry_id: number;
}

// This is the customers config of an editable section of a dashboard
export interface CustomerEditableSection {
  config: CustomerEditableSectionConfig;
  id: number;
  edit_version_number: number;
}

// While we only need layout right now.
// Leaving this as an interface for future expansion
export interface CustomerEditableSectionConfig {
  // Charts are created from EditableSectionConfig.charts
  layout: CustomerEditableSectionLayout[];
}

export interface CustomerEditableSectionLayout extends Layout {
  template_id?: string; // ID of the template this chart is created from
}

// REQUESTS

type DashboardHierarchyResponse = {
  root_dashboard_id: string;
  dashboards: Record<string, Dashboard>;
};

export type DashboardVersionHierarchyResponse = Record<string, DashboardVersion>;

type FetchDashboardData = {
  dashboard_version: DashboardVersion;
  dashboard: Dashboard;
  breadcrumbs: Breadcrumb[]; // inclusive of root folder to current folder context
  fido_token?: string;
  // TODO(zifanxiang/tarastentz): Remove this once we completely rollout drilldowns.
  dashboard_hierarchy?: DashboardHierarchyResponse;
  dashboard_version_hierarchy?: DashboardVersionHierarchyResponse;
};

export const {
  actionFn: fetchDashboard,
  requestAction: fetchDashboardRequest,
  successAction: fetchDashboardSuccess,
  errorAction: fetchDashboardError,
} = defineAPIAction<FetchDashboardData>(
  ACTION.FETCH_DASHBOARD,
  'dashboards',
  'get_dashboard_template',
  'GET',
);

export const { actionFn: createDashboard, successAction: createDashboardSuccess } =
  defineAPIPostAction<{ name: string; parent_id: number }, { new_dashboard_template: Dashboard }>(
    ACTION.CREATE_DASHBOARD,
    'dashboards',
    'create_dashboard_template',
    'POST',
  );

export const { actionFn: deleteDashboard, successAction: deleteDashboardSuccess } = defineAPIAction(
  ACTION.DELETE_DASHBOARD,
  'dashboards',
  '',
  'DELETE',
);

type ActivateDisabledDashboardPostData = { disable_dashboard_id: number };

export const {
  actionFn: activateDisabledDashboard,
  successAction: activateDisabledDashboardSuccess,
} = defineAPIPostAction<ActivateDisabledDashboardPostData, {}>(
  ACTION.ACTIVATE_DISABLED_DASHBOARD,
  'dashboards',
  'activate',
  'POST',
);

export const { actionFn: fetchDashboardList, successAction: fetchDashboardListSuccess } =
  defineAPIAction<{ dashboard_template_list: Dashboard[] }>(
    ACTION.FETCH_DASHBOARD_LIST,
    'dashboards',
    'get_dashboard_template_list',
    'GET',
  );

export const { actionFn: getDashboardCount, successAction: getDashboardCountSuccess } =
  defineAPIAction<{ result: number }>(
    ACTION.GET_DASHBOARD_COUNT,
    'dashboards',
    'dashboard_count',
    'GET',
  );

export const { actionFn: renameDashboard, successAction: renameDashboardSuccess } =
  defineAPIPostAction<{ name: string }, { name: string }>(
    ACTION.RENAME_DASHBOARD,
    'dashboards',
    'rename_dashboard_template',
    'POST',
  );

export type UpdateDefaultTimezoneBody = {
  default_timezone: Timezones;
};

export const {
  actionFn: updateDashboardDefaultTimezone,
  successAction: updateDashboardDefaultTimezoneSuccess,
} = defineAPIPostAction<UpdateDefaultTimezoneBody, {}>(
  ACTION.UPDATE_DASHBOARD_DEFAULT_TIMEZONE,
  'dashboards',
  'update_default_timezone',
  'POST',
);

type UpdateDashboardDisableFiltersWhileLoadingBody = {
  disable_filters_while_loading: boolean;
};

export const {
  actionFn: updateDashboardDisableFiltersWhileLoading,
  successAction: updateDisableFiltersWhileLoadingSuccess,
} = defineAPIPostAction<UpdateDashboardDisableFiltersWhileLoadingBody, {}>(
  ACTION.UPDATE_DISABLE_FILTERS_WHILE_LOADING,
  'dashboards',
  'update_dashboard_disable_filters_while_loading',
  'POST',
);

export const {
  actionFn: updateDashboardStatePersistance,
  successAction: updateDashboardStatePersistanceSuccess,
} = defineAPIPostAction<{ should_persist_customer_state: boolean }, {}>(
  ACTION.UPDATE_DASHBOARD_STATE_PERSISTANCE,
  'dashboards',
  'update_dashboard_state_persistance',
  'POST',
);

export const {
  actionFn: updateDashboardCacheConfig,
  successAction: updateDashboardCacheConfigSuccess,
} = defineAPIPostAction<DashboardCachingConfigOptions, {}>(
  ACTION.UPDATE_DASHBOARD_CACHE_CONFIG,
  'dashboards',
  'update_caching_config',
  'POST',
);

export const { actionFn: getEditableSectionUsage } = defineAPIAction<{
  count: number;
  customer_names: string[];
}>(ACTION.GET_EDITABLE_SECTION_USAGE, 'dashboards', 'get_editable_section_usage', 'GET');

// Share Dashboard Actions

type SaveShareLinkTitleBody = {
  share_link_title: string;
};

export const { actionFn: saveShareLinkTitle, requestAction: saveShareLinkTitleRequest } =
  defineAPIPostAction<SaveShareLinkTitleBody, {}>(
    ACTION.SAVE_SHARE_LINK_TITLE,
    'dashboards',
    'save_share_link_title',
    'POST',
  );

export interface CreateChildDashboardTemplateData {
  new_child_dashboard_template: Dashboard;
}

export const {
  actionFn: createChildDashboardTemplate,
  requestAction: createChildDashboardRequest,
  successAction: createChildDashboardSuccess,
  errorAction: createChildDashboardError,
} = defineAPIPostAction<
  { name: string; parent_dashboard_id: number; user_id: number },
  CreateChildDashboardTemplateData
>(ACTION.CREATE_CHILD_DASHBOARD, 'dashboards', 'create_child_dashboard_template', 'POST');
