import { ThunkAction, AnyAction } from '@reduxjs/toolkit';

import { EmbedReportBuilder } from 'actions/reportBuilderActions';
import { REPORTED_ANALYTIC_ACTION_TYPES } from 'constants/types';
import * as RD from 'remotedata';
import { isSuccess } from 'remotedata';
import { ReportBuilderReduxState } from 'reportBuilderContent/reducers/rootReducer';
import { SelectedReportType, ReportType } from 'reportBuilderContent/reducers/types';
import { sendAnalyticsEventThunk } from 'telemetry/telemetryThunks';
import { Metadata } from 'telemetry/telemetryUtils';

const getReportBuilderMetadata = (reportBuilder: RD.ResponseData<EmbedReportBuilder>) => {
  const reportBuilderData = isSuccess(reportBuilder) ? reportBuilder.data : undefined;
  return { report_builder_id: reportBuilderData?.id, report_builder_name: reportBuilderData?.name };
};

export const sendReportBuilderAnalyticsEvent =
  (
    selectedReport: SelectedReportType | null,
    event: REPORTED_ANALYTIC_ACTION_TYPES,
    metadata?: Metadata,
  ): Thunk =>
  (dispatch, getState) => {
    if (!selectedReport) {
      const { reportBuilder } = getState().embeddedReportBuilder;
      const reportBuilderMetadata = getReportBuilderMetadata(reportBuilder);
      dispatch(sendAnalyticsEventThunk(event, { ...reportBuilderMetadata, ...metadata }));
    } else if (selectedReport.type === ReportType.CUSTOMER_REPORT) {
      dispatch(sendCustomerReportAnalyticsEvent(selectedReport.id, event, metadata));
    } else if (selectedReport.type === ReportType.BUILT_IN) {
      dispatch(sendBuiltInAnalyticsEvent(selectedReport.id, event, metadata));
    } // Only end user edits are tracked (no edits to built ins)
  };

export const sendCustomerReportAnalyticsEvent =
  (customerReportId: number, event: REPORTED_ANALYTIC_ACTION_TYPES, metadata?: Metadata): Thunk =>
  (dispatch, getState) => {
    const { reports, reportBuilder } = getState().embeddedReportBuilder;
    const report = reports.find((report) => report.id === customerReportId);
    const reportBuilderMetadata = getReportBuilderMetadata(reportBuilder);
    dispatch(
      sendAnalyticsEventThunk(event, {
        customer_report_id: customerReportId,
        customer_report_name: report?.name,
        ...reportBuilderMetadata,
        ...metadata,
      }),
    );
  };

export const sendBuiltInAnalyticsEvent =
  (builtInId: string, event: REPORTED_ANALYTIC_ACTION_TYPES, metadata?: Metadata): Thunk =>
  (dispatch, getState) => {
    const { reportBuilderVersion, reportBuilder } = getState().embeddedReportBuilder;
    const builtInReports = reportBuilderVersion?.config.builtInReports;
    const report = builtInReports?.[builtInId];
    const reportBuilderMetadata = getReportBuilderMetadata(reportBuilder);
    dispatch(
      sendAnalyticsEventThunk(event, {
        built_in_id: builtInId,
        built_in_name: report?.name,
        ...reportBuilderMetadata,
        ...metadata,
      }),
    );
  };

type Thunk = ThunkAction<void, ReportBuilderReduxState, unknown, AnyAction>;
