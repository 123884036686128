import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Provider } from 'react-redux';

import { baseThemeName } from 'components/ds';
import { ReportBuilderWrapper } from 'pages/ReportBuilder';

import configureStore from './store';

export const MainApp = ({
  customerToken,
  reportBuilderEmbedId,
  environment,
  variables,
  embedJwt,
  localeCode,
  timezone,
  theme,
  currencyCode,
}) => {
  const parsedVariables = variables === undefined ? {} : JSON.parse(variables);

  return (
    <div className="explo-embed" style={{ height: '100%', width: '100%' }}>
      <div
        className={baseThemeName}
        style={{ all: 'initial', boxSizing: 'border-box', font: 'inherit', lineHeight: 'normal' }}>
        <Provider store={configureStore()}>
          {/* Add StylesProvider/ThemeProvider if any material ui will be used */}
          <DndProvider backend={HTML5Backend}>
            <ReportBuilderWrapper
              currencyCode={currencyCode}
              customerToken={customerToken}
              embedJwt={embedJwt}
              embedType="embedded"
              embeddedVariables={parsedVariables}
              environment={environment}
              localeCode={localeCode}
              reportBuilderEmbedId={reportBuilderEmbedId}
              theme={theme}
              timezone={timezone}
            />
          </DndProvider>
        </Provider>
      </div>
    </div>
  );
};
