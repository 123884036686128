import { FC, useCallback } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { CustomerReport, deleteCustomerReport } from 'actions/customerReportActions';
import { IconName } from 'components/ds/Icon';
import { BaseListItem } from 'pages/ReportBuilder/HomeView/BaseListItem';
import { ReportBuilderReduxState } from 'reportBuilderContent/reducers/rootReducer';
import { ReportType } from 'reportBuilderContent/reducers/types';
import { openCustomerReportThunk } from 'reportBuilderContent/thunks/reportThunks';
import { getReportName } from 'utils/customerReportUtils';

interface Props {
  report: CustomerReport;
}

export const ReportListItem: FC<Props> = ({ report }) => {
  const dispatch = useDispatch();

  const { customerToken, jwt, isPreview } = useSelector(
    (state: ReportBuilderReduxState) => ({
      customerToken: state.embeddedReportBuilder.requestInfo.customerToken,
      isPreview: state.embeddedReportBuilder.isPreview,
      jwt: state.embeddedReportBuilder.requestInfo.embedJwt,
    }),
    shallowEqual,
  );

  const handleDelete = useCallback(
    () =>
      dispatch(
        deleteCustomerReport({
          customerToken,
          jwt,
          postData: { report_id: report.id },
        }),
      ),
    [customerToken, dispatch, jwt, report.id],
  );

  return (
    <BaseListItem
      id={report.id.toString()}
      isStarred={report.is_starred}
      key={report.id}
      menuOptions={[
        {
          disabled: isPreview,
          icon: 'trash' as IconName,
          name: 'Delete',
          onClick: handleDelete,
        },
      ]}
      modified={report.modified}
      name={getReportName(report.name)}
      onClick={() => dispatch(openCustomerReportThunk(report))}
      reportInfo={{ type: ReportType.CUSTOMER_REPORT, id: report.id }}
    />
  );
};
