import { FC, useCallback, useMemo } from 'react';
import { Layout } from 'react-grid-layout';
import { useDispatch } from 'react-redux';

import {
  addChartToEditableSectionThunk,
  removeChartTemplateFromEditableSectionThunk,
} from 'reducers/thunks/editableSectionThunks';
import { DashboardVariableMap } from 'types/dashboardTypes';
import { EditableSectionChart } from 'types/dashboardVersionConfig';
import { isChartInstanceOfTemplate } from 'utils/editableSectionUtils';
import { partition } from 'utils/standard';

import { AddChartMenuItem } from './AddChartMenuItem';

interface Props {
  chartsToAdd: EditableSectionChart[];
  isEditing: boolean;
  layout: Layout[] | undefined;
  onItemClicked: () => void;
  numCols: number;
  variables: DashboardVariableMap | null;
}

export const AddChartMenu: FC<Props> = ({
  chartsToAdd,
  isEditing,
  layout,
  numCols,
  onItemClicked,
  variables,
}) => {
  const dispatch = useDispatch();

  const { chartsInLayout, chartsOutOfLayout } = useMemo(() => {
    chartsToAdd.sort((firstChart, secondChart) => firstChart.name.localeCompare(secondChart.name));
    const [chartsInLayout, chartsOutOfLayout] = partition(chartsToAdd, (chartToAdd) =>
      layout?.find((chart) => isChartInstanceOfTemplate(chart, chartToAdd)),
    );

    return { chartsInLayout, chartsOutOfLayout };
  }, [chartsToAdd, layout]);

  const onChartItemClicked = useCallback(
    (isInLayout: boolean, chart: EditableSectionChart) => {
      if (!isEditing) return;
      if (!isInLayout) {
        dispatch(addChartToEditableSectionThunk(chart, numCols));
      } else if (layout) {
        dispatch(removeChartTemplateFromEditableSectionThunk(chart));
      }
      onItemClicked();
    },
    [dispatch, isEditing, layout, numCols, onItemClicked],
  );

  return (
    <>
      {chartsOutOfLayout.map((chart) => (
        <AddChartMenuItem
          chartName={chart.name}
          dp={chart.data_panel}
          isInLayout={false}
          key={chart.data_panel.id}
          onChartItemClicked={() => onChartItemClicked(false, chart)}
          variables={variables}
        />
      ))}
      {chartsInLayout.map((chart) => (
        <AddChartMenuItem
          isInLayout
          chartName={chart.name}
          dp={chart.data_panel}
          key={chart.data_panel.id}
          onChartItemClicked={() => onChartItemClicked(true, chart)}
          variables={variables}
        />
      ))}
    </>
  );
};
