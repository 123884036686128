import React from 'react';
import { render, unmountComponentAtNode } from 'react-dom';

import { loadStyles, unloadStyles } from 'utils/loadStyles';

import MainApp from './reactIndex';

import '@blueprintjs/core/lib/css/blueprint.css';
import '@blueprintjs/table/lib/css/table.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'funnel-graph-js/dist/css/theme.min.css';
import 'funnel-graph-js/dist/css/main.min.css';

class EmbeddoDashboard extends HTMLElement {
  static get observedAttributes() {
    return [
      'dash-customer-token',
      'dash-user-group-token',
      'variables',
      'refresh-minutes',
      'locale-code',
      'timezone',
      'dash-jwt',
      'currency-code',
    ];
  }

  disconnectedCallback() {
    unmountComponentAtNode(this);

    unloadStyles();
  }

  createCollapsed(
    id,
    customerToken,
    dashboardEmbedId,
    isProduction,
    environment,
    variables,
    updateUrlParams,
    customStyles,
    refreshMinutes,
    localeCode,
    timezone,
    analyticsProperties,
    embedJwt,
    dashboardTheme,
    currencyCode,
    disableEditableSectionEditing,
    hideEditableSection,
    hideEditableSectionEditControls,
  ) {
    return React.createElement(MainApp, {
      id,
      customerToken,
      dashboardEmbedId,
      isProduction,
      environment,
      variables,
      updateUrlParams,
      customStyles,
      refreshMinutes,
      localeCode,
      timezone,
      analyticsProperties,
      embedJwt,
      dashboardTheme,
      currencyCode,
      disableEditableSectionEditing,
      hideEditableSection,
      hideEditableSectionEditControls,
    });
  }

  connectedCallback() {
    loadStyles(() => {
      const id = this.getAttribute('id');
      const isProduction = this.getAttribute('isProduction');
      const environment = this.getAttribute('environment');
      const variables = this.getAttribute('variables');
      const updateUrlParams = this.getAttribute('updateUrlParams');
      const customStyles = this.getAttribute('custom-styles');
      const refreshMinutes = this.getAttribute('refresh-minutes');
      const localeCode = this.getAttribute('locale-code');
      const currencyCode = this.getAttribute('currency-code');
      const timezone = this.getAttribute('timezone');
      const analyticsProperties = this.getAttribute('analytics-properties');
      const dashJwt = this.getAttribute('dash-jwt');
      const dashboardTheme = this.getAttribute('theme');
      const disableEditableSectionEditing = this.getAttribute('disable-editable-section-editing');
      const hideEditableSection = this.getAttribute('hide-editable-section');
      const hideEditableSectionEditControls = this.getAttribute(
        'hide-editable-section-edit-controls',
      );

      const dashGroupToken =
        this.getAttribute('dash-customer-token') ?? this.getAttribute('dash-user-group-token');

      let customerToken, dashboardEmbedId;
      if (dashGroupToken) {
        dashboardEmbedId = dashGroupToken.split(':')[0];
        customerToken = dashGroupToken.split(':')[1];
      } else if (dashJwt) {
        customerToken = null;
        dashboardEmbedId = null;
      } else {
        customerToken = this.getAttribute('userGroupToken');
        dashboardEmbedId = this.getAttribute('dashboardEmbedId');
      }

      render(
        this.createCollapsed(
          id,
          customerToken,
          dashboardEmbedId,
          isProduction,
          environment,
          variables,
          updateUrlParams,
          customStyles,
          refreshMinutes,
          localeCode,
          timezone,
          analyticsProperties,
          dashJwt,
          dashboardTheme,
          currencyCode,
          disableEditableSectionEditing,
          hideEditableSection,
          hideEditableSectionEditControls,
        ),
        this,
      );
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  attributeChangedCallback(name, oldValue, newValue) {
    if (!oldValue) return;
    this.connectedCallback();
  }
}

if (!customElements.get('explo-dashboard'))
  customElements.define('explo-dashboard', EmbeddoDashboard);
