import { combineReducers } from 'redux';

import { embeddedAuthReducer } from 'reducers/embeddedAuthReducer';
import { fidoReducer } from 'reducers/fidoReducer';
import { reportAiReducer } from 'reportBuilderContent/reducers/reportAiReducer';
import { telemetryReducer } from 'telemetry/telemetryReducer';

import { embeddedReportBuilderReducer } from './embeddedReportBuilderReducer';
import { reportBuilderJobReducer } from './reportBuilderJobReducer';
import { reportEditingReducer } from './reportEditingReducer';

const rootReducer = combineReducers({
  analytics: telemetryReducer,
  embeddedReportBuilder: embeddedReportBuilderReducer,
  reportEditing: reportEditingReducer,
  reportBuilderJob: reportBuilderJobReducer,
  reportAi: reportAiReducer,
  fido: fidoReducer,
  embeddedAuth: embeddedAuthReducer,
});

export type ReportBuilderReduxState = ReturnType<typeof rootReducer>;

export default rootReducer;
