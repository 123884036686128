import { FC, useContext } from 'react';
import { useDispatch } from 'react-redux';

import 'react-resizable/css/styles.css';
import { ExportType } from 'actions/exportActions';
import DashboardLayoutContext from 'components/DashboardLayout/DashboardLayoutContext';
import { ExportOptions } from 'components/ExportOptions/ExportOptions';
import { sprinkles } from 'components/ds';
import { EmbedButton, EmbedPopover } from 'components/embed';
import '@explo-tech/react-grid-layout/css/styles.css';
import { REPORTED_ANALYTIC_ACTION_TYPES } from 'constants/types';
import { clearShareLink } from 'reducers/dashboardLayoutReducer';
import { downloadDashboardThunk } from 'reducers/thunks/dashboardLayoutThunks/';
import { sendAnalyticsEventThunk } from 'telemetry/telemetryThunks';
import { DashboardVariableMap, ExportElemConfig } from 'types/dashboardTypes';
import { useDownload } from 'utils/exportUtils';

type Props = {
  config: ExportElemConfig;
  exportVars: DashboardVariableMap;
  isSharedView?: boolean;
};

export const DashboardExportElement: FC<Props> = ({ config, isSharedView, exportVars }) => {
  const dispatch = useDispatch();
  const context = useContext(DashboardLayoutContext);

  const { windowState, downloadInfo } = useDownload(undefined);

  const doNotPopover =
    config.disableImageDownload && config.disablePdfDownload && config.disableShareLink;

  const webShareProps = { config, exportVars, isSharedView };
  const fileName = config.downloadDashboardFileName;

  const trigger = (
    <EmbedButton
      fillWidth
      data-testid="dashboard-export-element"
      disabled={doNotPopover}
      icon="arrow-up-from-line"
      onClick={() =>
        dispatch(sendAnalyticsEventThunk(REPORTED_ANALYTIC_ACTION_TYPES.SHARE_BUTTON_CLICKED))
      }
      variant="primary">
      {config.label}
    </EmbedButton>
  );

  return (
    <div className={sprinkles({ paddingTop: 'sp2.5' })}>
      <EmbedPopover
        onOpenChange={(open) => {
          if (!open) dispatch(clearShareLink());
        }}
        portalContainerId={context.dashboardLayoutTagId}
        trigger={trigger}
        width="large">
        <ExportOptions
          downloadDashboardImage={(email) =>
            dispatch(downloadDashboardThunk(ExportType.Image, exportVars, fileName, email))
          }
          downloadDashboardPdf={(email) =>
            dispatch(downloadDashboardThunk(ExportType.PDF, exportVars, fileName, email))
          }
          downloadInfo={downloadInfo}
          webShareProps={webShareProps}
          windowState={windowState}
        />
      </EmbedPopover>
    </div>
  );
};
