import { JobDefinition } from 'actions/jobQueueActions';
import {
  setDashboardLoaded,
  setDataPanelsLoading,
  setDefaultDropdownInfo,
  setRefreshDataInfo,
  updateAdHocOperationInstructions,
  updateDashboardVariables,
  updateRefreshDataInfo,
} from 'reducers/dashboardDataReducer';
import * as RD from 'remotedata';
import {
  DASHBOARD_ELEMENT_TYPES,
  DashboardElement,
  DashboardVariableMap,
  SelectElemConfig,
} from 'types/dashboardTypes';
import { AdHocOperationInstructions } from 'types/dataPanelTemplate';
import { DataPanel, ResourceDataset } from 'types/exploResource';
import * as dashboardUtils from 'utils/dashboardUtils';
import { getDashboardTimezone } from 'utils/dashboardUtils';
import * as extraVarUtils from 'utils/extraVariableUtils';
import { useFidoForRequest } from 'utils/fido/fidoUtils';
import { getSelectFilterDatasetId } from 'utils/filterUtils';
import { keyBy, uniq, without } from 'utils/standard';
import * as variableUtils from 'utils/variableUtils';

import { enqueueDashboardJobsThunk } from '../dashboardLayoutThunks';
import { DashboardLayoutThunk } from '../dashboardLayoutThunks/types';

import {
  fetchDataPanelsThunk,
  fetchPrimaryData,
  fetchRowCountData,
  fetchSecondaryData,
} from './fetchDataPanelThunks';
import { fetchDatasetsThunk } from './fetchDatasetPreviewThunks';
import { fetchFidoComputationDataThunk } from './fetchFidoDataThunks';
import { DashboardDataThunk } from './types';
import { DashboardConfig, getDashboardConfig, getDataPanelsDependentOnVars } from './utils';

/*
 * This function is called on initial load or when something major changes.
 * For example customer, version, or timezone changing.
 * It gets default values for dropdowns and then fetches everything else
 */
export const initializeDashboardDataThunk =
  (elements: DashboardElement[], datasets: Record<string, ResourceDataset>): DashboardDataThunk =>
  (dispatch) => {
    const elemsWithDefaultValues = dashboardUtils.getDashboardElemsWithDefaultQueryValues(elements);
    if (elemsWithDefaultValues.length > 0) {
      dispatch(fetchDropdownQueryDefaultsThunk({ defaultElems: elemsWithDefaultValues, datasets }));
    } else {
      dispatch(fetchDashboardDataThunk({}));
    }
  };

type DropdownQueryDefaultsData = {
  defaultElems: DashboardElement[];
  datasets: Record<string, ResourceDataset>;
  // Used when variable is changed, not on mount
  varNameSet?: Set<string>;
};

// This function fetches datasets necessary for default dropdowns
const fetchDropdownQueryDefaultsThunk =
  ({ defaultElems, datasets, varNameSet }: DropdownQueryDefaultsData): DashboardLayoutThunk =>
  (dispatch) => {
    const elementIds = defaultElems.map((elem) => elem.id);
    const datasetIdsForDefaultElems = Array.from(
      dashboardUtils.extractDatasetIdsFromElems(defaultElems),
    );

    // Sets information needed to store, so we know what datasetIds we are waiting to be requested
    dispatch(
      setDefaultDropdownInfo({
        datasetIds: datasetIdsForDefaultElems,
        elementIds,
        changedVarNames: varNameSet ? Array.from(varNameSet) : undefined,
      }),
    );
    // And then we fetch the datasets
    dispatch(fetchDatasetsThunk(datasetIdsForDefaultElems, datasets));
  };

/*
 * This thunk is called by middleware when all datasets needed for default dropdowns
 * have finished loading. It sets the variables for them and then fetches rest of dashboard
 */
export const defaultDropdownsFinishedLoadingThunk =
  (): DashboardDataThunk => (dispatch, getState) => {
    const state = getState();
    const config = getDashboardConfig(state);
    const { variables, datasetData } = state.dashboardData;
    const defaultDropdownInfo = state.dashboardData.defaultDropdownInfo;
    if (!config || !variables || !defaultDropdownInfo) return;

    const elementsById = keyBy(config.elements, (element) => element.id);

    const defaultVars: DashboardVariableMap = {};

    const calledOnMount = defaultDropdownInfo.changedVarNames === undefined;
    const changedVarNameSet = new Set(defaultDropdownInfo.changedVarNames ?? []);

    const defaultElements: DashboardElement[] = [];

    defaultDropdownInfo.elementIds.forEach((elemId) => {
      const elem = elementsById[elemId];
      if (!elem) return;

      const isMultiSelect = elem.element_type === DASHBOARD_ELEMENT_TYPES.MULTISELECT;
      defaultElements.push(elem);
      const currentValue = variables[elem.name];

      //  If the variable is set onMount for a multiselect we do not want to override it
      if (calledOnMount && isMultiSelect && currentValue !== undefined) return;

      const elemWasCleared = !calledOnMount && currentValue === undefined;
      if (changedVarNameSet.has(elem.name) || elemWasCleared) return;

      const elemConfig = elem.config as SelectElemConfig;
      const { queryTable, queryValueColumn, queryDisplayColumn } = elemConfig.valuesConfig;
      if (!queryTable || !queryValueColumn) return;

      const data = datasetData[queryTable.id];
      if (!data?.rows?.length) return;

      const rowWithValue = dashboardUtils.findRowWithValue(
        data.rows,
        queryValueColumn.name,
        currentValue,
      );
      if (rowWithValue) {
        if (calledOnMount && !isMultiSelect) {
          defaultVars[extraVarUtils.getDisplayVarName(elem.name)] = dashboardUtils.getValueFromRow(
            rowWithValue,
            queryValueColumn.name,
            queryDisplayColumn?.name,
          ).defaultDisplay;
        }
        return;
      }

      const { defaultValue, defaultDisplay } = dashboardUtils.getDefaultValueFromRows(
        elemConfig.valuesConfig,
        data.rows,
      );

      if (isMultiSelect) {
        if (defaultValue === undefined) return;
        defaultVars[elem.name] = [defaultValue] as string[] | number[];
        defaultVars[extraVarUtils.getLengthVarName(elem.name)] = 1;
      } else {
        defaultVars[elem.name] = defaultValue;
        if (defaultDisplay) {
          defaultVars[extraVarUtils.getDisplayVarName(elem.name)] = defaultDisplay;
        }
      }
    });

    dispatch(updateDashboardVariables(defaultVars));

    if (calledOnMount) {
      dispatch(fetchDashboardDataThunk({ updatedDatasetIds: defaultDropdownInfo.datasetIds }));
    } else {
      dispatch(
        fetchDropdownUpdatesFromDefaultThunk(config, defaultElements, Object.keys(defaultVars)),
      );
    }
  };

type FetchDashboardDataData = {
  updatedDatasetIds?: string[];
  changedVarNames?: string[];
  dataPanelsToLoad?: string[];
  shouldOverrideCache?: boolean;
};

/*
 * This thunk is called in many ways
 * 1. When initializing or refreshing entire dashboard with no defaults
 *    - That means all data panels are fetched and any datasets needed for elements are fetched
 * 2. When initializing or refreshing and only datasetIds passed in
 *    - That means all data panels are fetched and only non loaded datasets needed for elements are fetched
 * 3. When one or more variables are changed
 *    - A list of changed var names are sent along with a list of data panels and already loaded datasets
 *    - This fetches the list of data panels if any, and any other datasets affected by the variable(s) change
 */
export const fetchDashboardDataThunk =
  ({
    updatedDatasetIds,
    changedVarNames,
    dataPanelsToLoad,
    shouldOverrideCache,
  }: FetchDashboardDataData): DashboardDataThunk =>
  (dispatch, getState) => {
    const state = getState();
    const config = getDashboardConfig(state);
    if (!config) return;

    const { elements, datasets } = config;

    let dataPanels: DataPanel[] = [];
    if (dataPanelsToLoad) {
      dataPanelsToLoad.forEach((dpId) => {
        const dataPanel = config.dataPanels[dpId];
        if (dataPanel) dataPanels.push(dataPanel);
      });
    } else {
      dataPanels = Object.values(config.dataPanels);
    }

    const changedVarNameSet = new Set(changedVarNames ?? []);

    const uniqueDatasetIds = dashboardUtils.getDatasetIdsForElems(elements, datasets);
    dataPanels.forEach((dp) =>
      variableUtils
        .getDatasetIdsForDataPanel(dp, datasets)
        .forEach((datasetId) => uniqueDatasetIds.add(datasetId)),
    );

    if (dataPanels.length > 0)
      dispatch(fetchDataPanelsThunk(dataPanels, config, shouldOverrideCache));

    const datasetIdsToUpdate = without(
      variableUtils.getDatasetIdsDependentOnVariable(
        Array.from(uniqueDatasetIds),
        config.datasets,
        changedVarNameSet,
      ),
      ...(updatedDatasetIds ?? []),
    );

    if (datasetIdsToUpdate.length === 0) {
      if (dataPanels.length === 0) dispatch(setDashboardLoaded(true));
      return;
    }
    dispatch(fetchDatasetsThunk(datasetIdsToUpdate, datasets));
  };

/*
 * This is called after variables are changed with the list of var names changed
 */
export const fetchDataAfterVariableChange =
  (changedVarNames: string[], clearData?: boolean): DashboardDataThunk =>
  (dispatch, getState) => {
    if (changedVarNames.length === 0) return;

    const state = getState();
    const config = getDashboardConfig(state);
    const variables = state.dashboardData.variables;
    if (!config || !variables) return;

    const varNameSet = new Set(changedVarNames);

    const dataPanelsToLoad = getDataPanelsDependentOnVars(config, varNameSet, variables);
    if (dataPanelsToLoad.length) {
      dispatch(setDataPanelsLoading({ ids: dataPanelsToLoad, loading: true, clearData }));
    }

    let defaultElems = dashboardUtils.getDashboardElemsWithDefaultQueryValues(
      config.elements.filter((e) => !varNameSet.has(e.name)),
    );
    defaultElems = variableUtils.getElemsReliantOnVariableChange(
      defaultElems,
      config.datasets,
      varNameSet,
    );

    dispatch(setRefreshDataInfo({ loadingDataPanelIds: dataPanelsToLoad, changedVarNames }));

    if (defaultElems.length === 0) {
      return dispatch(fetchDropdownUpdatesFromDefaultThunk(config, defaultElems));
    }
    dispatch(
      fetchDropdownQueryDefaultsThunk({
        defaultElems,
        datasets: config.datasets,
        varNameSet,
      }),
    );
  };

/*
 * This is called after default dropdowns are fetched. It checks if other dropdowns
 * were affected by variable changes and re-requests the datasets
 */
const fetchDropdownUpdatesFromDefaultThunk =
  (
    config: DashboardConfig,
    defaultElems: DashboardElement[],
    changedDefaultVariableNames?: string[],
  ): DashboardDataThunk =>
  (dispatch, getState) => {
    const { variables, refreshDataInfo } = getState().dashboardData;
    if (!variables) return;

    let dataPanelsToLoad: string[] = [];
    if (changedDefaultVariableNames?.length) {
      const defVarNameSet = new Set(changedDefaultVariableNames);
      dataPanelsToLoad = getDataPanelsDependentOnVars(config, defVarNameSet, variables);
      if (dataPanelsToLoad.length) {
        dispatch(setDataPanelsLoading({ ids: dataPanelsToLoad, loading: true }));
      }
    }

    const varNameSet = new Set(refreshDataInfo.changedVarNames);

    const datasetIdsForDefaultElems = dashboardUtils.extractDatasetIdsFromElems(defaultElems);
    const defaultElemNames = new Set(defaultElems.map((elem) => elem.name));

    const datasetsToUpdate = Object.values(config.datasets).filter((dataset) => {
      if (datasetIdsForDefaultElems.has(dataset.id)) return false;
      if (variableUtils.isQueryDependentOnVariable(varNameSet, dataset)) return true;
      return variableUtils.isQueryDependentOnVariable(defaultElemNames, dataset);
    });

    const updatedElems = dashboardUtils
      .getDashboardElemsUsingDatasets(config.elements, datasetsToUpdate)
      .filter((elem) => variables[elem.name] !== undefined);

    dataPanelsToLoad = uniq(dataPanelsToLoad.concat(refreshDataInfo.loadingDataPanelIds));
    if (updatedElems.length === 0) {
      return dispatch(
        fetchDashboardDataThunk({
          changedVarNames: refreshDataInfo.changedVarNames,
          dataPanelsToLoad,
          updatedDatasetIds: Array.from(datasetIdsForDefaultElems),
        }),
      );
    }

    const datasetIdsToLoad = uniq(dashboardUtils.getDatasetIdsFromElems(updatedElems));
    dispatch(
      updateRefreshDataInfo({
        updatedElementIds: updatedElems.map((elem) => elem.id),
        datasetsLeftToLoad: datasetIdsToLoad,
        loadingDataPanelIds: dataPanelsToLoad,
        datasetIds: datasetIdsToLoad.concat(Array.from(datasetIdsForDefaultElems)),
      }),
    );
    dispatch(fetchDatasetsThunk(datasetIdsToLoad, config.datasets));
  };

/*
 * This thunk is called by middleware when all datasets needed for dropdown updates
 * have finished loading. It checks if current variables are valid and then requests data panels
 */
export const dropdownUpdatesFinishedLoadingThunk =
  (): DashboardDataThunk => (dispatch, getState) => {
    const state = getState();
    const config = getDashboardConfig(state);
    const { variables, refreshDataInfo, datasetData } = state.dashboardData;
    if (!config || !variables || !refreshDataInfo) return;

    const elementsById = keyBy(config.elements, (element) => element.id);

    const newVars: DashboardVariableMap = {};
    const changedVarSet = new Set(refreshDataInfo.changedVarNames);

    refreshDataInfo.updatedElementIds?.forEach((elemId) => {
      const elem = elementsById[elemId];
      if (!elem || changedVarSet.has(elem.name) || !variables[elem.name]) return;

      const config = elem.config as SelectElemConfig;
      const datasetId = getSelectFilterDatasetId(config);
      const queryValueColName = config.valuesConfig.queryValueColumn?.name || '';
      if (!datasetId || !queryValueColName) return;

      const datasetRows = datasetData[datasetId]?.rows ?? [];

      if (elem.element_type === DASHBOARD_ELEMENT_TYPES.MULTISELECT) {
        // Can also be number[] but typescript is annoying
        const varValues = variables[elem.name] as string[];
        const newValues = varValues.filter((value) =>
          dashboardUtils.findRowWithValue(datasetRows, queryValueColName, value),
        );
        // If length hasn't changed no need to update
        if (varValues.length === newValues.length) return;

        newVars[elem.name] = newValues;
        newVars[extraVarUtils.getLengthVarName(elem.name)] = newValues.length;
      } else if (
        !dashboardUtils.findRowWithValue(datasetRows, queryValueColName, variables[elem.name])
      ) {
        // if the value was not in the new dataset rows, mark it as undefined
        newVars[elem.name] = undefined;
        extraVarUtils
          .getListOfExtraVarsForElement(elem.name, elem.element_type)
          .forEach((extraVarName) => (newVars[extraVarName] = undefined));
      }
    });

    dispatch(updateDashboardVariables(newVars));
    dispatch(
      fetchDashboardDataThunk({
        updatedDatasetIds: refreshDataInfo.datasetIds ?? [],
        changedVarNames: refreshDataInfo.changedVarNames,
        dataPanelsToLoad: refreshDataInfo.loadingDataPanelIds,
      }),
    );
  };

/*
 * This thunk is called by tables/report builders when a filter is added or page changed
 * Updates the data panels adHocInstructions and fetches necessary data
 */
export const adHocInstructionUpdatedThunk =
  (
    dataPanelId: string,
    adHocInstructions: AdHocOperationInstructions,
    skipRowCount = false,
  ): DashboardDataThunk =>
  (dispatch, getState) => {
    const state = getState();
    const config = getDashboardConfig(state);
    const { variables, datasetData } = state.dashboardData;
    if (!config || !variables) return;

    const dataPanel = config.dataPanels[dataPanelId];
    if (!dataPanel) return;

    const dashboardTimezone = getDashboardTimezone(
      RD.getOrDefault(state.embedDashboard.dashboard, undefined),
    );

    const jobs: JobDefinition[] = [];

    dispatch(
      updateAdHocOperationInstructions({
        id: dataPanelId,
        instructions: adHocInstructions,
      }),
    );

    const dataPanelVariables = variableUtils.getDataPanelQueryContext(dataPanel, variables);

    if (
      useFidoForRequest(
        state.dashboardLayout.requestInfo,
        state.fido,
        config.datasets[dataPanel.table_id],
      )
    ) {
      dispatch(
        fetchFidoComputationDataThunk(
          dashboardUtils.prepareDataPanelForFetch(
            dataPanelVariables,
            dataPanel,
            config.datasets,
            datasetData,
            config.elements,
            config.dataPanels,
            undefined,
            dashboardTimezone,
          ),
          config.datasets,
          dataPanelVariables,
          adHocInstructions,
        ),
      );
    } else {
      dispatch(fetchPrimaryData(dataPanel, config, jobs, dataPanelVariables, adHocInstructions));
      if (!skipRowCount)
        dispatch(fetchRowCountData(dataPanel, config, jobs, dataPanelVariables, adHocInstructions));

      if (jobs.length > 0) dispatch(enqueueDashboardJobsThunk({ jobs }));
    }
  };

/*
 * This thunk is called by edit dashboard page when a data panel link is changed
 * Checks if variable is even set and if it is re-fetches necessary data panels
 */
export const dataPanelLinkUpdatedThunk =
  (elementName: string, dataPanelIds: string[]): DashboardDataThunk =>
  (dispatch, getState) => {
    if (dataPanelIds.length === 0) return;
    const state = getState();
    const config = getDashboardConfig(state);
    const { variables } = state.dashboardData;
    if (!config || !variables) return;

    const hasVar = Object.keys(variables).find(
      (v) => v === elementName || v.startsWith(`${elementName}.`),
    );
    if (!hasVar) return;

    const dataPanels: DataPanel[] = [];
    dataPanelIds.forEach((dpId) => {
      const dp = config.dataPanels[dpId] ?? config.editableSectionCharts?.[dpId]?.data_panel;
      if (dp) dataPanels.push(dp);
    });

    if (dataPanels.length > 0) dispatch(fetchDataPanelsThunk(dataPanels, config));
  };

/*
 * This thunk is called by edit dashboard page when a data panel config is changed
 * Either requests all data or just secondary data
 */
export const onDataPanelUpdateThunk =
  (
    dataPanelId: string,
    shouldRecompute: boolean,
    shouldRecomputeSecondary: boolean,
  ): DashboardDataThunk =>
  (dispatch, getState) => {
    if (!shouldRecompute && !shouldRecomputeSecondary) return;

    const state = getState();
    const config = getDashboardConfig(state);
    if (!config) return;

    const dataPanel =
      config.dataPanels[dataPanelId] ?? config.editableSectionCharts?.[dataPanelId]?.data_panel;
    if (!dataPanel) return;

    const dashboardTimezone = getDashboardTimezone(
      RD.getOrDefault(state.embedDashboard.dashboard, undefined),
    );

    const dataPanelVariables = variableUtils.getDataPanelQueryContext(
      dataPanel,
      state.dashboardData.variables ?? {},
    );

    if (
      useFidoForRequest(
        state.dashboardLayout.requestInfo,
        state.fido,
        config.datasets[dataPanel.table_id],
      )
    ) {
      return dispatch(
        fetchFidoComputationDataThunk(
          dashboardUtils.prepareDataPanelForFetch(
            dataPanelVariables,
            dataPanel,
            config.datasets,
            state.dashboardData.datasetData,
            config.elements,
            config.dataPanels,
            undefined,
            dashboardTimezone,
          ),
          config.datasets,
          dataPanelVariables,
        ),
      );
    }

    if (shouldRecompute) dispatch(fetchDataPanelsThunk([dataPanel], config));
    else if (shouldRecomputeSecondary) {
      const jobs: JobDefinition[] = [];
      dispatch(fetchSecondaryData(dataPanel, config, jobs, dataPanelVariables));
      if (jobs.length > 0) dispatch(enqueueDashboardJobsThunk({ jobs }));
    }
  };

/*
 * This thunk is called when data panels are added to DashboardLayout
 */
export const onNewDataPanelsAddedThunk =
  (dataPanels: DataPanel[]): DashboardDataThunk =>
  (dispatch, getState) => {
    if (dataPanels.length === 0) return;
    const state = getState();
    const config = getDashboardConfig(state);
    if (!config) return;

    dispatch(fetchDataPanelsThunk(dataPanels, config));
  };
