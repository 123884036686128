export const PLAN_TYPES = {
  LAUNCH: 'Launch',
  GROWTH_V2: 'Growth V2',
  GROWTH: 'Growth',
  PRO: 'Pro',
  ENTERPRISE: 'Enterprise',
  DEACTIVATED: 'Deactivated',
} as const;
export type PLAN_TYPES = typeof PLAN_TYPES[keyof typeof PLAN_TYPES];

export const TRIAL_STATUS = {
  COMPLETED: 'completed',
  IN_PROGRESS: 'in_progress',
} as const;
export type TRIAL_STATUS = typeof TRIAL_STATUS[keyof typeof TRIAL_STATUS];
