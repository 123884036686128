export enum ConnectDataSourceStep {
  GETTING_STARTED = 'Getting started',
  SELECT_DB = 'Select a database',
  ENTER_CREDS = 'Enter credentials',
  SECURITY = 'Configure security',
  REVIEW = 'Review',
}
export const CONNECT_STEPS_ORDER = [
  ConnectDataSourceStep.GETTING_STARTED,
  ConnectDataSourceStep.SELECT_DB,
  ConnectDataSourceStep.ENTER_CREDS,
  ConnectDataSourceStep.SECURITY,
  ConnectDataSourceStep.REVIEW,
];

// These should match SupportedDataSource names
export enum DATABASES {
  POSTGRES = 'PostgreSQL',
  REDSHIFT = 'Redshift',
  BIGQUERY = 'BigQuery',
  MYSQL = 'MySQL',
  SNOWFLAKE = 'Snowflake',
  ROCKSET = 'Rockset',
  AZURE = 'AzureSqlDb',
  AZURESYNAPSE = 'AzureSynapse',
  SQLSERVER = 'MicrosoftSqlServer',
  CLICKHOUSE = 'ClickHouse',
  DATABRICKS = 'Databricks',
  ATHENA = 'Athena',
  CUBECLOUD = 'CubeCloud',
  SUPABASE = 'Supabase',
  CRATEDB = 'CrateDB',
  TIMESCALE = 'Timescale',
  COCKROACH = 'CockroachDB',
  SINGLESTORE = 'SingleStore',
  MATERIALIZE = 'Materialize',
  PARADE_DB = 'ParadeDB',
  TINYBIRD = 'Tinybird',
}

export const BETA_DB_SET: Set<string> = new Set([
  DATABASES.SINGLESTORE,
  DATABASES.ATHENA,
  DATABASES.COCKROACH,
  DATABASES.DATABRICKS,
  DATABASES.SQLSERVER,
  DATABASES.AZURE,
  DATABASES.AZURESYNAPSE,
  DATABASES.MATERIALIZE,
  DATABASES.PARADE_DB,
  DATABASES.TINYBIRD,
]);

export enum FIDO_TYPES {
  POSTGRES = 'postgres',
  MYSQL = 'mysql',
  SQLSERVER = 'mss',
  REDSHIFT = 'redshift',
  BIGQUERY = 'bigquery',
  SNOWFLAKE = 'snowflake',
}

export const DatabasesToPort = {
  [DATABASES.POSTGRES]: '5432',
  [DATABASES.REDSHIFT]: '5439',
  [DATABASES.MYSQL]: '3306',
  [DATABASES.AZURE]: '1433',
  [DATABASES.AZURESYNAPSE]: '1433',
  [DATABASES.SQLSERVER]: '1433',
  [DATABASES.CLICKHOUSE]: '9440',
  [DATABASES.CUBECLOUD]: '5432',
  [DATABASES.SUPABASE]: '5432',
  [DATABASES.CRATEDB]: '5432',
  [DATABASES.TIMESCALE]: '5432',
  [DATABASES.COCKROACH]: '26257',
  [DATABASES.MATERIALIZE]: '6875',
  [DATABASES.PARADE_DB]: '5432',
  [DATABASES.TINYBIRD]: '5432',
} as const;

export type DatabasePort = typeof DatabasesToPort[keyof typeof DatabasesToPort];

export const PortToDatabase = Object.entries(DatabasesToPort).reduce((acc, [db, port]) => {
  (acc[port] ??= []).push(db as DATABASES);
  return acc;
}, {} as Record<DatabasePort, DATABASES[]>);

/**
 * This is an explo public and private key pair used for securing an SSH tunnel connection.
 * If we ever need to regenerate this pair use: ssh-keygen -t rsa -b 4096 -C "support@explo.co"
 * You can output the public key using: openssl rsa -in explo.pem -pubout
 * When regenerating update the Private Key in:
 * 1Pass (Explo Test DB Subaccount) (soon to be depricated),
 * Heroku (account settings -> SSH keys) (soon to be depricated), and
 * AWS Fido Prod secrets manager under the name fido/explo-private-key in us-west2 region.
 * When regenerating update the Public Key here.
 */
export const EXPLO_PUBLIC_KEY =
  'ssh-rsa AAAAB3NzaC1yc2EAAAADAQABAAACAQDAFBLukDmz6C8AHJ/pRDPE4Fd9weZ+xr5BfDE1wSugYFRE5UWqpLRWyVsRYR2Hg35Uwp4T2OQTqXmAN3LHDeiVeJ4p9RT8Hcszo1oxh2pwe1lBuBbhaF9FBcLY2UJS0ZA2bUUD+sCU+QHIyx2hX3YxwA2tVWhDpcGRnG94sz0JgoR+6XZlLGg4ibK4sB++qXZJYCMF0V/wctiKpipTn1jckdt4b0m+Zknt4siky4KvlScXtwEWHY/nT7GcX+QZJ/mJfCOMOOfe5zRPN5CmNjM+qZMqWFk3FwP053Kdx4YolalC2WE5tupSaauFLe+efApT/sROsCugfn6xPCZJ+ByZINrm5MjD2US3CxysocBmapV+i8JEp0pII1NqoqC3ZSrniXxHVo2EwDFCj2MZAWV0odRNGKGOOZEh2958rCqx881NXlw4mREQ13Beugk2oFW47vrY+qLIk+uj1Bx2lSiNgBhbHieDewTMYeP1JZJXRpjMQg7y4Ud5n0jOue4u0b2dZP6lTjfwl+cR1BfLqFc6R8itPemtqS2+gMR67ojl1KtrglM8ZRG0L4ROVxE0miPyuOfledBd0WTZrIq9yHjBLSuFP2qVmXZgPT/XScYEc6h0mXYG8q/wd+R4aGSBpHHtzwaxxG8SkCx3odqUrSfKRMW00zjaVPDHdNTklMQqhQ== support@explo.co';

export enum DATA_SOURCE_AUTH {
  USERNAME_PASSWORD = 'username-password',
  SNOWFLAKE_PASSWORD = 'username-password',
  // AWS_IAM = 'AWS IAM Authentication',
  // Commented out since fido does not support yet, but left code structure in place so easy to add back in
}

// this should be kept in sync with SUPPORTED_FIDO_DATA_SOURCES on the backend
export const SUPPORTED_FIDO_DATA_SOURCES = [
  DATABASES.POSTGRES,
  DATABASES.MYSQL,
  DATABASES.SQLSERVER,
  DATABASES.REDSHIFT,
  DATABASES.BIGQUERY,
  DATABASES.SNOWFLAKE,
];

export const JDBC_DATA_SOURCES = [
  DATABASES.POSTGRES,
  DATABASES.MYSQL,
  DATABASES.SQLSERVER,
  DATABASES.REDSHIFT,
];

export const NEW_SCHEMA_CREATED_SUCCESS =
  'You have successfully connected a data source. You may want to sync your tables by clicking the three dots to the right of a schema name.';

export const ERROR_CONNECTING_MSG =
  'There was an error with your credentials. Please check your firewall settings and ensure the above information is correct.';

export const CONNECTED_BUT_NO_TABLES_MSG = `We successfully connected to your database, but found no tables. If this is expected, you're ready to go! Otherwise, this may be a permission issue with your credentials.`;

export enum TUNNEL {
  SSH = 'ssh-tunnel',
  PUBLIC_INTERNET = 'public-internet',
}

export enum SSH_AUTH_TYPE {
  TENANT = 'tenant',
  VENDOR = 'vendor',
}

export const FIDO_TYPE_KEY = '@type';
