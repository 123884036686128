import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { listCustomerReportsSuccess } from 'actions/customerReportActions';
import { embedFetchDashboardActions } from 'actions/embedActions';
import { ReportedAnalyticsAction } from 'actions/teamActions';
import { AnalyticsMetadata, Metadata } from 'telemetry/telemetryUtils';
import { TelemetryEmbedType } from 'telemetry/types';

interface TelemetryReducerState {
  analyticsMetadata: AnalyticsMetadata | null;
  analyticsToken: string | null;
  visitorId: string | null;
  embedType: TelemetryEmbedType | null;
  analyticsProperties?: Metadata;
  environment?: string;
  shouldSendAnalyticsJSEvents: boolean;
  shouldSendErrorJSEvents: boolean;
  reportActions?: ReportedAnalyticsAction[];
}

type SetAnalyticsVars = {
  analyticsProperties: Metadata | undefined;
  environment: string | undefined;
  embedType: TelemetryEmbedType;
};

const createInitialState = (): TelemetryReducerState => ({
  analyticsMetadata: null,
  analyticsToken: null,
  visitorId: null,
  embedType: null,
  shouldSendAnalyticsJSEvents: false,
  shouldSendErrorJSEvents: false,
  reportActions: [],
});

const telemetrySlice = createSlice({
  name: 'telemetry',
  initialState: createInitialState(),
  reducers: {
    clearTelemetrySlice: createInitialState,
    setVisitorId: (state, { payload }: PayloadAction<string>) => {
      state.visitorId = payload;
    },
    setAnalyticsVars: (state, { payload }: PayloadAction<SetAnalyticsVars>) => {
      state.analyticsProperties = payload.analyticsProperties;
      state.embedType = payload.embedType;
      state.environment = payload.environment;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(listCustomerReportsSuccess, (state, { payload }) => {
        const { customer, report_builder, team } = payload;
        state.analyticsMetadata = {
          team_id: team.id,
          team_name: team.name,
          customer_id: customer.id,
          customer_name: customer.name,
          customer_is_demo: customer.is_demo_group,
          customer_provided_id: customer.provided_id,
          report_builder_id: report_builder.id,
          report_builder_name: report_builder.name,
        };
        state.analyticsToken = team.explo_analytics_token;
        state.shouldSendAnalyticsJSEvents = !!team.should_send_js_analytics_events;
        state.shouldSendErrorJSEvents = !!team.entitlements.enable_error_js_events;
        state.reportActions = team.analytics_reporting_actions;
      })
      .addCase(embedFetchDashboardActions.successAction, (state, { payload }) => {
        const { team, customer, dashboard_template } = payload;
        state.analyticsMetadata = {
          team_id: team.id,
          team_name: team.team_name,
          customer_id: customer.id,
          customer_name: customer.name,
          customer_provided_id: customer.provided_id,
          customer_is_demo: customer.is_demo_group,
          dashboard_template_id: dashboard_template.id,
          dashboard_template_name: dashboard_template.name,
        };
        state.analyticsToken = team.explo_analytics_token;
        state.shouldSendAnalyticsJSEvents = !!team.should_send_js_analytics_events;
        state.shouldSendErrorJSEvents = !!team.entitlements.enable_error_js_events;
        state.reportActions = team.analytics_reporting_actions;
      });
  },
});

export const { clearTelemetrySlice, setVisitorId, setAnalyticsVars } = telemetrySlice.actions;

export const telemetryReducer = telemetrySlice.reducer;
