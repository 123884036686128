import { FC, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { EXPORT_TYPE_NAME_MAP, ExportType } from 'actions/exportActions';
import { Tag, sprinkles } from 'components/ds';
import {
  EmbedButton,
  EmbedModal,
  EmbedModalClose,
  EmbedModalContent,
  EmbedModalFooter,
  EmbedModalHeader,
  EmbedRadioGroup,
} from 'components/embed';
import { EmbedText } from 'pages/ReportBuilder/EmbedText';
import {
  DEFAULT_EXPORT_TYPE,
  allExportOptions,
  imageExportOptions,
  tabularExportOptions,
} from 'pages/ReportBuilder/ReportView/ExportButton/types';
import { getEnableScreenshotExportReportBuilder } from 'reducers/selectors';
import { isError, isLoading, isSuccess } from 'remotedata';
import {
  closeReportModal,
  getCurrentView,
} from 'reportBuilderContent/reducers/reportEditingReducer';
import { ReportBuilderReduxState } from 'reportBuilderContent/reducers/rootReducer';
import { exportReport } from 'reportBuilderContent/thunks/exportThunks';
import { isTableVisualization } from 'reportBuilderContent/thunks/utils';

import { ImageExportLimitationWarning } from './ImageExportLimitationWarning';

type Props = { reportName: string; isIframe: boolean };

export const ExportNowModal: FC<Props> = ({ reportName, isIframe }) => {
  const [exportType, setExportType] = useState<ExportType>(DEFAULT_EXPORT_TYPE);
  const dispatch = useDispatch();
  const { selectedReport, exportUrl, enableScreenshotExport, currentView } = useSelector(
    (state: ReportBuilderReduxState) => ({
      selectedReport: state.reportEditing.selectedReport,
      exportUrl: state.reportEditing.exportUrl,
      enableScreenshotExport: getEnableScreenshotExportReportBuilder(state),
      currentView: getCurrentView(state.reportEditing),
    }),
    shallowEqual,
  );

  const loading = isLoading(exportUrl);
  const exportOptions = enableScreenshotExport ? allExportOptions : tabularExportOptions;

  return (
    <EmbedModal
      isOpen
      isIframe={isIframe}
      onClose={() => dispatch(closeReportModal())}
      size="small">
      <EmbedModalHeader title="Export now" />
      <EmbedModalContent
        className={sprinkles({ flexItems: 'column', gap: 'sp1', paddingX: 'sp3' })}
        size="small">
        <EmbedText body="b1">
          Immediately export and download the current view. Unsaved changes will be exported.
        </EmbedText>
        <EmbedRadioGroup
          defaultValue={DEFAULT_EXPORT_TYPE}
          disabled={loading}
          onValueChange={(value) => setExportType(value as ExportType)}
          renderValue={(value) => {
            const formattedValue = EXPORT_TYPE_NAME_MAP[value as ExportType];
            if (value === ExportType.CSV && isTableVisualization(currentView?.visualization))
              return (
                <div className={sprinkles({ flexItems: 'center', gap: 'sp.5' })}>
                  {formattedValue}
                  <Tag>Recommended for tables</Tag>
                </div>
              );

            return formattedValue;
          }}
          value={exportType}
          values={exportOptions}
        />
        {imageExportOptions.includes(exportType) ? <ImageExportLimitationWarning /> : null}
        {isError(exportUrl) ? (
          <EmbedText body="b3" color="contentSecondary">
            Unable to export report. Please try again then contact support if the issue persists.
          </EmbedText>
        ) : isSuccess(exportUrl) ? (
          <EmbedText body="b3" color="contentSecondary">
            Export complete. Your download will start in a few seconds.{' '}
            <a href={exportUrl.data} rel="noreferrer" target="_blank">
              If not, click here.
            </a>
          </EmbedText>
        ) : null}
      </EmbedModalContent>
      <EmbedModalFooter>
        <EmbedModalClose variant="secondary">Cancel</EmbedModalClose>
        <EmbedButton
          data-testid="export-now-button"
          loading={loading}
          onClick={() =>
            selectedReport &&
            dispatch(
              exportReport({ selectedReport, reportName, exportType: exportType as ExportType }),
            )
          }
          variant="primary">
          Export now
        </EmbedButton>
      </EmbedModalFooter>
    </EmbedModal>
  );
};
