import { DateTime } from 'luxon';

// TODO(SHIBA-5977): Write tests for these functions.
export const getDateRangeOutOfBoundsErrorMessage = (
  startDate: DateTime,
  endDate: DateTime,
  minDate?: DateTime,
  maxDate?: DateTime,
): string => {
  return (
    `Date range ${startDate.toISODate()} - ${endDate.toISODate()} is out of bounds. ` +
    getBoundsMessage(minDate, maxDate)
  );
};

export const getDateOutOfBoundsErrorMessage = (
  date: DateTime,
  minDate?: DateTime,
  maxDate?: DateTime,
): string => {
  return `Date ${date.toISODate()} is out of bounds. ` + getBoundsMessage(minDate, maxDate);
};

const getBoundsMessage = (minDate?: DateTime, maxDate?: DateTime): string => {
  let boundsMessage = '';
  if (minDate && maxDate) {
    boundsMessage = `Needs to be between ${minDate.toISODate()} and ${maxDate.toISODate()}`;
  } else if (minDate) {
    boundsMessage = `Needs to be after ${minDate.toISODate()}`;
  } else if (maxDate) {
    boundsMessage = `Needs to be before ${maxDate.toISODate()}`;
  }

  return boundsMessage;
};
