import { ThunkAction, createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosRequestConfig } from 'axios';
import { Action, AnyAction, Dispatch } from 'redux';

import { createApiRequestConfig } from 'actions/actionUtils';
import {
  CustomerEditableSection,
  CustomerEditableSectionLayout,
  CustomerEditableSectionConfig,
} from 'actions/dashboardActions';
import { sendPing } from 'actions/pingActions';
import { ACTION } from 'actions/types';
import { EmbeddedDashboardType } from 'components/EmbeddedDashboard/types';
import { PingTypes } from 'constants/types';
import { EmbedReduxState } from 'embeddedContent/reducers/rootReducer';
import {
  deleteEditableSectionChart,
  setEditableSectionLayout,
} from 'reducers/dashboardEditConfigReducer';
import { setIsEditingEditableSection } from 'reducers/dashboardInteractionsReducer';
import {
  discardEditableSectionLayoutChanges,
  updateEmbedEditableSectionLayout,
} from 'reducers/embedDashboardReducer';
import { ReduxState } from 'reducers/rootReducer';
import { getEditableSectionConfig, getEditableSectionLayout } from 'reducers/selectors';
import {
  sendAddEditableSectionChartEventThunk,
  sendRemoveEditableSectionChartEventThunk,
} from 'reducers/thunks/customEventThunks';
import { getEditableSectionDataPanel } from 'reducers/thunks/dashboardDataThunks/utils';
import * as RD from 'remotedata';
import { EditableSectionChart } from 'types/dashboardVersionConfig';
import { createEditableSectionItemId } from 'utils/dashboardUtils';
import { getVacantLayoutPos, isChartInstanceOfTemplate } from 'utils/editableSectionUtils';
import {
  pingEDSInteractionMsg,
  EDSInteraction,
  trackEDSInteractionEvent,
} from 'utils/edsPingUtils';
import { isEqual, pick } from 'utils/standard';
import { makeThunkRequest } from 'utils/thunkUtils';

import {
  fetchDashboardDataThunk,
  onNewDataPanelsAddedThunk,
} from './dashboardDataThunks/requestLogicThunks';
import { deleteDataPanelAftermathThunk } from './dashboardSelectionThunks';

type Thunk = ThunkAction<void, ReduxState, unknown, AnyAction>;

export const deleteEditableSectionChartThunk =
  (dpId: string): Thunk =>
  (dispatch) => {
    dispatch(deleteEditableSectionChart());
    dispatch(deleteDataPanelAftermathThunk(dpId));
  };

export const addChartToEditableSectionThunk =
  (chartTemplate: EditableSectionChart, columns: number, providedId?: string): Thunk =>
  (dispatch, getState) => {
    const state = getState();
    pingAndTrackEdsEvent(state.embedDashboard, dispatch, EDSInteraction.ADDED_CHART);

    const config = getEditableSectionConfig(state);
    if (!config) return;

    const layout = getEditableSectionLayout(state) || [];
    const newLayout = [...layout];
    const { data_panel: dp } = chartTemplate;
    const opType = dp.visualize_op.operation_type;
    const chartId = providedId || createEditableSectionItemId(dp.id);
    const chartLayout = getVacantLayoutPos(opType, newLayout, columns);
    const chart: CustomerEditableSectionLayout = {
      ...chartLayout,
      i: chartId,
      template_id: dp.id,
    };
    newLayout.push(chart);
    dispatch(setEditableSectionLayoutThunk(newLayout));

    const chartDataPanel = getEditableSectionDataPanel(chartId, dp);
    dispatch(onNewDataPanelsAddedThunk([chartDataPanel]));

    dispatch(sendAddEditableSectionChartEventThunk(chartId, chartTemplate));
  };

/**
 * Deletes the first instance of a chartTemplate
 */
export const removeChartTemplateFromEditableSectionThunk =
  (chartTemplate: EditableSectionChart): Thunk =>
  (dispatch, getState) => {
    const layout = getEditableSectionLayout(getState());
    if (!layout) return;

    const chart = layout.find((item) => isChartInstanceOfTemplate(item, chartTemplate));
    if (chart) dispatch(removeChartFromEditableSectionThunk(chart.i));
  };

/**
 * @param chartId - Either user-provided ID or layout.i (chart ID). NEVER chart.data_panel.id (chart template ID)
 */
export const removeChartFromEditableSectionThunk = (chartId: string): Thunk => {
  return (dispatch, getState) => {
    const state = getState();
    pingAndTrackEdsEvent(state.embedDashboard, dispatch, EDSInteraction.DELETED_CHART);

    const config = getEditableSectionConfig(state);
    if (!config) return;

    const layout = getEditableSectionLayout(state);
    if (!layout) return;

    const index = layout.findIndex((item) => item.i === chartId);
    if (index === -1) return;

    const newLayout = [...layout];
    const chart = newLayout.splice(index, 1)[0];
    if (!chart) return; // Should never happen with findIndex

    const chartTemplate = Object.values(config.charts).find(
      (chartTemplate) => chart.template_id === chartTemplate.data_panel.id,
    );

    dispatch(setEditableSectionLayoutThunk(newLayout));
    dispatch(deleteDataPanelAftermathThunk(chartId));
    dispatch(sendRemoveEditableSectionChartEventThunk(chartId, chartTemplate));
  };
};

const pingAndTrackEdsEvent = (
  embedDashboard: EmbedReduxState['embedDashboard'],
  dispatch: Dispatch,
  interaction: EDSInteraction,
) => {
  if (RD.isSuccess(embedDashboard.dashboard)) {
    dispatch(
      sendPing({
        postData: {
          message: pingEDSInteractionMsg({
            customer: embedDashboard.customer ?? undefined,
            team: embedDashboard.team ?? undefined,
            dashboard: embedDashboard.dashboard.data,
            interaction,
          }),
          message_type: PingTypes.PING_END_USER_ACTION,
        },
      }) as unknown as Action,
    );
    trackEDSInteractionEvent({
      customer: embedDashboard.customer ?? undefined,
      team: embedDashboard.team ?? undefined,
      dashboard: embedDashboard.dashboard.data,
      interaction,
    });
  }
};

export const updateEditableSectionLayoutThunk =
  (newLayout: CustomerEditableSectionLayout[]): Thunk =>
  (dispatch, getState) => {
    const layout = getEditableSectionLayout(getState());
    if (!layout) return;

    // Only update position and size
    const updatedLayout = layout.map((item) => ({
      ...item,
      ...pick(
        newLayout.find((elem) => elem.i === item.i),
        ['x', 'y', 'w', 'h', 'minH'],
      ),
    }));
    if (isEqual(layout, updatedLayout)) return;
    dispatch(setEditableSectionLayoutThunk(updatedLayout));
  };

export const setEditableSectionLayoutThunk =
  (layout: CustomerEditableSectionLayout[]): Thunk =>
  (dispatch, getState) => {
    const {
      dashboardInteractions: { interactionsInfo },
      dashboardLayout: { requestInfo },
    } = getState();

    if (requestInfo.type === 'embedded') {
      dispatch(updateEmbedEditableSectionLayout(layout));
    } else {
      const isEditing = interactionsInfo.isEditing;
      dispatch(setEditableSectionLayout({ layout, isPreview: !isEditing }));
    }
  };

// TODO: Should we send edit version number to not allow overwrites?
type SaveEditableSectionParams = {
  config: CustomerEditableSectionConfig;
  dashboard_embed_id: string;
};

const allowedSaveEditableSectionEmbedTypes: Set<EmbeddedDashboardType> = new Set([
  'embedded',
  'iframe',
  'shared',
  'portal',
]);

export const saveEditableSectionThunk = (): Thunk => (dispatch, getState) => {
  const {
    embedDashboard: { hasEditableSectionChanges },
    dashboardLayout: { requestInfo },
  } = getState();

  if (
    hasEditableSectionChanges &&
    requestInfo.type === 'embedded' &&
    allowedSaveEditableSectionEmbedTypes.has(requestInfo.embedType)
  ) {
    dispatch(saveEditableSection());
  }

  dispatch(setIsEditingEditableSection(false));
};

// Helper function for saveEditableSectionThunk, don't use directly
export const saveEditableSection = createAsyncThunk<
  { customer_editable_section: CustomerEditableSection },
  void,
  { state: EmbedReduxState }
>(ACTION.SAVE_CUSTOMER_EDITABLE_SECTION, async (_, { getState }) => {
  const {
    embedDashboard: { currentEditableSectionLayout },
    dashboardLayout: { requestInfo },
  } = getState();

  let requestConfig: AxiosRequestConfig | null = null;
  if (currentEditableSectionLayout && requestInfo.type === 'embedded') {
    const postData: SaveEditableSectionParams = {
      config: { layout: currentEditableSectionLayout },
      dashboard_embed_id: requestInfo.resourceEmbedId,
    };

    requestConfig = createApiRequestConfig(
      'embed/save_editable_section/',
      'POST',
      postData,
      requestInfo.customerToken,
      requestInfo.jwt,
    );
  }

  return makeThunkRequest(requestConfig, 'Error saving editable section');
});

export const discardEditableSectionLayoutChangesThunk = (): Thunk => (dispatch, getState) => {
  const {
    embedDashboard: { hasEditableSectionChanges },
    dashboardLayout: { requestInfo },
  } = getState();

  if (hasEditableSectionChanges && requestInfo.type === 'embedded') {
    dispatch(discardEditableSectionLayoutChanges());
    dispatch(fetchDashboardDataThunk({ shouldOverrideCache: true }));
  }
};
