import Color from 'color';
import Highcharts, { ChartScrollablePlotAreaOptions, PointOptionsObject } from 'highcharts';
import { PureComponent, RefObject, createRef } from 'react';
import ReactDOMServer from 'react-dom/server';
import { ConnectedProps, connect } from 'react-redux';

import { DatasetDataObject } from 'actions/datasetActions';
import { vars } from 'components/ds/vars.css';
import {
  COMBO_CHART_DATA_FORMATS,
  ChartShapeBorderDefaultColor,
  chartShapeBorderDefaultWidth,
  Timezones,
} from 'constants/dashboardConstants';
import { DATE_TYPES, VIZ_OPS_WITH_CATEGORY_SELECT_DRILLDOWN } from 'constants/dataConstants';
import {
  ColumnColorTracker,
  GROUPED_STACKED_OPERATION_TYPES,
  LineElasticity,
  OPERATION_TYPES,
  SortAxis,
  SortOption,
  SortOrder,
  V2TwoDimensionChartInstructions,
  VisualizeOperationGeneralFormatOptions,
} from 'constants/types';
import { formatTwoDimensionalData } from 'dataFormatters/twoDimensionalDataFormatter';
import { GlobalStyleConfig } from 'globalStyles/types';
import { NeedsConfigurationPanel } from 'pages/dashboardPage/needsConfigurationPanel';
import { ChartMenuInfo } from 'reducers/dashboardLayoutReducer';
import { AllStates } from 'reducers/rootReducer';
import { DashboardVariable, DashboardVariableMap, DrilldownVariable } from 'types/dashboardTypes';
import { FilterOperation } from 'types/dataPanelTemplate';
import { DatasetSchema } from 'types/datasets';
import { PivotAgg } from 'types/dateRangeTypes';
import {
  getColorFromPaletteTracker,
  getColorTrackerCategoryName,
} from 'utils/colorCategorySyncUtils';
import { getColorColumn, isSelectedColorDateType } from 'utils/colorColUtils';
import { getColDisplayText } from 'utils/dataPanelColUtils';
import { insertZeroesForMissingDateData } from 'utils/dateUtils';
import { getDrilldownChartVariable, isColorColUsed, setCategorySelect } from 'utils/drilldownUtils';
import { getAggregationOrFormula } from 'utils/fido/fidoInstructionShimUtils';
import { format } from 'utils/localizationUtils';
import { sortByValues } from 'utils/sortUtils';
import { flatten, orderBy, partition, sortBy } from 'utils/standard';
import { getTimezoneAwareUnix } from 'utils/timezoneUtils';
import { replaceVariablesInString } from 'utils/variableUtils';

import { SeriesOptions } from './constants/types';
import { DrilldownChart } from './shared/drilldownChart';
import {
  areRequiredVariablesSetTwoDimViz,
  formatLabel,
  formatLegend,
  formatValue,
  getAxisNumericalValue,
  getColorColNames,
  getColorPalette,
  getColorZones,
  getLabelStyle,
  isTwoDimVizInstructionsReadyToDisplay,
  shouldProcessColAsDate,
  xAxisFormat,
} from './utils';
import {
  filterBarChartDataToCategories,
  truncateCategoriesToMaxCategories,
} from './utils/filterDataUtils';
import {
  createYAxisBaseTooltip,
  getMultiYAxisInstructions,
  getSingleYAxisInstructions,
  getValueFormat,
  getYAxisChartIndex,
  getYAxisFormatById,
} from './utils/multiYAxisUtils';
import { sharedTitleConfig, sharedTooltipConfigs } from './utils/sharedConfigs';

const ONE_SECOND = 1000;
const ONE_HOUR = ONE_SECOND * 60 * 60;
const ONE_DAY = ONE_HOUR * 24;
const ONE_WEEK = ONE_DAY * 7;
const ONE_MONTH = ONE_WEEK * 4;
const ONE_QUARTER = ONE_MONTH * 3;
const ONE_YEAR = ONE_MONTH * 12;

type Props = {
  backgroundColor: string;
  loading?: boolean;
  previewData: Record<string, string | number>[];
  instructions?: V2TwoDimensionChartInstructions;
  dataPanelTemplateId: string;
  variables: DashboardVariableMap;
  schema: DatasetSchema;
  selectedColorColName?: string;
  normalize?: boolean;
  grouped?: boolean;
  horizontal?: boolean;
  generalOptions?: VisualizeOperationGeneralFormatOptions;
  globalStyleConfig: GlobalStyleConfig;
  isComboChart?: boolean;
  canUseMultiYAxis?: boolean;
  setVariable?: (value: DashboardVariable) => void;
  colorTracker?: ColumnColorTracker;
  dataPanelProvidedId: string;
  datasetNamesToId: Record<string, string>;
  datasetData: DatasetDataObject;
  operationType: OPERATION_TYPES;
  setChartMenu: (info: ChartMenuInfo | null) => void;
  filterOperation?: FilterOperation;
} & PropsFromRedux;

class BarChart extends PureComponent<Props> {
  drilldownRef: RefObject<HTMLDivElement>;
  constructor(props: Props) {
    super(props);
    this.drilldownRef = createRef<HTMLDivElement>();
  }
  render() {
    const {
      generalOptions,
      instructions,
      loading,
      operationType,
      variables,
      dataPanelTemplateId,
      selectedColorColName,
      dataPanelProvidedId,
      setVariable,
    } = this.props;
    const requiredVarNotsSet = !areRequiredVariablesSetTwoDimViz(variables, instructions);
    const instructionsReadyToDisplay = isTwoDimVizInstructionsReadyToDisplay(
      instructions,
      operationType,
    );

    if (loading || !instructionsReadyToDisplay || requiredVarNotsSet) {
      return (
        <NeedsConfigurationPanel
          fullHeight
          instructionsNeedConfiguration={!instructionsReadyToDisplay}
          loading={loading}
          requiredVarsNotSet={requiredVarNotsSet}
        />
      );
    }

    const drilldownVar = this.getDrilldownVariable();

    const spec = this._spec();

    return (
      <DrilldownChart
        chartOptions={spec}
        customMenuOptions={
          generalOptions?.customMenu?.enabled ? generalOptions.customMenu.menuOptions : undefined
        }
        dataPanelId={dataPanelTemplateId}
        drilldownRef={this.drilldownRef}
        drilldownVar={drilldownVar}
        instructions={instructions}
        selectedColorColName={selectedColorColName}
        setCategorySelect={
          instructions?.drilldown?.categorySelectEnabled &&
          VIZ_OPS_WITH_CATEGORY_SELECT_DRILLDOWN.has(operationType)
            ? (category: string, colorColumn?: string) =>
                setCategorySelect(
                  dataPanelProvidedId,
                  variables,
                  category,
                  colorColumn,
                  selectedColorColName,
                  setVariable,
                  instructions,
                )
            : undefined
        }
        underlyingDataEnabled={this.getUnderlyingDrilldownEnabled()}
      />
    );
  }

  _spec = (): Highcharts.Options | undefined => {
    const {
      previewData,
      schema,
      instructions,
      normalize,
      backgroundColor,
      generalOptions,
      globalStyleConfig,
      canUseMultiYAxis,
      variables,
      setChartMenu,
      dataPanelTemplateId,
      datasetData,
      datasetNamesToId,
      horizontal,
      operationType,
      selectedColorColName,
    } = this.props;
    if (schema?.length === 0 || !previewData) return;

    // this is a short term fix en lieu of this bug being fixed by vega:
    // Ref: TU/447fn2df
    this.processDatesData();
    const stacking = this.getStacking();
    const { valueFormatId, decimalPlaces } = getValueFormat(instructions?.yAxisFormats?.[0]);
    const isDate = DATE_TYPES.has(instructions?.categoryColumn?.column.type || '');

    const isSortingEnabled = this.getSortingEnabled();
    const axisCategories = this.getAxisCategories(isSortingEnabled);
    const transformedData = this.transformData(axisCategories, isSortingEnabled);

    const { data, categories: transformedXAxisCategories } = filterBarChartDataToCategories(
      transformedData,
      axisCategories,
      instructions?.xAxisFormat,
      isDate,
    );

    const underlyingDataEnabled = this.getUnderlyingDrilldownEnabled();
    const categorySelectEnabled =
      instructions?.drilldown?.categorySelectEnabled &&
      VIZ_OPS_WITH_CATEGORY_SELECT_DRILLDOWN.has(operationType);

    const tickInterval = this.getTickInterval();
    const hasClickEvents =
      underlyingDataEnabled || categorySelectEnabled || generalOptions?.customMenu?.enabled;

    const categoryDisplayNameMap: Record<string, string> = {};
    if (instructions?.xAxisFormat?.sortAxis === SortAxis.MANUAL) {
      const manualSortOrder = instructions?.xAxisFormat?.sortManualCategoriesOrder || [];
      manualSortOrder.forEach((manualCategory) => {
        categoryDisplayNameMap[manualCategory.category] = manualCategory.displayName;
      });
    }

    const xAxisType = this.getXAxisType(transformedXAxisCategories);
    const drilldownRef = this.drilldownRef;

    return {
      chart: {
        type: this.getChartType(),
        backgroundColor,
        scrollablePlotArea: this.getScrollablePlotAreaInstructions(
          transformedXAxisCategories,
          data,
        ),
      },
      series: data,
      title: sharedTitleConfig,
      colors: getColorPalette(globalStyleConfig, instructions?.colorFormat),
      boost: {
        seriesThreshold: 500,
      },
      plotOptions: {
        column: { minPointLength: 1 },
        series: {
          zones: getColorZones(instructions?.colorFormat, variables, datasetNamesToId, datasetData),
          animation: false,
          borderColor:
            instructions?.chartSpecificFormat?.barChart?.borderColor ??
            ChartShapeBorderDefaultColor,
          borderWidth:
            instructions?.chartSpecificFormat?.barChart?.borderWidth ??
            chartShapeBorderDefaultWidth,
          cursor: hasClickEvents ? 'pointer' : undefined,
          point: {
            events: {
              click: function (e) {
                if (!hasClickEvents || !drilldownRef.current) return;

                const subCategory: string | undefined = isColorColUsed(
                  selectedColorColName,
                  instructions,
                )
                  ? //@ts-ignore
                    e.point.series.userOptions.rawColorData
                  : undefined;

                const scrollContainer = drilldownRef.current.querySelector(
                  'div.highcharts-scrolling',
                );
                const scrollOffsetX = scrollContainer?.scrollLeft ?? 0;
                const scrollOffsetY = scrollContainer?.scrollTop ?? 0;

                const menuInfo: ChartMenuInfo = {
                  chartId: dataPanelTemplateId,
                  chartX: e.chartX - scrollOffsetX,
                  chartY: e.chartY - scrollOffsetY,
                  category: e.point.category,
                  subCategory,
                };

                setChartMenu(menuInfo);
              },
            },
          },
          stacking,
          states: { hover: { borderColor: '#000000' } },
          dataLabels: {
            enabled: instructions?.xAxisFormat?.showBarValues,
            formatter: function () {
              const text =
                stacking === 'percent'
                  ? `${format('0.2f')(this.percentage || 0)}%`
                  : formatValue({
                      value: this.y || 0,
                      decimalPlaces,
                      formatId: valueFormatId,
                      hasCommas: true,
                    });
              if (!this.point.color) {
                return text;
              }
              return (
                `<span style="color: ${
                  new Color(this.point.color).isDark() ? vars.colors.white : vars.colors.black
                }">` +
                text +
                '</span>'
              );
            },

            style: {
              textOutline: 'none',
              ...getLabelStyle(globalStyleConfig, 'primary'),
            },
          },
          //@ts-ignore
          borderRadius: instructions?.xAxisFormat?.barCornerRadius,
          pointRange: tickInterval,
        },
      },
      yAxis: canUseMultiYAxis
        ? getMultiYAxisInstructions(
            globalStyleConfig,
            instructions,
            variables,
            datasetNamesToId,
            datasetData,
          )
        : getSingleYAxisInstructions(
            globalStyleConfig,
            instructions,
            variables,
            datasetNamesToId,
            datasetData,
            this.props.normalize,
          ),
      xAxis: {
        ...xAxisFormat(globalStyleConfig, instructions?.xAxisFormat),
        type: xAxisType,
        left:
          // Edge case that needs left padding
          !horizontal &&
          instructions?.xAxisFormat?.enableScroll &&
          !instructions.yAxisFormats?.[0]?.oppositeAligned &&
          instructions.yAxisFormats?.[0]?.showTitle &&
          instructions.yAxisFormats?.[0]?.title?.trim()
            ? 60
            : undefined,
        categories: !isDate ? transformedXAxisCategories : undefined,
        labels: {
          overflow: 'allow', // Let labels overflow outside just the chart area (related to left property above)
          formatter: function () {
            return formatLabel(
              categoryDisplayNameMap[this.value] ?? this.value,
              instructions?.categoryColumn?.column.type,
              instructions?.categoryColumn?.bucket?.id,
              instructions?.categoryColumn?.bucketSize,
              instructions?.xAxisFormat?.dateFormat,
              instructions?.xAxisFormat?.stringFormat,
            );
          },
          style: getLabelStyle(globalStyleConfig, 'secondary'),
          enabled: !instructions?.xAxisFormat?.hideAxisLabels,
          rotation: instructions?.xAxisFormat?.rotationAngle,
        },
        // force highcharts to put the ticks on the quarters
        units:
          instructions?.categoryColumn?.bucket?.id === PivotAgg.DATE_QUARTER
            ? [['month', [3, 6, 12]]]
            : undefined,
        minTickInterval: tickInterval,
        visible: !instructions?.xAxisFormat?.hideAxisLine,
      },
      legend: formatLegend(globalStyleConfig, instructions?.legendFormat),
      tooltip: {
        ...sharedTooltipConfigs,
        formatter: function () {
          return ReactDOMServer.renderToStaticMarkup(
            createYAxisBaseTooltip({
              tooltipFormatter: this,
              globalStyleConfig,
              instructions,
              includePercent: normalize || instructions?.tooltipFormat?.showPct,
              showSelectedOnly: instructions?.tooltipFormat?.showSelectedOnly,
            }),
          );
        },
      },
    };
  };

  getChartType = () => (this.props.horizontal ? 'bar' : 'column');

  getComboChartSeriesInfo = (
    dataFormat: COMBO_CHART_DATA_FORMATS | undefined,
  ): Pick<SeriesOptions, 'type'> & Partial<SeriesOptions> => {
    const isStraight =
      this.props.instructions?.chartSpecificFormat?.lineChart?.elasticity ===
      LineElasticity.STRAIGHT;

    if (dataFormat === COMBO_CHART_DATA_FORMATS.DOT) {
      return { type: 'line', lineWidth: 0, marker: { enabled: true } };
    } else if (dataFormat === COMBO_CHART_DATA_FORMATS.AREA) {
      // @ts-ignore
      return {
        type: isStraight ? 'area' : 'areaspline',
        ...this.getLineChartConfig(true),
      };
    } else if (dataFormat === COMBO_CHART_DATA_FORMATS.BAR) {
      return { type: 'column' };
    } else {
      // @ts-ignore
      return { type: isStraight ? 'line' : 'spline', ...this.getLineChartConfig(false) };
    }
  };

  getLineChartConfig = (
    isAreaChart: boolean,
  ): Highcharts.PlotLineOptions & Highcharts.PlotAreaOptions & Highcharts.PlotSplineOptions => {
    const { instructions, globalStyleConfig } = this.props;

    const lineChart = instructions?.chartSpecificFormat?.lineChart ?? {};

    const showMarkers = !lineChart.hideMarkers;
    const dashStyle = isAreaChart ? undefined : lineChart.lineType;
    const lineWidth = lineChart?.lineWidth || globalStyleConfig.container.lineWidth.default;

    return { marker: { enabled: showMarkers }, dashStyle, lineWidth };
  };

  isGroupedStacked = () => GROUPED_STACKED_OPERATION_TYPES.has(this.props.operationType);

  // for grouped stacked bar charts, the grouping column is the first in the schema
  getGroupingColName = () => this.props.schema[0].name;

  // Set a custom tick interval + point range for dates based on the bucket id
  // Highcharts isn't good at automatically calculating these
  getTickInterval = () => {
    const { instructions } = this.props;
    if (!DATE_TYPES.has(instructions?.categoryColumn?.column.type || '')) return;

    const bucketId = instructions?.categoryColumn?.bucket?.id;

    if (bucketId === PivotAgg.DATE_HOUR) {
      return ONE_HOUR;
    } else if (bucketId === PivotAgg.DATE_DAY) {
      return ONE_DAY;
    } else if (bucketId === PivotAgg.DATE_WEEK) {
      return ONE_WEEK;
    } else if (bucketId === PivotAgg.DATE_MONTH) {
      return ONE_MONTH;
    } else if (bucketId === PivotAgg.DATE_QUARTER) {
      return ONE_QUARTER;
    } else if (bucketId === PivotAgg.DATE_YEAR) {
      return ONE_YEAR;
    } else {
      return;
    }
  };

  getXAxisColName = () => {
    const { schema } = this.props;

    const xAxisIndex = this.isGroupedStacked() ? 1 : 0;
    return schema[xAxisIndex].name;
  };

  getXAxisType = (categories?: string[]) => {
    const { instructions } = this.props;
    if (DATE_TYPES.has(instructions?.categoryColumn?.column.type || '')) return 'datetime';
    if (categories?.length) return 'category';
  };

  getSortingEnabled = () => {
    const { instructions } = this.props;
    return (
      instructions?.xAxisFormat?.sortAxis !== SortAxis.NONE &&
      (instructions?.xAxisFormat?.sortOption === SortOption.DESC ||
        instructions?.xAxisFormat?.sortOption === SortOption.ASC ||
        instructions?.xAxisFormat?.sortAxis === SortAxis.MANUAL)
    );
  };

  getAxisCategories = (isSortingEnabled: boolean) => {
    const { instructions, previewData, schema, operationType } = this.props;

    const { xAxisColName, aggColNames } = this.getAggColNames(schema);
    const sortColNames = this.getSortColumnNames();
    const xAxisFormat = instructions?.xAxisFormat;

    if (isSortingEnabled) {
      const valByCategory: Record<string, number> = {};
      const addValueToCategory = (value: string | number, category: string | number) => {
        const parsed = getAxisNumericalValue(value);
        if (isNaN(parsed)) return;
        valByCategory[category] += parsed;
      };

      previewData.forEach((row) => {
        const category = row[xAxisColName];
        if (!valByCategory[category]) {
          valByCategory[category] = 0;
        }
        if (this.isColumnSortActive() && sortColNames) {
          sortColNames.map((sortCol) => addValueToCategory(row[sortCol], category));
        } else if (isColorColUsed(this.props.selectedColorColName, instructions)) {
          const { aggColName } = getColorColNames(schema, operationType);

          addValueToCategory(row[aggColName], category);
        } else {
          aggColNames.map((aggCol) => addValueToCategory(row[aggCol], category));
        }
      });

      return truncateCategoriesToMaxCategories(
        this.sortCategories(valByCategory),
        xAxisFormat,
        true,
        this.shouldReverseSort(),
      );
    } else {
      const categories = new Set(previewData.map((row) => String(row[xAxisColName])));
      return truncateCategoriesToMaxCategories(Array.from(categories), xAxisFormat);
    }
  };

  // column and bar charts sort in opposite directions, with column sorting left to right
  // and column sorting bottom to top. Visually, though we want column to sort top to bottom,
  // so we have to reverse the sorting here to make the behavior the same
  shouldReverseSort = () => this.getChartType() === 'bar';

  sortCategories = (valByCategory: Record<string, number>) => {
    const { instructions } = this.props;
    const sortAxis = instructions?.xAxisFormat?.sortAxis;
    const categoryList = Object.keys(valByCategory);

    if (sortAxis === SortAxis.MANUAL) {
      return orderBy(categoryList, (category) => {
        const index = (instructions?.xAxisFormat?.sortManualCategoriesOrder || []).findIndex(
          (order) => order.category === category,
        );
        return index === -1 ? categoryList.length : index;
      });
    }

    const isAscendingOption = instructions?.xAxisFormat?.sortOption === SortOption.ASC;
    const isAscending = this.shouldReverseSort() ? !isAscendingOption : isAscendingOption;
    const sortDirection = isAscending ? SortOrder.ASC : SortOrder.DESC;
    const useCatAxis = instructions?.xAxisFormat?.sortAxis === SortAxis.CAT_AXIS;
    return sortByValues(
      categoryList,
      (category) => (useCatAxis ? category : valByCategory[category]),
      sortDirection,
    );
  };

  getStacking = () => {
    if (this.props.normalize) return 'percent';
    if (this.props.grouped) return;
    return 'normal';
  };

  processDatesData = () => {
    const {
      instructions,
      previewData,
      schema,
      operationType,
      filterOperation,
      timezone,
      enableFillMissingDates,
    } = this.props;
    const isCategoryColDate = shouldProcessColAsDate(instructions?.categoryColumn);
    const isColorColDate = isSelectedColorDateType(instructions || {});
    let isGroupingColDate = false;

    if (this.isGroupedStacked()) {
      isGroupingColDate = shouldProcessColAsDate(instructions?.groupingColumn);
    }

    if (
      !previewData ||
      (!isCategoryColDate && !isColorColDate && !isGroupingColDate) ||
      !schema?.length ||
      !instructions?.categoryColumn?.column.type
    )
      return;

    const { xAxisColName, colorColName } = getColorColNames(schema, operationType);

    previewData.forEach((row) => {
      if (isCategoryColDate) {
        // checking if the data is a number because if it is, that means that it is already
        // in unix time and we have already processed it. If we pass in a unix timestamp to
        // getTimezoneAwareUnix, it returns undefined
        if (typeof row[xAxisColName] !== 'number') {
          row[xAxisColName] = getTimezoneAwareUnix(row[xAxisColName] as string);
        }
      }
      if (isColorColDate) {
        if (typeof row[colorColName] !== 'number') {
          row[colorColName] = getTimezoneAwareUnix(row[colorColName] as string);
        }
      }
      if (isGroupingColDate) {
        const groupingColName = this.getGroupingColName();
        if (typeof row[groupingColName] !== 'number') {
          row[groupingColName] = getTimezoneAwareUnix(row[groupingColName] as string);
        }
      }
    });

    if (
      instructions?.chartSpecificFormat?.timeSeriesDataFormat?.zeroMissingDates &&
      enableFillMissingDates
    ) {
      const colNames = {
        xAxisColName,
        yAxisColNames: this.getAggColNames(schema).aggColNames,
        colorColName: isColorColUsed(colorColName, instructions) ? colorColName : xAxisColName,
      };
      insertZeroesForMissingDateData(
        previewData,
        instructions.categoryColumn,
        colNames,
        filterOperation,
        timezone,
      );
    }
  };

  bringLinesToFront = (seriesList: SeriesOptions[]) => {
    return flatten(
      partition(seriesList, (series) => series.type !== 'line' && series.type !== 'spline'),
    );
  };

  transformData = (
    axisCategories: string[] | undefined,
    isSortingEnabled: boolean,
  ): SeriesOptions[] => {
    // This is for when there are multiple bars/lines selected
    const { instructions, schema, isComboChart } = this.props;
    const isDate = DATE_TYPES.has(instructions?.categoryColumn?.column.type || '');

    if (
      !instructions?.aggColumns ||
      instructions.aggColumns.length === 0 ||
      !schema ||
      schema.length === 0
    )
      return [];

    let seriesList: SeriesOptions[];
    if (isColorColUsed(this.props.selectedColorColName, instructions)) {
      seriesList = this.transformColorData(schema, axisCategories);
    } else {
      seriesList = this.transformAggColsData(schema);
    }

    if (isComboChart) seriesList = this.bringLinesToFront(seriesList);

    if (!isDate && isSortingEnabled) {
      if (axisCategories?.length) {
        seriesList.forEach((series) => {
          series.data = sortBy(series.data as PointOptionsObject[], (row) =>
            axisCategories.indexOf(row.name as string),
          );
        });
      }
    }

    // Ensure stable legend sorting, not currently configurable. That being said,
    // combo charts have a specific sorting and we dont' want to change that here
    return isComboChart ? seriesList : sortBy(seriesList, (series) => series.name);
  };

  transformColorData = (
    schema: DatasetSchema,
    axisCategories: string[] | undefined,
  ): SeriesOptions[] => {
    const { colorTracker, instructions, previewData, selectedColorColName, operationType } =
      this.props;
    const { xAxisColName, colorColName, aggColName } = getColorColNames(schema, operationType);
    const isDate = DATE_TYPES.has(instructions?.categoryColumn?.column.type || '');
    const series: Record<string, SeriesOptions> = {};

    const selectedColorCol = getColorColumn(instructions, selectedColorColName);
    const isGroupedStacked = this.isGroupedStacked();
    const grouping = this.getGroupingColName();
    const drilldownVar = this.getDrilldownVariable();
    const drilldownColor =
      drilldownVar?.color !== undefined ? String(drilldownVar.color) : undefined;
    const drilldownCategory =
      drilldownVar?.category !== undefined ? String(drilldownVar.category) : undefined;

    previewData.forEach((row) => {
      const value = row[xAxisColName];
      if (isDate && value === undefined) return;
      const colorValue = row[colorColName];
      const colorCategory = formatLabel(
        colorValue,
        selectedColorCol?.column.type,
        selectedColorCol?.bucket?.id,
      );

      // if isGroupedStacked is false, we don't use groupingCategory
      // but it does have the chance to error on formatLabel
      const groupingCategory = isGroupedStacked
        ? formatLabel(
            row[grouping],
            instructions?.groupingColumn?.column.type,
            instructions?.groupingColumn?.bucket?.id,
          )
        : '';
      const name = isGroupedStacked ? colorCategory + ':' + groupingCategory : colorCategory;
      const stringValue = String(value);
      const entry = {
        name: stringValue,
        y: getAxisNumericalValue(row[aggColName]),
        x: isDate ? parseInt(stringValue) : axisCategories?.indexOf(stringValue),
        selected: drilldownVar
          ? drilldownCategory === stringValue && drilldownColor === String(colorValue)
          : false,
      };

      if (series[name] && (isGroupedStacked ? series[name]['stack'] === groupingCategory : true)) {
        series[name].data.push(entry);
      } else {
        const category = getColorTrackerCategoryName(xAxisColName, colorColName);
        series[name] = {
          type: this.getChartType(),
          name: name,
          rawColorData: colorValue,
          data: [entry],
          color: getColorFromPaletteTracker({
            columnName: category,
            valueName: String(colorValue),
            colorTracker,
          }),
          stack: isGroupedStacked ? groupingCategory : undefined,
        };
      }
    });

    const seriesData = Object.values(series);

    if (isDate && instructions?.chartSpecificFormat?.timeSeriesDataFormat?.hideLatestPeriodData) {
      seriesData.forEach((series) => series.data.pop());
      return seriesData;
    }

    return seriesData;
  };

  getDrilldownVariable = (): DrilldownVariable | undefined => {
    const { variables, instructions, dataPanelProvidedId } = this.props;

    return getDrilldownChartVariable(variables, instructions, dataPanelProvidedId);
  };

  transformAggColsData = (schema: DatasetSchema): SeriesOptions[] => {
    const {
      previewData,
      instructions,
      isComboChart,
      canUseMultiYAxis,
      variables,
      datasetNamesToId,
      datasetData,
      colorTracker,
    } = this.props;
    const { xAxisColName, aggColNames, groupingColName } = this.getAggColNames(schema);
    const isGroupedStacked = this.isGroupedStacked();
    const aggCols = instructions?.aggColumns || [];
    const isDate = DATE_TYPES.has(instructions?.categoryColumn?.column.type || '');

    const series: Record<string, SeriesOptions> = {};
    const selectedCategory = this.getDrilldownVariable()?.category;

    const stacking = this.getStacking();

    formatTwoDimensionalData(previewData, instructions).forEach((row) => {
      const groupingCategory = formatLabel(
        row[groupingColName],
        instructions?.groupingColumn?.column.type,
        instructions?.groupingColumn?.bucket?.id,
      );

      aggColNames.forEach((colName, index) => {
        if (isDate && row[xAxisColName] === undefined) return;
        const aggCol = aggCols[index];
        if (!aggCol) return;

        const y = getAxisNumericalValue(row[colName]);

        if (isNaN(y)) return;

        const name = isGroupedStacked ? colName + ':' + groupingCategory : colName;
        const x = (
          isDate ? getAxisNumericalValue(row[xAxisColName]) : row[xAxisColName]
        )?.toString();

        const entry = {
          x: isDate ? parseInt(x) : undefined,
          y,
          selected: selectedCategory === x,
          name: x,
        };

        if (series[name]) {
          series[name].data.push(entry);
        } else {
          const comboChartInfo = isComboChart
            ? this.getComboChartSeriesInfo(aggCol.column.dataFormat)
            : undefined;

          const seriesName = isGroupedStacked
            ? name
            : aggCol.column.friendly_name
            ? replaceVariablesInString(
                aggCol.column.friendly_name,
                variables,
                datasetNamesToId,
                datasetData,
              )
            : getColDisplayText(aggCol) || colName;

          series[name] = {
            type: comboChartInfo ? comboChartInfo.type : this.getChartType(),
            name: seriesName,
            data: [entry],
            dataLabels: {
              formatter: function () {
                if (stacking === 'percent') return `${format('0.2f')(this.percentage || 0)}%`;
                const { valueFormatId, decimalPlaces } = getValueFormat(
                  getYAxisFormatById(instructions?.yAxisFormats, aggCol.yAxisFormatId) ||
                    instructions?.yAxisFormats?.[0], // fallback to the globally set yAxisFormat
                );
                return formatValue({
                  value: this.y || 0,
                  decimalPlaces,
                  formatId: valueFormatId,
                  hasCommas: true,
                });
              },
            },
            yAxis: getYAxisChartIndex(aggCol.yAxisFormatId, canUseMultiYAxis, instructions),
            color: aggCol.column.name
              ? getColorFromPaletteTracker({
                  columnName: colName,
                  valueName: aggCol.column.name,
                  colorTracker,
                })
              : undefined,
            stack: isGroupedStacked ? groupingCategory : undefined,
            ...comboChartInfo,
          };
        }
      });
    });

    return Object.values(series);
  };

  isColumnSortActive = () => {
    const { instructions } = this.props;
    const xAxisFormat = instructions?.xAxisFormat;
    return (
      xAxisFormat?.sortAxis === SortAxis.COLUMN && xAxisFormat.sortOption && xAxisFormat.sortColumns
    );
  };

  getSortColumnNames = () => {
    const { instructions } = this.props;
    const sortColumns = instructions?.xAxisFormat?.sortColumns || [];
    const sortColumnNames: string[] = [];
    sortColumns.forEach((sortColumn) => {
      const colName = getAggregationOrFormula(sortColumn)?.targetPropertyId;
      if (colName) sortColumnNames.push(colName);
    });
    return sortColumnNames;
  };

  getAggColNames = (schema: DatasetSchema) => {
    let xAxisColName, aggColNames, groupingColName;

    if (this.isGroupedStacked()) {
      xAxisColName = schema[1].name;
      aggColNames = schema.map((col) => col.name).slice(2);
      groupingColName = this.getGroupingColName();
    } else {
      xAxisColName = schema[0].name;
      aggColNames = schema.map((col) => col.name).slice(1);
      groupingColName = '';
    }

    return {
      xAxisColName,
      aggColNames,
      groupingColName,
    };
  };

  getUnderlyingDrilldownEnabled = () => {
    return !!this.props.generalOptions?.enableRawDataDrilldown;
  };

  getScrollablePlotAreaInstructions = (
    categories: string[] | undefined,
    data: SeriesOptions[],
  ): ChartScrollablePlotAreaOptions | undefined => {
    const { instructions, horizontal } = this.props;
    if (!instructions?.xAxisFormat?.enableScroll) return;

    let numCategories = categories?.length;
    if (numCategories === undefined && data.length === 1) numCategories = data[0].data.length;

    const totalScrollableArea = numCategories ? numCategories * 40 : undefined;
    if (!totalScrollableArea) return;

    return {
      minWidth: !horizontal ? totalScrollableArea : undefined,
      minHeight: horizontal ? totalScrollableArea : undefined,
      opacity: 1,
    };
  };
}

const mapStateToProps = (state: AllStates) => {
  return {
    timezone:
      'dashboardLayout' in state ? state.dashboardLayout.requestInfo.timezone : Timezones.UTC,
    enableFillMissingDates:
      'dashboardLayout' in state ? state.dashboardLayout.enableFillMissingDates : false,
  };
};

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(BarChart);
