import { QueryExecutionResponse } from '@explo-tech/fido-api';
import { AnyAction, ThunkAction, createAsyncThunk } from '@reduxjs/toolkit';
import { DateTime } from 'luxon';

import { createApiRequestConfig } from 'actions/actionUtils';
import {
  FetchDashboardDatasetPreviewBody,
  FetchEditorDatasetPreviewBody,
} from 'actions/datasetActions';
import { JobDefinition } from 'actions/jobQueueActions';
import { FetchDashboardDatasetPreviewData, QueryDebuggingInformation } from 'actions/responseTypes';
import { ACTION } from 'actions/types';
import { DEFAULT_ROWS_PER_PAGE } from 'components/ds/DataGrid/paginator';
import { DashboardLayoutRequestInfo } from 'reducers/dashboardLayoutReducer';
import { DashboardStates, ReduxState } from 'reducers/rootReducer';
import { EVENTS, trackEvent } from 'telemetry/exploAnalytics';
import { ResourceDataset } from 'types/exploResource';
import { getViewFromDatasetId, useFidoForRequest } from 'utils/fido/fidoUtils';
import { makeThunkRequest, createApiRequestConfigWithRequestInfo } from 'utils/thunkUtils';

import { enqueueDashboardJobsThunk } from '../dashboardLayoutThunks';
import { DashboardLayoutThunk } from '../dashboardLayoutThunks/types';
import { shouldUseFidoForDefaultDataSource } from '../syncSchemaFlowThunks';

import { fetchFidoViewData, fetchFidoViewPreview } from './fetchFidoDataThunks';
import { getDashboardConfig } from './utils';

export const fetchSavedDatasetThunk =
  (datasetId: string): ThunkAction<void, ReduxState, unknown, AnyAction> =>
  (dispatch, getState) => {
    const state = getState();
    const datasetData = state.dashboardData.datasetData;

    // If dataset has been saved we should re run for dashboard only if it was already fetched
    if (!(datasetId in datasetData)) return;

    const config = getDashboardConfig(state);
    if (config?.datasets) dispatch(fetchDatasetsThunk([datasetId], config.datasets));
  };

export const fetchDatasetsThunk =
  (datasetIds: string[], datasets: Record<string, ResourceDataset>): DashboardLayoutThunk =>
  (dispatch, getState) => {
    if (datasetIds.length === 0) return;
    const state = getState();
    const requestInfo = state.dashboardLayout.requestInfo;

    const jobs: JobDefinition[] = [];

    datasetIds.forEach((datasetId) => {
      const postData: FetchDashboardDatasetPreviewBody = {
        variables: state.dashboardData.variables ?? {},
        query_limit: requestInfo.datasetMaxRows ?? 5000,
        ...attachDatasetForDatasetPreview(requestInfo, datasetId, datasets),
      };

      if (useFidoForRequest(state.dashboardLayout.requestInfo, state.fido, datasets[datasetId])) {
        dispatch(fetchFidoViewData({ body: postData, dataset: datasets[datasetId] }));
      } else if (requestInfo.useJobQueue) {
        jobs.push({
          job_type: ACTION.FETCH_DASHBOARD_DATASET_PREVIEW,
          job_args: postData,
        });
      } else {
        dispatch(fetchDatasetPreviewThunk(postData));
      }
    });
    if (jobs.length > 0) dispatch(enqueueDashboardJobsThunk({ jobs }));
  };

export const fetchDatasetPreviewThunk = createAsyncThunk<
  FetchDashboardDatasetPreviewData,
  FetchDashboardDatasetPreviewBody,
  {
    state: DashboardStates;
  }
>(ACTION.FETCH_DASHBOARD_DATASET_PREVIEW, async (args, { getState, rejectWithValue }) => {
  const requestInfo = getState().dashboardLayout.requestInfo;

  const urls = {
    embedUrl: `embed/get_preview/`,
    appUrl: 'datasets/get_preview/',
  };

  const requestConfig = createApiRequestConfigWithRequestInfo(urls, 'POST', requestInfo, args);

  return makeThunkRequest(requestConfig, 'Error fetching dataset preview', { rejectWithValue });
});

export const fetchEditorDatasetPreviewThunk =
  (
    body: {
      selectedDatasetId: string;
      parentSchemaId: number;
      query: string;
    },
    pageNumber?: number,
    onSuccess?: (data: QueryExecutionResponse | FetchDashboardDatasetPreviewData) => void,
  ): ThunkAction<void, ReduxState, unknown, AnyAction> =>
  (dispatch, getState) => {
    const { currentUser, dashboardData, dashboardLayout, fido, dashboardEditConfig } = getState();

    if (dashboardLayout.requestInfo.type !== 'app') return;

    const page = pageNumber ?? 1;

    if (shouldUseFidoForDefaultDataSource(body.parentSchemaId.toString(), getState())) {
      const selectedView = getViewFromDatasetId(
        fido.computedViews,
        dashboardEditConfig.config?.datasets,
        body.selectedDatasetId,
      );

      if (!selectedView) return;

      dispatch(
        fetchFidoViewPreview({
          view: selectedView,
          body: {
            query: body.query,
            dataRequestParameters: {
              includeTotalResults: true,
              pagingConfiguration: { page: page - 1, perPage: DEFAULT_ROWS_PER_PAGE },
            },
            queryContext: dashboardData.variables ?? {},
            computation: null,
          },
          onSuccess,
        }),
      );
    } else {
      const postData = {
        dataset_id: body.selectedDatasetId,
        query: body.query,
        parent_schema_id: body.parentSchemaId,
        variables: dashboardData.variables ?? {},
        customer_id: dashboardLayout.requestInfo.customerId,
        resource_id: dashboardLayout.requestInfo.resourceId,
        timezone: DateTime.local().zoneName,
      };

      const offset = (page - 1) * DEFAULT_ROWS_PER_PAGE;

      if (currentUser.team?.feature_flags.use_job_queue) {
        const jobs: JobDefinition[] = [];

        jobs.push({
          job_type: ACTION.FETCH_EDITOR_DATASET_PREVIEW,
          job_args: { ...postData, offset },
          onSuccess,
        });
        if (page === 1) {
          jobs.push({ job_type: ACTION.FETCH_EDITOR_DATASET_ROW_COUNT, job_args: postData });
        }

        dispatch(enqueueDashboardJobsThunk({ jobs }));
      } else {
        dispatch(
          fetchEditorDatasetPreviewPrimaryData({ postData: { ...postData, offset }, onSuccess }),
        );
        if (page === 1) dispatch(fetchEditorDatasetPreviewRowCount({ postData }));
      }
    }

    trackEvent(EVENTS.RAN_CODE, {
      dataset_id: body.selectedDatasetId,
      dataset_query: body.query,
    });
  };

export const fetchEditorDatasetPreviewPrimaryData = createAsyncThunk<
  FetchDashboardDatasetPreviewData,
  {
    postData: FetchEditorDatasetPreviewBody;
    onSuccess?: (data: FetchDashboardDatasetPreviewData) => void;
  },
  {
    state: DashboardStates;
    rejectValue: { error_msg: string; query_information: QueryDebuggingInformation };
  }
>(ACTION.FETCH_EDITOR_DATASET_PREVIEW, async ({ postData, onSuccess }) => {
  const requestConfig = createApiRequestConfig('datasets/get_preview/', 'POST', postData);

  return makeThunkRequest(requestConfig, 'Error fetching dataset preview', {
    onSuccess,
  });
});

export const fetchEditorDatasetPreviewRowCount = createAsyncThunk<
  { _total_row_count: number },
  {
    postData: FetchEditorDatasetPreviewBody;
  },
  {
    state: DashboardStates;
  }
>(ACTION.FETCH_EDITOR_DATASET_ROW_COUNT, async ({ postData }) => {
  const requestConfig = createApiRequestConfig('datasets/get_row_count/', 'POST', postData);

  return makeThunkRequest(requestConfig, 'Error fetching dataset preview row count');
});

const attachDatasetForDatasetPreview = (
  requestInfo: DashboardLayoutRequestInfo,
  datasetId: string,
  datasets: Record<string, ResourceDataset>,
) => {
  const dataset = datasets[datasetId];
  // Checking dataset here for fido
  if (requestInfo.type === 'embedded' || !dataset) return { dataset_id: datasetId };

  return {
    dataset_id: dataset.id,
    query: dataset.query,
    parent_schema_id: dataset.parent_schema_id,
  };
};
