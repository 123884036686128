import cx from 'classnames';
import { FC } from 'react';

import { DatasetDataObject } from 'actions/datasetActions';
import { sprinkles } from 'components/ds';
import Markdown from 'components/markdown';
import { embedSprinkles } from 'globalStyles/sprinkles.css';
import {
  DashboardVariableMap,
  TextDashboardElemConfig,
  TEXT_ELEM_SIZES,
} from 'types/dashboardTypes';
import { replaceVariablesInString } from 'utils/variableUtils';

import * as styles from './dashboardTextElement.css';

type Props = {
  config: TextDashboardElemConfig;
  variables: DashboardVariableMap;
  datasetNamesToId: Record<string, string>;
  datasetData: DatasetDataObject;
};

const TEXT_SIZE_TO_CLASSNAME: Record<TEXT_ELEM_SIZES, string> = {
  [TEXT_ELEM_SIZES.SMALL]: embedSprinkles({ body: 'primary' }),
  // Medium is displayed as Chart Title and that has fontWeight 700
  [TEXT_ELEM_SIZES.MEDIUM]: cx(embedSprinkles({ heading: 'h2' }), sprinkles({ fontWeight: 700 })),
  [TEXT_ELEM_SIZES.LARGE]: embedSprinkles({ heading: 'h1' }),
};

export const DashboardTextElement: FC<Props> = ({
  config,
  variables,
  datasetData,
  datasetNamesToId,
}) => {
  const renderEmptyText = () => {
    return (
      <div
        className={sprinkles({ color: 'gray11', fontStyle: 'italic' })}
        style={{ fontSize: config.textSize || 14 }}>
        No text currently configured.
      </div>
    );
  };

  const renderText = (configText: string) => {
    const text = replaceVariablesInString(configText, variables, datasetNamesToId, datasetData);
    return <Markdown markdownText={text} />;
  };

  return (
    <div
      className={cx(
        styles.root,
        config.alignmentHoriz,
        config.alignmentVertical,
        TEXT_SIZE_TO_CLASSNAME[config.textSize ?? TEXT_ELEM_SIZES.SMALL],
      )}>
      {config.text ? renderText(config.text) : renderEmptyText()}
    </div>
  );
};
