import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit';

import {
  createReportBuilderDraft,
  saveExploreDraft,
  saveReportBuilderDraft,
} from './thunks/resourceSaveThunks';

export enum SaveStatus {
  // The resource is not currently being saved. This is the state that resources go back to after a
  // success save.
  IDLE = 0,
  // The save has been requested but is not yet in flight due to debouncing.
  REQUESTED,
  // The save request is in flight.
  REQUEST_IN_FLIGHT,
  // The save request is in flight and additional changes have been made since the request was made.
  REQUEST_IN_FLIGHT_HAD_ADDITIONAL_CHANGES,
  FAILED,
  // The save request succeeded and there were additional unsaved changes that were blocked from
  // being saved while the request was in flight.
  SUCCEEDED_HAS_ADDITIONAL_CHANGES_TO_SAVE,
}

interface SavingReducerState {
  errorMessage?: string;
  resourceSaveStatuses: Record<number, SaveStatus>;
}

const initialState: SavingReducerState = {
  resourceSaveStatuses: {},
};

export interface ResourceSaveUpdate {
  resourceId: number;
  resourceSaveStatus: SaveStatus;
}

export interface SaveErrorPayload {
  errorMessage: string;
  resourceId: number;
}

const resourceSavingSlice = createSlice({
  name: 'resourceSaving',
  initialState,
  reducers: {
    clearSavingReducer: () => {
      return initialState;
    },
    setSaveError: (state, { payload }: PayloadAction<SaveErrorPayload>) => {
      state.errorMessage = payload.errorMessage;
      state.resourceSaveStatuses[payload.resourceId] = SaveStatus.FAILED;
    },
    setResourceSaveStatus: (state, { payload }: PayloadAction<ResourceSaveUpdate>) => {
      state.resourceSaveStatuses[payload.resourceId] = payload.resourceSaveStatus;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        isAnyOf(
          saveExploreDraft.pending,
          saveReportBuilderDraft.pending,
          createReportBuilderDraft.pending,
        ),
        (state) => {
          state.errorMessage = undefined;
        },
      )
      .addMatcher(
        isAnyOf(
          saveExploreDraft.fulfilled,
          saveReportBuilderDraft.fulfilled,
          createReportBuilderDraft.fulfilled,
        ),
        (state, { payload }) => {
          const resourceId = payload.resource_id;
          const currentStatus = state.resourceSaveStatuses[resourceId];
          state.resourceSaveStatuses[resourceId] =
            currentStatus === SaveStatus.REQUEST_IN_FLIGHT_HAD_ADDITIONAL_CHANGES
              ? SaveStatus.SUCCEEDED_HAS_ADDITIONAL_CHANGES_TO_SAVE
              : SaveStatus.IDLE;
        },
      );
  },
});

export const resourceSavingReducer = resourceSavingSlice.reducer;
export const { clearSavingReducer, setSaveError, setResourceSaveStatus } =
  resourceSavingSlice.actions;
