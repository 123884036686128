import Highcharts from 'highcharts';
import { PureComponent } from 'react';
import ReactDOMServer from 'react-dom/server';

import { DatasetDataObject } from 'actions/datasetActions';
import { vars } from 'components/ds';
import { ChartTooltip } from 'components/embed';
import {
  ChartShapeBorderDefaultColor,
  chartShapeBorderDefaultWidth,
  STANDARD_PIE_CHART_SIZE,
} from 'constants/dashboardConstants';
import { BOOLEAN, DATE_TYPES, V2_NUMBER_FORMATS } from 'constants/dataConstants';
import {
  ColumnColorTracker,
  OPERATION_TYPES,
  V2TwoDimensionChartInstructions,
  VisualizeOperationGeneralFormatOptions,
} from 'constants/types';
import { formatTwoDimensionalData } from 'dataFormatters/twoDimensionalDataFormatter';
import { GlobalStyleConfig } from 'globalStyles/types';
import { NeedsConfigurationPanel } from 'pages/dashboardPage/needsConfigurationPanel';
import { ChartMenuInfo } from 'reducers/dashboardLayoutReducer';
import { DashboardVariable, DashboardVariableMap } from 'types/dashboardTypes';
import { DatasetSchema } from 'types/datasets';
import { getColorFromPaletteTracker } from 'utils/colorCategorySyncUtils';
import { getColDisplayText } from 'utils/dataPanelColUtils';
import { parseVariableSelectDateRanges } from 'utils/dateTimeUtils';
import { getDrilldownChartVariable } from 'utils/drilldownUtils';
import { format } from 'utils/localizationUtils';
import { orderBy } from 'utils/standard';
import { getTimezoneAwareUnix } from 'utils/timezoneUtils';
import { replaceVariablesInString } from 'utils/variableUtils';

import { DrilldownChart } from './shared/drilldownChart';
import {
  areRequiredVariablesSetTwoDimViz,
  formatLabel,
  formatLegend,
  formatValue,
  getAxisNumericalValue,
  getColorColNames,
  getColorPalette,
  getLabelStyle,
  isTwoDimVizInstructionsReadyToDisplay,
} from './utils';
import { getTotalMaxCategories } from './utils/filterDataUtils';
import { sharedTitleConfig, sharedTooltipConfigs } from './utils/sharedConfigs';

const DEFAULT_PCT_DECIMAL_PLACES = 1;

type Props = {
  backgroundColor: string;
  colorTracker?: ColumnColorTracker;
  loading?: boolean;
  previewData: Record<string, string | number>[];
  instructions?: V2TwoDimensionChartInstructions;
  dataPanelId: string;
  dataPanelProvidedId: string;
  variables: DashboardVariableMap;
  schema: DatasetSchema;
  donut?: boolean;
  globalStyleConfig: GlobalStyleConfig;
  generalOptions?: VisualizeOperationGeneralFormatOptions;
  operationType: OPERATION_TYPES;
  setChartMenu: (info: ChartMenuInfo | null) => void;
  setVariable?: (value: DashboardVariable) => void;
  datasetNamesToId?: Record<string, string>;
  datasetData?: DatasetDataObject;
};

type SeriesOptions = Omit<Highcharts.SeriesPieOptions, 'data'> & {
  data: (Highcharts.PointOptionsObject & { name: string | number; y: number })[];
};

type State = {};

class PieChart extends PureComponent<Props, State> {
  excludedCategories: (string | number)[] | undefined;

  constructor(props: Props) {
    super(props);
    this.excludedCategories = undefined;
  }

  render() {
    const { generalOptions, instructions, loading, variables, operationType } = this.props;
    const requiredVarNotsSet = !areRequiredVariablesSetTwoDimViz(variables, instructions);
    const instructionsReadyToDisplay = isTwoDimVizInstructionsReadyToDisplay(
      instructions,
      operationType,
    );

    if (loading || !instructionsReadyToDisplay || requiredVarNotsSet) {
      return (
        <NeedsConfigurationPanel
          fullHeight
          instructionsNeedConfiguration={!instructionsReadyToDisplay}
          loading={loading}
          requiredVarsNotSet={requiredVarNotsSet}
        />
      );
    }

    return (
      <DrilldownChart
        chartOptions={this._spec()}
        customMenuOptions={
          generalOptions?.customMenu?.enabled ? generalOptions?.customMenu?.menuOptions : undefined
        }
        dataPanelId={this.props.dataPanelId}
        drilldownVar={this.getDrilldownVariable()}
        excludedCategories={this.excludedCategories}
        instructions={instructions}
        setCategorySelect={
          instructions?.drilldown?.categorySelectEnabled ? this.setCategorySelect : undefined
        }
        underlyingDataEnabled={this.getUnderlyingDrilldownEnabled()}
      />
    );
  }

  setCategorySelect = (category: string) => {
    const { setVariable, instructions } = this.props;
    if (!setVariable || !instructions?.categoryColumn) return;
    const categoryColumn = instructions.categoryColumn;
    const drilldownVar = this.getDrilldownVariable();

    setVariable({
      category:
        category === drilldownVar?.category
          ? undefined
          : parseVariableSelectDateRanges(category, categoryColumn),
    });
  };

  getDrilldownVariable = () => {
    const { variables, instructions, dataPanelProvidedId } = this.props;

    return getDrilldownChartVariable(variables, instructions, dataPanelProvidedId);
  };

  _spec = (): Highcharts.Options | undefined => {
    const {
      generalOptions,
      previewData,
      schema,
      instructions,
      backgroundColor,
      globalStyleConfig,
      setChartMenu,
      dataPanelId,
    } = this.props;
    if (schema?.length === 0 || !previewData) return;

    // this is a short term fix en lieu of this bug being fixed by vega:
    // Ref: TU/447fn2df
    this.processDatesData();
    const { decimalPlaces } = this.getValueFormat();
    const pieChart = instructions?.chartSpecificFormat?.pieChart;

    const decimalPoints = pieChart?.pctDecimalPlaces ?? DEFAULT_PCT_DECIMAL_PLACES;

    const data = this.transformData();
    const underlyingDrilldownEnabled = this.getUnderlyingDrilldownEnabled();

    const categorySelectEnabled = instructions?.drilldown?.categorySelectEnabled;
    const hasClickEvents =
      underlyingDrilldownEnabled || categorySelectEnabled || !!generalOptions?.customMenu?.enabled;

    return {
      chart: {
        type: 'pie',
        backgroundColor,
      },
      series: data,
      title: sharedTitleConfig,
      colors: getColorPalette(globalStyleConfig, instructions?.colorFormat),
      plotOptions: {
        series: {
          animation: false,
          borderColor:
            instructions?.chartSpecificFormat?.pieChart?.borderColor ??
            ChartShapeBorderDefaultColor,
          borderWidth:
            instructions?.chartSpecificFormat?.pieChart?.borderWidth ??
            chartShapeBorderDefaultWidth,
          cursor: hasClickEvents ? 'pointer' : undefined,
          point: {
            events: {
              click: function (e) {
                if (!hasClickEvents) return;

                setChartMenu({
                  chartId: dataPanelId,
                  chartX: e.chartX,
                  chartY: e.chartY,
                  category: e.point.name,
                });
              },
            },
          },
        },
        pie: {
          size: pieChart?.useStandardPieSize ? STANDARD_PIE_CHART_SIZE : undefined,
          innerSize: this.getInnerSize(),
          showInLegend: true,
          dataLabels: {
            enabled: !pieChart?.hideChartValues || pieChart?.useColorForLabel,
            formatter: function () {
              let label = '';
              if (pieChart?.useColorForLabel) {
                label = formatPieLabel(this.point.name, instructions);
              }
              if (!pieChart?.hideChartValues) {
                const valueFormat =
                  instructions?.chartSpecificFormat?.pieChart?.valuesFormat?.id ||
                  V2_NUMBER_FORMATS.PERCENT.id;
                let value;
                if (V2_NUMBER_FORMATS.PERCENT.id === valueFormat) {
                  value = `${format(`.${decimalPoints}f`)(this.point.percentage || 0)}%`;
                } else {
                  value = formatValue({
                    value: this.point.options.y || 0,
                    decimalPlaces: decimalPoints,
                    formatId:
                      instructions?.chartSpecificFormat?.pieChart?.valuesFormat?.id ||
                      V2_NUMBER_FORMATS.PERCENT.id,
                    hasCommas: true,
                  });
                }
                return label ? `${label} (${value})` : value;
              }
              return label;
            },
            style: {
              ...getLabelStyle(
                globalStyleConfig,
                'primary',
                pieChart?.showLabelsInsideSlices ? vars.colors.black : undefined,
              ),
            },
            ...(pieChart?.showLabelsInsideSlices ? { distance: '-20%' } : {}),
          },
        },
      },
      legend: {
        ...formatLegend(globalStyleConfig, instructions?.legendFormat),
        labelFormatter: function () {
          return formatPieLabel(this.name, instructions);
        },
      },
      tooltip: {
        ...sharedTooltipConfigs,
        formatter: function () {
          return ReactDOMServer.renderToStaticMarkup(
            <ChartTooltip
              globalStyleConfig={globalStyleConfig}
              header={formatPieLabel(this.point.name, instructions)}
              includePct={instructions?.yAxisFormats?.[0].showPct}
              pctDecimalPlaces={
                // for percents, use the same # of decimal points as the non-tooltip labels
                decimalPoints
              }
              points={this.series.data.map((point) => ({
                color: String(point.color),
                name: point.series.name,
                value: point.y,
              }))}
              selectedPoints={[
                {
                  color: String(this.color),
                  name: this.series.name,
                  value: this.point.y,
                  format: {
                    decimalPlaces,
                    formatId:
                      instructions?.yAxisFormats?.[0]?.numberFormat?.id ||
                      V2_NUMBER_FORMATS.NUMBER.id,
                  },
                },
              ]}
            />,
          );
        },
      },
    };
  };

  getUnderlyingDrilldownEnabled = () => {
    return !!this.props.generalOptions?.enableRawDataDrilldown;
  };

  getInnerSize = () => {
    if (this.props.donut) return '50%';
  };

  processDatesData = () => {
    const { instructions, previewData, schema } = this.props;

    if (
      !previewData ||
      !DATE_TYPES.has(instructions?.categoryColumn?.column.type || '') ||
      !schema ||
      schema.length === 0
    )
      return;

    const xAxisColName = schema[0].name;

    if (
      instructions?.categoryColumn?.bucket?.id &&
      instructions.categoryColumn.bucket.id.indexOf('DATE_PART') >= 0
    )
      return;

    previewData.forEach((row) => {
      // If it's a number, it has already been converted to milliseconds
      if (instructions?.categoryColumn?.column.type && typeof row[xAxisColName] !== 'number')
        row[xAxisColName] = getTimezoneAwareUnix(row[xAxisColName] as string);
    });
  };

  getValueFormat = () => {
    const { instructions } = this.props;

    return {
      decimalPlaces: instructions?.yAxisFormats?.[0]?.decimalPlaces ?? 2,
    };
  };

  transformData = () => {
    // This is for when there are multiple bars/lines selected
    const { instructions, schema } = this.props;

    if (
      !instructions?.aggColumns ||
      instructions.aggColumns.length === 0 ||
      !schema ||
      schema.length === 0
    )
      return [];

    let series;

    // This is only used when changed from another chart that had colorColumnOptions
    // Probably should remove that at some point and never allow pie charts to have them
    if (instructions.colorColumnOptions) series = this.transformColorData(schema);
    else series = this.transformAggColsData(schema);

    return this.truncateDataMaxCategories(series);
  };

  truncateDataMaxCategories = (series?: SeriesOptions[]): SeriesOptions[] => {
    const { instructions } = this.props;
    if (!series || !series[0]?.data) return [];
    const sortedData = orderBy(series[0].data, 'y', 'desc');

    const maxCategories = getTotalMaxCategories(
      instructions?.chartSpecificFormat?.pieChart?.maxCategories,
    );
    if (maxCategories) {
      const topEntries = sortedData.slice(0, maxCategories);
      const otherEntries = sortedData.slice(maxCategories);
      if (otherEntries.length > 0) {
        this.excludedCategories = topEntries.map((info) => info.name);
        const total = otherEntries.reduce((total, entry) => total + entry.y, 0);
        topEntries.push({
          name: 'Other',
          y: total,
        });
      }

      series[0].data = topEntries;
    } else {
      series[0].data = sortedData;
    }

    return series;
  };

  transformAggColsData = (schema: DatasetSchema): SeriesOptions[] | undefined => {
    const { previewData, instructions, variables, datasetData, datasetNamesToId, colorTracker } =
      this.props;
    if (!instructions?.aggColumns?.length) return;

    const xAxisColName = schema[0].name;
    const aggColName = schema[1].name;
    const aggCol = instructions.aggColumns[0];

    const drilldownVar = this.getDrilldownVariable();

    const seriesName = aggCol.column.friendly_name
      ? replaceVariablesInString(
          aggCol.column.friendly_name,
          variables,
          datasetNamesToId,
          datasetData,
        )
      : getColDisplayText(aggCol) || aggColName;
    const series: SeriesOptions = { type: 'pie', name: seriesName, data: [] };

    formatTwoDimensionalData(previewData, instructions).forEach((row) => {
      const name = row[xAxisColName];

      const y = getAxisNumericalValue(row[aggColName]);

      if (isNaN(y)) return;

      const sliced = name === drilldownVar?.category;
      const color = getColorFromPaletteTracker({
        columnName: xAxisColName,
        valueName: String(name),
        colorTracker,
      });

      series.data.push({ name, y, sliced, color });
    });

    return [series];
  };

  transformColorData = (schema: DatasetSchema): SeriesOptions[] | undefined => {
    const { previewData, instructions, operationType } = this.props;
    const { xAxisColName, aggColName } = getColorColNames(schema, operationType);

    if (!instructions?.aggColumns) return;
    const aggCol = instructions?.aggColumns[0];

    const seriesData: Record<string, { name: string | number; y: number }> = {};

    previewData.forEach((row) => {
      const category = row[xAxisColName];

      const y = getAxisNumericalValue(row[aggColName]);

      if (isNaN(y)) return;

      if (seriesData[category]) {
        seriesData[category].y += getAxisNumericalValue(row[aggColName]);
      } else {
        seriesData[category] = {
          name: category,
          y,
        };
      }
    });

    return [
      {
        type: 'pie',
        name: aggCol.column.friendly_name || getColDisplayText(aggCol) || aggColName,
        data: Object.values(seriesData),
      },
    ];
  };
}

function formatPieLabel(
  name: string,
  instructions: V2TwoDimensionChartInstructions | undefined,
): string {
  if (instructions?.categoryColumn?.column.type === BOOLEAN) {
    const trueLabel = instructions?.chartSpecificFormat?.pieChart?.trueLabel || 'true';
    const falseLabel = instructions?.chartSpecificFormat?.pieChart?.falseLabel || 'false';
    name = String(name) === 'true' ? trueLabel : falseLabel;
  }
  return formatLabel(
    name,
    instructions?.categoryColumn?.column.type,
    instructions?.categoryColumn?.bucket?.id,
    instructions?.categoryColumn?.bucketSize,
    instructions?.xAxisFormat?.dateFormat,
    instructions?.xAxisFormat?.stringFormat,
    !!instructions?.chartSpecificFormat?.pieChart?.maxCategories,
  );
}

export { PieChart };
