import { TypeCellProps } from '@inovua/reactdatagrid-community/types';
import cx from 'classnames';
import { FC, useCallback, useMemo, RefObject } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { CustomerReportView } from 'actions/customerReportActions';
import { ReportBuilderDataset } from 'actions/reportBuilderConfigActions';
import { PaginatorProps } from 'components/ds/DataGrid/paginator';
import { isPagingDisabled } from 'components/ds/DataGrid/utils';
import { EmbedDataGrid, EmbedPivotTable } from 'components/embed';
import * as gridStyles from 'components/embed/EmbedDataGrid/index.css';
import { UseEmbedColumnsParams } from 'components/embed/EmbedDataGrid/useEmbedColumns';
import * as styles from 'components/embed/EmbedPivotTable/index.css';
import { transformRows } from 'components/embed/EmbedPivotTable/pivotUtils';
import { usePivotColumns } from 'components/embed/EmbedPivotTable/usePivotColumns';
import { TIME_COLUMN_TYPES } from 'constants/dataConstants';
import { SortInfo } from 'constants/types';
import { NoDataSelected } from 'pages/ReportBuilder/ReportView/NoDataSelected';
import { useTotalAccumulator } from 'pages/ReportBuilder/ReportView/ReportChart/useTotalAccumulator';
import { useTotalFooter } from 'pages/ReportBuilder/ReportView/ReportChart/useTotalFooter';
import { getDataGridSort } from 'pages/ReportBuilder/ReportView/ReportChart/utils';
import { useReportColumns } from 'pages/ReportBuilder/ReportView/useReportColumns';
import { isLoading, isSuccess } from 'remotedata';
import {
  handleTableColumnOrderChange,
  getCurrentColorTracker,
  updateSort,
  setViewPage,
} from 'reportBuilderContent/reducers/reportEditingReducer';
import { ReportBuilderReduxState } from 'reportBuilderContent/reducers/rootReducer';
import { ReportData } from 'reportBuilderContent/reducers/types';
import {
  RB_PIVOT_ROW_LIMIT,
  filterViewParams,
  isPivotView,
} from 'reportBuilderContent/thunks/utils';
import { DatasetColumn } from 'types/datasets';
import { PivotAgg } from 'types/dateRangeTypes';
import { getGroupByName } from 'utils/V2ColUtils';
import { getSchemaAndColConfigs } from 'utils/customerReportUtils';
import { getTimezoneAwareUnix } from 'utils/timezoneUtils';

import { GRID_ROW_HEIGHT } from '../../constants';

type Props = {
  view: CustomerReportView;
  containerRef: RefObject<HTMLDivElement>;
  dataset?: ReportBuilderDataset;
  reportData: ReportData;
  onSelect?: (selection: { column: string; value: string | number }[]) => void;
  onFilter?: (column: DatasetColumn) => void;
};

export const ReportDataGrid: FC<Props> = ({
  view,
  reportData,
  containerRef,
  dataset,
  onSelect,
  onFilter,
}) => {
  const dispatch = useDispatch();
  const colorCategoryTracker = useSelector((state: ReportBuilderReduxState) =>
    getCurrentColorTracker(state.reportEditing),
  );

  const filteredViewParams = useMemo(
    () =>
      filterViewParams({
        visualization: view.visualization,
        aggregations: view.aggregations,
        groupBys: view.groupBys,
        columnGroupBys: view.columnGroupBys,
      }),
    [view.aggregations, view.columnGroupBys, view.groupBys, view.visualization],
  );

  const { schema, columnConfigs, pivotColumns, groupByColumns } = useMemo(
    () =>
      getSchemaAndColConfigs(dataset, view?.columnOrder, view?.hiddenColumns, filteredViewParams),
    [dataset, view?.columnOrder, view?.hiddenColumns, filteredViewParams],
  );

  const paginatorProps: PaginatorProps = useMemo(() => {
    const totalRowCount = isSuccess(reportData.rowCount) ? reportData.rowCount.data : undefined;

    return {
      totalRowCount,
      isPagingDisabled: isPagingDisabled(reportData.unsupportedOperations),
      currentPage: reportData.page,
      loading: isLoading(reportData.rowCount),
      goToPage: ({ page }) => dispatch(setViewPage(page)),
    };
  }, [reportData.rowCount, reportData.unsupportedOperations, reportData.page, dispatch]);

  const onColumnOrderChange = useCallback(
    (newOrder: string[]) => dispatch(handleTableColumnOrderChange(newOrder)),
    [dispatch],
  );

  const sortInfo = useMemo(() => getDataGridSort(view.sort), [view.sort]);

  // Drilldown and regular tables have different cell click props
  const handlePivotCellClick = useCallback(
    (event: MouseEvent, cellProps: TypeCellProps) => {
      // Filter drilldown by all the group by values for the selected row
      // Resulting drilldown data will show the "Breakdown" as separate rows
      const selection: { column: string; bucket?: PivotAgg; value: string }[] = [];

      // Group bys for the current row (e.g. if groupBys=['neighborhood_group', 'neighborhood'],
      // clicking on the 1st group will give rowGroupBys=['neighborhood_group']
      // clicking on the 1st row inside the group will give rowGroupBys=['neighborhood_group', 'neighborhood']
      const rowGroupBys = cellProps.data?.fieldPath || [];

      // TODO: For cell selection, Reactdatagrid can't cleanly determine what pivot column was clicked
      // e.g. If you click on a row with 3 different pivot columns, columnIndex just gives the actual column index (like 7)
      const row = cellProps.data.array?.[0];
      rowGroupBys?.forEach?.((column: string) => {
        const value = row?.[column]; // Don't use keyPath because those values are transformed

        // rowGroupBys uses the formatted column name including the bucket so we need the original column name
        const groupBy = filteredViewParams.groupBys.find(
          (groupBy) => getGroupByName(groupBy) === column,
        );
        const columnName = groupBy?.column.name || column;

        // convertColumnAndValueIntoFilter expects a unix timestamp for time columns
        const val =
          groupBy && TIME_COLUMN_TYPES.has(groupBy?.column.type)
            ? getTimezoneAwareUnix(value)
            : value;
        if (val !== undefined) {
          selection.push({ column: columnName, bucket: groupBy?.bucket, value: val });
        }
      });
      if (selection.length) onSelect?.(selection);
    },
    [filteredViewParams.groupBys, onSelect],
  );

  const handleSortColumn = useCallback(
    (sort: SortInfo[]) => dispatch(updateSort(sort)),
    [dispatch],
  );

  const columnParams: UseEmbedColumnsParams = useMemo(
    () => ({
      rows: reportData.rows ?? [],
      schema,
      shouldTruncateText: true,
      disableCustomStyles: true,
      onFilterColumn: onFilter,
      onSortColumn: handleSortColumn,
      colorTracker: colorCategoryTracker,
      containerRef,
      columnConfigs,
    }),
    [
      reportData.rows,
      schema,
      onFilter,
      handleSortColumn,
      colorCategoryTracker,
      containerRef,
      columnConfigs,
    ],
  );

  const shouldPivot = isPivotView(view);
  const transformedRows = useMemo(
    () =>
      shouldPivot && reportData.rows
        ? transformRows(reportData.rows, schema, columnConfigs, pivotColumns)
        : reportData.rows || [],
    [shouldPivot, reportData.rows, schema, columnConfigs, pivotColumns],
  );
  const isRowLimitReached = useMemo(
    () => transformedRows.length >= RB_PIVOT_ROW_LIMIT,
    [transformedRows.length],
  );

  const totalAccumulator = useTotalAccumulator(
    reportData.rows,
    transformedRows,
    schema,
    filteredViewParams.aggs,
    filteredViewParams.columnGroupBys,
    filteredViewParams.groupBys,
  );
  const footerRows = useTotalFooter(schema, columnConfigs, containerRef, totalAccumulator, view);

  const gridColumns = useReportColumns(columnParams);
  const reportPivotColumns = usePivotColumns({
    groupByColumns,
    pivotColumns,
    schema,
    columnConfigs,
    totalAccumulator,
    view,
  });

  if (!dataset) return <NoDataSelected datasetDeleted />;

  const canSelectCells = view.groupBys?.length;
  const sharedProps = {
    loading: reportData.isLoading,
    schema,
  };

  if (shouldPivot && reportPivotColumns) {
    return (
      <EmbedPivotTable
        {...sharedProps}
        {...reportPivotColumns}
        activeIndex={undefined}
        className={cx(styles.dataGrid, gridStyles.container)}
        footerRows={footerRows}
        groupByColumns={groupByColumns || []}
        isRowLimitReached={isRowLimitReached}
        onCellClick={canSelectCells ? handlePivotCellClick : undefined}
        pivotColumns={pivotColumns || []}
        rows={transformedRows}
      />
    );
  }

  return (
    <EmbedDataGrid
      // Resizing a column in one view affects the widths of columns in other views. Set key to rerender when changing views
      key={view.id}
      {...columnParams}
      {...sharedProps}
      columnConfigs={columnConfigs}
      columns={gridColumns}
      footerRows={footerRows}
      onColumnOrderChange={reportData.rows == null ? undefined : onColumnOrderChange}
      paginatorProps={paginatorProps}
      rowHeight={GRID_ROW_HEIGHT}
      rows={reportData.rows || []}
      sortInfo={sortInfo}
    />
  );
};
