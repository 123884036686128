import { IconName as BlueprintIconName } from '@blueprintjs/icons';

import { V2PivotTableInstructions } from 'actions/V2PivotTableActions';
import { COMBO_CHART_DATA_FORMATS } from 'constants/dashboardConstants';
import { STRING_FORMATS } from 'constants/dataConstants';
import { AggregationType, BaseCol, GroupByBucket } from 'types/columnTypes';
import {
  TEXT_ELEM_HORIZ_ALIGNMENTS,
  TextElemHorizAlignments,
  TitleValueArrangements,
  VerticalContentAlignments,
} from 'types/dashboardTypes';
import { DatasetColumn, DatasetSchema } from 'types/datasets';
import {
  DEFAULT_DATE_RANGES,
  PeriodComparisonRangeTypes,
  PeriodRangeTypes,
  PivotAgg,
  RELATIVE_DATE_OPTIONS,
  TrendGroupingOptions,
} from 'types/dateRangeTypes';
import { FilterOperator } from 'types/filterOperations';
import { ChoroplethMapFormat, DensityMapFormat, LocationMarkerMapFormat } from 'types/maps';

import { DATE_RELATIVE_OPTION } from './dataPanelEditorConstants';

// TODO: Break up file into separate files in types top-level directory

//Should be kept in sync with MESSAGE_TYPE_TO_SLACK_CHANNEL
export enum PingTypes {
  PING_ONLINE = 'PING_ONLINE',
  PING_ONLINE_LAUNCH = 'PING_ONLINE_LAUNCH',
  PING_TEAM_CREATION = 'PING_TEAM_CREATION',
  PING_PRICING_PLAN_UPDATE = 'PING_PRICING_PLAN_UPDATE',
  PING_DATASOURCE_TESTING = 'PING_DATASOURCE_TESTING',
  PING_USER_WITHOUT_TEAM = 'PING_USER_WITHOUT_TEAM',
  PING_POWERED_BY_EXPLO_CLICKED = 'PING_POWERED_BY_EXPLO_CLICKED',
  PING_AI_USAGE = 'PING_AI_USAGE',
  PING_CUSTOMER_ACTION = 'PING_CUSTOMER_ACTION',
  PING_END_USER_ACTION = 'PING_END_USER_ACTION',
}
export type AccessGroupDataSourcesMap = {
  [accessGroupId: number]: {
    availableDataSourceIds: number[];
    defaultDataSourceId: number | undefined;
  };
};

export type AccessGroupDataSourcesForSchemaMap = {
  [schemaId: number]: AccessGroupDataSourcesMap;
};

export interface ChartColumnInfo extends ComboChartFormatInfo {
  name?: string;
  friendly_name?: string;
  type?: string;
}

// Should only be used by combo charts. Too complex to pull out right now
interface ComboChartFormatInfo {
  dataFormat?: COMBO_CHART_DATA_FORMATS;
}

// Deprecated: Use AggColInfo
export interface AggedChartColumnInfo {
  column: ChartColumnInfo;
  agg: AggregationType;
  yAxisFormatId?: string;
}

// Deprecated: Use GroupByColInfo
export interface CategoryChartColumnInfo {
  column: ChartColumnInfo;
  bucket?: GroupByBucket;
  bucketElemId?: string;
  bucketSize?: number;
  // Used only on FE for displaying Smart Grouping Charts
  smartBucketInfo?: SmartBucketInfo;
}

export interface SmartBucketInfo {
  startTime: number;
  endTime: number;
}

interface TimeSeriesDataFormat {
  hideLatestPeriodData?: boolean;
  zeroMissingDates?: boolean;
  zeroMissingValues?: boolean;
}

export enum Aggregation {
  COUNT = 'COUNT',
  COUNT_DISTINCT = 'COUNT_DISTINCT',
  AVG = 'AVG',
  SUM = 'SUM',
  MIN = 'MIN',
  MAX = 'MAX',
  '25_PERCENTILE' = '25_PERCENTILE',
  MEDIAN = 'MEDIAN',
  '75_PERCENTILE' = '75_PERCENTILE',

  FORMULA = 'FORMULA',
  FIRST = 'FIRST',
}

export enum OPERATION_TYPES {
  FILTER = 'FILTER',
  GROUP_BY = 'GROUP_BY',

  // KPIs
  VISUALIZE_NUMBER_V2 = 'VISUALIZE_NUMBER_V2',
  VISUALIZE_PROGRESS_V2 = 'VISUALIZE_PROGRESS_V2',
  VISUALIZE_NUMBER_TREND_V2 = 'VISUALIZE_NUMBER_TREND_V2',
  VISUALIZE_NUMBER_TREND_TEXT_PANEL = 'VISUALIZE_NUMBER_TREND_TEXT_PANEL',

  // Special Charts
  VISUALIZE_HEAT_MAP_V2 = 'VISUALIZE_HEAT_MAP_V2',
  VISUALIZE_SCATTER_PLOT_V2 = 'VISUALIZE_SCATTER_PLOT_V2',
  VISUALIZE_SPIDER_CHART = 'VISUALIZE_SPIDER_CHART',
  VISUALIZE_BOX_PLOT_V2 = 'VISUALIZE_BOX_PLOT_V2',
  VISUALIZE_CHOROPLETH_MAP = 'VISUALIZE_CHOROPLETH_MAP',
  VISUALIZE_SANKEY_CHART = 'VISUALIZE_SANKEY_CHART',
  VISUALIZE_LOCATION_MARKER_MAP = 'VISUALIZE_LOCATION_MARKER_MAP',
  VISUALIZE_CALENDAR_HEATMAP = 'VISUALIZE_CALENDAR_HEATMAP',
  VISUALIZE_DENSITY_MAP = 'VISUALIZE_DENSITY_MAP',

  // Bar Charts
  VISUALIZE_VERTICAL_BAR_V2 = 'VISUALIZE_VERTICAL_BAR_V2',
  VISUALIZE_VERTICAL_100_BAR_V2 = 'VISUALIZE_VERTICAL_100_BAR_V2',
  VISUALIZE_VERTICAL_GROUPED_BAR_V2 = 'VISUALIZE_VERTICAL_GROUPED_BAR_V2',
  VISUALIZE_VERTICAL_GROUPED_STACKED_BAR_V2 = 'VISUALIZE_VERTICAL_GROUPED_STACKED_BAR_V2',

  VISUALIZE_HORIZONTAL_BAR_V2 = 'VISUALIZE_HORIZONTAL_BAR_V2',
  VISUALIZE_HORIZONTAL_100_BAR_V2 = 'VISUALIZE_HORIZONTAL_100_BAR_V2',
  VISUALIZE_HORIZONTAL_GROUPED_BAR_V2 = 'VISUALIZE_HORIZONTAL_GROUPED_BAR_V2',
  VISUALIZE_HORIZONTAL_GROUPED_STACKED_BAR_V2 = 'VISUALIZE_HORIZONTAL_GROUPED_STACKED_BAR_V2',

  // Line Charts
  VISUALIZE_LINE_CHART_V2 = 'VISUALIZE_LINE_CHART_V2',
  VISUALIZE_COMBO_CHART_V2 = 'VISUALIZE_COMBO_CHART_V2',

  // Area Charts
  VISUALIZE_AREA_CHART_V2 = 'VISUALIZE_AREA_CHART_V2',
  VISUALIZE_AREA_100_CHART_V2 = 'VISUALIZE_AREA_100_CHART_V2',

  // Pie Charts
  VISUALIZE_PIE_CHART_V2 = 'VISUALIZE_PIE_CHART_V2',
  VISUALIZE_DONUT_CHART_V2 = 'VISUALIZE_DONUT_CHART_V2',

  // Funnel Charts
  VISUALIZE_FUNNEL_V2 = 'VISUALIZE_FUNNEL_V2',
  VISUALIZE_VERTICAL_BAR_FUNNEL_V2 = 'VISUALIZE_VERTICAL_BAR_FUNNEL_V2',

  // Tables
  VISUALIZE_TABLE = 'VISUALIZE_TABLE',
  VISUALIZE_REPORT_BUILDER = 'VISUALIZE_REPORT_BUILDER',
  VISUALIZE_PIVOT_TABLE = 'VISUALIZE_PIVOT_TABLE',
  VISUALIZE_PIVOT_REPORT_BUILDER = 'VISUALIZE_PIVOT_REPORT_BUILDER',
  VISUALIZE_COLLAPSIBLE_LIST = 'VISUALIZE_COLLAPSIBLE_LIST',
  VISUALIZE_PIVOT_TABLE_V2 = 'VISUALIZE_PIVOT_TABLE_V2',
}

const NON_CHART_OPERATIONS = new Set([
  OPERATION_TYPES.FILTER,
  OPERATION_TYPES.GROUP_BY,
  OPERATION_TYPES.VISUALIZE_REPORT_BUILDER,
  OPERATION_TYPES.VISUALIZE_PIVOT_REPORT_BUILDER,
]);

// Tables that display a row count
export const ROW_COUNT_OPERATIONS_SET = new Set([
  OPERATION_TYPES.VISUALIZE_TABLE,
  OPERATION_TYPES.VISUALIZE_REPORT_BUILDER,
  OPERATION_TYPES.VISUALIZE_PIVOT_TABLE,
  OPERATION_TYPES.VISUALIZE_PIVOT_REPORT_BUILDER,
  OPERATION_TYPES.VISUALIZE_COLLAPSIBLE_LIST,
]);

// All tables
export const VISUALIZE_TABLE_OPERATIONS_SET = new Set([
  ...ROW_COUNT_OPERATIONS_SET,
  OPERATION_TYPES.VISUALIZE_PIVOT_TABLE_V2,
]);

// This is the list of all charts not including tables
export const CHART_VISUALIZATION_OPERATIONS = Object.values(OPERATION_TYPES).filter(
  (val) => !NON_CHART_OPERATIONS.has(val) && !VISUALIZE_TABLE_OPERATIONS_SET.has(val),
);

export const CHART_VISUALIZATION_OPERATIONS_SET = new Set(CHART_VISUALIZATION_OPERATIONS);

export const V2_CHART_GOAL_LINE_OPERATIONS = new Set([
  OPERATION_TYPES.VISUALIZE_HORIZONTAL_BAR_V2,
  OPERATION_TYPES.VISUALIZE_HORIZONTAL_GROUPED_BAR_V2,
  OPERATION_TYPES.VISUALIZE_HORIZONTAL_GROUPED_STACKED_BAR_V2,
  OPERATION_TYPES.VISUALIZE_HORIZONTAL_100_BAR_V2,
  OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_V2,
  OPERATION_TYPES.VISUALIZE_VERTICAL_GROUPED_BAR_V2,
  OPERATION_TYPES.VISUALIZE_VERTICAL_GROUPED_STACKED_BAR_V2,
  OPERATION_TYPES.VISUALIZE_VERTICAL_100_BAR_V2,
  OPERATION_TYPES.VISUALIZE_LINE_CHART_V2,
  OPERATION_TYPES.VISUALIZE_AREA_CHART_V2,
  OPERATION_TYPES.VISUALIZE_COMBO_CHART_V2,
  OPERATION_TYPES.VISUALIZE_SPIDER_CHART,
]);

export const VISUALIZATION_OPERATIONS = Object.values(OPERATION_TYPES).filter(
  (val) => !NON_CHART_OPERATIONS.has(val),
);

export const V2_COLOR_ZONE_OPERATIONS = new Set([
  OPERATION_TYPES.VISUALIZE_COMBO_CHART_V2,
  OPERATION_TYPES.VISUALIZE_AREA_100_CHART_V2,
  OPERATION_TYPES.VISUALIZE_AREA_CHART_V2,
  OPERATION_TYPES.VISUALIZE_LINE_CHART_V2,
  OPERATION_TYPES.VISUALIZE_HORIZONTAL_GROUPED_STACKED_BAR_V2,
  OPERATION_TYPES.VISUALIZE_HORIZONTAL_GROUPED_BAR_V2,
  OPERATION_TYPES.VISUALIZE_HORIZONTAL_100_BAR_V2,
  OPERATION_TYPES.VISUALIZE_HORIZONTAL_BAR_V2,
  OPERATION_TYPES.VISUALIZE_VERTICAL_GROUPED_STACKED_BAR_V2,
  OPERATION_TYPES.VISUALIZE_VERTICAL_GROUPED_BAR_V2,
  OPERATION_TYPES.VISUALIZE_VERTICAL_100_BAR_V2,
  OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_V2,
]);

export const HEAT_MAP_COLOR_ZONE_OPERATIONS = new Set([
  OPERATION_TYPES.VISUALIZE_HEAT_MAP_V2,
  OPERATION_TYPES.VISUALIZE_CALENDAR_HEATMAP,
]);

export const COLOR_CATEGORY_OPERATION_TYPES = new Set([
  OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_V2,
  OPERATION_TYPES.VISUALIZE_VERTICAL_100_BAR_V2,
  OPERATION_TYPES.VISUALIZE_VERTICAL_GROUPED_BAR_V2,
  OPERATION_TYPES.VISUALIZE_VERTICAL_GROUPED_STACKED_BAR_V2,
  OPERATION_TYPES.VISUALIZE_HORIZONTAL_BAR_V2,
  OPERATION_TYPES.VISUALIZE_HORIZONTAL_100_BAR_V2,
  OPERATION_TYPES.VISUALIZE_HORIZONTAL_GROUPED_BAR_V2,
  OPERATION_TYPES.VISUALIZE_HORIZONTAL_GROUPED_STACKED_BAR_V2,
  OPERATION_TYPES.VISUALIZE_LINE_CHART_V2,
  OPERATION_TYPES.VISUALIZE_AREA_CHART_V2,
  OPERATION_TYPES.VISUALIZE_AREA_100_CHART_V2,
]);

export const NO_HEADER_OPERATION_TYPES = new Set([
  OPERATION_TYPES.VISUALIZE_NUMBER_V2,
  OPERATION_TYPES.VISUALIZE_PROGRESS_V2,
  OPERATION_TYPES.VISUALIZE_NUMBER_TREND_V2,
  OPERATION_TYPES.VISUALIZE_NUMBER_TREND_TEXT_PANEL,
  OPERATION_TYPES.VISUALIZE_REPORT_BUILDER,
  OPERATION_TYPES.VISUALIZE_PIVOT_REPORT_BUILDER,
]);

export const REPORT_BUILDER_TYPES = new Set([
  OPERATION_TYPES.VISUALIZE_REPORT_BUILDER,
  OPERATION_TYPES.VISUALIZE_PIVOT_REPORT_BUILDER,
]);

export const BAR_CHART_TYPES = new Set([
  OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_V2,
  OPERATION_TYPES.VISUALIZE_VERTICAL_100_BAR_V2,
  OPERATION_TYPES.VISUALIZE_VERTICAL_GROUPED_BAR_V2,
  OPERATION_TYPES.VISUALIZE_VERTICAL_GROUPED_STACKED_BAR_V2,
  OPERATION_TYPES.VISUALIZE_HORIZONTAL_BAR_V2,
  OPERATION_TYPES.VISUALIZE_HORIZONTAL_100_BAR_V2,
  OPERATION_TYPES.VISUALIZE_HORIZONTAL_GROUPED_BAR_V2,
  OPERATION_TYPES.VISUALIZE_HORIZONTAL_GROUPED_STACKED_BAR_V2,
]);

export const PIE_CHART_TYPES = new Set([
  OPERATION_TYPES.VISUALIZE_PIE_CHART_V2,
  OPERATION_TYPES.VISUALIZE_DONUT_CHART_V2,
]);

export const GROUPED_OPERATION_TYPES = new Set([
  OPERATION_TYPES.VISUALIZE_VERTICAL_GROUPED_BAR_V2,
  OPERATION_TYPES.VISUALIZE_HORIZONTAL_GROUPED_BAR_V2,
]);

export const HORIZONTAL_OPERATION_TYPES = new Set([
  OPERATION_TYPES.VISUALIZE_HORIZONTAL_BAR_V2,
  OPERATION_TYPES.VISUALIZE_HORIZONTAL_100_BAR_V2,
  OPERATION_TYPES.VISUALIZE_HORIZONTAL_GROUPED_BAR_V2,
  OPERATION_TYPES.VISUALIZE_HORIZONTAL_GROUPED_STACKED_BAR_V2,
]);

export const GROUPED_STACKED_OPERATION_TYPES = new Set([
  OPERATION_TYPES.VISUALIZE_VERTICAL_GROUPED_STACKED_BAR_V2,
  OPERATION_TYPES.VISUALIZE_HORIZONTAL_GROUPED_STACKED_BAR_V2,
]);

export const COLOR_SYNC_CHART_TYPES = new Set([
  // TODO: Support Categorical colors for Pivot tables
  // OPERATION_TYPES.VISUALIZE_PIVOT_TABLE,
  OPERATION_TYPES.VISUALIZE_TABLE,
  OPERATION_TYPES.VISUALIZE_VERTICAL_BAR_V2,
  OPERATION_TYPES.VISUALIZE_VERTICAL_100_BAR_V2,
  OPERATION_TYPES.VISUALIZE_VERTICAL_GROUPED_BAR_V2,
  OPERATION_TYPES.VISUALIZE_VERTICAL_GROUPED_STACKED_BAR_V2,
  OPERATION_TYPES.VISUALIZE_HORIZONTAL_BAR_V2,
  OPERATION_TYPES.VISUALIZE_HORIZONTAL_100_BAR_V2,
  OPERATION_TYPES.VISUALIZE_HORIZONTAL_GROUPED_BAR_V2,
  OPERATION_TYPES.VISUALIZE_HORIZONTAL_GROUPED_STACKED_BAR_V2,
  OPERATION_TYPES.VISUALIZE_PIE_CHART_V2,
  OPERATION_TYPES.VISUALIZE_DONUT_CHART_V2,
  OPERATION_TYPES.VISUALIZE_LINE_CHART_V2,
  OPERATION_TYPES.VISUALIZE_AREA_100_CHART_V2,
  OPERATION_TYPES.VISUALIZE_AREA_CHART_V2,
  OPERATION_TYPES.VISUALIZE_SPIDER_CHART,
  OPERATION_TYPES.VISUALIZE_COMBO_CHART_V2,
]);

export const KPI_NUMBER_TREND_OPERATION_TYPES = new Set([
  OPERATION_TYPES.VISUALIZE_NUMBER_TREND_V2,
  OPERATION_TYPES.VISUALIZE_NUMBER_TREND_TEXT_PANEL,
]);

export enum ChartTypeSections {
  PERFORMANCE_INDICATOR = 'Performance Indicator',
  BAR_CHART = 'Bar Chart',
  LINE_CHART = 'Line Chart',
  AREA_CHART = 'Area Chart',
  PIE_CHART = 'Pie Chart',
  TABLE = 'Data Table',
  FUNNEL = 'Funnel',
  SPECIAL = 'SPECIAL',
  MAP = 'Map',
}

export enum EMAIL_FREQUENCY {
  DAILY = 'Daily',
  WEEKLY = 'Weekly',
  MONTHLY = 'Monthly',
}

export type EmailCadenceTime = {
  hour?: number;
  minute?: number;
  // unused
  timezone?: string;
  isPm?: boolean;
};

export type FilterValueDateType = {
  startDate?: string;
  endDate?: string;
};

export type FilterValueRelativeDateType = {
  number?: number;
  relativeTimeType?: { id: DATE_RELATIVE_OPTION };
};

export type FilterValueNumberRangeType = {
  min?: number;
  max?: number;
};

export type FilterValueMultiSelectType = string[] | number[];

export type FilterValueType =
  | string
  | number
  | undefined
  | FilterValueMultiSelectType
  | FilterValueDateType
  | FilterValueRelativeDateType
  | FilterValueNumberRangeType
  | RELATIVE_DATE_OPTIONS
  | DEFAULT_DATE_RANGES;

export enum FilterValueSourceType {
  INPUT = 'INPUT',
  VARIABLE = 'VARIABLE',
}

export interface FilterClause {
  filterColumn?: DatasetColumn;
  filterOperation?: { id: FilterOperator };
  filterValue: FilterValueType;
  filterValueSource?: FilterValueSourceType;
  filterValueVariableId?: string;
  filterValueVariableProperty?: string;
  conditionalFilterConfig?: ConditionalFilterConfig;
}

export type ConditionalFilterConfig = {
  // If undefined or false then its constant
  isConditional?: boolean;
  // List of KPIs that if selected enable this filter
  chartsConditionalOn?: string[];
};

export interface FilterOperationInstructions {
  matchOnAll: boolean;
  filterClauses: FilterClause[];
}

export type UserTransformedSchema = (DatasetColumn & { isVisible: boolean })[];

export interface SchemaChange {
  col: string;
  newColName?: string | null;
  hideCol?: boolean; // If true, hidden when page loads, but can be unhidden by customer
  keepCol: boolean; // If false, hidden and cannot be unhidden
  showTooltip?: boolean;
  tooltipText?: string;
}

export enum DateDisplayFormat {
  NORMAL = 'MM/DD/YYYY',
  NUMERIC_SHORT = 'MM-DD-YY',
  NUMERIC_LONG = 'DD-MM-YYYY',
  DATE_TIMESTAMP = 'DD/MM/YYYY (hh:mm:ss)',
  VERBAL_SHORT = 'Mon Day, Year',
  VERBAL_LONG = 'Month Day, Year',
  RELATIVE_TO_NOW = 'Relative to Now',
  CUSTOM = 'Custom',
}

// We now use luxon, so displaying updated formats
export const DateDisplayFormatToLuxon: Record<DateDisplayFormat, string> = {
  [DateDisplayFormat.NORMAL]: 'MM/dd/yyyy',
  [DateDisplayFormat.NUMERIC_SHORT]: 'MM-dd-yy',
  [DateDisplayFormat.NUMERIC_LONG]: 'dd-MM-yyyy',
  [DateDisplayFormat.DATE_TIMESTAMP]: 'dd/MM/yyyy (hh:mm:ss)',
  [DateDisplayFormat.VERBAL_SHORT]: 'Mon Day, Year',
  [DateDisplayFormat.VERBAL_LONG]: 'Month Day, Year',
  [DateDisplayFormat.RELATIVE_TO_NOW]: 'Relative to Now',
  [DateDisplayFormat.CUSTOM]: 'Custom',
};

export type DateDisplayOptions = {
  format?: DateDisplayFormat;
  customFormat?: string;
  alignment?: TEXT_ELEM_HORIZ_ALIGNMENTS;

  // Used for report builder grouping
  datePartAgg?: PivotAgg;
};

export type StringFormat = { format?: STRING_FORMATS; replaceUnderscores?: boolean };

export enum StringDisplayFormat {
  NORMAL = 'Normal',
  CATEGORY = 'Category',
  LINK = 'Link',
  IMAGE = 'Image',
}

export enum StringUrlDisplayFormat {
  LABEL = 'Label',
  COLUMN = 'Column',
}

export enum ImageShapeFormat {
  CIRCLE = 'Circle',
  RECTANGLE = 'Rectangle',
}

export type StringDisplayOptions = {
  format?: StringDisplayFormat;
  urlFormat?: StringUrlDisplayFormat;
  urlColumnName?: string;
  urlLinkColor?: string;
  openInSameTab?: boolean;
  label?: string;
  alignment?: TEXT_ELEM_HORIZ_ALIGNMENTS;
  categoryColorAssignments?: Record<string | number, string>;
  addedCategories?: { name: string; color: string }[];
  imageShape?: ImageShapeFormat;
};

export enum NumberDisplayFormat {
  NORMAL = 'Normal',
  CURRENCY = 'Currency ($)',
  PERCENT = 'Percent (%)',
  TIME = 'Time (00:00)',
}

export enum NumberDisplayDisplayType {
  VALUE = 'Value',
  PROGRESS_BAR = 'Progress Bar',
}

export type NumberDisplayTypeOptions = {
  progressBarGoal?: string;
  useColumnMaxForProgressBarGoal?: boolean;
  useOtherColumnAsMax?: boolean;
  goalColumnName?: string;
};

export enum GradientType {
  NONE = 'None',
  LINEAR = 'Linear',
  DIVERGING = 'Diverging',
}

export type GradientShape = {
  hue1: string;
  hue2: string;
  hue3: string;
};

export enum GradientPointType {
  COMPUTED = 'Computed',
  NUMBER = 'Number',
}

export type GradientPointOptions = {
  type?: GradientPointType;
  value?: string;
};

export type GradientOptions = {
  minpoint?: GradientPointOptions;
  midpoint?: GradientPointOptions;
  maxpoint?: GradientPointOptions;
};

export type NumberDisplayOptions = {
  decimalPlaces?: number;
  hasCommas?: boolean;
  format?: NumberDisplayFormat;
  goal?: string;
  timeFormat?: { id: string };
  timeCustomFormat?: string;
  customDurationFormat?: string;
  useColumnMaxForGoal?: boolean;
  displayType?: NumberDisplayDisplayType;
  displayTypeOptions?: NumberDisplayTypeOptions;
  gradientType?: GradientType;
  gradient?: Partial<GradientShape>;
  gradientOptions?: GradientOptions;
  alignment?: TEXT_ELEM_HORIZ_ALIGNMENTS;
  multiplier?: number;
  disableHoverTooltip?: boolean;
  zeroCharacter?: string;
  displayNegativeValuesWithParentheses?: boolean;
};

export enum BooleanDisplayFormat {
  TICK = 'tick',
  CROSS = 'cross',
  BLANK = 'blank',
}

export type BooleanColorCellOptions = {
  trueBackgroundColor?: string;
  falseBackgroundColor?: string;
  trueTextColor?: string;
  falseTextColor?: string;
};

export type BooleanDisplayOptions = {
  trueIcon?: BooleanDisplayFormat;
  falseIcon?: BooleanDisplayFormat;
  alignment?: TEXT_ELEM_HORIZ_ALIGNMENTS;
  colorCell?: BooleanColorCellOptions;
};

export type DisplayOptions =
  | DateDisplayOptions
  | StringDisplayOptions
  | NumberDisplayOptions
  | BooleanDisplayOptions;

export type TableJoinColumnConfig = {
  joinOn?: boolean;
  joinTable?: { id: string };
  joinColumn?: { name: string };
  joinDisplayColumn?: { name: string; column: { type: string } };
};

export type SchemaDisplayOption = DisplayOptions & TableJoinColumnConfig;

export type SchemaDisplayOptions = Record<string, SchemaDisplayOption>;

export type TableDrilldownConfig = {
  drilldownEnabled?: boolean;
  drilldownColumn?: string;
  allColumns?: boolean;
};

export type RowLevelAction = {
  menuLabel: string;
  eventName: string;
};

export type VisualizeTableInstructions = BaseTableInstructions & {
  baseSchemaList?: DatasetSchema;
  changeSchemaList: SchemaChange[];
  isSchemaCustomizationEnabled?: boolean;
  drilldownConfig?: TableDrilldownConfig;
  rowLevelActionConfigs?: RowLevelAction[];
};

export type TablePDFFormat = {
  headerEnabled?: boolean;
  leftOption?: SECTION_OPTIONS;
  leftContent?: string;
  centerOption?: SECTION_OPTIONS;
  centerContent?: string;
  rightOption?: SECTION_OPTIONS;
  rightContent?: string;
};

type CsvFormat = { tsvEnabled?: boolean };

export enum SECTION_OPTIONS {
  BLANK = 'BLANK',
  IMAGE = 'IMAGE',
  TEXT = 'TEXT',
}

export type VisualizePivotTableInstructions = BaseTableInstructions & {
  rowColumn?: CategoryChartColumnInfo;
  colColumn?: CategoryChartColumnInfo;
  aggregation?: AggedChartColumnInfo;

  displaySumRow?: boolean;
  sumRowText?: string;
  sumRowBold?: boolean;
  sumRowBackgroundColor?: string;
  firstColumnTitle?: string;
  dateFormat?: string;
  stringFormat?: StringFormat;
  changeSchemaList?: SchemaChange[];
};

export enum COLUMN_FITS {
  FILL = 'Fill',
  CELL = 'Fit cell',
  HEADER = 'Fit cell and header',
}
export const ColumnFitOptions = Object.values(COLUMN_FITS).map((fit) => ({ value: fit }));

export type ColumnWidths = Record<string, number>;
export type LegacyWidths = Array<number | null | undefined>;

export type BaseTableInstructions = {
  columnFit?: COLUMN_FITS;
  columnWidths?: LegacyWidths | ColumnWidths;
  shouldTruncateText?: boolean;
  isFilteringDisabled?: boolean;
  isFooterHidden?: boolean;
  disableDownloadExport?: boolean;
  enableEmailExport?: boolean;
  disablePdfDownload?: boolean;
  rowHeight?: number;
  rowsPerPage?: number;
  isColumnSortingDisabled?: boolean;
  isInitialSortDesc?: boolean;
  defaultSortedColumn?: DefaultSortColumn | DefaultSortColumn[];
  isColumnLinesEnabled?: boolean;
  isRowLinesDisabled?: boolean;
  isColumnHeadersBolded?: boolean;
  isFirstColumnBolded?: boolean;
  isFirstColumnFrozen?: boolean;
  shouldVisuallyGroupByFirstColumn?: boolean;
  generalFormat?: V2GeneralInstructions;
  pdfFormat?: TablePDFFormat;
  csvFormat?: CsvFormat;
  schemaDisplayOptions?: SchemaDisplayOptions;
  orderedColumnNames?: string[];
  ignoreInvalidDates?: boolean;
};

type BaseAxisFormat = {
  decimalPlaces?: number;
  hideAxisLabels?: boolean;
  max?: number;
  min?: number;
  numberFormat?: { id: string };
  reverseAxis?: boolean;
  showDecimals?: boolean;
  showTitle?: boolean;
  title?: string;
  useLogScale?: boolean;
};

// These three sort enums and type are used for sorting things on the FE
export enum SortAxis {
  NONE = 'None',
  MANUAL = 'MANUAL',
  AGG_AXIS = 'AGG_AXIS',
  CAT_AXIS = 'CAT_AXIS',
  COLUMN = 'COLUMN',
}

export enum SortOption {
  ASC = 'Ascending',
  DESC = 'Descending',
  DEFAULT = 'Default', // No sorting?
}

export type ManualSortCategory = {
  category: string;
  displayName: string;
};

// These three sort enums and type are used for sorting things on the BE
export enum SortOrder {
  ASC = 'ASC',
  DESC = 'DESC',
}

export interface SortInfo {
  column: { name: string };
  order: SortOrder;
}

export type DefaultSortColumn = { column?: string; order?: SortOrder };

export type XAxisFormat = BaseAxisFormat & {
  showBarValues?: boolean;
  barCornerRadius?: number;
  hideTotalValues?: boolean;
  labelPadding?: number;
  sortOption?: SortOption;
  sortAxis?: SortAxis;
  // supporting a single column for now, but will make the data model support multiple
  sortColumns?: AggedChartColumnInfo[];
  sortManualCategoriesOrder?: ManualSortCategory[];
  dateFormat?: string;
  stringFormat?: StringFormat;
  maxCategories?: number;
  showOther?: boolean; // If maxCategories and this is true then show "Other" category
  hideAxisTicks?: boolean;
  hideAxisLine?: boolean;
  flipAxis?: boolean;
  enableScroll?: boolean;
  rotationAngle?: number;
};

export type YAxisFormat = BaseAxisFormat & {
  id?: string;
  colorFromAggColumn?: AggedChartColumnInfo;
  oppositeAligned?: boolean;
  hideGridLines?: boolean;
  showPct?: boolean;
};

export enum LegendPosition {
  TOP = 'Top',
  BOTTOM = 'Bottom',
  LEFT = 'Left',
  RIGHT = 'Right',
  AUTO = 'Auto',
}

export type LegendFormat = {
  position?: LegendPosition;
  title?: string;
  showTitle?: boolean;
  hideLegend?: boolean;
  stringFormat?: StringFormat;
};

export type AreaChartFormat = {
  reverseGroupOrder?: boolean;
  useGradientFill?: boolean;
};

export type PieChartFormat = {
  hideChartValues?: boolean;
  showLabelsInsideSlices?: boolean;
  valuesFormat?: { id: string };
  useColorForLabel?: boolean;
  maxCategories?: number;
  pctDecimalPlaces?: number;
  trueLabel?: string;
  falseLabel?: string;
  useStandardPieSize?: boolean;
} & ChartShapeBorderFormat;

export type HeatMapFormat = {
  nullMissingValues?: boolean;
  showCellLabels?: boolean;
  valueFormat?: ValueFormatOptions;
  hideZeros?: boolean;
  gradientType?: GradientType;
  gradient?: Partial<GradientShape>;
  gradientOptions?: GradientOptions;
  enableVerticalScroll?: boolean;
  verticalCellHeight?: number;
  sortedXAxis?: string[];
  sortedYAxis?: string[];
};

export type FunnelChartFormat = {
  hideChartValues?: boolean;
  useStageForLabel?: boolean;
  isVertical?: boolean;
  sortedStages?: Array<string>;
};

export type BarFunnelChartFormat = {
  lostLabel?: string;
  wonLabel?: string;
  entityName?: string;
  isNotCumulative?: boolean;
  minBarWidth?: number;
};

export enum LineElasticity {
  CURVED = 'Curved',
  STRAIGHT = 'Straight',
}

export type LineChartFormat = {
  elasticity?: LineElasticity;
  hideMarkers?: boolean;
  lineWidth?: number;
  lineType?: PlotLineType;
};

export enum ColorPalette {
  DEFAULT = 'Default',
  GREEN = 'Green',
  BLUE = 'Blue',
  CUSTOM = 'Custom Palette',
}

export enum ColorPaletteV2 {
  CATEGORICAL = 'Categorical',
  DIVERGING = 'Diverging',
  GRADIENT = 'Gradient',
}

export type ColorZone = {
  zoneThreshold?: string;
  zoneColor: string;
  zoneId: string;
};

export type ColorFormat = {
  selectedPalette?: ColorPalette | ColorPaletteV2;
  customColors?: string;
  useZones?: boolean;
  colorZones?: ColorZone[];
};

export enum CustomStylesCornerRadiusCategories {
  BUTTON = 'buttons',
  INPUT_FIELDS = 'inputFields',
}

export enum SpiderChartShape {
  ROUND = 'Round',
  POLYGON = 'Polygon',
}

export type SpiderChartFormat = {
  spiderChartShape?: SpiderChartShape;
  lineWidth?: number;
};

export type ChartShapeBorderFormat = {
  borderWidth?: number;
  borderColor?: string;
};

export type SankeyChartFormat = {
  units?: string;
  numberFormat?: string;
  decimalPlaces?: number;
  multiplier?: number;
  showValues?: boolean;
  showPercentages?: boolean;
  showPctOnTooltips?: boolean;
  showPctOnChart?: boolean;
};

export type ChartSpecificFormat = {
  barChart?: ChartShapeBorderFormat;
  pieChart?: PieChartFormat;
  areaChart?: AreaChartFormat;
  heatMap?: HeatMapFormat;
  funnelChart?: FunnelChartFormat;
  barFunnelChart?: BarFunnelChartFormat;
  lineChart?: LineChartFormat;
  timeSeriesDataFormat?: TimeSeriesDataFormat;
  spiderChart?: SpiderChartFormat;
  choroplethMap?: ChoroplethMapFormat;
  sankeyChart?: SankeyChartFormat;
};

export enum PlotLineType {
  SOLID = 'Solid',
  SHORT_DASH = 'ShortDash',
  SHORT_DOT = 'ShortDot',
  DOT = 'Dot',
  DASH = 'Dash',
  LONG_DASH = 'LongDash',
}

export type GoalLineFormat = {
  showGoalLine?: boolean;
  goalValue?: string;
  goalValueMax?: string;
  isGoalBand?: boolean;
  lineType?: PlotLineType;
  lineColor?: string;
  lineWidth?: number;
  label?: string;
  labelColor?: string;
  isXAxisGoal?: boolean;
  // If chart has multiple y axes can assign to one so it doesn't repeat
  yAxisId?: string;
};

type TooltipFormat = {
  showPct?: boolean;
  showSelectedOnly?: boolean;
};

export enum ColorFilterPlacement {
  RIGHT_CORNER = 'rightCorner',
  BELOW_TITLE = 'belowTitle',
}

export interface ColorColumnOption {
  column: DatasetColumn;
  bucket?: GroupByBucket;
  selected?: boolean;
}

export type VisualizeGeospatialChartInstructions = {
  latitudeColumn?: BaseCol;
  longitudeColumn?: BaseCol;
  rowLimit?: number;
  mapFormat?: LocationMarkerMapFormat;
  densityMapFormat?: DensityMapFormat;
  tooltipColumns?: BaseCol[];
  generalFormat?: V2GeneralInstructions;
};

export type V2TwoDimensionChartInstructions = {
  aggColumns?: AggedChartColumnInfo[];
  categoryColumn?: CategoryChartColumnInfo;
  colorColumnOptions?: ColorColumnOption[];
  colorFilterPlacement?: ColorFilterPlacement;
  defaultColorGroupingOff?: boolean;
  xAxisFormat?: XAxisFormat;
  yAxisFormats?: YAxisFormat[];
  legendFormat?: LegendFormat;
  generalFormat?: V2GeneralInstructions;
  tooltipFormat?: TooltipFormat;
  colorFormat?: ColorFormat;
  chartSpecificFormat?: ChartSpecificFormat;
  drilldown?: DrilldownConfig;
  groupingColumn?: CategoryChartColumnInfo;
} & GoalLineChartConfig;

type DrilldownConfig = {
  categorySelectEnabled?: boolean;
};

export type KPIGeneralFormat = V2GeneralInstructions & {
  subtitle?: string;
  alignment?: TextElemHorizAlignments;
  vertical_content_alignment?: VerticalContentAlignments;
  title_value_arrangement?: TitleValueArrangements;
  titleColor?: string;
};

export type KPIValueFormat = {
  units?: string;
  emptyText?: string;
  numberFormat?: { id: string };
  multiplyFactor?: number;
  decimalPlaces?: number;
  // can be a dashboard variable
  progressGoal?: string | number;
  progressHideGoal?: boolean;
  progressShowPct?: boolean;
  pctDecimalPlaces?: number;
  progressBarColor?: string;
  bold?: boolean;
  italic?: boolean;
  timeFormat?: { id: string };
  timeCustomerFormat?: string;
  customDurationFormat?: string;
  horizAlignment?: TextElemHorizAlignments;
  imageUrl?: string;
  significantDigits?: number;
  unitPadding?: boolean;
};

export enum ColorSettings {
  CONSTANT = 'constant',
  CONDITIONAL = 'conditional',
}

export type KPIColorFormat = {
  colorSettingType?: ColorSettings;
  constantColor?: string;
  conditionalTriggerValue?: string;
  conditionalPositiveColor?: string;
  conditionalNegativeColor?: string;
  applyColorToProgressGoal?: boolean;
};

export type KPITitleFormat = {
  horizAlignment?: TextElemHorizAlignments;
  fontSize?: number;
};

export type V2KPIChartInstructions = {
  aggColumn?: AggedChartColumnInfo;
  generalFormat?: KPIGeneralFormat;
  valueFormat?: KPIValueFormat;
  colorFormat?: KPIColorFormat;
  actionConfig?: KPIActionConfig;
};

export type KPIActionConfig = {
  selectionEnabled?: boolean;
};

export type LinkFormat = {
  link?: boolean;
  url?: string;
  openInNewTab?: boolean;
};

export type V2GeneralInstructions = {
  showTooltip?: boolean;
  tooltipText?: string;
};

export type BoxPlotFormat = {
  seriesLabelByColumn?: { [columnName: string]: string };
  isVertical?: boolean;
  hideWhisker?: boolean;
  fillColorByColumn?: { [columnName: string]: string };
  medianColorByColumn?: { [columnName: string]: string };
};

export type V2BoxPlotInstructions = {
  groupingColumn?: CategoryChartColumnInfo;
  calcColumns?: ChartColumnInfo[];
  xAxisFormat?: XAxisFormat;
  yAxisFormat?: YAxisFormat;
  legendFormat?: LegendFormat;
  boxPlotFormat?: BoxPlotFormat;
  generalFormat?: V2GeneralInstructions;
  sortedStages?: string[];
} & GoalLineChartConfig;

export type ScatterPlotFormat = {
  radius?: number;
  seriesLabel?: string;
  useJitter?: boolean;
};

export type V2ScatterPlotInstructions = {
  xAxisColumn?: ChartColumnInfo;
  yAxisColumn?: ChartColumnInfo;
  generalFormat?: V2GeneralInstructions;
  groupingColumn?: ChartColumnInfo;
  colorFormat?: ColorFormat;
  xAxisFormat?: XAxisFormat;
  yAxisFormat?: YAxisFormat;
  legendFormat?: LegendFormat;
  scatterPlotFormat?: ScatterPlotFormat;
} & GoalLineChartConfig;

export type GoalLineChartConfig = {
  goalLines?: GoalLineFormat[];
};

export type KPIComparisonInfo = {
  customStartDate?: string;
  customEndDate?: string;
  rangeElemId?: string;
  timePeriodElemId?: string;
};

export interface KPIPeriodColumnInfo {
  column: ChartColumnInfo;
  periodRange: PeriodRangeTypes;
  customStartDate?: string;
  customEndDate?: string;
  customStartDateVariable?: string;
  customEndDateVariable?: string;
  rangeElemId?: string;
  timePeriodElemId?: string;
  trendDateOffset?: number;
  comparisonInfo?: KPIComparisonInfo;
}

export interface KPITrendDisplayFormat {
  comparisonColor?: string;
  showAbsoluteChange?: boolean;
  showTrendChangePeriodLabel?: boolean;
  periodColor?: string;
  trendColorsReversed?: boolean;
  useTrendTag?: boolean;
  trendDecimalPlaces?: number;
  showPreviousValue?: boolean;
}

export type V2KPITrendInstructions = {
  aggColumn?: AggedChartColumnInfo;
  periodColumn?: KPIPeriodColumnInfo;
  periodComparisonRange?: PeriodComparisonRangeTypes;
  trendGrouping?: TrendGroupingOptions | TrendGroupToggleOption;
  trendGroupingElementId?: string;
  valueFormat?: KPIValueFormat;
  titleFormat?: KPITitleFormat;
  displayFormat?: KPITrendDisplayFormat;
  generalFormat?: V2GeneralInstructions;
  textOnlyFormat?: V2KPITrendTextOnlyInstructions;
  // TODO(zifanxiang): Remove this after the database migration and all clients have been reloaded.
  hideTrendLines?: boolean;
};

export const TrendGroupToggleOptionId = 'KPI_TREND_DATE_GROUP_TOGGLE';
export type TrendGroupToggleOption = typeof TrendGroupToggleOptionId;

type V2KPITrendTextOnlyInstructions = {
  subtitle?: string;
};

// New instruction keys need to be made optional/nullable
// because all existing data panel templates won't have them present
// when the new instruction is added
export type VisualizeOperationInstructions = {
  VISUALIZE_TABLE: VisualizeTableInstructions;
  V2_TWO_DIMENSION_CHART?: V2TwoDimensionChartInstructions;
  V2_KPI?: V2KPIChartInstructions;
  V2_BOX_PLOT?: V2BoxPlotInstructions;
  V2_KPI_TREND?: V2KPITrendInstructions;
  V2_SCATTER_PLOT?: V2ScatterPlotInstructions;
  VISUALIZE_PIVOT_TABLE?: VisualizePivotTableInstructions;
  VISUALIZE_COLLAPSIBLE_LIST?: VisualizeCollapsibleListInstructions;
  VISUALIZE_PIVOT_TABLE_V2?: V2PivotTableInstructions;
  VISUALIZE_GEOSPATIAL_CHART?: VisualizeGeospatialChartInstructions;
};

// Group By Types

export type PivotOperationInstructions = {
  aggregations: PivotOperationAggregation[];
};

export interface SelectedDropdownInputItem {
  name: string;
  id: string;
  icon?: BlueprintIconName | JSX.Element;
  value?: string | number;
  isDivider?: boolean;
  isTitle?: boolean;
  secondaryText?: string;
}

export type PivotOperationAggregation = {
  type: AggregationType | null;
  aggedOnColumn: DatasetColumn | null;
  additionalCol?: DatasetColumn;
  aggLabel?: string;
};

// this needs to be kept in sync with models.ReportedAnalyticsAction
export enum REPORTED_ANALYTIC_ACTION_TYPES {
  DASHBOARD_PAGE_VIEWED = 'Dashboard Page Viewed',
  SHARED_DASHBOARD_PAGE_VIEWED = 'Shared Dashboard Page Viewed',
  PORTAL_DASHBOARD_PAGE_VIEWED = 'Portal Dashboard Page Viewed',
  SHARED_CHART_VIEWS = 'Shared Chart Viewed',
  SHARE_BUTTON_CLICKED = 'Share Button Clicked',
  CSV_DOWNLOADED = 'CSV Downloaded',
  DATA_PANEL_PDF_DOWNLOADED = 'Data Panel PDF Downloaded',
  TABLE_SORTED = 'Table Sorted',
  TABLED_PAGED = 'Table Paged',
  TABLE_FILTERED = 'Table Filtered',
  DROPDOWN_SELECTED = 'Dropdown Selected',
  MULTISELECT_SELECTED = 'Multiselect Selected',
  DATEPICKER_SELECTED = 'Datepicker Selected',
  SLIDER_CHANGED = 'Slider Changed',

  PORTAL_LOG_IN_EMAIL_SENT = 'Portal Log In Email Sent',
  PORTAL_LOG_IN_EMAIL_CLICKED = 'Portal Log In Email Clicked',

  ANALYTICS_HUB_VIEWED = 'Analytics Hub Viewed',
  CUSTOMER_REPORT_OPENED = 'Customer Report Opened',
  BUILT_IN_REPORT_OPENED = 'Built In Report Opened',
  CUSTOMER_REPORT_CREATED = 'Customer Report Created',
  CUSTOMER_REPORT_COPIED = 'Customer Report Copied',
  REPORT_CADENCE_CREATED = 'Report Cadence Created',
  REPORT_EXPORTED = 'Report Exported',
  CUSTOMER_REPORT_FILTER_OPENED = 'Report Filter Opened',
  CUSTOMER_REPORT_AI_OPENED = 'Report AI Opened',
  CUSTOMER_REPORT_AI_CHART_CREATED = 'Report AI Chart Created',
  CUSTOMER_REPORT_AI_VIEW_CREATED = 'Report AI View Created',
  CUSTOMER_REPORT_AI_MESSAGE_SENT = 'Report AI Message Sent',
  CUSTOMER_REPORT_AI_DATA_QUERY_FAILED = 'Report AI Data Query Failed',

  CUSTOMER_REPORT_SAVED = 'Report Saved',
  CUSTOMER_REPORT_FAVORITED = 'Report Favorited',
  CUSTOMER_REPORT_UNFAVORITED = 'Report Unfavorited',
  CUSTOMER_REPORT_DOCS_OPENED = 'Docs Opened',
}

export const PAGE_EVENTS = new Set([
  REPORTED_ANALYTIC_ACTION_TYPES.DASHBOARD_PAGE_VIEWED,
  REPORTED_ANALYTIC_ACTION_TYPES.SHARED_DASHBOARD_PAGE_VIEWED,
  REPORTED_ANALYTIC_ACTION_TYPES.PORTAL_DASHBOARD_PAGE_VIEWED,
  REPORTED_ANALYTIC_ACTION_TYPES.ANALYTICS_HUB_VIEWED,
]);

export interface SupportedLocale {
  name: string;
  localeCode: string;
}

export interface SupportedCurrency {
  name: string;
  currencyCode: string;
}

export interface NumberDefinition {
  decimal: string;
  thousands: string;
  grouping: number[];
  percent?: string;
}

export const DEFAULT_NO_DATA_FONT_SIZE = 36;

export interface NoDataConfig {
  hideChartIcon?: boolean;
  noDataText?: string;
  noDataFontSize?: number;
  isZeroNoData?: boolean;
  hideFilterAndDownloadButtons?: boolean;
}

export interface HeaderConfig {
  isHeaderHidden?: boolean;
  title?: string;
}

export enum CustomMenuOptionType {
  CUSTOM_JS_EVENT = 'Custom JS Event',
}

export interface CustomMenuOptionConfig {
  name: string;
  type: CustomMenuOptionType;
  customJSEventName?: string;
}

export interface CustomMenuConfig {
  enabled?: boolean;
  menuOptions?: CustomMenuOptionConfig[];
}

export interface ExportConfig {
  // This disables all exports
  isDownloadButtonHidden?: boolean;
  downloadFileName?: string;
  rawDataDownloadFileName?: string;
  // This disables downloads to browser
  disableDownloadExport?: boolean;
  // This enables exporting to an email
  enableEmailExport?: boolean;

  disableCsvExport?: boolean;
  disableXlsxExport?: boolean;
  disableImageExport?: boolean;
  csvFormat?: CsvFormat;

  // PDF is only for tables
  disablePdfDownload?: boolean;
  pdfFormat?: TablePDFFormat;
}

export interface VisualizeOperationGeneralFormatOptions {
  noDataState?: NoDataConfig;
  headerConfig?: HeaderConfig;
  linkFormat?: LinkFormat;
  enableRawDataDrilldown?: boolean;
  export?: ExportConfig;
  customMenu?: CustomMenuConfig;
}

export interface DropdownOption {
  id: string;
  value: string | number;
  name: string;
}

type CategoryColumnTitle = {
  showTitle?: boolean;
  title?: string;
};

export type VisualizeCollapsibleListInstructions = {
  rowColumns?: CategoryChartColumnInfo[];
  aggregations?: AggedChartColumnInfo[];
  showCategories?: boolean;
  hideFooter?: boolean;
  disableDownloadExport?: boolean;
  enableEmailExport?: boolean;
  generalFormat?: V2GeneralInstructions;
  numberDisplayOptions?: Record<string, NumberDisplayOptions>;
  groupByDisplayOptions?: Record<string, DisplayOptions>;
  isSortingDisabled?: boolean;
  sortColumns?: Record<string, DefaultSortColumn>;
  categoryColumnTitle?: CategoryColumnTitle;
  orderedColumnNames?: string[];
  rowHeight?: number;
  sortedStages?: string[];
};

export type ValueFormatOptions = {
  numberFormat?: { id: string };
  showDecimals?: boolean;
  decimalPlaces?: number;
};

export type Total = {
  name: string;
  category?: string;
  total?: number;
};

// For charts, if the palette is "Custom Palette", the key is the Data Panel ID since we don't want to share custom palettes between charts
// Otherwise, the key is the palette name
// For tables, the key is Data Panel ID since tables do NOT share palettes
// For Report Builder, the key is the dataset ID since colors are configured on the dataset
export type ColorCategoryTracker = Partial<Record<string, ColumnColorTracker>>;
export type ColumnColorTracker = Partial<Record<string, CategoryToColor>>; // Key is column name
export type CategoryToColor = Partial<Record<string, string>>; // Key is value name. Value is color

export enum INPUT_TYPE {
  MANUAL = 'MANUAL',
  QUERY = 'QUERY',
}

export const INPUT_TYPES: Record<INPUT_TYPE, { id: INPUT_TYPE; name: string }> = {
  MANUAL: { id: INPUT_TYPE.MANUAL, name: 'Manual' },
  QUERY: { id: INPUT_TYPE.QUERY, name: 'Query' },
};

export enum AIChatErrors {
  MAX_MESSAGES_ERROR = 'MAX_MESSAGES_ERROR',
}
