import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Layout } from 'react-grid-layout';
import { v4 as uuidv4 } from 'uuid';

import { V2PivotTableInstructions } from 'actions/V2PivotTableActions';
import {
  CustomerEditableSectionLayout,
  fetchDashboardRequest,
  fetchDashboardSuccess,
} from 'actions/dashboardActions';
import {
  clearDashboardConfigReducer,
  createDashboardDataPanel,
  createDashboardDataset,
  createDashboardElement,
  deleteDashboardElement,
  deleteDataPanel,
  duplicateDashboardItem,
  publishNewDashboardVersionSuccess,
  saveDashboardElementUpdates,
  switchCurrentlyEditingDashboardVersion,
  switchEditingDashboard,
  toggleElementVisibilityForSecondaryLayout,
  toggleFilterLink,
  updateElementConfig,
  updateElementContainerLocation,
  updateElementLocation,
} from 'actions/dashboardV2Actions';
import {
  createFilterClause,
  deleteFilterClause,
  selectFilterColumn,
  selectFilterOperator,
  updateFilterValue,
  updateFilterValueSource,
  updateFilterValueVariable,
  updateGeneralFormatOptions,
  updateSelectedChart,
  updateVisualizeOperationAction,
  UpdateVisualizeOperationPayload,
} from 'actions/dataPanelConfigActions';
import { updateDrilldownDataPanel } from 'actions/dataPanelTemplateAction';
import {
  Dataset,
  DatasetData,
  DatasetDataObject,
  deleteDataset,
  DrilldownConfig,
  editDatasetName,
  fetchEditorDatasetPreviewError,
  fetchEditorDatasetPreviewRequest,
  fetchEditorDatasetPreviewSuccess,
  fetchEditorDatasetRowCountError,
  fetchEditorDatasetRowCountSuccess,
  saveDatasetQuery,
  saveDraftComputedViewQuery,
  saveDraftDatasetQuery,
  updateDashboardDatasetSchema,
  updateDatasetDrilldownColConfig,
  updateDatasetDrilldownColConfigs,
} from 'actions/datasetActions';
import {
  updateDashboardEmailLayout,
  updateDashboardEmailText,
  updateDashboardLayout,
  updateDashboardMobileLayout,
  updateDashboardPdfLayout,
} from 'actions/layoutActions';
import {
  BASE_CONFIG_BY_DASH_ELEM,
  DEFAULT_DATA_PANEL_HEIGHT,
  DEFAULT_DATA_PANEL_WIDTH,
  DRAGGING_ITEM_CONFIG_BY_TYPE,
  EMPTY_DATA_PANEL_STATE,
  EMPTY_FILTER_OP_STATE,
  EMPTY_GROUP_BY_OP_STATE,
  EMPTY_VISUALIZATION_INSTRUCTIONS,
} from 'constants/dashboardConstants';
import { V2_VIZ_INSTRUCTION_TYPE } from 'constants/dataConstants';
import { EMPTY_FILTER_CLAUSE } from 'constants/dataPanelEditorConstants';
import {
  BAR_CHART_TYPES,
  COLUMN_FITS,
  ConditionalFilterConfig,
  GROUPED_STACKED_OPERATION_TYPES,
  OPERATION_TYPES,
  V2BoxPlotInstructions,
  V2KPIChartInstructions,
  V2KPITrendInstructions,
  V2ScatterPlotInstructions,
  V2TwoDimensionChartInstructions,
  VisualizeCollapsibleListInstructions,
  VisualizeGeospatialChartInstructions,
  VisualizePivotTableInstructions,
  VisualizeTableInstructions,
} from 'constants/types';
import * as RD from 'remotedata';
import {
  ContainerElemConfig,
  DASHBOARD_ELEMENT_TYPES,
  DASHBOARD_LAYOUT_CONFIG,
  DashboardElement,
  DashboardElementConfig,
  DashboardVersionHierarchy,
  VIEW_MODE,
} from 'types/dashboardTypes';
import {
  DashboardParam,
  DashboardStickyHeaderConfig,
  DashboardVersionConfig,
  EditableSectionChart,
  EditableSectionConfig,
  EditableSectionSettings,
} from 'types/dashboardVersionConfig';
import { DataPanelTemplate } from 'types/dataPanelTemplate';
import { PivotAgg, TEXT_TREND_PERIOD_COMPARISON_RANGE_TYPES } from 'types/dateRangeTypes';
import { VersionInfo } from 'types/exploResource';
import {
  CustomerPermissionsForObject,
  initCustomerPermissionsForObject,
} from 'types/permissionTypes';
import { saveResourceConfig } from 'utils/customEventUtils';
import {
  createDashboardItemId,
  getLayoutFromDashboardVersionConfig,
  newOperatorDoesntHaveVariableOption,
  newOperatorShouldClearSelectedVariable,
  removeElemFromStickyHeader,
  updateUserInputFieldsWithDeletedElem,
  updateUserInputFieldsWithNewElemName,
} from 'utils/dashboardUtils';
import { fetchDatasetSuccessDrilldownConfig, initConfig } from 'utils/drilldownDatasetUtils';
import { isChartInstanceOfTemplate } from 'utils/editableSectionUtils';
import {
  getEmbeddoResponseFromFidoResponse,
  getEmbeddoSchemaFromFidoSchema,
} from 'utils/fido/fidoShims';
import { removeDataPanelsFromLinks, removeDatasetFromLinks } from 'utils/filterLinking';
import { isValidOperationForFilter } from 'utils/filterOperations';
import {
  addElementToLayout,
  getElementsById,
  removeElementsFromLayoutById,
} from 'utils/layoutResolverUtil';
import * as layoutUtils from 'utils/layoutUtils';
import * as namingUtils from 'utils/naming';
import { cloneDeep, partition, pickBy, uniqBy } from 'utils/standard';

import {
  clearSelectedDashboardItem,
  setSelectedDashboardItem,
} from './dashboardInteractionsReducer';
import {
  fetchEditorDatasetPreviewPrimaryData,
  fetchEditorDatasetPreviewRowCount,
} from './thunks/dashboardDataThunks/fetchDatasetPreviewThunks';
import { fetchFidoViewPreview } from './thunks/dashboardDataThunks/fetchFidoDataThunks';
import { createComputedView, saveComputedView } from './thunks/fidoThunks';
import { saveExploreDraft } from './thunks/resourceSaveThunks';
import { revertResourceToVersionThunk } from './thunks/versionManagementThunks';
import { clearEmptyPanels } from './utils';

export const DRILLDOWN_DATA_PANEL_ID = '_drilldown_data_panel';

type AddEditableSectionChartPayload = {
  id: string;
  name: string;
  datasetId: string;
  vizType: OPERATION_TYPES;
};

type EditableSectionModal =
  | { type: 'AddChart' }
  | { type: 'DeleteChart'; chartId: string }
  | { type: 'EditChartInfo'; chartId: string }
  | { type: 'EditChartPermissions'; chartId: string }
  | null;

interface DashboardEditConfigReducerState {
  // TODO(zifanxiang): Remove the config and versionInfo once drilldowns is fully rolled out. The
  // state here should purely depend on the versionHierarchy and the currently selected dashboard.
  config?: DashboardVersionConfig;
  versionInfo?: VersionInfo;
  editableSectionModal: EditableSectionModal;
  editingDataPanelId: string | null;
  editableSectionLayout: CustomerEditableSectionLayout[] | null;
  // Used for dataset editor
  datasetData: DatasetDataObject;
  loadingDatasetId: string | null;
  // Used to control whether dataset editor is open
  selectedDatasetId: string | null;
  versionHierarchy: RD.ResponseData<DashboardVersionHierarchy>;
  currentDashboardId: number | null;
}

const initialState: DashboardEditConfigReducerState = {
  editableSectionModal: null,
  editingDataPanelId: null,
  editableSectionLayout: null,
  datasetData: {},
  loadingDatasetId: null,
  selectedDatasetId: null,
  versionHierarchy: RD.Idle(),
  currentDashboardId: null,
};

function actionWithEditingDPT(
  {
    config,
    editingDataPanelId,
    currentDashboardId,
    versionHierarchy,
  }: DashboardEditConfigReducerState,
  actionFn: (editingDPT: DataPanelTemplate) => void,
) {
  if (!config || !editingDataPanelId || !currentDashboardId) return;

  const dpToEdit =
    config.data_panels[editingDataPanelId] ??
    config.editable_section?.charts[editingDataPanelId]?.data_panel;

  if (!dpToEdit) return;
  actionFn(dpToEdit);

  if (RD.isSuccess(versionHierarchy)) {
    const currentDashboardVersionConfig =
      versionHierarchy.data.dashboardVersions[currentDashboardId].configuration;
    const dpToEditInHierarchy = currentDashboardVersionConfig.data_panels[editingDataPanelId];
    if (dpToEditInHierarchy) {
      actionFn(dpToEditInHierarchy);
    }
  }
  dashboardUpdated(currentDashboardId);
}

const updateDatasetData = (
  state: DashboardEditConfigReducerState,
  id: string,
  func: (data: DatasetData) => void,
) => {
  const datasetData = state.datasetData[id];

  if (datasetData) func(datasetData);
};

const getCurrentDashboardVersionConfigFromHierarchy = (
  state: DashboardEditConfigReducerState,
): DashboardVersionConfig | null => {
  if (!RD.isSuccess(state.versionHierarchy) || !state.currentDashboardId) {
    return null;
  }

  return state.versionHierarchy.data.dashboardVersions[state.currentDashboardId].configuration;
};

const getRootDashboardVersionConfigFromHierarchy = (
  state: DashboardEditConfigReducerState,
): DashboardVersionConfig | null => {
  if (!RD.isSuccess(state.versionHierarchy)) {
    return null;
  }

  return state.versionHierarchy.data.dashboardVersions[state.versionHierarchy.data.rootDashboardId]
    .configuration;
};

const toggleStickyHeaderForConfig = (config: DashboardVersionConfig, dashboardId: number) => {
  if (!config.dashboard_page_layout_config) {
    config.dashboard_page_layout_config = {};
  }

  const hasStickyHeader = !!config.dashboard_page_layout_config.stickyHeader;

  const stickyHeader = config.dashboard_page_layout_config.stickyHeader ?? {};
  if (stickyHeader.enabled === undefined) stickyHeader.enabledExpandableFilterRow = true;
  stickyHeader.enabled = !stickyHeader.enabled;

  if (!hasStickyHeader) {
    const exportId = createDashboardItemId(dashboardId);

    const exportButtonConfig = {
      id: exportId,
      name: namingUtils.getDefaultElementName(DASHBOARD_ELEMENT_TYPES.EXPORT, config),
      element_type: DASHBOARD_ELEMENT_TYPES.EXPORT,
      config: { ...BASE_CONFIG_BY_DASH_ELEM[DASHBOARD_ELEMENT_TYPES.EXPORT] },
      elemLocation: DASHBOARD_LAYOUT_CONFIG.HEADER,
    };
    config.elements[exportId] = exportButtonConfig;
    stickyHeader.headerContentOrder = [exportId];
  }
  config.dashboard_page_layout_config.stickyHeader = stickyHeader;
};

/**
 * Updates the configuration on the root dashboard if its exists (if drilldowns is enabled and
 * we're sending down the serialized dashboard version hierarchy) or falls back to updating the
 * current dashboard configuration.
 * @param state
 * @param updateRootDashboardConfigFn Function that takes in the dashboardVersionHierarchy and
 *    updates the configuration of the parent accordingly.
 * @param updateCurrentDashboardConfigFn Function that takes in the current dashboard configuration
 *    and updates accordingly.
 */
const updateRootDashboardConfigIfExistsOrCurrentDashboard = (
  state: DashboardEditConfigReducerState,
  updateRootDashboardConfigFn: (dashboardVersionHierarchy: DashboardVersionHierarchy) => void,
  updateCurrentDashboardConfigFn: (config: DashboardVersionConfig) => void,
) => {
  let dashboardToUpdate = state.currentDashboardId;
  if (RD.isSuccess(state.versionHierarchy)) {
    const versionHierarchy = state.versionHierarchy.data;
    dashboardToUpdate = versionHierarchy.rootDashboardId;
    updateRootDashboardConfigFn(versionHierarchy);
  } else if (state.config) {
    updateCurrentDashboardConfigFn(state.config);
  }
  dashboardUpdated(dashboardToUpdate as number);
};

const dashboardEditConfigSlice = createSlice({
  name: 'dashboardEditConfig',
  initialState,
  reducers: {
    updateDataPanelProvidedId: (state, { payload }: PayloadAction<string>) => {
      actionWithEditingDPT(state, (dp) => (dp.provided_id = payload));
    },
    updateDataPanelDataset: (state, { payload }: PayloadAction<string>) => {
      actionWithEditingDPT(state, (dp) => {
        dp.table_id = payload;
        dp.filter_op = EMPTY_FILTER_OP_STATE();
        dp.group_by_op = EMPTY_GROUP_BY_OP_STATE();
        dp.visualize_op.instructions = EMPTY_VISUALIZATION_INSTRUCTIONS();
      });
    },
    setDashboardParam: (state, { payload }: PayloadAction<DashboardParam>) => {
      if (!state.config || !state.currentDashboardId) return;
      if (!state.config.params) state.config.params = {};
      state.config.params[payload.id] = payload;
      dashboardUpdated(state.currentDashboardId);
    },
    deleteDashboardParam: (state, { payload }: PayloadAction<string>) => {
      if (!state.config?.params[payload] || !state.currentDashboardId) return;
      delete state.config.params[payload];
      dashboardUpdated(state.currentDashboardId);
    },
    setLoadingDatasetId: (state, { payload }: PayloadAction<string | null>) => {
      state.loadingDatasetId = payload;
    },
    updateDashboardCategoryColors: (state, { payload }: PayloadAction<Record<string, string>>) => {
      if (!state.config || !state.currentDashboardId) return;
      state.config.category_colors = payload;
      dashboardUpdated(state.currentDashboardId);
    },
    updateStickyHeaderElementOrder: (state, { payload }: PayloadAction<string[]>) => {
      if (!state.config?.dashboard_page_layout_config?.stickyHeader || !state.currentDashboardId)
        return;

      const rootDashboardVersionConfiguration = getRootDashboardVersionConfigFromHierarchy(state);
      if (rootDashboardVersionConfiguration?.dashboard_page_layout_config?.stickyHeader) {
        rootDashboardVersionConfiguration.dashboard_page_layout_config.stickyHeader.headerContentOrder =
          payload;
        const rootDashboardId = RD.isSuccess(state.versionHierarchy)
          ? state.versionHierarchy.data.rootDashboardId
          : null;
        if (rootDashboardId) {
          dashboardUpdated(rootDashboardId);
        }
      } else {
        state.config.dashboard_page_layout_config.stickyHeader.headerContentOrder = payload;
        dashboardUpdated(state.currentDashboardId);
      }
    },
    toggleStickyHeader: (state, { payload: dashboardId }: PayloadAction<number>) => {
      if (!state.config || !state.currentDashboardId) return;

      if (RD.isSuccess(state.versionHierarchy)) {
        const rootDashboardId = state.versionHierarchy.data.rootDashboardId;
        toggleStickyHeaderForConfig(
          state.versionHierarchy.data.dashboardVersions[rootDashboardId].configuration,
          rootDashboardId,
        );
        dashboardUpdated(rootDashboardId);
      } else {
        toggleStickyHeaderForConfig(state.config, dashboardId);
        dashboardUpdated(state.currentDashboardId);
      }
    },
    updateStickyHeader: (state, { payload }: PayloadAction<DashboardStickyHeaderConfig>) => {
      if (!state.config?.dashboard_page_layout_config || !state.currentDashboardId) return;

      const rootDashboardVersionConfiguration = getRootDashboardVersionConfigFromHierarchy(state);
      if (rootDashboardVersionConfiguration?.dashboard_page_layout_config) {
        rootDashboardVersionConfiguration.dashboard_page_layout_config.stickyHeader = payload;
        const rootDashboardId = RD.isSuccess(state.versionHierarchy)
          ? state.versionHierarchy.data.rootDashboardId
          : null;
        if (rootDashboardId) {
          dashboardUpdated(rootDashboardId);
        }
      } else {
        state.config.dashboard_page_layout_config.stickyHeader = payload;
        dashboardUpdated(state.currentDashboardId);
      }
    },
    toggleEditableSectionEditing: (state) => {
      if (!state.config || !state.currentDashboardId) return;
      if (!state.config.editable_section) {
        state.config.editable_section = {
          enabled: true,
          charts: {},
          default_layout: [],
          settings: { title: 'Your Overview' },
        };
      } else {
        state.config.editable_section.enabled = !state.config.editable_section.enabled;
      }

      dashboardUpdated(state.currentDashboardId);
    },
    setEditableSectionModal: (state, { payload }: PayloadAction<EditableSectionModal>) => {
      state.editableSectionModal = payload;
    },
    addEditableSectionChart: (
      state,
      { payload }: PayloadAction<AddEditableSectionChartPayload>,
    ) => {
      if (!state.config?.editable_section || !state.currentDashboardId) return;

      const newDataPanel = EMPTY_DATA_PANEL_STATE(
        payload.id,
        payload.datasetId,
        payload.vizType,
        namingUtils.getDefaultPanelProvidedId(payload.vizType, state.config),
        payload.name,
      );

      state.config.editable_section.charts[payload.id] = {
        name: payload.name,
        permissions: initCustomerPermissionsForObject(),
        data_panel: newDataPanel,
      };
      state.editableSectionModal = null;
      state.editingDataPanelId = payload.id;

      dashboardUpdated(state.currentDashboardId);
    },
    deleteEditableSectionChart: (state) => {
      const editableSection = state.config?.editable_section;
      if (
        !state.currentDashboardId ||
        !editableSection ||
        state.editableSectionModal?.type !== 'DeleteChart'
      )
        return;

      const chartId = state.editableSectionModal.chartId;
      const chart = editableSection.charts[chartId];
      if (!chart) return;

      editableSection.default_layout = editableSection.default_layout.filter(
        (chartLayout) => !isChartInstanceOfTemplate(chartLayout, chart),
      );

      if (state.editingDataPanelId === chartId) state.editingDataPanelId = null;
      state.editableSectionModal = null;
      delete editableSection.charts[chartId];

      dashboardUpdated(state.currentDashboardId);
    },
    duplicateEditableSectionChart: (
      state,
      { payload }: PayloadAction<{ chartId: string; newId: string }>,
    ) => {
      const config = state.config;
      const editableSectionConfig = config?.editable_section;
      if (!config || !editableSectionConfig || !state.currentDashboardId) {
        return;
      }

      const chart = getEditableSectionChart(editableSectionConfig, payload.chartId);
      const newDp = createDataPanelFromEditableSectionChart(config, chart, payload.newId);
      editableSectionConfig.charts[payload.newId] = {
        name: `${chart.name} (Copy)`,
        data_panel: newDp,
        permissions: chart.permissions,
      };

      dashboardUpdated(state.currentDashboardId);
    },
    duplicateEditableSectionChartToMainSection: (
      state: DashboardEditConfigReducerState,
      { payload }: PayloadAction<{ chartId: string; newId: string }>,
    ) => {
      const config = state.config;
      const editableSectionConfig = config?.editable_section;
      if (!config || !editableSectionConfig || !state.currentDashboardId) {
        return;
      }

      const newDp = createDataPanelFromEditableSectionChart(
        config,
        getEditableSectionChart(editableSectionConfig, payload.chartId),
        payload.newId,
      );
      const sourceChartLayout = editableSectionConfig.default_layout.find(
        (layout) => layout.i === payload.chartId,
      );
      const dashboardLayoutBottomY = layoutUtils.getLayoutBottomY(config.dashboard_layout);
      const copyChartLayout: Layout = {
        x: 0,
        y: dashboardLayoutBottomY,
        w: sourceChartLayout ? sourceChartLayout.w : DEFAULT_DATA_PANEL_WIDTH,
        h: sourceChartLayout ? sourceChartLayout.h : DEFAULT_DATA_PANEL_HEIGHT,
        i: payload.newId,
      };
      config.dashboard_layout.push(copyChartLayout);
      config.data_panels[payload.newId] = newDp;

      dashboardUpdated(state.currentDashboardId);
    },
    duplicateDataPanelToEditableSection: (
      state: DashboardEditConfigReducerState,
      { payload }: PayloadAction<{ dataPanelId: string; newId: string }>,
    ) => {
      const config = state.config;
      const sourceDataPanel = config?.data_panels[payload.dataPanelId];
      if (!config || !sourceDataPanel || !state.currentDashboardId) {
        throw new Error('The specified data panel does not exist');
      }

      const editableSectionConfig = config.editable_section;
      if (!editableSectionConfig || !editableSectionConfig.enabled) {
        throw new Error('There is no editable section or the editable section is disabled');
      }

      const newDp = {
        ...sourceDataPanel,
        id: payload.newId,
      };
      const newChartName =
        sourceDataPanel.visualize_op.generalFormatOptions?.headerConfig?.title ||
        sourceDataPanel.provided_id;
      editableSectionConfig.charts[payload.newId] = {
        name: newChartName,
        data_panel: newDp,
        permissions: initCustomerPermissionsForObject(),
      };

      dashboardUpdated(state.currentDashboardId);
    },
    updateEditableSectionChartInfo: (
      state,
      // Provided id is not passed in when editing through format tab
      { payload }: PayloadAction<{ name: string; providedId?: string }>,
    ) => {
      if (!state.currentDashboardId) {
        return;
      }
      let chartId: string;
      if (payload.providedId) {
        if (state.editableSectionModal?.type !== 'EditChartInfo') return;
        chartId = state.editableSectionModal.chartId;
      } else {
        if (!state.editingDataPanelId) return;
        chartId = state.editingDataPanelId;
      }

      const chart = state.config?.editable_section?.charts[chartId];
      if (!chart) return;

      chart.name = payload.name;

      if (payload.providedId) chart.data_panel.provided_id = payload.providedId;
      state.editableSectionModal = null;

      // All new charts will have this set to true, so we need to update the default
      if (chart.data_panel.visualize_op.generalFormatOptions?.headerConfig?.title !== undefined)
        chart.data_panel.visualize_op.generalFormatOptions.headerConfig.title = payload.name;

      dashboardUpdated(state.currentDashboardId);
    },
    updateEditableSectionChartPermissions: (
      state,
      { payload }: PayloadAction<CustomerPermissionsForObject>,
    ) => {
      if (
        !state.config?.editable_section ||
        state.editableSectionModal?.type !== 'EditChartPermissions' ||
        !state.currentDashboardId
      )
        return;

      const chartId = state.editableSectionModal.chartId;
      const chart = state.config.editable_section.charts[chartId];
      if (!chart) return;

      state.editableSectionModal = null;

      // If chart gets removed from all customers need to remove from default layout
      if (chart.permissions.allCustomers && !payload.allCustomers) {
        state.config.editable_section.default_layout =
          state.config.editable_section.default_layout.filter((elem) => elem.i !== chartId);
      }

      chart.permissions = payload;
      dashboardUpdated(state.currentDashboardId);
    },
    setEditableSectionLayout: (
      state,
      { payload }: PayloadAction<{ layout: CustomerEditableSectionLayout[]; isPreview: boolean }>,
    ) => {
      if (payload.isPreview) {
        state.editableSectionLayout = payload.layout;
        return;
      }

      if (!state.config?.editable_section || !state.currentDashboardId) return;
      state.config.editable_section.default_layout = payload.layout;
      state.editableSectionLayout = null;
      dashboardUpdated(state.currentDashboardId);
    },
    updateEditableSectionSettings: (
      state,
      { payload }: PayloadAction<Partial<EditableSectionSettings>>,
    ) => {
      if (!state.config?.editable_section || !state.currentDashboardId) return;
      state.config.editable_section.settings = {
        ...state.config.editable_section.settings,
        ...payload,
      };
      dashboardUpdated(state.currentDashboardId);
    },
    updateDatasetDrilldownConfig: (
      state,
      { payload }: PayloadAction<{ id: string; updates: Partial<DrilldownConfig> }>,
    ) => {
      if (!state.currentDashboardId) {
        return;
      }

      const dataset = state.config?.datasets[payload.id];
      if (!dataset || state.currentDashboardId) return;
      dataset.drilldownConfig = { ...dataset.drilldownConfig, ...payload.updates };
      dashboardUpdated(state.currentDashboardId);
    },
    updateFilterConditionalConfig: (
      state,
      { payload }: PayloadAction<{ idx: number; config: ConditionalFilterConfig }>,
    ) => {
      actionWithEditingDPT(state, (editingDPT) => {
        editingDPT.filter_op.instructions.filterClauses[payload.idx].conditionalFilterConfig =
          payload.config;
      });
    },
    setSelectedDatasetId: (state, { payload }: PayloadAction<string | null>) => {
      state.selectedDatasetId = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setSelectedDashboardItem, (state, { payload }) => {
        if (!state.config) return;
        if (payload.type && payload.type !== DASHBOARD_ELEMENT_TYPES.DATA_PANEL) return;
        state.editingDataPanelId = payload.id;
      })
      .addCase(clearSelectedDashboardItem, (state) => {
        state.editingDataPanelId = null;
      })
      .addCase(switchCurrentlyEditingDashboardVersion, (state, { payload }) => {
        const { dashboardVersion } = payload;
        if (dashboardVersion.version_number === state.versionInfo?.version_number) return;
        state.config = dashboardVersion.configuration;
        state.versionInfo = {
          is_draft: dashboardVersion.is_draft,
          version_number: dashboardVersion.version_number,
          edit_version_number: dashboardVersion.edit_version_number,
          change_comments: dashboardVersion.change_comments,
        };
      })
      .addCase(revertResourceToVersionThunk.fulfilled, (state, { payload, meta }) => {
        const { new_version } = payload;

        if (!meta.arg.isExplore || !('configuration' in new_version)) return;

        state.config = new_version.configuration;
        state.versionInfo = {
          is_draft: new_version.is_draft,
          version_number: new_version.version_number,
          edit_version_number: new_version.edit_version_number,
          change_comments: new_version.change_comments,
        };

        if (payload?.dashboard_version_hierarchy) {
          RD.update(state.versionHierarchy, (data) => {
            data.dashboardVersions = {
              ...payload.dashboard_version_hierarchy,
            };
          });
        }
      })
      .addCase(publishNewDashboardVersionSuccess, (state, { payload }) => {
        const { dashboard_version } = payload;
        state.versionInfo = {
          is_draft: dashboard_version.is_draft,
          version_number: dashboard_version.version_number,
          edit_version_number: dashboard_version.edit_version_number,
          change_comments: dashboard_version.change_comments,
        };
      })
      .addCase(saveExploreDraft.fulfilled, (state, { payload }) => {
        if (state.versionInfo) state.versionInfo.edit_version_number = payload.edit_version_number;
        if (RD.isSuccess(state.versionHierarchy)) {
          const dashboardId = payload.resource_id;
          const dashboardVersion = state.versionHierarchy.data.dashboardVersions[dashboardId];
          dashboardVersion.edit_version_number = payload.edit_version_number;
        }
      })
      // Data Panel Reducers
      .addCase(clearDashboardConfigReducer, () => {
        return initialState;
      })
      .addCase(fetchDashboardRequest, () => {
        return initialState;
      })
      .addCase(fetchDashboardSuccess, (state, { payload }) => {
        state.config = payload.dashboard_version.configuration;
        if (state.config) clearEmptyPanels(state.config);

        state.currentDashboardId = payload.dashboard.id;
        state.versionInfo = {
          is_draft: payload.dashboard_version.is_draft,
          version_number: payload.dashboard_version.version_number,
          edit_version_number: payload.dashboard_version.edit_version_number,
          change_comments: payload.dashboard_version.change_comments,
        };
        if (payload.dashboard_version_hierarchy && payload.dashboard_hierarchy) {
          state.versionHierarchy = RD.Success({
            dashboardVersions: {
              ...payload.dashboard_version_hierarchy,
            },
            rootDashboardId: parseInt(payload.dashboard_hierarchy.root_dashboard_id),
          });
        }
      })
      .addCase(createDashboardDataPanel, (state, { payload }) => {
        if (!state.config || !state.currentDashboardId) return;

        const newDataPanel = EMPTY_DATA_PANEL_STATE(
          payload.id,
          payload.datasetId,
          payload.vizType,
          namingUtils.getDefaultPanelProvidedId(payload.vizType, state.config),
          payload.name,
          payload.containerId,
        );
        state.config.data_panels[newDataPanel.id] = newDataPanel;
        state.editingDataPanelId = payload.id;
        layoutUtils.addItemToConfigLayouts(state.config, cloneDeep(payload), newDataPanel.id);

        const currentDashboardVersionConfig = getCurrentDashboardVersionConfigFromHierarchy(state);
        if (currentDashboardVersionConfig) {
          currentDashboardVersionConfig.data_panels[newDataPanel.id] = newDataPanel;
          layoutUtils.addItemToConfigLayouts(
            currentDashboardVersionConfig,
            cloneDeep(payload),
            newDataPanel.id,
          );
        }

        dashboardUpdated(state.currentDashboardId);
      })
      .addCase(duplicateDashboardItem, (state, { payload }) => {
        if (!state.config || !state.currentDashboardId) return;

        const { dashboardItem, itemType, dashId } = payload;
        const config = state.config;
        if ((dashboardItem as DashboardElement)?.elemLocation === DASHBOARD_LAYOUT_CONFIG.HEADER) {
          // duplicating a sticky header element follows a different code path because it doesn't use a ReactGridLayout
          duplicateStickyHeaderElement(
            config,
            dashboardItem as DashboardElement,
            dashId,
            itemType,
            state.currentDashboardId,
          );
          return;
        }
        // either the whole dashboard or the container that holds this element
        let layout = config.dashboard_layout;

        if (dashboardItem.container_id) {
          const containerConfig = config.elements[dashboardItem.container_id]
            .config as ContainerElemConfig;

          // if we're in a container, then we want to be editing the container's layout
          layout = containerConfig.layout;
        }

        const elem = layout.find((item) => item.i === dashboardItem.id);
        if (!elem) return;

        const newElementConfig = cloneDeep(dashboardItem);

        const newElemId = layoutUtils.placeDuplicatedElementInLayout({
          newElementLayout: cloneDeep(elem),
          newElementConfig,
          layout: cloneDeep(layout),
          config,
          // place the cloned item at the bottom of the column that contains the parent element
          yStart: elem.y + elem.h - 1,
          dashId,
        });

        if (itemType === DASHBOARD_ELEMENT_TYPES.DATA_PANEL) {
          const dataPanel = dashboardItem as DataPanelTemplate;
          Object.values(config.elements).forEach(({ config }) => {
            const dataPanelsLinked = config.datasetLinks?.[dataPanel.table_id]?.dataPanels;
            if (dataPanelsLinked?.includes(dataPanel.id)) {
              dataPanelsLinked.push(newElemId);
            }
          });
        }

        if (itemType === DASHBOARD_ELEMENT_TYPES.CONTAINER) {
          // for container elements, we have to clone all of the contained elements
          const newContainerConfig = (newElementConfig as DashboardElement)
            .config as ContainerElemConfig;
          // clone this so we can iterate over it
          const oldLayout = cloneDeep(newContainerConfig.layout);
          // but clear the layout so that we can add to it fresh
          newContainerConfig.layout = [];

          oldLayout.forEach((newContainerElementLayout) => {
            // we have to clone deep here so that only the duplicated panel gets the new container_id below
            const newContainerElementConfig = cloneDeep(
              newContainerElementLayout.i in config.data_panels
                ? config.data_panels[newContainerElementLayout.i]
                : config.elements[newContainerElementLayout.i],
            );

            // we have to manually swap the container id over to our new duped container
            newContainerElementConfig.container_id = newElementConfig.id;
            layoutUtils.placeDuplicatedElementInLayout({
              newElementLayout: newContainerElementLayout,
              newElementConfig: newContainerElementConfig,
              layout: newContainerConfig.layout,
              config,
              yStart: newContainerElementLayout.y,
              dashId: dashId,
            });
          });
        }
        dashboardUpdated(state.currentDashboardId);
      })
      // Dataset Editor Reducers
      .addCase(fetchEditorDatasetPreviewPrimaryData.pending, (state, { meta }) => {
        const datasetData = state.datasetData[meta.arg.postData.dataset_id];

        if (!datasetData) {
          state.datasetData[meta.arg.postData.dataset_id] = { loading: true };
        } else {
          state.datasetData[meta.arg.postData.dataset_id] = {
            loading: true,
            totalRowCount: datasetData.totalRowCount,
          };
        }
      })
      .addCase(fetchEditorDatasetPreviewRequest, (state, { payload }) => {
        const datasetData = state.datasetData[payload.postData.dataset_id];

        if (!datasetData) {
          state.datasetData[payload.postData.dataset_id] = { loading: true };
        } else {
          state.datasetData[payload.postData.dataset_id] = {
            loading: true,
            totalRowCount: datasetData.totalRowCount,
          };
        }
      })
      .addCase(fetchEditorDatasetPreviewPrimaryData.fulfilled, (state, { meta, payload }) => {
        if (!state.currentDashboardId) {
          return;
        }

        const datasetId = meta.arg.postData.dataset_id;
        const dataset = state.config?.datasets[datasetId];
        if (!dataset) return;
        dataset.drilldownColumnConfigs = fetchDatasetSuccessDrilldownConfig(
          dataset,
          payload.dataset_preview.schema,
        );

        updateDatasetData(state, datasetId, (data) => {
          data.schema = payload.dataset_preview.schema;
          data.rows = payload.dataset_preview._rows;
          data.queryInformation = payload.query_information;
          data.error = undefined;
          data.loading = false;
          data.unsupportedOperations = payload.dataset_preview._unsupported_operations;
        });

        // Need to call in case drilldownColumnConfigs changes
        dashboardUpdated(state.currentDashboardId);
      })
      .addCase(fetchEditorDatasetPreviewSuccess, (state, { payload }) => {
        if (!state.currentDashboardId) {
          return;
        }

        const datasetId = payload.postData.dataset_id;

        const dataset =
          state.config?.datasets[datasetId] ??
          getDatasetFromFidoId(state.config?.datasets, payload.postData.dataset_id);
        if (!dataset) return;
        dataset.drilldownColumnConfigs = fetchDatasetSuccessDrilldownConfig(
          dataset,
          payload.dataset_preview.schema,
        );

        updateDatasetData(state, datasetId, (data) => {
          data.schema = payload.dataset_preview.schema;
          data.rows = payload.dataset_preview._rows;
          data.queryInformation = payload.query_information;
          data.error = undefined;
          data.loading = false;
          data.unsupportedOperations = payload.dataset_preview._unsupported_operations;
        });

        // Need to call in case drilldownColumnConfigs changes
        dashboardUpdated(state.currentDashboardId);
      })
      .addCase(fetchEditorDatasetPreviewPrimaryData.rejected, (state, { error, meta, payload }) => {
        const datasetId = meta.arg.postData.dataset_id;

        updateDatasetData(state, datasetId, (data) => {
          data.error = error.message ?? 'Internal Error';
          data.loading = false;
          data.queryInformation = payload?.query_information;
        });
      })
      .addCase(fetchEditorDatasetPreviewError, (state, { payload }) => {
        const datasetId = payload.postData.dataset_id;

        updateDatasetData(state, datasetId, (data) => {
          data.error = payload.error_msg ?? 'Internal Error';
          data.loading = false;
          data.queryInformation = payload.query_information;
        });
      })
      .addCase(fetchFidoViewPreview.pending, (state, { meta }) => {
        const dataset = getDatasetFromFidoId(state.config?.datasets, meta.arg.view.id);
        if (!dataset) return;
        state.datasetData[dataset.id] = { loading: true };
      })
      .addCase(fetchFidoViewPreview.fulfilled, (state, { payload, meta }) => {
        if (!state.currentDashboardId) {
          return;
        }

        const dataset = getDatasetFromFidoId(state.config?.datasets, meta.arg.view.id);
        if (!dataset) return;

        const { schema, totalResults, rows, queryInformation } =
          getEmbeddoResponseFromFidoResponse(payload);
        dataset.drilldownColumnConfigs = fetchDatasetSuccessDrilldownConfig(dataset, schema);

        updateDatasetData(state, dataset.id, (data) => {
          data.schema = schema;
          data.rows = rows;
          data.queryInformation = queryInformation;
          data.error = undefined;
          data.loading = false;
          data.unsupportedOperations = undefined;
          data.totalRowCount = totalResults ?? undefined;
        });

        dashboardUpdated(state.currentDashboardId);
      })
      .addCase(fetchFidoViewPreview.rejected, (state, { meta, error }) => {
        const dataset = getDatasetFromFidoId(state.config?.datasets, meta.arg.view.id);
        if (!dataset) return;
        updateDatasetData(state, dataset.id, (data) => {
          data.error = error.message ?? 'Something went wrong';
          data.loading = false;
        });
      })
      .addCase(fetchEditorDatasetPreviewRowCount.fulfilled, (state, { payload, meta }) => {
        updateDatasetData(state, meta.arg.postData.dataset_id, (data) => {
          data.totalRowCount = payload._total_row_count;
        });
      })
      .addCase(fetchEditorDatasetPreviewRowCount.rejected, (state, { meta }) => {
        updateDatasetData(state, meta.arg.postData.dataset_id, (data) => {
          data.totalRowCount = undefined;
        });
      })
      .addCase(fetchEditorDatasetRowCountSuccess, (state, { payload }) => {
        updateDatasetData(state, payload.postData.dataset_id, (data) => {
          data.totalRowCount = payload._total_row_count;
        });
      })
      .addCase(fetchEditorDatasetRowCountError, (state, { payload }) => {
        updateDatasetData(state, payload.postData.dataset_id, (data) => {
          data.totalRowCount = undefined;
        });
      })
      .addCase(saveDatasetQuery, (state, { payload }) => {
        if (!state.config || !state.currentDashboardId) return;

        updateRootDashboardConfigIfExistsOrCurrentDashboard(
          state,
          (dashboardVersionHierarchy) => {
            const datasetToUpdate =
              dashboardVersionHierarchy.dashboardVersions[dashboardVersionHierarchy.rootDashboardId]
                .configuration.datasets[payload.dataset_id];
            datasetToUpdate.query = payload.query;
            datasetToUpdate.queryDraft = undefined;
            datasetToUpdate.schema = payload.schema;
          },
          (config) => {
            const datasetToUpdate = config.datasets[payload.dataset_id];
            datasetToUpdate.query = payload.query;
            datasetToUpdate.queryDraft = undefined;
            datasetToUpdate.schema = payload.schema;
          },
        );
      })
      .addCase(saveDraftDatasetQuery, (state, { payload }) => {
        if (!state.config || !state.currentDashboardId) return;

        updateRootDashboardConfigIfExistsOrCurrentDashboard(
          state,
          (dashboardVersionHierarchy) => {
            dashboardVersionHierarchy.dashboardVersions[
              dashboardVersionHierarchy.rootDashboardId
            ].configuration.datasets[payload.dataset_id].queryDraft = payload.queryDraft;
          },
          (config) => {
            config.datasets[payload.dataset_id].queryDraft = payload.queryDraft;
          },
        );
      })
      .addCase(saveComputedView.fulfilled, (state, { meta }) => {
        if (!state.config || !state.currentDashboardId) return;

        const dataset = getDatasetFromFidoId(state.config.datasets, meta.arg.id);

        if (!dataset) return;

        // the actual saving of this is handled in the fidoReducer
        dataset.queryDraft = undefined;
        dataset.query = meta.arg.query;
        dataset.schema = getEmbeddoSchemaFromFidoSchema(meta.arg.columnDefinitions);

        // these are more conditionally saved
        dataset.table_name = meta.arg.name;
        dataset.namespace_id = meta.arg.namespaceId;
        dashboardUpdated(state.currentDashboardId);
        state.loadingDatasetId = null;
      })
      .addCase(saveDraftComputedViewQuery, (state, { payload }) => {
        if (!state.config || !state.config.datasets || !state.currentDashboardId) return;

        const dataset = state.config.datasets[payload.viewId];

        if (!dataset) return;

        dataset.queryDraft = payload.queryDraft;
        dashboardUpdated(state.currentDashboardId);
      })
      .addCase(createDashboardDataset, (state, { payload }) => {
        if (!state.config || !state.currentDashboardId) return;

        const newDataset: Dataset = {
          id: createDashboardItemId(payload.dashId),
          table_name: payload.name,
          parent_schema_id: payload.parentSchemaId,
          query: '',
          drilldownColumnConfigs: {},
          drilldownConfig: { columnFit: COLUMN_FITS.HEADER },
        };
        updateRootDashboardConfigIfExistsOrCurrentDashboard(
          state,
          (dashboardVersionHierarchy) => {
            dashboardVersionHierarchy.dashboardVersions[
              dashboardVersionHierarchy.rootDashboardId
            ].configuration.datasets[newDataset.id] = newDataset;
          },
          (config) => {
            config.datasets[newDataset.id] = newDataset;
          },
        );
        state.selectedDatasetId = newDataset.id;
      })
      .addCase(createComputedView.fulfilled, (state, { payload, meta }) => {
        if (!state.config || !state.currentDashboardId) return;

        const id = uuidv4();

        state.config.datasets[id] = {
          id: id,
          fido_id: payload.view.id ?? undefined,
          namespace_id: payload.view.namespaceId ?? '',
          table_name: payload.view.name,
          parent_schema_id: meta.arg.namespace?.id ?? -1,
          drilldownColumnConfigs: {},
          drilldownConfig: { columnFit: COLUMN_FITS.HEADER },
          query: '',
        };
        state.selectedDatasetId = id;
        dashboardUpdated(state.currentDashboardId);
      })
      .addCase(updateDashboardDatasetSchema, (state, { payload }) => {
        if (!state.config || !state.currentDashboardId) return;

        state.config.datasets[payload.datasetId].parent_schema_id = payload.newParentSchemaId;
        dashboardUpdated(state.currentDashboardId);
      })
      .addCase(deleteDataset, (state, { payload }) => {
        if (!state.config || !state.currentDashboardId) return;
        updateRootDashboardConfigIfExistsOrCurrentDashboard(
          state,
          (dashboardVersionHierarchy) => {
            const datasets =
              dashboardVersionHierarchy.dashboardVersions[dashboardVersionHierarchy.rootDashboardId]
                .configuration.datasets;
            delete datasets[payload.datasetId];
            const allDashboardElements = Object.entries(dashboardVersionHierarchy.dashboardVersions)
              .map(([, dashboardVersion]) => {
                return Object.values(dashboardVersion.configuration.elements);
              })
              .flat();
            removeDatasetFromLinks(payload.datasetId, allDashboardElements);
          },
          (config) => {
            delete config.datasets[payload.datasetId];
            removeDatasetFromLinks(payload.datasetId, Object.values(config.elements));
          },
        );
        delete state.config.datasets[payload.datasetId];

        dashboardUpdated(state.currentDashboardId);
        state.loadingDatasetId = null;
        state.selectedDatasetId = null;
      })
      .addCase(editDatasetName, (state, { payload }) => {
        if (!state.config || !state.currentDashboardId) return;
        state.config.datasets[payload.datasetId].table_name = payload.name;
        dashboardUpdated(state.currentDashboardId);
        state.loadingDatasetId = null;
      })
      .addCase(updateDatasetDrilldownColConfig, (state, { payload }) => {
        if (!state.config || !state.currentDashboardId) return;
        const dataset = state.config.datasets[payload.datasetId];
        if (!dataset.drilldownColumnConfigs) {
          dataset.drilldownColumnConfigs = {};
        }

        if (!dataset.drilldownColumnConfigs[payload.colName]) {
          if (payload.displayName === undefined || payload.index === undefined) return;
          dataset.drilldownColumnConfigs[payload.colName] = initConfig(
            payload.index,
            payload.displayName,
          );
        }

        const colConfig = dataset.drilldownColumnConfigs[payload.colName];

        if (payload.displayName !== undefined) colConfig.displayName = payload.displayName;
        if (payload.index !== undefined) colConfig.index = payload.index;
        if (payload.isIncluded !== undefined) colConfig.isIncluded = payload.isIncluded;
        if (payload.isVisible !== undefined) colConfig.isVisible = payload.isVisible;
        if (payload.displayFormatting !== undefined)
          colConfig.displayFormatting = payload.displayFormatting;

        dashboardUpdated(state.currentDashboardId);
      })
      .addCase(updateDatasetDrilldownColConfigs, (state, { payload }) => {
        if (!state.config || !state.currentDashboardId) return;

        state.config.datasets[payload.datasetId].drilldownColumnConfigs = payload.newConfigs;
        dashboardUpdated(state.currentDashboardId);
      })
      // Edit Dashboard Reducers
      .addCase(updateDashboardLayout, (state, { payload }) => {
        if (!state.config || !state.currentDashboardId) return;

        state.config.dashboard_layout = cloneDeep(payload);
        dashboardUpdated(state.currentDashboardId);
      })
      .addCase(updateDashboardPdfLayout, (state, { payload }) => {
        if (!state.config || !state.currentDashboardId) return;
        state.config.pdf_layout = cloneDeep(payload);
        dashboardUpdated(state.currentDashboardId);
      })
      .addCase(updateDashboardEmailLayout, (state, { payload }) => {
        if (!state.config || !state.currentDashboardId) return;
        state.config.email_layout = cloneDeep(payload);
        dashboardUpdated(state.currentDashboardId);
      })
      .addCase(updateDashboardMobileLayout, (state, { payload }) => {
        if (!state.config || !state.currentDashboardId) return;
        state.config.mobile_layout = cloneDeep(payload);
        dashboardUpdated(state.currentDashboardId);
      })
      .addCase(updateDashboardEmailText, (state, { payload }) => {
        if (!state.config || !state.currentDashboardId) return;

        if (payload.isHeader) {
          state.config.email_header_html = payload.html;
        } else {
          state.config.email_footer_html = payload.html;
        }
        dashboardUpdated(state.currentDashboardId);
      })
      .addCase(deleteDataPanel, (state, { payload }) => {
        if (!state.config || !state.currentDashboardId) return;
        delete state.config.data_panels[payload.id];
        layoutUtils.updateSecondaryLayoutsAfterMainLayoutChange(state.config);

        removeDataPanelsFromLinks([payload.id], state.config.elements);

        dashboardUpdated(state.currentDashboardId);
      })
      .addCase(createDashboardElement, (state, { payload }) => {
        if (!state.config || !state.currentDashboardId) return;
        const newElement = {
          id: payload.id,
          name: namingUtils.getDefaultElementName(payload.elementType, state.config),
          element_type: payload.elementType,
          config: { ...BASE_CONFIG_BY_DASH_ELEM[payload.elementType] },
          container_id: payload.containerId,
        };
        state.config.elements[newElement.id] = newElement;

        layoutUtils.addItemToConfigLayouts(state.config, cloneDeep(payload), newElement.id);

        if (RD.isSuccess(state.versionHierarchy)) {
          const versionHierarchy = state.versionHierarchy.data;
          versionHierarchy.dashboardVersions[state.currentDashboardId].configuration.elements[
            newElement.id
          ] = newElement;
          layoutUtils.addItemToConfigLayouts(
            versionHierarchy.dashboardVersions[state.currentDashboardId].configuration,
            cloneDeep(payload),
            newElement.id,
          );
        }
        dashboardUpdated(state.currentDashboardId);
      })
      .addCase(updateElementConfig, (state, { payload }) => {
        if (!state.currentDashboardId) {
          return;
        }

        const element = state.config?.elements[payload.elementId];
        if (!element) return;

        updateElement(element, payload.config, payload.newElementType);

        if (RD.isSuccess(state.versionHierarchy)) {
          const versionHierarchy = state.versionHierarchy.data;
          const elementToUpdate =
            versionHierarchy.dashboardVersions[state.currentDashboardId].configuration.elements[
              payload.elementId
            ];
          if (!elementToUpdate) {
            return;
          }
          updateElement(elementToUpdate, payload.config, payload.newElementType);
        }
        dashboardUpdated(state.currentDashboardId);
      })
      .addCase(deleteDashboardElement, (state, { payload }) => {
        if (!state.config || !state.currentDashboardId) return;
        const { elementType, elementId } = payload;

        deleteElement(state.config, elementId, elementType);
        if (RD.isSuccess(state.versionHierarchy)) {
          deleteElement(
            state.versionHierarchy.data.dashboardVersions[state.currentDashboardId].configuration,
            elementId,
            elementType,
          );
        }

        dashboardUpdated(state.currentDashboardId);
      })
      .addCase(saveDashboardElementUpdates, (state, { payload }) => {
        if (payload.name && state.config && state.currentDashboardId) {
          const oldName = state.config.elements[payload.id].name;
          state.config.elements[payload.id].name = cloneDeep(payload.name);
          updateUserInputFieldsWithNewElemName(state.config, oldName, payload.name);
          dashboardUpdated(state.currentDashboardId);
        }
      })
      .addCase(updateVisualizeOperationAction, (state, { payload }) => {
        actionWithEditingDPT(state, (editingDpt) => {
          updateVisualizeOperation_(editingDpt, payload);
        });
      })
      .addCase(updateSelectedChart, (state, { payload }) => {
        actionWithEditingDPT(state, (editingDpt) => {
          updateSelectedChart_(editingDpt, payload.to);
        });
      })
      .addCase(createFilterClause, (state, { payload }) => {
        actionWithEditingDPT(state, (editingDPT) => {
          if (payload) {
            const clause = cloneDeep(EMPTY_FILTER_CLAUSE);
            clause.filterColumn = payload;
            editingDPT.filter_op.instructions.filterClauses.push(clause);
          } else {
            editingDPT.filter_op.instructions.filterClauses.push(EMPTY_FILTER_CLAUSE);
          }
        });
      })
      .addCase(deleteFilterClause, (state, { payload }) => {
        actionWithEditingDPT(state, (editingDPT) => {
          editingDPT.filter_op.instructions.filterClauses.splice(payload, 1);
        });
      })
      .addCase(selectFilterColumn, (state, { payload }) => {
        actionWithEditingDPT(state, (editingDPT) => {
          const oldColumn =
            editingDPT.filter_op.instructions.filterClauses[payload.index].filterColumn;
          if (oldColumn && oldColumn.type !== payload.column.type) {
            editingDPT.filter_op.instructions.filterClauses[payload.index].filterOperation =
              undefined;
            editingDPT.filter_op.instructions.filterClauses[payload.index].filterValue = undefined;
          }
          editingDPT.filter_op.instructions.filterClauses[payload.index].filterColumn =
            payload.column;
        });
      })
      .addCase(selectFilterOperator, (state, { payload }) => {
        actionWithEditingDPT(state, (editingDPT) => {
          if (
            newOperatorShouldClearSelectedVariable(
              payload.operator,
              editingDPT.filter_op.instructions.filterClauses[payload.index].filterOperation?.id,
            )
          ) {
            editingDPT.filter_op.instructions.filterClauses[payload.index].filterValueVariableId =
              undefined;
            editingDPT.filter_op.instructions.filterClauses[
              payload.index
            ].filterValueVariableProperty = undefined;
          }

          if (newOperatorDoesntHaveVariableOption(payload.operator)) {
            editingDPT.filter_op.instructions.filterClauses[payload.index].filterValueSource =
              undefined;
          }

          editingDPT.filter_op.instructions.filterClauses[payload.index].filterOperation = {
            id: payload.operator,
          };
        });
      })
      .addCase(updateFilterValue, (state, { payload }) => {
        actionWithEditingDPT(state, (editingDPT) => {
          editingDPT.filter_op.instructions.filterClauses[payload.index].filterValue =
            payload.value;
        });
      })
      .addCase(updateFilterValueSource, (state, { payload }) => {
        actionWithEditingDPT(state, (editingDPT) => {
          editingDPT.filter_op.instructions.filterClauses[payload.index].filterValueSource =
            payload.newSource;
        });
      })
      .addCase(updateFilterValueVariable, (state, { payload }) => {
        actionWithEditingDPT(state, (editingDPT) => {
          const filterClause = editingDPT.filter_op.instructions.filterClauses[payload.index];
          if (!filterClause) return;
          filterClause.filterValueVariableId = payload.variableId;
          filterClause.filterValueVariableProperty = payload.property;
        });
      })
      .addCase(updateGeneralFormatOptions, (state, { payload }) => {
        actionWithEditingDPT(state, (editingDPT) => {
          editingDPT.visualize_op.generalFormatOptions = payload;
        });
      })
      .addCase(updateDrilldownDataPanel, (state, { payload }) => {
        if (!state.config) return;
        if (payload === undefined) {
          if (!(DRILLDOWN_DATA_PANEL_ID in state.config.data_panels)) return;
          delete state.config.data_panels[DRILLDOWN_DATA_PANEL_ID];
        } else {
          payload.dataPanel.id = DRILLDOWN_DATA_PANEL_ID;
          state.config.data_panels[DRILLDOWN_DATA_PANEL_ID] =
            payload.dataPanel as DataPanelTemplate;
        }
      })
      .addCase(toggleElementVisibilityForSecondaryLayout, (state, { payload }) => {
        if (!state.config) return;
        // Just safety checking to make sure we don't edit the main layout
        if (
          payload.layoutType !== VIEW_MODE.EMAIL &&
          payload.layoutType !== VIEW_MODE.PDF &&
          payload.layoutType !== VIEW_MODE.MOBILE
        )
          return;

        let newLayout = getLayoutFromDashboardVersionConfig(state.config, payload.layoutType);
        // For some reason some elements are duplicated in the layout, so we need to remove them
        // Should hopefully be able to remove after a bit
        newLayout = uniqBy(newLayout, (elem) => elem.i);

        const elem = newLayout.find((elem) => elem.i === payload.id);

        if (elem !== undefined) {
          newLayout = removeElementsFromLayoutById(newLayout, new Set([payload.id]));
        } else {
          const elementToAdd = getElementsById(
            state.config.dashboard_layout,
            new Set([payload.id]),
          );
          if (elementToAdd.length === 1) {
            newLayout = addElementToLayout(
              newLayout,
              elementToAdd[0],
              payload.layoutType === VIEW_MODE.MOBILE,
            );
          }
        }

        switch (payload.layoutType) {
          case VIEW_MODE.PDF:
            state.config.pdf_layout = newLayout;
            break;
          case VIEW_MODE.MOBILE:
            state.config.mobile_layout = newLayout;
            break;
          case VIEW_MODE.EMAIL:
            state.config.email_layout = newLayout;
            break;
        }
      })
      .addCase(toggleFilterLink, (state, { payload }) => {
        if (!state.currentDashboardId) {
          return;
        }

        const dp =
          state.config?.data_panels?.[payload.dataPanelId] ??
          state.config?.editable_section?.charts[payload.dataPanelId]?.data_panel;
        const elem = state.config?.elements?.[payload.elementId];
        if (!elem || !dp) return;

        const datasetLinks = elem.config.datasetLinks?.[dp.table_id];
        if (!datasetLinks) return;
        if (!datasetLinks.dataPanels) datasetLinks.dataPanels = [];

        const dpIdx = datasetLinks.dataPanels.findIndex((id) => id === dp.id);
        if (dpIdx === -1) {
          datasetLinks.dataPanels.push(dp.id);
        } else {
          datasetLinks.dataPanels.splice(dpIdx, 1);
        }
        dashboardUpdated(state.currentDashboardId);
      })
      .addCase(updateElementLocation, (state, { payload }) => {
        if (!state.config || !state.currentDashboardId) return;
        moveElementToLocation(state.config, payload.elementId, payload.newLocation);
        const currentDashboardConfiguration = getCurrentDashboardVersionConfigFromHierarchy(state);
        if (currentDashboardConfiguration) {
          moveElementToLocation(
            currentDashboardConfiguration,
            payload.elementId,
            payload.newLocation,
          );
        }

        dashboardUpdated(state.currentDashboardId);
      })
      .addCase(updateElementContainerLocation, (state, { payload }) => {
        if (!state.config || !state.currentDashboardId) return;

        const element: { id: string; container_id?: string } = payload.isDataPanel
          ? state.config.data_panels[payload.elementId]
          : state.config.elements[payload.elementId];

        if (payload.removeElem) {
          layoutUtils.moveElementFromContainerToBody(state.config, payload.containerId, element);
        } else {
          layoutUtils.moveElementIntoContainer(state.config, payload.containerId, element);
        }

        const currentDashboardConfiguration = getCurrentDashboardVersionConfigFromHierarchy(state);
        if (currentDashboardConfiguration) {
          const elementFromVersionHierarchy = payload.isDataPanel
            ? currentDashboardConfiguration.data_panels[payload.elementId]
            : currentDashboardConfiguration.elements[payload.elementId];
          if (payload.removeElem) {
            layoutUtils.moveElementFromContainerToBody(
              currentDashboardConfiguration,
              payload.containerId,
              elementFromVersionHierarchy,
            );
          } else {
            layoutUtils.moveElementIntoContainer(
              currentDashboardConfiguration,
              payload.containerId,
              elementFromVersionHierarchy,
            );
          }
        }

        dashboardUpdated(state.currentDashboardId);
      })
      .addCase(switchEditingDashboard, (state, { payload }) => {
        if (!RD.isSuccess(state.versionHierarchy)) return;

        const versionHierarchy = state.versionHierarchy.data;
        const newDashboardVersion = versionHierarchy.dashboardVersions[payload.dashboardId];
        if (!newDashboardVersion) {
          // This should not happen. This a safety check that we're not attempting to switch to a
          // non-existent version.
          return;
        }

        const rootDashboardVersion =
          versionHierarchy.dashboardVersions[versionHierarchy.rootDashboardId];
        if (!rootDashboardVersion) {
          // This should not happen, there must be a root version at all times.
          return;
        }

        state.config = newDashboardVersion.configuration;
        state.currentDashboardId = payload.dashboardId;
        state.versionInfo = {
          is_draft: newDashboardVersion.is_draft,
          version_number: newDashboardVersion.version_number,
          edit_version_number: newDashboardVersion.edit_version_number,
          change_comments: newDashboardVersion.change_comments,
        };
      });
  },
});

export const dashboardEditConfigReducer = dashboardEditConfigSlice.reducer;
export const {
  updateStickyHeaderElementOrder,
  toggleStickyHeader,
  updateStickyHeader,
  toggleEditableSectionEditing,
  addEditableSectionChart,
  duplicateEditableSectionChart,
  duplicateEditableSectionChartToMainSection,
  duplicateDataPanelToEditableSection,
  setEditableSectionModal,
  deleteEditableSectionChart,
  updateDataPanelProvidedId,
  updateDataPanelDataset,
  setEditableSectionLayout,
  updateEditableSectionChartInfo,
  updateEditableSectionChartPermissions,
  updateEditableSectionSettings,
  updateDatasetDrilldownConfig,
  updateDashboardCategoryColors,
  updateFilterConditionalConfig,
  setDashboardParam,
  deleteDashboardParam,
  setLoadingDatasetId,
  setSelectedDatasetId,
} = dashboardEditConfigSlice.actions;

function dashboardUpdated(dashboardId: number) {
  saveResourceConfig(dashboardId);
}

const updateVisualizeOperation_ = (
  editingDPT: DataPanelTemplate,
  payload: UpdateVisualizeOperationPayload,
) => {
  if (!editingDPT?.visualize_op.instructions) return;

  const operationType = payload.operationType;
  const vizInstructions = payload.visualizeInstructions;

  if (operationType === OPERATION_TYPES.VISUALIZE_TABLE) {
    editingDPT.visualize_op.instructions.VISUALIZE_TABLE =
      vizInstructions as VisualizeTableInstructions;
  } else if (
    V2_VIZ_INSTRUCTION_TYPE[operationType] === 'Two-dimensional' ||
    operationType === OPERATION_TYPES.VISUALIZE_VERTICAL_GROUPED_STACKED_BAR_V2 ||
    operationType === OPERATION_TYPES.VISUALIZE_HORIZONTAL_GROUPED_STACKED_BAR_V2
  ) {
    editingDPT.visualize_op.instructions.V2_TWO_DIMENSION_CHART =
      vizInstructions as V2TwoDimensionChartInstructions;
  } else if (
    operationType === OPERATION_TYPES.VISUALIZE_NUMBER_V2 ||
    operationType === OPERATION_TYPES.VISUALIZE_PROGRESS_V2
  ) {
    editingDPT.visualize_op.instructions.V2_KPI = vizInstructions as V2KPIChartInstructions;
  } else if (operationType === OPERATION_TYPES.VISUALIZE_BOX_PLOT_V2) {
    editingDPT.visualize_op.instructions.V2_BOX_PLOT = vizInstructions as V2BoxPlotInstructions;
  } else if (operationType === OPERATION_TYPES.VISUALIZE_SCATTER_PLOT_V2) {
    editingDPT.visualize_op.instructions.V2_SCATTER_PLOT =
      vizInstructions as V2ScatterPlotInstructions;
  } else if (
    operationType === OPERATION_TYPES.VISUALIZE_NUMBER_TREND_V2 ||
    operationType === OPERATION_TYPES.VISUALIZE_NUMBER_TREND_TEXT_PANEL
  ) {
    editingDPT.visualize_op.instructions.V2_KPI_TREND = vizInstructions as V2KPITrendInstructions;
  } else if (operationType === OPERATION_TYPES.VISUALIZE_PIVOT_TABLE) {
    editingDPT.visualize_op.instructions.VISUALIZE_PIVOT_TABLE =
      vizInstructions as VisualizePivotTableInstructions;
  } else if (operationType === OPERATION_TYPES.VISUALIZE_COLLAPSIBLE_LIST) {
    editingDPT.visualize_op.instructions.VISUALIZE_COLLAPSIBLE_LIST =
      vizInstructions as VisualizeCollapsibleListInstructions;
  } else if (operationType === OPERATION_TYPES.VISUALIZE_PIVOT_TABLE_V2) {
    editingDPT.visualize_op.instructions.VISUALIZE_PIVOT_TABLE_V2 =
      vizInstructions as V2PivotTableInstructions;
  } else if (
    operationType === OPERATION_TYPES.VISUALIZE_LOCATION_MARKER_MAP ||
    operationType === OPERATION_TYPES.VISUALIZE_DENSITY_MAP
  ) {
    editingDPT.visualize_op.instructions.VISUALIZE_GEOSPATIAL_CHART =
      vizInstructions as VisualizeGeospatialChartInstructions;
  }
};

const updateSelectedChart_ = (editingDPT: DataPanelTemplate, newOpType: OPERATION_TYPES) => {
  const operationType = editingDPT.visualize_op.operation_type;
  const visualizeInstructions = editingDPT.visualize_op.instructions;
  if (BAR_CHART_TYPES.has(operationType) && !BAR_CHART_TYPES.has(newOpType)) {
    if (visualizeInstructions.V2_TWO_DIMENSION_CHART?.categoryColumn)
      visualizeInstructions.V2_TWO_DIMENSION_CHART.categoryColumn.bucketSize = undefined;
  }

  // Calendar Heatmap has a fixed bucket size
  if (
    newOpType === OPERATION_TYPES.VISUALIZE_CALENDAR_HEATMAP &&
    visualizeInstructions.V2_TWO_DIMENSION_CHART?.categoryColumn
  )
    visualizeInstructions.V2_TWO_DIMENSION_CHART.categoryColumn.bucket = {
      id: PivotAgg.DATE_PART_MONTH_DAY,
    };

  if (
    GROUPED_STACKED_OPERATION_TYPES.has(operationType) &&
    !GROUPED_STACKED_OPERATION_TYPES.has(newOpType) &&
    editingDPT.visualize_op.instructions.V2_TWO_DIMENSION_CHART?.groupingColumn
  ) {
    editingDPT.visualize_op.instructions.V2_TWO_DIMENSION_CHART.groupingColumn = undefined;
  }

  // Since we're treating NUMBER_TREND_V2 operation types with hidden trend lines as the new
  // NUMBER_TREND_TEXT_PANEL operation type, provide a way for the user to select the
  // NUMBER_TREND_V2 type by clearing the set hideTrendLines property.
  if (
    operationType === OPERATION_TYPES.VISUALIZE_NUMBER_TREND_V2 &&
    visualizeInstructions.V2_KPI_TREND?.hideTrendLines &&
    newOpType === OPERATION_TYPES.VISUALIZE_NUMBER_TREND_V2
  ) {
    visualizeInstructions.V2_KPI_TREND.hideTrendLines = undefined;
  }

  // When switching to number trend this clears any potential lingering instruction
  // specific to text trend that are not available to number trend.
  if (
    newOpType === OPERATION_TYPES.VISUALIZE_NUMBER_TREND_V2 &&
    visualizeInstructions.V2_KPI_TREND?.periodComparisonRange &&
    TEXT_TREND_PERIOD_COMPARISON_RANGE_TYPES.has(
      visualizeInstructions.V2_KPI_TREND?.periodComparisonRange,
    )
  ) {
    visualizeInstructions.V2_KPI_TREND.periodComparisonRange = undefined;
    if (visualizeInstructions.V2_KPI_TREND.periodColumn) {
      visualizeInstructions.V2_KPI_TREND.periodColumn.comparisonInfo = undefined;
    }
  }

  editingDPT.visualize_op.operation_type = newOpType;
};

export const getEditPageDataPanels = createSelector(
  (state: DashboardEditConfigReducerState) => state.config?.data_panels,
  (state: DashboardEditConfigReducerState, getRegardlessOfEnabled?: boolean) =>
    state.config?.editable_section?.enabled || getRegardlessOfEnabled
      ? state.config?.editable_section?.charts
      : undefined,
  (dataPanels, editableCharts) => {
    const editableDps = Object.values(editableCharts ?? {}).map((chart) => chart.data_panel);
    return Object.values(dataPanels ?? {}).concat(editableDps);
  },
);

export const getDataPanelsPartitionedBySection = createSelector(
  (state: DashboardEditConfigReducerState) => state.config?.data_panels,
  (state: DashboardEditConfigReducerState) => state.config?.editable_section?.charts,
  (dataPanels, editableCharts) => ({
    mainDataPanels: Object.values(dataPanels ?? {}),
    editableSectionDataPanels: Object.values(editableCharts ?? {}).map((chart) => chart.data_panel),
  }),
);

export const getEditPageElements = createSelector(
  (state: DashboardEditConfigReducerState) => state.config?.elements,
  (state: DashboardEditConfigReducerState, getRegardlessOfEnabled?: boolean) =>
    state.config?.dashboard_page_layout_config?.stickyHeader?.enabled || getRegardlessOfEnabled,
  (elements, includeStickyHeader) => {
    const elementValues = Object.values<DashboardElement>(elements ?? {});
    if (includeStickyHeader) return elementValues;
    return elementValues.filter(
      (element) => element?.elemLocation !== DASHBOARD_LAYOUT_CONFIG.HEADER,
    );
  },
);

export const getDashboardElementsPartitionedBySection = createSelector(
  (state: DashboardEditConfigReducerState) => state.config?.elements,
  (elements) => {
    const [mainElements, stickyHeaderElements] = partition(
      Object.values(elements ?? {}),
      (element) => element?.elemLocation !== DASHBOARD_LAYOUT_CONFIG.HEADER,
    );
    return { mainElements, stickyHeaderElements };
  },
);

const getDatasetFromFidoId = (datasets: Record<string, Dataset> | undefined, fidoId: string) =>
  Object.values(datasets ?? {}).find((dataset) => dataset.fido_id === fidoId);

const getEditableSectionChart = (
  editableSection: EditableSectionConfig,
  chartId: string,
): EditableSectionChart => {
  const sourceChart = editableSection.charts[chartId];
  if (!sourceChart) {
    throw new Error('Specified editable chart not found');
  }

  return sourceChart;
};

const createDataPanelFromEditableSectionChart = (
  config: DashboardVersionConfig,
  sourceChart: EditableSectionChart,
  newChartId: string,
): DataPanelTemplate => {
  return {
    ...sourceChart.data_panel,
    id: newChartId,
    provided_id: namingUtils.getDefaultPanelProvidedId(
      sourceChart.data_panel.visualize_op.operation_type,
      config,
    ),
  };
};

const duplicateStickyHeaderElement = (
  config: DashboardVersionConfig,
  dashboardItem: DashboardElement,
  dashId: number,
  itemType: DASHBOARD_ELEMENT_TYPES,
  currentDashboardId: number,
) => {
  if (itemType === DASHBOARD_ELEMENT_TYPES.DATA_PANEL) return; // we shouldn't get here, but don't allow copying data panels in sticky header

  const stickyHeaderConfigOrder =
    config.dashboard_page_layout_config?.stickyHeader?.headerContentOrder;

  if (!stickyHeaderConfigOrder) return;

  const headerElementId = stickyHeaderConfigOrder?.find((id) => id === dashboardItem.id);
  if (!headerElementId) return;

  const newElementConfig = cloneDeep(dashboardItem);
  const newElementId = layoutUtils.duplicateDashboardElement({
    newElementConfig,
    dashId,
    config,
  });
  stickyHeaderConfigOrder.push(newElementId);

  dashboardUpdated(currentDashboardId);
};

const updateElement = (
  element: DashboardElement,
  newElementConfig?: DashboardElementConfig,
  newElementType?: DASHBOARD_ELEMENT_TYPES,
) => {
  if (newElementConfig) {
    element.config = newElementConfig;
  }
  if (newElementType) {
    if (
      element.config.operator &&
      !isValidOperationForFilter(element.config.operator, newElementType)
    ) {
      element.config.operator = undefined;
    }

    element.element_type = newElementType;
  }
};

const deleteElement = (
  config: DashboardVersionConfig,
  elementId: string,
  elementType: DASHBOARD_ELEMENT_TYPES,
) => {
  const elementNames: string[] = [];
  const idsToUnlink: string[] = [];
  if (elementType === DASHBOARD_ELEMENT_TYPES.CONTAINER) {
    config.elements = pickBy(config.elements, (elem) => {
      if (elem.id === elementId || elem.container_id === elementId) {
        elementNames.push(elem.name);
        return false;
      }
      return true;
    });
    config.data_panels = pickBy(config.data_panels, (panel) => {
      if (panel.container_id === elementId) {
        idsToUnlink.push(panel.id);
        return false;
      }
      return true;
    });
  } else {
    elementNames.push(config.elements[elementId].name);
    delete config.elements[elementId];

    removeElemFromStickyHeader(config.dashboard_page_layout_config, elementId);
  }

  removeDataPanelsFromLinks(idsToUnlink, config.elements);

  layoutUtils.updateSecondaryLayoutsAfterMainLayoutChange(config);
  updateUserInputFieldsWithDeletedElem(config, elementNames);
};

const moveElementToLocation = (
  config: DashboardVersionConfig,
  elementId: string,
  newLocation: DASHBOARD_LAYOUT_CONFIG,
) => {
  const element = config.elements[elementId];
  if (!element) return;

  config.dashboard_page_layout_config ??= {};

  const layoutConfig = config.dashboard_page_layout_config;
  layoutConfig.stickyHeader ??= {};
  layoutConfig.stickyHeader.headerContentOrder ??= [];

  if (
    (!element.elemLocation || element.elemLocation === DASHBOARD_LAYOUT_CONFIG.DASHBOARD_BODY) &&
    newLocation === DASHBOARD_LAYOUT_CONFIG.HEADER
  ) {
    layoutConfig.stickyHeader.headerContentOrder.push(elementId);
    config.elements[elementId].container_id = undefined;
  } else if (
    element.elemLocation === DASHBOARD_LAYOUT_CONFIG.HEADER &&
    newLocation === DASHBOARD_LAYOUT_CONFIG.DASHBOARD_BODY
  ) {
    const elemWasRemoved = removeElemFromStickyHeader(layoutConfig, elementId);
    if (elemWasRemoved) {
      const { w, h } = DRAGGING_ITEM_CONFIG_BY_TYPE[element.element_type];
      config.dashboard_layout.unshift({
        i: element.id,
        x: 0,
        y: 0,
        w,
        h,
      });

      layoutUtils.addItemToConfigLayouts(
        config,
        { newLayout: config.dashboard_layout },
        element.id,
      );
    }
  }

  element.elemLocation = newLocation;
};
