import { ComputedView, Namespace } from '@explo-tech/fido-api';

import { DataSource, ParentSchema } from 'actions/dataSourceActions';
import { Dataset } from 'actions/datasetActions';
import { ReportBuilderDataset } from 'actions/reportBuilderConfigActions';
import { AccessGroup } from 'actions/teamActions';
import { DashboardLayoutRequestInfo } from 'reducers/dashboardLayoutReducer';
import { FidoReducerState } from 'reducers/fidoReducer';
import { ReduxState } from 'reducers/rootReducer';
import * as RD from 'remotedata';

export const getDefaultDataSource = (
  namespaceId: string,
  schemas: ParentSchema[],
  dataSources: DataSource[],
  accessGroups: AccessGroup[],
) => {
  const currentSchema = schemas.find((s) => s.fido_id === namespaceId);

  if (!currentSchema) return null;

  // We're fetching a preview, so just use an arbitrary access group to get the data source
  // to use. When we do permissioning, this will likely change
  const defaultDataSource = accessGroups
    .map((accessGroup) =>
      dataSources.find(
        (d) =>
          d.parent_schema_id === currentSchema?.id &&
          accessGroup.default_data_source_ids?.includes(d.id),
      ),
    )
    .find((d) => !!d);

  if (defaultDataSource) return defaultDataSource;

  // this should never happen, but if a user somehow created a new schema without setting a default data source,
  // just use an arbitrary data source for that schema
  return dataSources.find((d) => d.parent_schema_id === currentSchema?.id) ?? null;
};

export const getDataSource = (
  {
    namespaceId,
    parentSchemaDataSourceMapping,
    dataSources,
    schemas,
    type,
  }: {
    namespaceId: string;
    parentSchemaDataSourceMapping?: Record<string, string>;
    dataSources?: DataSource[];
    schemas?: ParentSchema[];
    type: 'embedded' | 'app';
  },
  fido?: FidoReducerState,
) => {
  if (type === 'embedded') {
    return fido?.dataSourceMapping[namespaceId];
  }

  if (!schemas || !dataSources || !parentSchemaDataSourceMapping) return null;

  const schema = schemas.find((s) => s.fido_id === namespaceId);

  if (!schema) return null;

  const dataSourceId = parentSchemaDataSourceMapping[schema.id.toString()];
  return dataSources.find((d) => d.id.toString() === dataSourceId)?.fido_id;
};

export const getViewFromDatasetId = (
  computedViews: RD.ResponseData<ComputedViewWithIds[]>,
  datasets: Record<string, Dataset> | undefined,
  datasetId: string,
) => {
  const fidoId = Object.values(datasets ?? {}).find((d) => d.id === datasetId)?.fido_id;

  if (!fidoId || !RD.isSuccess(computedViews)) return null;

  return computedViews.data.find((view) => view.id === fidoId) ?? null;
};

/**
 * Returns true if the team is configured to use FIDO and the requested data source is configured to use FIDO.
 * This allows us to only use FIDO for certain data sources, even if the team is set to use FIDO, which helps with
 * rollout.
 */
export const useFidoForRequest = (
  requestInfo: Pick<
    DashboardLayoutRequestInfo,
    'useFido' | 'type' | 'parentSchemaDataSourceMapping'
  >,
  fido: FidoReducerState,
  dataset: Pick<Dataset | ReportBuilderDataset, 'parent_schema_id' | 'namespace_id'>,
  usePreferredExecutionMethod = false,
) => {
  if (!requestInfo.useFido) return false;

  if (requestInfo.type === 'app') {
    // this shouldn't be true, but for safety
    if (!requestInfo.parentSchemaDataSourceMapping) return false;
    const dataSourceId = requestInfo.parentSchemaDataSourceMapping[dataset.parent_schema_id];
    const dataSource = fido.embeddoDaos.dataSources?.find((d) => d.id.toString() === dataSourceId);
    const prefersEmbeddo =
      usePreferredExecutionMethod && dataSource?.preferred_execution_method === 'embeddo';

    return !prefersEmbeddo && !!dataSource?.fido_id;
  } else {
    const namespaceId = dataset.namespace_id ?? '';
    const dataSourceMappedToEmbeddo =
      fido.dataSourcePreferredExecutionMapping[namespaceId] === 'embeddo';
    const prefersEmbeddo = usePreferredExecutionMethod && dataSourceMappedToEmbeddo;
    return !prefersEmbeddo && !!fido.dataSourceMapping[namespaceId];
  }
};

export const shouldUseFidoForDefaultDataSource = (
  schemaId: string,
  {
    teamData,
    dataSource,
    currentUser,
  }: Pick<ReduxState, 'teamData' | 'dataSource' | 'currentUser'>,
) => {
  if (!currentUser.team?.feature_flags.use_fido) return;

  const dataSources = dataSource.dataSources;
  const team = teamData.data;

  if (!RD.isSuccess(dataSources) || !team) return false;

  // We're fetching a preview, so just use an arbitrary access group to get the data source
  // to use. When we do permissioning, this will likely change
  const defaultDataSourceIds = new Set();

  team.access_groups.forEach((accessGroup) =>
    accessGroup.default_data_source_ids.forEach((id) => defaultDataSourceIds.add(id)),
  );

  return !!(
    dataSources.data.find(
      (d) => d.parent_schema_id.toString() === schemaId && defaultDataSourceIds.has(d.id),
    ) ?? null
  )?.fido_id;
};

const fileNameRegex = new RegExp('[^a-zA-Z0-9-_. ]', 'g');
export const sanitizeFileNameForExports = (fileName: string) => {
  const processed = makeAbsentOrNotBlank(fileName);

  if (processed == null) return null;

  return processed.replaceAll(fileNameRegex, '');
};

export const makeAbsentOrNotBlank = (value: string | null | undefined) => {
  if (value == null) return null;

  return value.trim().length === 0 ? null : value;
};
// a little hackey, but the response type is always guaranteed to have id (and namespaceId) set, but the generated types don't
// extend this guarantee to the actual object in the response type
export type ComputedViewWithIds = ComputedView & { id: string; namespaceId: string };
export type NamespaceWithIds = Namespace & { id: string };
