import { configureStore } from '@reduxjs/toolkit';

import { dashboardDataMiddleware } from 'store/dashboardDataMiddleware';

import rootReducer from './reducers/rootReducer';

export default () => {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat([dashboardDataMiddleware]),
    preloadedState: {},
    devTools: false,
  });
};
