import {
  And1,
  Equal1,
  GreaterThan1,
  GreaterThanOrEqual1,
  Having,
  In1,
  LessThan1,
  LessThanOrEqual1,
  Not1,
  Null1,
  Or1,
  Property,
  StringContains1,
  StringPropertyValue,
} from '@explo-tech/fido-api';

import { SchemaDataType } from 'constants/dataConstants';
import { FilterOperationInstructions, FilterValueType } from 'constants/types';
import { FILTER_OPS_NEGATED, FilterOperator } from 'types/filterOperations';
import { getAggSuffix } from 'utils/V2ColUtils';
import { getFilterValue } from 'utils/fido/fidoInstructionShimUtils';

/**
 * Structurally the same as processFilter
 *
 * @param filterInfo
 * @param timezone
 * @param properties
 */
export const processHaving = (
  filterInfo: FilterOperationInstructions | undefined,
  timezone: string,
  properties: Property[],
) => {
  if (!filterInfo || filterInfo.filterClauses.length === 0) return null;

  const filters: Having[] = [];

  filterInfo.filterClauses.forEach(({ filterOperation, filterColumn, filterValue }) => {
    if (!filterOperation || !filterColumn) return null;

    // when we're done implementing this'll just be Having | null
    const having = getHaving(
      filterColumn.name,
      filterColumn.type,
      filterOperation.id,
      filterValue,
      timezone,
      properties,
    );
    if (!having) return null;

    if (FILTER_OPS_NEGATED.has(filterOperation.id)) {
      const not: Not1 = {
        '@type': 'not',
        value: having,
      };

      filters.push(not);
    } else {
      filters.push(having);
    }
  });

  if (filters.length === 1) {
    return filters[0];
  } else if (filters.length > 1) {
    if (filterInfo.matchOnAll) {
      const andFilter: And1 = { values: filters, '@type': 'and' };
      return andFilter;
    } else {
      const orFilter: Or1 = { values: filters, '@type': 'or' };
      return orFilter;
    }
  }
  return null;
};

/**
 * Structurally the same as getFilter
 *
 * @param columnName
 * @param columnType
 * @param operation
 * @param valueSource
 * @param timezone
 * @param properties
 */
export const getHaving = (
  columnName: string,
  columnType: string,
  operation: FilterOperator,
  valueSource: FilterValueType | undefined,
  timezone: string,
  properties: Property[],
) => {
  const value = getFilterValue(valueSource, operation, columnType as SchemaDataType, timezone);
  if (!value) return null;

  const property = properties.find(
    (p) => p.targetPropertyId === columnName || ('propertyId' in p && p.propertyId === columnName),
  );
  if (!property) return null;

  // There's this really stupid thing where propertyId is null for COUNT aggregate properties
  if (property['@type'] === 'aggregate' && !property.propertyId) {
    const aggSuffix = getAggSuffix(property.aggregation);
    property.propertyId = property.targetPropertyId?.replace(aggSuffix, '') || null;
  }

  switch (operation) {
    case FilterOperator.NUMBER_EQ:
    case FilterOperator.STRING_IS:
    case FilterOperator.NUMBER_NEQ:
    case FilterOperator.STRING_IS_NOT: {
      if (!value.value) return null;
      const filter: Equal1 = {
        '@type': 'eq',
        value: value.value,
        property,
      };
      return filter;
    }
    case FilterOperator.DATE_IS:
    case FilterOperator.DATE_IS_NOT:
    case FilterOperator.DATE_TODAY:
    case FilterOperator.DATE_IS_BETWEEN: {
      if (!value.values) return null;
      const startFilter: GreaterThanOrEqual1 = {
        '@type': 'gte',
        value: value.values[0],
        property,
      };
      const endFilter: LessThanOrEqual1 = {
        '@type': 'lte',
        value: value.values[1],
        property,
      };
      const and: And1 = {
        '@type': 'and',
        values: [startFilter, endFilter],
      };
      return and;
    }
    case FilterOperator.BOOLEAN_IS_TRUE:
    case FilterOperator.BOOLEAN_IS_FALSE: {
      // isVarReference shouldn't ever be set for these
      if (!value?.value) return null;
      const filter: Equal1 = {
        '@type': 'eq',
        value: value.value,
        property,
      };
      return filter;
    }
    case FilterOperator.STRING_IS_NOT_IN:
    case FilterOperator.STRING_IS_IN:
    case FilterOperator.NUMBER_IS_IN:
    case FilterOperator.NUMBER_IS_NOT_IN: {
      if (!value.values) return null;
      const filter: In1 = {
        '@type': 'in',
        values: value.values,
        property,
      };
      return filter;
    }
    case FilterOperator.NUMBER_LT:
    case FilterOperator.DATE_LT: {
      if (!value.value) return null;
      const filter: LessThan1 = {
        '@type': 'lt',
        value: value.value,
        property,
      };
      return filter;
    }
    case FilterOperator.NUMBER_GT:
    case FilterOperator.DATE_GT: {
      if (!value.value) return null;
      const filter: GreaterThan1 = {
        '@type': 'gt',
        value: value.value,
        property,
      };
      return filter;
    }
    case FilterOperator.NUMBER_LTE:
    case FilterOperator.DATE_LTE: {
      if (!value.value) return null;
      const filter: LessThanOrEqual1 = {
        '@type': 'lte',
        value: value.value,
        property,
      };
      return filter;
    }
    case FilterOperator.NUMBER_GTE:
    case FilterOperator.DATE_GTE: {
      if (!value.value) return null;
      const filter: GreaterThanOrEqual1 = {
        '@type': 'gte',
        value: value.value,
        property,
      };
      return filter;
    }
    case FilterOperator.NUMBER_IS_BETWEEN:
    case FilterOperator.DATE_PREVIOUS:
    case FilterOperator.DATE_NEXT: {
      if (!value?.values) return null;
      const startFilter: GreaterThanOrEqual1 = {
        '@type': 'gte',
        value: value.values[0],
        property,
      };
      const endFilter: LessThanOrEqual1 = {
        '@type': 'lte',
        value: value.values[1],
        property,
      };
      const and: And1 = {
        '@type': 'and',
        values: [startFilter, endFilter],
      };
      return and;
    }
    case FilterOperator.STRING_CONTAINS:
    case FilterOperator.STRING_DOES_NOT_CONTAIN: {
      if (!value.value) return null;
      const contains: StringContains1 = {
        '@type': 'str-ctns',
        value: value.value as StringPropertyValue,
        property,
        caseInsensitive: true,
      };
      return contains;
    }
    case FilterOperator.IS_EMPTY:
    case FilterOperator.IS_NOT_EMPTY: {
      const isNull: Null1 = {
        '@type': 'null',
        property,
      };
      return isNull;
    }
  }
};
