import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { CustomerEditableSection, CustomerEditableSectionLayout } from 'actions/dashboardActions';
import { updateDrilldownDataPanel } from 'actions/dataPanelTemplateAction';
import { embedFetchDashboardActions } from 'actions/embedActions';
import { EmbedCustomer, EmbedTeam } from 'actions/teamActions';
import { VisualizeTableInstructions } from 'constants/types';
import { DRILLDOWN_DATA_PANEL_ID } from 'reducers/dashboardEditConfigReducer';
import * as RD from 'remotedata';
import { CustomerDashboardState } from 'types/customerDashboardStateTypes';
import {
  DASHBOARD_RESPONSE_KEY_RENAME_MAP,
  DashboardVariable,
  DashboardVariableMap,
  DashboardVersionHierarchy,
  EmbedDashboard,
  EmbedDashboardHierarchy,
} from 'types/dashboardTypes';
import { DashboardVersion } from 'types/dashboardVersion';
import { DashboardVersionConfig } from 'types/dashboardVersionConfig';
import { DataPanelTemplate } from 'types/dataPanelTemplate';
import { deepMapKeys } from 'utils/objectUtils';
import { isElementHidden } from 'utils/variableUtils';

import { saveEditableSection } from './thunks/editableSectionThunks';
import { clearEmptyPanels } from './utils';

interface EmbedDashboardReducer {
  dashboard: RD.ResponseData<EmbedDashboard>;
  dashboardVersion: DashboardVersion | null;
  team: EmbedTeam | null;
  customer: EmbedCustomer | null;
  customerDashboardState: CustomerDashboardState | null;

  customerEditableSection: CustomerEditableSection | null; // Saved config specific to customer (including layout)
  currentEditableSectionLayout?: CustomerEditableSectionLayout[]; // Unsaved layout specific to customer
  hasEditableSectionChanges: boolean;

  // Render a single data panel
  dataPanelId?: string;

  // Keep track of elements/charts that should be hidden in dashboard
  hiddenElements: string[];
  dashboardHierarchy: RD.ResponseData<EmbedDashboardHierarchy>;
  versionHierarchy: RD.ResponseData<DashboardVersionHierarchy>;
}

const initialState: EmbedDashboardReducer = {
  dashboard: RD.Idle(),
  dashboardVersion: null,
  team: null,
  customer: null,
  customerEditableSection: null,
  hiddenElements: [],
  customerDashboardState: null,
  hasEditableSectionChanges: false,
  dashboardHierarchy: RD.Idle(),
  versionHierarchy: RD.Idle(),
};

function updateConfiguration(
  state: EmbedDashboardReducer,
  updateFunc: (configuration: DashboardVersionConfig) => void,
): void {
  if (!state.dashboardVersion) return;

  updateFunc(state.dashboardVersion.configuration);
}

const embedDashboardSlice = createSlice({
  name: 'embedDashboard',
  initialState,
  reducers: {
    embedUpdateTableColWidths: (state, { payload }: PayloadAction<EmbedUpdateColWidths>) => {
      updateConfiguration(state, (configuration) => {
        const editingDPT = configuration.data_panels[payload.dataPanelTemplateId];

        if (!editingDPT?.visualize_op.instructions) return;
        editingDPT.visualize_op.instructions.VISUALIZE_TABLE = payload.visualizeInstructions;
      });
    },
    updateEmbedEditableSectionLayout: (
      state,
      { payload }: PayloadAction<CustomerEditableSectionLayout[]>,
    ) => {
      state.currentEditableSectionLayout = payload;
      state.hasEditableSectionChanges = true;
    },
    discardEditableSectionLayoutChanges: (state) => {
      state.currentEditableSectionLayout = state.customerEditableSection?.config.layout;
      state.hasEditableSectionChanges = false;
    },
    setHiddenElements: (state, { payload }: PayloadAction<DashboardVariableMap>) => {
      state.hiddenElements = [];
      Object.entries(payload).forEach(([varName, value]) => {
        const [elemName, elemExtraVar] = varName.split('.');
        if (elemExtraVar === 'hide' && isElementHidden(value)) state.hiddenElements.push(elemName);
      });
    },
    toggleElementsVisibilities: (
      state,
      { payload }: PayloadAction<{ varName: string; value: DashboardVariable }[]>,
    ) => {
      for (const { varName, value } of payload) {
        const [elemName, elemExtraVar] = varName.split('.');
        if (elemExtraVar !== 'hide') return;

        if (isElementHidden(value)) {
          state.hiddenElements.push(elemName);
        } else {
          state.hiddenElements = state.hiddenElements.filter((element) => element !== elemName);
        }
      }
    },
    updateCustomerDashboardState: (state, { payload }: PayloadAction<CustomerDashboardState>) => {
      state.customerDashboardState = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(embedFetchDashboardActions.requestAction, () => {
        return { ...initialState, dashboard: RD.Loading() };
      })
      .addCase(embedFetchDashboardActions.successAction, (state, { payload }) => {
        state.dashboard = RD.Success(payload.dashboard_template);
        state.dashboardVersion = payload.dashboard_version;
        state.team = payload.team;
        state.customer = payload.customer;
        state.customerEditableSection = payload.customer_editable_section;
        state.customerDashboardState = payload.customer_dashboard_state;
        state.dataPanelId = payload.data_panel_id; // Should this go in interactions reducer as selectedItem?

        state.hasEditableSectionChanges = false;
        state.currentEditableSectionLayout =
          payload.customer_editable_section?.config.layout ??
          payload.dashboard_version.configuration.editable_section?.default_layout;

        if (state.dashboardVersion) clearEmptyPanels(state.dashboardVersion.configuration);

        if (payload?.dashboard_hierarchy) {
          state.dashboardHierarchy = RD.Success(
            deepMapKeys(
              payload.dashboard_hierarchy,
              (responseKey: string) =>
                DASHBOARD_RESPONSE_KEY_RENAME_MAP[responseKey] || responseKey,
            ) as EmbedDashboardHierarchy,
          );
          if (payload?.dashboard_version_hierarchy && payload?.dashboard_hierarchy) {
            state.versionHierarchy = RD.Success({
              dashboardVersions: {
                ...payload.dashboard_version_hierarchy,
              },
              rootDashboardId: parseInt(payload.dashboard_hierarchy.root_dashboard_id),
            });
          }
        }
      })
      .addCase(embedFetchDashboardActions.errorAction, (state, { payload }) => {
        state.dashboard = RD.Error(payload.errorData?.detail ?? 'Error loading dashboard');
      })
      .addCase(updateDrilldownDataPanel, (state, { payload }) => {
        updateConfiguration(state, (config) => {
          if (payload === undefined) {
            if (!(DRILLDOWN_DATA_PANEL_ID in config.data_panels)) return;
            delete config.data_panels[DRILLDOWN_DATA_PANEL_ID];
          } else {
            payload.dataPanel.id = DRILLDOWN_DATA_PANEL_ID;
            config.data_panels[DRILLDOWN_DATA_PANEL_ID] = payload.dataPanel as DataPanelTemplate;
          }
        });
      })
      .addCase(saveEditableSection.fulfilled, (state, { payload }) => {
        state.customerEditableSection = payload.customer_editable_section;
        state.hasEditableSectionChanges = false;
      });
  },
});

export const embedDashboardReducer = embedDashboardSlice.reducer;
export const {
  embedUpdateTableColWidths,
  updateEmbedEditableSectionLayout,
  discardEditableSectionLayoutChanges,
  setHiddenElements,
  toggleElementsVisibilities,
  updateCustomerDashboardState,
} = embedDashboardSlice.actions;

type EmbedUpdateColWidths = {
  visualizeInstructions: VisualizeTableInstructions;
  dataPanelTemplateId: string;
};
