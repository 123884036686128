import { CustomerEditableSectionLayout } from 'actions/dashboardActions';
import { ReduxState } from 'reducers/rootReducer';
import { getEditableDatasets } from 'reducers/selectors';
import { DashboardElement, DashboardVariableMap } from 'types/dashboardTypes';
import { DashboardVersionConfig, EditableSectionChart } from 'types/dashboardVersionConfig';
import { DataPanelTemplate } from 'types/dataPanelTemplate';
import { DataPanel, ResourceDataset } from 'types/exploResource';
import { isChartInstanceOfTemplate } from 'utils/editableSectionUtils';
import {
  filterHiddenElements,
  filterHiddenPanels,
  getDataPanelsDependentOnVariable,
} from 'utils/variableUtils';

export type DashboardConfig = {
  dataPanels: Record<string, DataPanel>;
  datasets: Record<string, ResourceDataset>;
  elements: DashboardElement[];

  // Sometimes they need to be accessed even if not in layout when editing
  editableSectionCharts?: Record<string, EditableSectionChart>;
};

/*
 * This function gets things needed for all the requests like data panels, datasets, dashboard elements
 * Each type of embed has it in different places so this is where the logic of where to get it is at
 * Once we remove architect hopefully we can find a way to make this more efficient
 */
export const getDashboardConfig = (state: ReduxState): DashboardConfig | undefined => {
  const { type } = state.dashboardLayout.requestInfo;
  // TODO - FIDO add config.views for embed

  // Edit dashboard page
  if (type === 'app') {
    const config = state.dashboardEditConfig?.config;
    if (!config) return;

    const editableSectionDps = getEditableSectionDps(
      config,
      state.dashboardEditConfig.editableSectionLayout,
      state.dashboardInteractions.interactionsInfo.isEditing,
    );
    const dataPanels = editableSectionDps
      ? { ...config.data_panels, ...editableSectionDps }
      : config.data_panels;

    return {
      dataPanels,
      elements: Object.values(config.elements),
      datasets: getEditableDatasets(state),
      editableSectionCharts: config.editable_section?.enabled
        ? config.editable_section.charts
        : undefined,
    };
    // Embedded dashboard
  } else if (type === 'embedded') {
    return getEmbedDashboardConfig(
      state.embedDashboard?.dashboardVersion?.configuration,
      state.embedDashboard.hiddenElements,
      state.embedDashboard.currentEditableSectionLayout,
    );
  }
};

export const getEmbedDashboardConfig = (
  config: DashboardVersionConfig | undefined,
  hiddenElements: string[],
  layout: CustomerEditableSectionLayout[] | undefined,
): DashboardConfig | undefined => {
  if (!config) return;
  const hiddenElementSet = new Set(hiddenElements);
  const editableSectionDps = getEditableSectionDpsEmbed(config, layout);
  const dataPanels = filterHiddenPanels(config.data_panels, hiddenElementSet);

  return {
    dataPanels: editableSectionDps ? { ...dataPanels, ...editableSectionDps } : dataPanels,
    elements: filterHiddenElements(config.elements, hiddenElementSet),
    datasets: config.datasets,
  };
};

export const getEditableSectionDps = (
  { editable_section: config }: DashboardVersionConfig,
  previewLayout: CustomerEditableSectionLayout[] | null,
  isEditing: boolean,
) => {
  if (!config?.enabled) return;

  const layout = isEditing ? config.default_layout : previewLayout ?? config.default_layout;
  return getEditableSectionDpsHelper(config.charts, layout);
};

const getEditableSectionDpsEmbed = (
  { editable_section: config }: DashboardVersionConfig,
  layout: CustomerEditableSectionLayout[] | undefined,
) => {
  if (!config?.enabled) return;
  return getEditableSectionDpsHelper(config.charts, layout);
};

export const getEditableSectionDataPanel = (
  chartId: string,
  dataPanel: DataPanelTemplate,
): DataPanelTemplate => ({
  ...dataPanel,
  id: chartId,
});

/**
 * Derives a list of data panels required by the charts in an Editable Section
 * These data panels are used to fetch data and inject variables
 */
const getEditableSectionDpsHelper = (
  charts: Record<string, EditableSectionChart>,
  layout: CustomerEditableSectionLayout[] | undefined,
): Record<string, DataPanelTemplate> | undefined => {
  if (!layout?.length) return;

  const dataPanels: Record<string, DataPanelTemplate> = {};
  Object.values(charts).forEach((chart) => {
    for (const chartLayout of layout) {
      if (isChartInstanceOfTemplate(chartLayout, chart))
        // If multiple charts inherit from the same data panel template,
        // each may load different data if the chart-specific variables are provided
        dataPanels[chartLayout.i] = getEditableSectionDataPanel(chartLayout.i, chart.data_panel);
    }
  });

  return dataPanels;
};

export const getDataPanelsDependentOnVars = (
  config: DashboardConfig,
  varNameSet: Set<string>,
  variables: DashboardVariableMap,
): string[] => {
  return getDataPanelsDependentOnVariable(
    config.dataPanels,
    config.datasets,
    config.elements,
    varNameSet,
    variables,
  ).map((dp) => dp.id);
};
