import { Aggregation as FidoAggregation } from '@explo-tech/fido-api';
import { v4 } from 'uuid';

import { ReportBuilderColConfig } from 'actions/reportBuilderConfigActions';
import {
  AGGREGATIONS_TYPES,
  DATE_PART_INPUT_AGG,
  DATE_TYPES,
  DatePartInputAgg,
  FLOAT,
  INTEGER_DATA_TYPE,
  NUMBER_TYPES,
} from 'constants/dataConstants';
import {
  Aggregation,
  DateDisplayFormat,
  DateDisplayOptions,
  StringDisplayFormat,
  StringDisplayOptions,
} from 'constants/types';
import { isCountAggregation } from 'pages/ReportBuilder/utils/viewUtils';
import {
  AggColInfo,
  BaseCol,
  ColumnConfigs,
  ColumnConfigsWithName,
  GroupByBucket,
  GroupByColInfo,
  GroupByColTypes,
} from 'types/columnTypes';
import { DatasetColumn, DatasetSchema } from 'types/datasets';
import { DATETIME_PART_PIVOT_AGG_SET, PIVOT_AGG_TYPES, PivotAgg } from 'types/dateRangeTypes';

import { getNextAggType } from './aggUtils';
import { titleCase } from './graphUtils';

/** Should be used whenever displaying a column name to a user */
export const getColDisplay = (col: BaseCol, columnConfigs?: ColumnConfigsWithName) =>
  columnConfigs?.[col.name]?.name || col.name;

// Put a unique id for name so that we can use it as a key for formatting
export const getFormulaAgg = (): AggColInfo => ({
  column: { name: v4(), type: FLOAT },
  agg: { id: Aggregation.FORMULA, formula: '' },
});

export const getNewAggCol = (
  newCol: DatasetColumn,
  currentAggCols: AggColInfo[] | undefined,
): AggColInfo | undefined => {
  const currAggs: Set<Aggregation> = new Set();
  currentAggCols?.forEach(({ column, agg }) => {
    if (newCol.name === column.name) currAggs.add(agg.id);
  });
  const agg = getNextAggType(newCol, currAggs);
  if (!agg) return;
  return { column: { name: newCol.name, type: newCol.type }, agg };
};

export const createGroupByCol = (newCol: DatasetColumn): GroupByColInfo => {
  return {
    column: { name: newCol.name, type: newCol.type },
    bucket: DATE_TYPES.has(newCol.type) ? { id: PivotAgg.DATE_MONTH } : undefined,
  };
};

export const getAggColName = ({ column, agg }: AggColInfo) =>
  getAggColNameBase(column.name, agg.id);

export const getAggSuffix = (aggId: Aggregation | FidoAggregation) => `_${aggId.toLowerCase()}`;

export const getAggColNameBase = (columnName: string, aggId: Aggregation) => {
  const aggSuffix = getAggSuffix(aggId);
  return `${columnName}${aggSuffix}`;
};

/** Should be used whenever displaying an agg's name to a user */
export const getAggColDisplay = (aggCol: AggColInfo, columnConfigs?: ColumnConfigsWithName) => {
  const { column, agg } = aggCol;
  if (agg.id === Aggregation.FORMULA) {
    const columnConfig = columnConfigs?.[getAggColName(aggCol)];
    return columnConfig?.name || titleCase(column.name);
  }

  const columnConfig = columnConfigs?.[column.name];
  const aggName = AGGREGATIONS_TYPES[agg.id].name;
  return `${columnConfig?.name || titleCase(column.name)} (${aggName})`;
};

/** Should be used to access column configs */
export const getAggColConfig = (aggCol: AggColInfo, columnConfigs?: ColumnConfigsWithName) => {
  const { column, agg } = aggCol;
  const configKey = agg.id === Aggregation.FORMULA ? getAggColName(aggCol) : column.name;
  return columnConfigs?.[configKey] as ReportBuilderColConfig;
};

export const getGroupByName = ({ column, bucket }: GroupByColTypes): string => {
  if (!bucket) return column.name;

  // Report builder has pivotAgg be the ID vs {id: pivotAgg} in explore
  const pivotAgg = getPivotIdFromBucket(bucket);
  if (pivotAgg === DATE_PART_INPUT_AGG) return column.name;

  const pivot = PIVOT_AGG_TYPES[pivotAgg];
  return `${pivot.name.toLowerCase().replaceAll(' ', '_')}_${column.name}`;
};

/** Should be used whenever displaying a groupby's name to a user */
export const getGroupByDisplay = (
  { column, bucket }: GroupByColTypes,
  columnConfigs?: ColumnConfigsWithName,
) => {
  const columnConfig = columnConfigs?.[column.name];
  const friendlyName = columnConfig?.name || titleCase(column.name);
  if (!bucket) return friendlyName;

  const pivotAgg = getPivotIdFromBucket(bucket);
  if (pivotAgg === DATE_PART_INPUT_AGG) return friendlyName;
  const pivot = PIVOT_AGG_TYPES[pivotAgg];
  return `${friendlyName} (${pivot.name})`;
};

const getPivotIdFromBucket = (bucket: GroupByBucket | PivotAgg): PivotAgg | DatePartInputAgg =>
  typeof bucket === 'string' ? bucket : bucket.id;

type PivotTableConfig = {
  schema: DatasetSchema;
  columnConfigs: ColumnConfigs;
  pivotColumns: string[] | undefined;
  groupByColumns: string[] | undefined;
};

export const getPivotSchemaAndConfigs = (
  colConfigs: ColumnConfigsWithName,
  rowGroupBys: GroupByColTypes[],
  columnGroupBys: GroupByColTypes[],
  aggregations: AggColInfo[],
  scatterPlotGrouping: GroupByColTypes | undefined,
  isExplorePivotTable = false,
): PivotTableConfig => {
  const newConfigs: ColumnConfigs = {};
  const schema: DatasetSchema = [];
  const pivotColumns: string[] = [];
  const groupByColumns: string[] = [];

  rowGroupBys.forEach((groupBy) => {
    const col = getSchemaColFromGroupBy(colConfigs, newConfigs, groupBy, isExplorePivotTable);
    schema.push(col);
    groupByColumns.push(col.name);
  });

  if (scatterPlotGrouping) {
    const col = getSchemaColFromGroupBy(
      colConfigs,
      newConfigs,
      scatterPlotGrouping,
      isExplorePivotTable,
    );
    schema.push(col);
  }

  columnGroupBys.forEach((groupBy) => {
    const col = getSchemaColFromGroupBy(colConfigs, newConfigs, groupBy, isExplorePivotTable);
    schema.push(col);
    pivotColumns.push(col.name);
  });
  aggregations.forEach((agg) => {
    const col = getSchemaColFromAgg(colConfigs, newConfigs, agg, isExplorePivotTable);
    schema.push(col);
  });

  return {
    schema,
    columnConfigs: newConfigs,
    pivotColumns: pivotColumns.length > 0 ? pivotColumns : undefined,
    groupByColumns: groupByColumns.length > 0 ? groupByColumns : undefined,
  };
};

export const getSchemaColFromGroupBy = (
  colConfigs: ColumnConfigsWithName,
  newConfigs: ColumnConfigs,
  groupBy: GroupByColTypes,
  isExplorePivotTable = false,
): DatasetColumn => {
  const { column, bucket } = groupBy;
  const name = getGroupByName(groupBy);

  const colConfigKey = isExplorePivotTable ? name : column.name;
  let formatting = colConfigs[colConfigKey]?.displayFormatting;
  if (bucket) {
    const pivotAgg = getPivotIdFromBucket(bucket);
    const dateFormatting = formatting as DateDisplayOptions | undefined;

    if (DATETIME_PART_PIVOT_AGG_SET.has(pivotAgg)) {
      formatting = { ...dateFormatting, datePartAgg: pivotAgg as PivotAgg };
    } else {
      let customFormat = 'MMM yyyy';
      if (pivotAgg === PivotAgg.DATE_DAY || pivotAgg === PivotAgg.DATE_WEEK)
        customFormat = 'MMM d, yyyy';
      else if (pivotAgg === PivotAgg.DATE_YEAR) customFormat = 'yyyy';
      else if (pivotAgg === PivotAgg.DATE_QUARTER) customFormat = 'Qq YYYY';
      else if (pivotAgg === PivotAgg.DATE_HOUR) customFormat = 'h a MMM d, yyyy';

      formatting = { ...dateFormatting, format: DateDisplayFormat.CUSTOM, customFormat };
    }
  }

  const stringDisplayOptions = formatting as StringDisplayOptions;
  if (
    stringDisplayOptions?.format === StringDisplayFormat.LINK &&
    stringDisplayOptions?.urlColumnName
  ) {
    formatting = {
      ...stringDisplayOptions,
      urlColumnName: getAggColName({
        column: { name: stringDisplayOptions.urlColumnName, type: column.type },
        agg: { id: Aggregation.MAX },
      }),
    };
  }

  newConfigs[name] = { displayFormatting: formatting };

  const friendlyName = isExplorePivotTable
    ? colConfigs[name]?.name
    : getGroupByDisplay(groupBy, colConfigs);

  return { name, friendly_name: friendlyName, type: column.type };
};

export const getSchemaColFromAgg = (
  colConfigs: ColumnConfigsWithName,
  newConfigs: ColumnConfigs,
  aggCol: AggColInfo,
  isExplorePivotTable = false,
): DatasetColumn => {
  const { column, agg } = aggCol;
  const name = getAggColName(aggCol);
  const friendlyName = isExplorePivotTable
    ? colConfigs[name].name
    : getAggColDisplay(aggCol, colConfigs);

  const isCount = isCountAggregation(aggCol.agg.id);
  const isDateOrNumber = NUMBER_TYPES.has(column.type) || DATE_TYPES.has(column.type);
  if (isExplorePivotTable) {
    newConfigs[name] = colConfigs[name];
  } else if (agg.id === Aggregation.FORMULA) {
    // Formulas have their own formatting
    newConfigs[name] = colConfigs[name];
  } else if (isDateOrNumber && !isCount) {
    // Numbers and dates should be formatted identically to the raw values, unless the agg is a count
    newConfigs[name] = colConfigs[column.name];
  }

  const type = isCount ? INTEGER_DATA_TYPE : agg.id === Aggregation.AVG ? FLOAT : column.type;
  return { name, type, friendly_name: friendlyName };
};
