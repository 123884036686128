import { SliderElementValue } from 'types/dashboardTypes';

export function getSliderThumbVariableName(thumbIndex: number) {
  return `thumb_${thumbIndex + 1}`;
}

export function convertArrayToSliderElementValue(valuesArray: number[]): SliderElementValue {
  const sliderElementValue: SliderElementValue = {};
  valuesArray.forEach((value, i) => {
    sliderElementValue[getSliderThumbVariableName(i)] = value;
  });
  return sliderElementValue;
}

export function convertSliderElementValueToArray(value: SliderElementValue): number[] {
  const numThumbs = Object.values(value).length;
  const arr: number[] = [];

  for (let i = 0; i < numThumbs; i++) {
    const thumbKey = getSliderThumbVariableName(i);
    arr.push(value[thumbKey]);
  }

  return arr;
}
