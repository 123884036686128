import cx from 'classnames';
import { Settings } from 'luxon';
import { FC, useEffect, useRef, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { CustomerReport } from 'actions/customerReportActions';
import { sprinkles } from 'components/ds';
import { Timezones } from 'constants/dashboardConstants';
import { GlobalStylesContext } from 'globalStyles';
import { getGlobalStyleVars } from 'globalStyles/getGlobalStyleVars/getGlobalStyleVars';
import { loadFonts } from 'globalStyles/utils';
import { ReportBuilderTab } from 'pages/ReportBuilder/HomeView/constants';
import { getOrDefault, hasNotReturned } from 'remotedata';
import {
  getCurrentTheme,
  setSelectedTab,
} from 'reportBuilderContent/reducers/embeddedReportBuilderReducer';
import { ReportBuilderReduxState } from 'reportBuilderContent/reducers/rootReducer';
import { ReportType } from 'reportBuilderContent/reducers/types';
import { openCustomerReportThunk } from 'reportBuilderContent/thunks/reportThunks';
import { keyBy } from 'utils/standard';

import { ReportBuilderHomeView } from './HomeView';
import * as styles from './ReportBuilder.css';
import { ReportBuilderPoller } from './ReportBuilderPoller';
import { ReportBuilderReportView } from './ReportView';
import { getRBStateFromURL } from './utils/navigationUtils';

Settings.defaultZone = Timezones.UTC;

type Props = { isIframe: boolean };

export const ReportBuilder: FC<Props> = ({ isIframe }) => {
  const dispatch = useDispatch();

  const {
    portalId,
    globalStyleConfig,
    fontConfig,
    team,
    selectedReport,
    reports,
    builtIns,
    favoriteBuiltIns,
  } = useSelector(
    (state: ReportBuilderReduxState) => ({
      selectedReport: state.reportEditing.selectedReport,
      portalId: state.embeddedReportBuilder.portalId,
      reports: state.embeddedReportBuilder.reports,
      favoriteBuiltIns: state.embeddedReportBuilder.favoriteBuiltIns,
      builtIns: state.embeddedReportBuilder.reportBuilderVersion?.config.builtInReports,
      team: state.embeddedReportBuilder.team,
      globalStyleConfig: getCurrentTheme(state.embeddedReportBuilder),
      fontConfig: state.embeddedReportBuilder.fontConfig,
    }),
    shallowEqual,
  );

  // reports should not trigger the URL parameters useEffect below
  const reportsRef = useRef<CustomerReport[]>(reports);
  useEffect(() => {
    reportsRef.current = reports;
  }, [reports]);

  // Set the current tab, report, and view based off URL parameters on initial load
  useEffect(() => {
    const navState = getRBStateFromURL();
    const reportsById = keyBy(reportsRef.current, 'id');
    if (
      navState.homeTab &&
      Object.values(ReportBuilderTab).includes(navState.homeTab as ReportBuilderTab)
    ) {
      dispatch(setSelectedTab({ tab: navState.homeTab as ReportBuilderTab }));
    } else if (navState.reportId && reportsById[navState.reportId]) {
      const report = reportsById[navState.reportId];
      let selectedViewId = navState.viewId;
      if (
        selectedViewId &&
        report.config.views?.find((view) => view.id === selectedViewId) === undefined
      ) {
        selectedViewId = undefined;
      }
      dispatch(openCustomerReportThunk({ ...report, viewId: selectedViewId }));
    }
  }, [dispatch]);

  useEffect(() => {
    if (!team?.payment_plan || hasNotReturned(fontConfig)) return;
    loadFonts(globalStyleConfig.text, getOrDefault(fontConfig, []), team.id);
  }, [globalStyleConfig, fontConfig, team]);

  // This component should only have built ins and customer report
  const renderReportBuilder = () => {
    if (selectedReport?.type === ReportType.BUILT_IN) {
      const builtIn = builtIns?.[selectedReport.id];
      if (builtIn)
        return (
          <ReportBuilderReportView
            description={builtIn.description}
            isIframe={isIframe}
            isStarred={favoriteBuiltIns.includes(builtIn.id)}
            rawReportName={builtIn.name}
            savedConfig={builtIn.config}
            selectedReport={selectedReport}
          />
        );
    } else if (selectedReport?.type === ReportType.CUSTOMER_REPORT) {
      const report = reports.find((report) => report.id === selectedReport.id);
      if (report)
        return (
          <ReportBuilderReportView
            isIframe={isIframe}
            isStarred={report.is_starred}
            rawReportName={report.name}
            savedConfig={report.config}
            selectedReport={selectedReport}
          />
        );
    }

    return <ReportBuilderHomeView />;
  };

  const globalStyleVars = useMemo(
    () => getGlobalStyleVars(globalStyleConfig, { forReportBuilder: true }),
    [globalStyleConfig],
  );

  return (
    <GlobalStylesContext.Provider
      value={{
        globalStyleConfig,
        globalStylesClassName: portalId,
        globalStyleVars,
      }}>
      <div
        className={cx(styles.container, sprinkles({ height: isIframe ? 'fillViewport' : 'fill' }))}
        id={portalId}
        style={globalStyleVars}>
        {renderReportBuilder()}
        <ReportBuilderPoller />
      </div>
    </GlobalStylesContext.Provider>
  );
};
