import { DateTime } from 'luxon';
import { FC, useCallback } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import '@explo-tech/react-grid-layout/css/styles.css';
import { PassedProps as ElementGridLayoutProps } from 'components/DashboardLayout/ElementGridLayout';
import { sprinkles } from 'components/ds';
import 'react-resizable/css/styles.css';
import { DashboardStates } from 'reducers/rootReducer';
import { getAreFiltersDisabled } from 'reducers/selectors';
import { setVariableThunk } from 'reducers/thunks/dashboardDataThunks/variableUpdateThunks';
import {
  ApplyFilterElemConfig,
  ContainerElemConfig,
  DASHBOARD_ELEMENT_TYPES,
  DashboardButtonElemConfig,
  DashboardElement,
  DashboardVariable,
  DashboardVariableMap,
  DateGroupToggleConfig,
  DateRangePickerElemConfig,
  DatepickerElemConfig,
  EventButtonElemConfig,
  ExportElemConfig,
  IframeElemConfig,
  ImageElemConfig,
  SelectElemConfig,
  SwitchElementConfig,
  TextDashboardElemConfig,
  TextInputElemConfig,
  TimePeriodDropdownElemConfig,
  VIEW_MODE,
  SliderElementConfig,
} from 'types/dashboardTypes';
import { isElemDisabledByDependency } from 'utils/dashboardUtils';
import { VariableSelectOptions, getMinAndMaxDates } from 'utils/extraVariableUtils';
import { getSelectFilterDatasetId } from 'utils/filterUtils';
import { assertNever } from 'utils/typeUtils';

import { DashboardApplyFilterElement } from './DashboardApplyFilterElement';
import DashboardContainerElement from './DashboardContainerElement';
import DashboardDateGroupSwitchElement from './DashboardDateGroupSwitchElement';
import {
  DashboardDateRangePickerElement,
  DateRangePickerValue,
} from './DashboardDateRangePickerElement';
import { DashboardEventElement } from './DashboardEventElement';
import { DashboardIframeElement } from './DashboardIframeElement';
import { DashboardImageElement } from './DashboardImageElement';
import { DashboardRefreshElement } from './DashboardRefreshElement';
import DashboardSliderElement from './DashboardSliderElement';
import DashboardTimePeriodDropdownElement from './DashboardTimePeriodDropdownElement';
import { DashboardDatepickerElement } from './dashboardDatepickerElement';
import { DashboardDropdownElement } from './dashboardDropdownElement';
import { DashboardExportElement } from './dashboardExportElement';
import { DashboardMultiSelectElement } from './dashboardMultiSelectElement';
import DashboardSwitchElement from './dashboardSwitchElement';
import { DashboardTextElement } from './dashboardTextElement';
import { DashboardTextInputElement } from './dashboardTextInputElement';
import DashboardToggleElement from './dashboardToggleElement';

type Props = {
  dashboardElement: DashboardElement;
  datasetNamesToId: Record<string, string>;
  elementNamesById: Record<string, string>;
  elementGridLayoutProps?: ElementGridLayoutProps;
  elementStartsOnRightSide: boolean;
  isInContainer?: boolean;
  isMobileView?: boolean;
  isResizing?: boolean;
  editableDashboard: boolean;
  variables: DashboardVariableMap;
};

export const DashboardElementView: FC<Props> = ({
  dashboardElement,
  elementNamesById,
  elementGridLayoutProps,
  elementStartsOnRightSide,
  variables,
  isResizing,
  isMobileView,
  editableDashboard,
  datasetNamesToId,
  isInContainer,
}) => {
  const dispatch = useDispatch();

  const { requestInfo, viewMode, blockedVariables, disableInputs, datasetData } = useSelector(
    (state: DashboardStates) => ({
      requestInfo: state.dashboardLayout.requestInfo,
      viewMode: state.dashboardInteractions.interactionsInfo.viewMode,
      blockedVariables: state.dashboardData.blockedVariables,
      disableInputs: getAreFiltersDisabled(state),
      datasetData: state.dashboardData.datasetData,
    }),
    shallowEqual,
  );
  const { timezone } = requestInfo;

  const value =
    dashboardElement.id in blockedVariables
      ? blockedVariables[dashboardElement.id]
      : variables[dashboardElement.name];

  const onNewValueSelect = useCallback(
    (newValue: DashboardVariable, options?: VariableSelectOptions) =>
      dispatch(
        setVariableThunk({
          varName: dashboardElement.name,
          value: newValue,
          elementId: dashboardElement.id,
          options,
        }),
      ),
    [dispatch, dashboardElement.name, dashboardElement.id],
  );

  const elemDisabled = isElemDisabledByDependency(
    dashboardElement.config,
    variables,
    elementNamesById,
  );
  const areInputsDisabled = disableInputs || elemDisabled;

  const renderElement = () => {
    switch (dashboardElement.element_type) {
      case DASHBOARD_ELEMENT_TYPES.TEXT:
        return (
          <DashboardTextElement
            config={dashboardElement.config as TextDashboardElemConfig}
            datasetData={datasetData}
            datasetNamesToId={datasetNamesToId}
            variables={variables}
          />
        );
      case DASHBOARD_ELEMENT_TYPES.SPACER:
        return <div className={editableDashboard ? editableSpacerClass : undefined} />;
      case DASHBOARD_ELEMENT_TYPES.DROPDOWN:
        return (
          <DashboardDropdownElement
            config={dashboardElement.config as SelectElemConfig}
            datasetData={datasetData}
            datasetNamesToId={datasetNamesToId}
            disabled={areInputsDisabled}
            isInContainer={isInContainer}
            onNewValueSelect={onNewValueSelect}
            openElementToLeft={elementStartsOnRightSide}
            value={value}
            variables={variables}
          />
        );
      case DASHBOARD_ELEMENT_TYPES.TIME_PERIOD_DROPDOWN:
        return (
          <DashboardTimePeriodDropdownElement
            config={dashboardElement.config as TimePeriodDropdownElemConfig}
            datasetNamesToId={datasetNamesToId}
            disabled={areInputsDisabled}
            isInContainer={isInContainer}
            onNewValueSelect={onNewValueSelect}
            openElementToLeft={elementStartsOnRightSide}
            value={value as number}
          />
        );
      case DASHBOARD_ELEMENT_TYPES.MULTISELECT:
        return (
          <DashboardMultiSelectElement
            config={dashboardElement.config as SelectElemConfig}
            datasetData={datasetData}
            datasetNamesToId={datasetNamesToId}
            disabled={areInputsDisabled}
            isInContainer={isInContainer}
            onNewValueSelect={onNewValueSelect}
            openElementToLeft={elementStartsOnRightSide}
            value={value}
            variables={variables}
          />
        );
      case DASHBOARD_ELEMENT_TYPES.DATEPICKER: {
        const { minDate, maxDate } = getMinAndMaxDates(variables, dashboardElement.name);
        return (
          <DashboardDatepickerElement
            config={dashboardElement.config as DatepickerElemConfig}
            datasetNamesToId={datasetNamesToId}
            disabled={areInputsDisabled}
            isInContainer={isInContainer}
            maxDate={maxDate}
            minDate={minDate}
            onNewValueSelect={onNewValueSelect}
            openElementToLeft={elementStartsOnRightSide}
            timezone={timezone}
            value={value as DateTime | string | undefined}
          />
        );
      }
      case DASHBOARD_ELEMENT_TYPES.DATE_RANGE_PICKER: {
        const { minDate, maxDate } = getMinAndMaxDates(variables, dashboardElement.name);
        return (
          <DashboardDateRangePickerElement
            isEmbed
            config={dashboardElement.config as DateRangePickerElemConfig}
            datasetNamesToId={datasetNamesToId}
            disabled={areInputsDisabled}
            isInContainer={isInContainer}
            maxDate={maxDate}
            minDate={minDate}
            onNewValueSelect={onNewValueSelect}
            openElementToLeft={elementStartsOnRightSide}
            timezone={timezone}
            value={value as DateRangePickerValue}
          />
        );
      }
      case DASHBOARD_ELEMENT_TYPES.EXPORT:
        return (
          <DashboardExportElement
            config={dashboardElement.config as ExportElemConfig}
            exportVars={variables}
            isSharedView={viewMode === VIEW_MODE.SHARE}
          />
        );
      case DASHBOARD_ELEMENT_TYPES.IMAGE:
        return (
          <DashboardImageElement
            config={dashboardElement.config as ImageElemConfig}
            datasetData={datasetData}
            datasetNamesToId={datasetNamesToId}
            variables={variables}
          />
        );
      case DASHBOARD_ELEMENT_TYPES.TOGGLE: {
        const config = dashboardElement.config as SelectElemConfig;
        let loading = false;
        const filterDatasetId = getSelectFilterDatasetId(config);
        if (filterDatasetId) {
          const data = datasetData[filterDatasetId];
          loading = data ? data.loading || !data.rows : true;
        }
        return (
          <DashboardToggleElement
            config={config}
            datasetData={datasetData}
            datasetNamesToId={datasetNamesToId}
            disabled={areInputsDisabled}
            loading={loading}
            onNewValueSelect={onNewValueSelect}
            value={value}
            variables={variables}
          />
        );
      }
      case DASHBOARD_ELEMENT_TYPES.DATE_GROUP_SWITCH:
        return (
          <DashboardDateGroupSwitchElement
            config={dashboardElement.config as DateGroupToggleConfig}
            datasetData={datasetData}
            datasetNamesToId={datasetNamesToId}
            disabled={areInputsDisabled}
            onNewValueSelect={onNewValueSelect}
            openElementToLeft={elementStartsOnRightSide}
            value={value}
          />
        );
      case DASHBOARD_ELEMENT_TYPES.SWITCH:
        return (
          <DashboardSwitchElement
            config={dashboardElement.config as SwitchElementConfig}
            datasetData={datasetData}
            datasetNamesToId={datasetNamesToId}
            disabled={areInputsDisabled}
            onNewValueSelect={onNewValueSelect}
            value={value}
            variables={variables}
          />
        );
      case DASHBOARD_ELEMENT_TYPES.SLIDER:
        return (
          <DashboardSliderElement
            config={dashboardElement.config as SliderElementConfig}
            datasetData={datasetData}
            datasetNamesToId={datasetNamesToId}
            disabled={areInputsDisabled}
            onNewValueSelect={onNewValueSelect}
            value={value}
            variables={variables}
          />
        );
      case DASHBOARD_ELEMENT_TYPES.CONTAINER:
        return elementGridLayoutProps ? (
          <DashboardContainerElement
            config={dashboardElement.config as ContainerElemConfig}
            elementGridLayoutProps={elementGridLayoutProps}
            id={dashboardElement.id}
            isMobileView={isMobileView}
            isResizing={!!isResizing}
            viewMode={viewMode}
          />
        ) : null;
      case DASHBOARD_ELEMENT_TYPES.APPLY_FILTER_BUTTON:
        return (
          <DashboardApplyFilterElement
            config={dashboardElement.config as ApplyFilterElemConfig}
            datasetNamesToId={datasetNamesToId}
          />
        );
      case DASHBOARD_ELEMENT_TYPES.REFRESH_BUTTON:
        return (
          <DashboardRefreshElement
            config={dashboardElement.config as DashboardButtonElemConfig}
            datasetNamesToId={datasetNamesToId}
          />
        );
      case DASHBOARD_ELEMENT_TYPES.EVENT_BUTTON:
        return (
          <DashboardEventElement
            config={dashboardElement.config as EventButtonElemConfig}
            datasetNamesToId={datasetNamesToId}
          />
        );
      case DASHBOARD_ELEMENT_TYPES.TEXT_INPUT:
        return (
          <DashboardTextInputElement
            config={dashboardElement.config as TextInputElemConfig}
            datasetNamesToId={datasetNamesToId}
            disabled={areInputsDisabled}
            onNewValueSelect={onNewValueSelect}
            value={value}
          />
        );
      case DASHBOARD_ELEMENT_TYPES.IFRAME:
        return (
          <DashboardIframeElement
            config={dashboardElement.config as IframeElemConfig}
            variables={variables}
          />
        );
      case DASHBOARD_ELEMENT_TYPES.DATA_PANEL:
        break;
      default:
        assertNever(dashboardElement.element_type);
    }
  };

  return <div className={sprinkles({ parentContainer: 'fill' })}>{renderElement()}</div>;
};

const editableSpacerClass = sprinkles({
  height: 'fill',
  border: 2,
  borderColor: 'outline',
  borderRadius: 4,
});
