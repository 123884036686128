import React from 'react';
import { render, unmountComponentAtNode } from 'react-dom';

import { loadStyles, unloadStyles } from 'utils/loadStyles';

import { MainApp } from './reactIndex';

import 'react-datepicker/dist/react-datepicker.css';

class ExploReportBuilder extends HTMLElement {
  static get observedAttributes() {
    return [
      'report-builder-token',
      'jwt',
      'variables',
      'locale-code',
      'timezone',
      'theme',
      'currency-code',
    ];
  }

  disconnectedCallback() {
    unmountComponentAtNode(this);

    unloadStyles();
  }

  createCollapsed(
    customerToken,
    reportBuilderEmbedId,
    environment,
    variables,
    embedJwt,
    localeCode,
    timezone,
    theme,
    currencyCode,
  ) {
    return React.createElement(MainApp, {
      customerToken,
      reportBuilderEmbedId,
      environment,
      variables,
      embedJwt,
      localeCode,
      timezone,
      theme,
      currencyCode,
    });
  }

  connectedCallback() {
    loadStyles(() => {
      const environment = this.getAttribute('environment');
      const reportBuilderToken = this.getAttribute('report-builder-token');
      const variables = this.getAttribute('variables');
      const embedJwt = this.getAttribute('jwt');
      const localeCode = this.getAttribute('locale-code');
      const timezone = this.getAttribute('timezone');
      const theme = this.getAttribute('theme');
      const currencyCode = this.getAttribute('currency-code');

      let customerToken, reportBuilderEmbedId;
      if (reportBuilderToken) {
        reportBuilderEmbedId = reportBuilderToken.split(':')[0];
        customerToken = reportBuilderToken.split(':')[1];
      } else if (embedJwt) {
        customerToken = null;
        reportBuilderEmbedId = null;
      }

      render(
        this.createCollapsed(
          customerToken,
          reportBuilderEmbedId,
          environment,
          variables,
          embedJwt,
          localeCode,
          timezone,
          theme,
          currencyCode,
        ),
        this,
      );
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  attributeChangedCallback(name, oldValue, newValue) {
    if (!oldValue) return;
    this.connectedCallback();
  }
}

if (!customElements.get('explo-report-builder'))
  customElements.define('explo-report-builder', ExploReportBuilder);
