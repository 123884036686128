import { DashboardLayoutRequestInfo } from 'reducers/dashboardLayoutReducer';

export const getDomain = () => process.env.REACT_APP_URL || 'https://app.explo.co/';

export const getEnvironment = () => {
  switch (process.env.REACT_APP_ENVIRONMENT) {
    case 'production-medme':
    case 'production':
    case 'production-secure':
      return 'production';
    case 'dev':
    case 'development':
    case 'experimental':
      return 'development';
    case 'preview':
    case 'staging':
    case 'staging-on-prem':
      return 'staging';
    default:
      // We choose to default to production so that any bugs won't affect prod environments
      return 'production';
  }
};

export const isJobQueueEnabledForEnvironment = () => {
  switch (process.env.REACT_APP_ENVIRONMENT) {
    case 'production':
    case 'preview':
    case 'staging':
      return true;
    // production-like-environments that don't have job queue set up
    case 'production-medme':
    case 'production-secure':
    case 'staging-on-prem':
    case 'dev':
    case 'experimental':
      return false;
    // local environments
    case 'development':
      return true;
    default:
      return false;
  }
};

export const isExternalFacingDashboard = () => {
  const isEmbedded = window.self !== window.top;
  const isShare = window.location.href.indexOf('/share/') > -1;
  const isPortal = window.location.href.indexOf('/portal') > -1;
  const isIframe = window.location.href.indexOf('/iframe') > -1;
  return isEmbedded || isShare || isPortal || isIframe;
};

export const isIframe = (requestInfo: DashboardLayoutRequestInfo) =>
  requestInfo.type === 'embedded' && requestInfo.embedType === 'iframe';

export const isEnvironmentSecure = () => !!process.env.REACT_APP_IS_SECURE;
