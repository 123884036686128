import cx from 'classnames';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FallbackProps } from 'react-error-boundary';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import ResizeObserver from 'react-resize-observer';
import { useIntersectionObserver } from 'usehooks-ts';

import { embedFetchDashboard } from 'actions/embedActions';
import DashboardLayout from 'components/DashboardLayout/DashboardLayout';
import { ErrorBoundary } from 'components/ErrorBoundary/ErrorBoundary';
import UsePageVisibility from 'components/HOCs/usePageVisibility';
import { sprinkles } from 'components/ds';
import { EmbedSpinner } from 'components/embed';
import { DASHBOARD_ROW_HEIGHT, MOBILE_BREAKPOINT_WIDTH } from 'constants/dashboardConstants';
import { DASHBOARD_CLASS_NAME } from 'constants/exportConstants';
import { REPORTED_ANALYTIC_ACTION_TYPES } from 'constants/types';
import { EmbedReduxState } from 'embeddedContent/reducers/rootReducer';
import { GlobalStylesProvider } from 'globalStyles';
import { GlobalStyleConfig } from 'globalStyles/types';
import { loadFonts } from 'globalStyles/utils';
import { DashboardLayoutRequestInfo } from 'reducers/dashboardLayoutReducer';
import {
  getCurrentTheme,
  setCustomStylesPageOverwrite,
  setDashboardTheme,
} from 'reducers/dashboardStylesReducer';
import { setHiddenElements, toggleElementsVisibilities } from 'reducers/embedDashboardReducer';
import { setEmbedType } from 'reducers/embeddedAuthReducer';
import { getArchetypeProperties, getEditableSectionLayout } from 'reducers/selectors';
import { sendDashboardReadyToLoadEventThunk } from 'reducers/thunks/customEventThunks';
import {
  SetVariablesData,
  setVariablesThunk,
} from 'reducers/thunks/dashboardDataThunks/variableUpdateThunks';
import * as RD from 'remotedata';
import { getOrDefault, hasNotReturned } from 'remotedata';
import { setUser } from 'telemetry/datadog';
import { pageView } from 'telemetry/exploAnalytics';
import { Metadata, useSetupAnalytics } from 'telemetry/telemetryUtils';
import { INPUT_EVENT, UpdateVariablePayload, UpdateVariablesPayload } from 'types/customEventTypes';
import { DashboardElement, DashboardVariableMap, PAGE_TYPE, VIEW_MODE } from 'types/dashboardTypes';
import { useCustomEvent } from 'utils/customEvent/useCustomEvent';
import { useJwtExpirationNotifier, useJwtRefreshListener } from 'utils/customEventUtils';
import { getLayoutFromDashboardVersionConfig } from 'utils/dashboardUtils';
import { useDashboardInteractionsInfo } from 'utils/hookUtils';
import { getLayoutHeightInRows } from 'utils/layoutResolverUtil';
import { loadLocale } from 'utils/localizationUtils';
import { maybeUpdateUrlParams as maybeUpdateUrlParamsFn } from 'utils/paramUtils';
import { showExploBranding } from 'utils/paymentPlanUtils';
import { mapKeys } from 'utils/standard';
import { getTimezone } from 'utils/timezoneUtils';
import {
  filterHiddenElements,
  filterHiddenPanels,
  getQueryVariables,
  getRefreshMinutes,
  getValueOrDefault,
  isVariableTrue,
  unwrapQueryVariablesForDashboardElements,
  wrapQueryVariablesForDashboardElements,
} from 'utils/variableUtils';

import { EmbeddedDashboardType, shouldUseUrlParams } from './types';

const SCREENSHOT_URL_PARAM = 'screenshot';

const ErrorFallback: FC<FallbackProps> = ({ error }) => (
  <div className={errorMessageStyle} role="alert">
    {error && error.message
      ? error.message
      : 'There was an error loading the dashboard. Please contact your support team for help.'}
  </div>
);

type Props = {
  dashboardEmbedId: string;
  viewMode: VIEW_MODE;
  embedType: EmbeddedDashboardType;
  customerToken: string | undefined;
  customStyles?: GlobalStyleConfig;
  embeddedVariables?: DashboardVariableMap;
  environment?: string;
  versionNumber?: number;
  isProduction?: string;
  isStrict?: boolean;
  refreshMinutes?: number;
  updateUrlParams?: boolean;
  localeCode?: string;
  currencyCode?: string;
  timezone?: string;
  analyticsProperties?: Metadata;
  embedJwt?: string;
  dashboardTheme?: string;
  disableEditableSectionEditing?: boolean;
  hideEditableSection?: boolean;
  hideEditableSectionEditControls?: boolean;
  id?: string;
};

const EmbeddedDashboardWrapper: FC<Props> = ({
  customStyles,
  dashboardTheme,
  ...dashboardProps
}) => {
  const dispatch = useDispatch();

  const { globalStyleConfig, fontConfig } = useSelector((state: EmbedReduxState) => ({
    fontConfig: state.dashboardStyles.fontConfig,
    globalStyleConfig: getCurrentTheme(state.dashboardStyles),
  }));
  const team = useSelector((state: EmbedReduxState) => state.embedDashboard.team);

  useEffect(() => {
    const theme = getValueOrDefault('theme', dashboardTheme);
    dispatch(setDashboardTheme(typeof theme === 'string' ? theme : undefined));
  }, [dashboardTheme, dispatch]);

  useEffect(() => {
    if (!customStyles) return;
    dispatch(setCustomStylesPageOverwrite(customStyles));
  }, [dispatch, customStyles]);

  useEffect(() => {
    if (!team || hasNotReturned(fontConfig)) return;
    loadFonts(globalStyleConfig.text, getOrDefault(fontConfig, []), team.id);
  }, [fontConfig, globalStyleConfig, team]);

  useEffect(() => {
    dispatch(setEmbedType(dashboardProps.embedType));
  }, [dashboardProps.embedType, dispatch]);

  useJwtRefreshListener();
  useJwtExpirationNotifier();

  const updateExploDashboardVariables = useCallback(
    ({ variables }: UpdateVariablesPayload) => {
      const newVariables: SetVariablesData['variables'] = [];
      if (Array.isArray(variables)) {
        for (const variable of variables) {
          if (typeof variable.varName === 'string') {
            newVariables.push({ varName: variable.varName, value: variable.value });
          }
        }
      }
      if (newVariables.length === 0) {
        return;
      }

      dispatch(toggleElementsVisibilities(newVariables));

      dispatch(
        setVariablesThunk({
          variables: newVariables,
          options: {
            // Variables may affect configs (x-axis group) - clear data to prevent rendering stale data with new configs
            clearData: true,
          },
        }),
      );
    },
    [dispatch],
  );

  const handleUpdateVariableEvent = useCallback(
    ({ detail: { varName, value } }: CustomEvent<UpdateVariablePayload>) => {
      updateExploDashboardVariables({ variables: [{ varName, value }] });
    },
    [updateExploDashboardVariables],
  );
  useCustomEvent(INPUT_EVENT.UPDATE_VARIABLE, handleUpdateVariableEvent);

  const handleUpdateVariablesEvent = useCallback(
    (event: CustomEvent<UpdateVariablesPayload>) => updateExploDashboardVariables(event.detail),
    [updateExploDashboardVariables],
  );
  useCustomEvent(INPUT_EVENT.UPDATE_VARIABLES, handleUpdateVariablesEvent);

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <GlobalStylesProvider globalStyleConfig={globalStyleConfig}>
        {(globalStylesClassName) => (
          <EmbeddedDashboard globalStylesClassName={globalStylesClassName} {...dashboardProps} />
        )}
      </GlobalStylesProvider>
    </ErrorBoundary>
  );
};

export default EmbeddedDashboardWrapper;

const EmbeddedDashboard: FC<Props & { globalStylesClassName: string }> = (props) => {
  const {
    viewMode,
    embedType,
    dashboardEmbedId,
    embeddedVariables,
    environment: environmentProp,
    versionNumber,
    isProduction: isProductionProp,
    isStrict,
    refreshMinutes,
    updateUrlParams,
    customerToken,
    localeCode,
    currencyCode,
    timezone: passedTimezone,
    globalStylesClassName,
    analyticsProperties,
    embedJwt,
    disableEditableSectionEditing,
    hideEditableSection,
    hideEditableSectionEditControls: passedHideEditableSectionEditControls,
    id: idProp,
  } = props;
  const dispatch = useDispatch();

  const containerRef = useRef<HTMLDivElement | null>(null);
  const observer = useIntersectionObserver(containerRef, {});

  const [width, setWidth] = useState<number | null>(null);

  const {
    archetypeProperties,
    dashboard,
    dashboardInteractions,
    dashboardVersion,
    team,
    customer,
    hiddenElements,
    editableSectionLayout,
    variables,
  } = useSelector(
    (state: EmbedReduxState) => ({
      archetypeProperties: getArchetypeProperties(state),
      dashboard: state.embedDashboard.dashboard,
      dashboardInteractions: state.dashboardInteractions,
      dashboardVersion: state.embedDashboard.dashboardVersion,
      team: state.embedDashboard.team,
      customer: state.embedDashboard.customer,
      hiddenElements: state.embedDashboard.hiddenElements,
      editableSectionLayout: getEditableSectionLayout(state),
      variables: state.dashboardData.variables,
    }),
    shallowEqual,
  );

  const urlVariables = useMemo(
    () => getQueryVariables(embedType, updateUrlParams),
    [embedType, updateUrlParams],
  );

  const modifiedUrlVariables = useMemo(() => {
    const elementNamesToElementMap = mapKeys(
      dashboardVersion?.configuration.elements ?? {},
      (element: DashboardElement) => element.name,
    );
    const unwrappedUrlVariables = unwrapQueryVariablesForDashboardElements(
      urlVariables,
      elementNamesToElementMap,
    );
    const wrappedUrlVariables = wrapQueryVariablesForDashboardElements(
      unwrappedUrlVariables,
      elementNamesToElementMap,
    );
    return wrappedUrlVariables;
  }, [urlVariables, dashboardVersion?.configuration.elements]);

  useEffect(() => {
    maybeUpdateUrlParamsFn(
      dashboardInteractions.interactionsInfo.updateUrlParams,
      archetypeProperties,
      { ...variables, ...modifiedUrlVariables },
    );
  }, [
    archetypeProperties,
    dashboardInteractions.interactionsInfo.updateUrlParams,
    modifiedUrlVariables,
    variables,
  ]);

  const isDashboardLoading = RD.isLoading(dashboard, true);
  const dashboardData = RD.isSuccess(dashboard) ? dashboard.data : null;

  const { version_number: dashboardVersionNumber, configuration: dashboardConfig } =
    dashboardVersion ?? {};

  const sendInitialPageView = useCallback(() => {
    switch (embedType) {
      case 'shared':
        if (isStrict) pageView('Shared Dashboard Page - Strict Format');
        else pageView('Shared Dashboard Page');
        break;
      case 'iframe':
        if (isStrict) pageView('Iframe Dashboard - Strict Format');
        else pageView('Iframe Dashboard');
        break;
      default:
        break;
    }
  }, [embedType, isStrict]);

  const { dashboardId, environment, isProduction } = useMemo(() => {
    const queryVariables = getQueryVariables(embedType, updateUrlParams);
    const environment = environmentProp || queryVariables['environment'];
    const isProduction = isProductionProp ?? queryVariables['is_production'];
    const dashboardId = idProp || queryVariables['id'];
    return { environment, isProduction, dashboardId };
  }, [idProp, embedType, updateUrlParams, environmentProp, isProductionProp]);

  const fetchDashboardData = useCallback(() => {
    dispatch(
      embedFetchDashboard(
        {
          customerToken,
          jwt: embedJwt,
          postData: {
            dashboard_embed_id: dashboardEmbedId,
            version_number: versionNumber,
            environment: environment as string | undefined,
            is_preview: embedType === 'preview',
          },
        },
        (data) => {
          setUser({
            endUserId: data.customer.id,
            endUserName: data.customer.name,
            teamId: data.team.id,
            teamName: data.team.team_name,
          });

          loadLocale({
            passedCurrencyCode: getValueOrDefault('currency_code', currencyCode),
            passedLocaleCode: getValueOrDefault('locale_code', localeCode),
            teamCurrencyCode: data.team.default_currency_code,
            teamLocaleCode: data.team.default_locale_code,
            useBrowserLocale: data.team.use_browser_locale,
          });
        },
      ),
    );
  }, [
    customerToken,
    embedJwt,
    dashboardEmbedId,
    dispatch,
    localeCode,
    versionNumber,
    environment,
    currencyCode,
    embedType,
  ]);

  const analyticsReady = useSetupAnalytics({
    pageViewEvent: getPageViewType(embedType),
    environment: environment as string | undefined,
    embedType,
    isProduction,
    analyticsProperties,
  });

  const onLoad = () => {
    sendInitialPageView();
    fetchDashboardData();
  };
  useEffect(onLoad, [
    dashboardEmbedId,
    customerToken,
    embedJwt,
    embeddedVariables,
    sendInitialPageView,
    fetchDashboardData,
  ]);

  const defaultVariables = useMemo(
    () => ({ ...modifiedUrlVariables, ...embeddedVariables }),
    [modifiedUrlVariables, embeddedVariables],
  );

  const hiddenElementSet = useMemo(() => new Set(hiddenElements), [hiddenElements]);

  useEffect(() => {
    dispatch(setHiddenElements(defaultVariables));
  }, [dispatch, defaultVariables]);

  const dashboardElements = useMemo(
    () => filterHiddenElements(dashboardConfig?.elements, hiddenElementSet),
    [dashboardConfig?.elements, hiddenElementSet],
  );

  const dataPanels = useMemo(
    () => Object.values(filterHiddenPanels(dashboardConfig?.data_panels, hiddenElementSet)),
    [dashboardConfig?.data_panels, hiddenElementSet],
  );

  const isVisible = UsePageVisibility();

  const dashboardTimezone = getTimezone(
    dashboardData?.default_timezone,
    getValueOrDefault('timezone', passedTimezone),
  );

  const disableEditingEditableSection = useMemo(
    () =>
      isVariableTrue(
        getValueOrDefault('disable-editable-section-editing', disableEditableSectionEditing),
      ),
    [disableEditableSectionEditing],
  );

  const hideEditableSectionEditControls = useMemo(
    () =>
      isVariableTrue(
        getValueOrDefault(
          'hide-editable-section-edit-controls',
          passedHideEditableSectionEditControls,
        ),
      ),
    [passedHideEditableSectionEditControls],
  );

  const shouldHideEditableSection = useMemo(
    () => isVariableTrue(getValueOrDefault('hide-editable-section', hideEditableSection)),
    [hideEditableSection],
  );

  const requestInfo: DashboardLayoutRequestInfo | undefined = useMemo(() => {
    if (!dashboardVersionNumber) return;
    return {
      type: 'embedded',
      embedType,
      resourceEmbedId: dashboardEmbedId,
      versionNumber: dashboardVersionNumber,
      timezone: dashboardTimezone,
      useJobQueue: team?.feature_flags?.use_job_queue ?? false,
      customerToken,
      jwt: embedJwt,
      environment: environment as string | undefined,
      useFido: team?.feature_flags?.use_fido ?? false,
      datasetMaxRows: team?.configuration?.dataset_max_rows,
      dataPanelMaxDataPoints: team?.configuration?.data_panel_max_data_points,
      enableEmailExports: team?.entitlements.enable_email_exports,
      enableScreenshotExports: team?.entitlements.enable_screenshot_exports,
    };
  }, [
    dashboardEmbedId,
    dashboardVersionNumber,
    dashboardTimezone,
    team,
    customerToken,
    embedJwt,
    environment,
    embedType,
  ]);

  // Update the width when the container changes being in view
  useEffect(() => {
    if (width === observer?.boundingClientRect.width) return;

    setWidth(observer?.boundingClientRect.width ?? null);
  }, [width, observer?.boundingClientRect.width]);

  const calculatedViewMode = useMemo(
    () =>
      viewMode !== VIEW_MODE.EMAIL &&
      viewMode !== VIEW_MODE.PDF &&
      width &&
      width < MOBILE_BREAKPOINT_WIDTH
        ? VIEW_MODE.MOBILE
        : viewMode,
    [viewMode, width],
  );

  const interactionsInfo = useDashboardInteractionsInfo({
    viewMode: calculatedViewMode,
    updateUrlParams: shouldUseUrlParams(embedType, updateUrlParams),
    disableFiltersWhileLoading: dashboardData?.disable_filters_while_loading,
    disableInputs: isStrict,
    supportEmail: team?.support_email ?? undefined,
    disableEditingEditableSection,
    hideEditableSectionEditControls,
    shouldPersistCustomerState: !!dashboardData?.should_persist_customer_state,
  });

  const hasError = !dashboardData || !dashboardConfig || !requestInfo || !customer;
  const didDashboardError = RD.isError(dashboard);
  const isLoading = isDashboardLoading || !analyticsReady;
  const isEditableSectionEnabled =
    team?.entitlements.enable_editable_section &&
    dashboardConfig?.editable_section?.enabled &&
    !shouldHideEditableSection;
  const dashboardLayout = dashboardConfig
    ? getLayoutFromDashboardVersionConfig(dashboardConfig, interactionsInfo.viewMode)
    : undefined;

  // Use a ref so estimatedHeight doesn't trigger sendDashboardReadyToLoadEvent multiple times
  const estimatedHeight = useRef(0);

  useEffect(() => {
    if (!dashboardLayout) return;

    const dashboardHeight = getLayoutHeightInRows(dashboardLayout) * DASHBOARD_ROW_HEIGHT;
    if (!isEditableSectionEnabled) {
      estimatedHeight.current = dashboardHeight;
      return;
    }

    // TODO: Figure out how to estimate this more accurately (depends on font size)
    const EDITABLE_SECTION_HEADER_HEIGHT = 50;
    const editableSectionRows = getLayoutHeightInRows(editableSectionLayout || []);
    const editableSectionHeight =
      editableSectionRows * DASHBOARD_ROW_HEIGHT + EDITABLE_SECTION_HEADER_HEIGHT;
    estimatedHeight.current = dashboardHeight + editableSectionHeight;
  }, [dashboardLayout, editableSectionLayout, isEditableSectionEnabled]);

  useEffect(() => {
    if (hasError || didDashboardError || isLoading) return;
    const id = dashboardId?.toString() || '';
    dispatch(sendDashboardReadyToLoadEventThunk(id, estimatedHeight.current));
  }, [hasError, isLoading, didDashboardError, dashboardId, dispatch]);

  if (didDashboardError) {
    return (
      <div className={errorMessageStyle} role="alert">
        {dashboard.error}
      </div>
    );
  } else if (isLoading || !dashboardLayout) {
    return <EmbedSpinner fillContainer size="xl" style={{ height: '100vh' }} />;
  } else if (hasError) {
    throw Error(
      'There was an error loading the dashboard. Please contact your support team for help.',
    );
  }

  const shouldFillViewport =
    dashboardConfig.dashboard_page_layout_config?.stickyHeader?.enabled &&
    (embedType === 'iframe' || embedType === 'shared') &&
    !modifiedUrlVariables?.[SCREENSHOT_URL_PARAM];

  return (
    <div
      className={cx(
        embedType === 'embedded' ? undefined : DASHBOARD_CLASS_NAME,
        sprinkles({ height: shouldFillViewport ? 'fillViewport' : 'fill' }),
        globalStylesClassName,
      )}
      ref={containerRef}>
      <DashboardLayout
        isViewOnly
        archetypeProperties={team?.archetype_properties}
        customer={customer}
        dashboardElements={dashboardElements}
        dashboardId={dashboardId?.toString()}
        dashboardLayout={dashboardLayout}
        dataPanels={dataPanels}
        datasets={dashboardConfig.datasets}
        editableSectionConfig={dashboardConfig.editable_section}
        isEditableSectionEnabled={isEditableSectionEnabled}
        isVisible={isVisible}
        pageLayoutConfig={dashboardConfig.dashboard_page_layout_config}
        pageType={embedType === 'shared' ? PAGE_TYPE.SHARED : PAGE_TYPE.EMBEDDED}
        params={dashboardConfig.params}
        refreshMinutes={getRefreshMinutes(refreshMinutes)}
        requestInfo={requestInfo}
        resourceId={dashboardData.id}
        showExploBranding={showExploBranding(team?.payment_plan)}
        teamName={team?.team_name}
        variablesDefaultValues={defaultVariables}
        width={width}
      />
      <ResizeObserver onResize={(resize) => setWidth(resize.width)} />
    </div>
  );
};

function getPageViewType(embedType: EmbeddedDashboardType) {
  switch (embedType) {
    case 'shared':
      return REPORTED_ANALYTIC_ACTION_TYPES.SHARED_DASHBOARD_PAGE_VIEWED;
    case 'portal':
      return REPORTED_ANALYTIC_ACTION_TYPES.PORTAL_DASHBOARD_PAGE_VIEWED;
    default:
      return REPORTED_ANALYTIC_ACTION_TYPES.DASHBOARD_PAGE_VIEWED;
  }
}

const errorMessageStyle = sprinkles({
  margin: 'sp2',
  padding: 'sp2',
  heading: 'h1',
  borderRadius: 8,
  backgroundColor: 'errorSubdued',
});
